import { isEqual, noop } from "lodash";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";
import { TimeHistogram, TraceOptions } from "../../../../models/ApiTypes";
import { SessionContext } from "../../../../contexts/SessionContext";
import { SettingsContext } from "../../../../contexts/SettingsContext";
import { numDefaultBinCount } from "../../../../Global";
import { useStatistics } from "../../../../hooks/UseStatistics";
import i18n from "../../../../i18n";
import { EventFilter } from "../../../../models/EventFilter";
import { Datastores } from "../../../../utils/Datastores";
import { Formatter, getTickValues } from "../../../../utils/Formatter";
import BarChartSelector from "../../../bar-chart-selector/BarChartSelector";
import DateTimeInput from "../../../datetime-input/DateTimeInput";
import Spinner from "../../../spinner/Spinner";
import Toast, { ToastTypes } from "../../../toast/Toast";
import { buildTimeFilter, getTimeFilterProps } from "../../../../utils/FilterBuilder";

type TimeFilterEditorPropsType = {
    options: TraceOptions;
    initialValue?: EventFilter;
    onUpdate?: (e: EventFilter | undefined) => void;
};
type TimeFilterEditorStateType = {
    start?: Date;
    end?: Date;
    requireStartInRange: boolean;
    requireEndInRange: boolean;
    exclude?: boolean;
};

export default function TimeFilterEditor(props: TimeFilterEditorPropsType) {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const [isInitializing, setIsInitializing] = useState(true);

    const [histogram, setHistogram] = useState<TimeHistogram>({ bins: [], startTimeCounts: [], endTimeCounts: [] });

    const [{ minDate, maxDate }] = useStatistics([]);

    const [state, setState] = useState<TimeFilterEditorStateType>(() => {
        const existing = getTimeFilterProps(props.initialValue);
        if (!existing)
            emitFilter(undefined);

        return {
            start: existing?.start !== undefined ? new Date(existing.start) : undefined,
            end: existing?.end !== undefined ? new Date(new Date(existing.end).getTime() - 1000) : undefined,
            requireStartInRange: existing?.requireStartInRange ?? true,
            requireEndInRange: existing?.requireEndInRange ?? true,
            exclude: props.initialValue?.caseTime?.exclude ?? existing?.exclude ?? false,
        };
    });

    const [subscriptionId] = useState<number>(() => { return Datastores.getTimeHistograms.getSubscriptionId(); });
    useEffect(() => {
        return () => { return Datastores.getTimeHistograms.cancelSubscription(subscriptionId); };
    }, []);

    useEffect(() => {
        if (minDate === undefined || maxDate === undefined)
            return;

        setIsInitializing(true);

        Datastores.getTimeHistograms.get({ ...props.options, nBins: numDefaultBinCount, binsToUse: getBins(numDefaultBinCount, minDate, maxDate) }, subscriptionId).then((result) => {
            setIsInitializing(false);

            const start = props.initialValue?.caseTime?.ge !== undefined ? new Date(props.initialValue.caseTime.ge) : minDate;
            const end = props.initialValue?.caseTime?.lt !== undefined ? new Date(props.initialValue.caseTime.lt) : maxDate;

            setHistogram(result);

            if (!state.start || !state.end) {
                const newState = { ...state, start, end };

                if (!isEqual(newState, state)) {
                    setState(newState);
                    emitFilter(undefined);
                }
            }
        }).catch(noop);
    }, [
        settings.apiRetry,
        settings.filters,
        minDate,
        maxDate,
    ]);

    useEffect(() => {
        // in case the initial value is undefined
        // we hit onUpdate to get rid of whatever has been submitted before
        if (props.initialValue === undefined && props.onUpdate)
            props.onUpdate(undefined);
    }, []);

    const xTicks = minDate && maxDate ? getTickValues(minDate.getTime() / 1000, maxDate.getTime() / 1000, 5, session.locale) : undefined;
    const histogramLabel = state.requireStartInRange && !state.requireEndInRange ? "startTimeCounts" : "endTimeCounts";

    const barData = getBarData(histogram);

    const brushDomain = [DateTime.fromJSDate(state.start ?? minDate ?? new Date()).toSeconds(), DateTime.fromJSDate(state.end ?? maxDate ?? new Date()).toSeconds()] as [number, number];

    return <>
        <Spinner isLoading={isInitializing} className="filterSpinner" />

        {!isInitializing && <div className="timeFilterEditor tabPage">
            <div className="timeForm">
                <div className="columnForm">
                    <label>
                        {i18n.t("filters.time.start")}
                    </label>
                    <DateTimeInput
                        onChange={(e) => { onUpdate({ ...state, start: e }); }}
                        value={state.start}
                        allowUndefined={false} />
                </div>

                <div className="columnForm">
                    <label>
                        {i18n.t("filters.time.end")}
                    </label>

                    <DateTimeInput
                        onChange={(e) => { onUpdate({ ...state, end: e }); }}
                        value={state.end}
                        allowUndefined={false} />
                </div>
                <div className="noselect mr">
                    <label className="df">
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={state.requireStartInRange}
                            id="checkbox-start-in-range"
                            data-testid="checkbox-start-in-range"
                            onChange={(e) => {
                                onUpdate({
                                    ...state,
                                    requireStartInRange: e.target.checked
                                });
                            }} />
                        <label htmlFor="checkbox-start-in-range" />
                        {i18n.t("filters.time.requireStartInRange")}
                    </label>
                </div>

                <div className="noselect">
                    <label className="df">
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={state.requireEndInRange}
                            id="checkbox-end-in-range"
                            data-testid="checkbox-end-in-range"
                            onChange={(e) => {
                                onUpdate({
                                    ...state,
                                    requireEndInRange: e.target.checked
                                });
                            }} />
                        <label htmlFor="checkbox-end-in-range" />
                        {i18n.t("filters.time.requireEndInRange")}
                    </label>
                </div>
                <Toast className="timeFilterEditorToast" type={ToastTypes.Info} visible={(state.start && state.end && state.start?.getTime() > state.end?.getTime()) || false}>
                    {i18n.t("filters.time.startAfterEnd")}
                </Toast>
            </div>
            <BarChartSelector
                data={barData}
                yLabel={i18n.t("common.caseCount").toString()}
                brushDomain={brushDomain}
                xDomain={[DateTime.fromJSDate(minDate!).toSeconds(), DateTime.fromJSDate(maxDate!).toSeconds()]}
                barLabels={histogram[histogramLabel].map(v => v === 0 ? "" : Formatter.formatNumber(v, 2, session.numberFormatLocale))}
                xTickValues={xTicks!.ticks}
                xTickFormatter={(value, idx) => {
                    const dt = DateTime.fromSeconds(value, { zone: "utc" }).toLocal();
                    return dt.toFormat(xTicks!.tickInterval.absoluteFormatter[idx > 0 ? 1 : 0](dt));
                }}
                yTickFormatter={(v) => Formatter.formatNumber(v, 2, session.numberFormatLocale)}
                onBrushDomainChange={onBrushDomainChange}
            />

            <div className="checkboxes">
                <label className="alignCheckboxes">
                    <div>
                        <input
                            type="checkbox"
                            className="checkbox"
                            id="checkbox-exclude"
                            data-testid="checkbox-exclude"
                            checked={!!state.exclude}
                            onChange={(e) => {
                                const newState = {
                                    ...state,
                                    exclude: e.target.checked
                                };
                                setState(newState);
                                emitFilter(newState);
                            }} />
                        <label htmlFor="checkbox-exclude" />
                    </div>
                    <div>
                        {i18n.t("filters.excludeProducts")}
                    </div>
                </label>
            </div>
        </div>}
    </>;

    function onBrushDomainChange(domain: [number, number]) {
        onUpdate({
            ...state,
            start: DateTime.fromSeconds(domain[0]).toJSDate(),
            end: DateTime.fromSeconds(domain[1]).toJSDate()
        });
    }

    function onUpdate(s: TimeFilterEditorStateType) {
        if (!minDate || !maxDate)
            return;

        const minSeconds = s.start ? Math.max(minDate.getTime() / 1000, DateTime.fromJSDate(s.start).toSeconds()) : minDate.getTime() / 1000;
        const maxSeconds = s.end ? Math.min(maxDate.getTime() / 1000, DateTime.fromJSDate(s.end).toSeconds()) : maxDate.getTime() / 1000;
        const newState = {
            ...s,
            start: DateTime.fromSeconds(minSeconds).toJSDate(),
            end: DateTime.fromSeconds(maxSeconds).toJSDate()
        };
        setState(newState);

        emitFilter(newState);
    }


    function emitFilter(s: TimeFilterEditorStateType | undefined) {
        if (!props.onUpdate)
            return;

        props.onUpdate(buildTimeFilter(s?.start, s?.end, s?.requireStartInRange === true, s?.requireEndInRange === true, s?.exclude === true));
    }


    function getBarData(histogram: TimeHistogram) {
        const counts = histogram[histogramLabel];
        const barData = Array.from({ length: counts.length }).map((v, i) => {
            return {
                x: (DateTime.fromISO(histogram.bins[i]).toSeconds() + DateTime.fromISO(histogram.bins[i + 1]).toSeconds()) / 2,
                y: counts[i]
            };
        });
        return barData;
    }
}

function getBins(binCount: number, minDate: Date, maxDate: Date) {
    const delta = (maxDate.getTime() - minDate.getTime());
    const bins: string[] = [];
    for (let i = 0; i < binCount; i++) {
        const factor = i / (binCount - 1);
        const date = new Date(minDate.getTime() + delta * factor);
        bins.push(date.toISOString());
    }

    return bins;
}
