import React, { useContext } from "react";
import { disableAllCalcOptions } from "../../models/ApiTypes";
import { ValueSpinner } from "../value-spinner/ValueSpinner";
import { SettingsContext } from "../../contexts/SettingsContext";
import { isCaseFilter } from "../../utils/FilterBuilder";
import { SessionContext } from "../../contexts/SessionContext";
import { useProductStats } from "../../hooks/UseProductStats";
import i18n from "../../i18n";
import { flatten, uniq } from "lodash";
import { useStatistics } from "../../hooks/UseStatistics";

export function ViewSubtitle() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const filters = settings?.previewFilters ?? settings.filters ?? [];
    // Obtain cases that are defined by explicit case filters
    const caseFilterCases = uniq(flatten(filters.map(f => isCaseFilter(f, session)).filter(f => f !== undefined)));

    const showCases = caseFilterCases?.length >= 1;

    const [stats, isStatsLoading] = useStatistics(undefined, {
        disable: !showCases,
    });

    const [allProductStats, , isAllProductStatsLoading] = useProductStats(false, { ...disableAllCalcOptions, eventFilters: [], limit: 0 }, { disable: showCases });
    const [productStats, , isProductStatsLoading] = useProductStats(false, { ...disableAllCalcOptions, limit: 1, }, { disable: showCases });

    const title = getSubtitle({
        hasCaseFilters: showCases,
        firstCase: caseFilterCases[0],
        firstProduct: productStats?.products[0]?.name,
        productCount: productStats?.log?.productCount,
        caseCount: stats.numFilteredTraces,
        allProductsCount: allProductStats?.log.productCount
    });

    const isLoading: boolean = title === undefined || isStatsLoading || isProductStatsLoading || isAllProductStatsLoading;

    return <div className="text viewSubtitleSpinner">
        <ValueSpinner isLoading={isLoading}>
            {title}
        </ValueSpinner>
    </div>;
}

export function getSubtitle(props: {
    hasCaseFilters: boolean,
    firstCase: string | undefined,
    firstProduct: string | undefined,
    productCount: number | undefined,
    caseCount: number | undefined,
    allProductsCount: number | undefined
}) {
    if (props.hasCaseFilters && props.caseCount === 1)
        return `${i18n.t("common.case")} ${props.firstCase}`;

    if (props.hasCaseFilters)
        // Fall back to showing the number of cases if we don't have exactly one order selected via case filter
        return i18n.t("common.numOrders", { count: props.caseCount ?? 0 }).toString();

    if (props.productCount === 1 && props.firstProduct !== undefined)
        return `${i18n.t("common.product")} ${props.firstProduct}`;

    if (props.allProductsCount !== undefined && props.productCount === props.allProductsCount)
        return i18n.t("common.allProducts").toString();

    return i18n.t("common.numProducts", { count: props.productCount ?? 0 }).toString();
}
