import React, { useContext, useEffect } from "react";
import { SessionContext, SessionType } from "../../contexts/SessionContext";
import { SettingsContext, SettingsType } from "../../contexts/SettingsContext";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { QuantityType, getAssignedQuantities } from "../../utils/Quantities";
import { getAllowedKpis, getKpiDefinition, getStatisticLabel, caseKpiControlsGetAllowedStatistics, graphKpiControlsGetAllowedStatistics } from "../../models/Kpi";
import i18n from "../../i18n";
import { viewSettingsInitialization } from "../../utils/Initializers";
import { buildControllerSpotlightId } from "../../utils/Utils";
import Dropdown from "../dropdown/Dropdown";
import { Spotlight } from "../spotlight/Spotlight";
import { QuantityDropdown } from "./KpiControlPrimitives";
import { AggregationTypes } from "../../contexts/ContextTypes";
import { KpiTypes, StatisticTypes } from "../../models/KpiTypes";
import { DeepPartial } from "../../utils/ObjectMerger";
import { set } from "lodash";
import { GroupingKeys } from "../../models/Dfg";

type KpiControlsProps = {
    /**
     * List of KPIs that should be available in the dropdown.
     */
    kpis: KpiTypes[],

    /**
     * Should be activated for bar chart controllers such that the y-axis info is added.
     */
    hasYAxis?: boolean,

    /**
     * Add a dropdown for the quantities.
     */
    addQuantityDropdown?: boolean,

    /**
     * Spotlight controller arguments.
     */
    spotlightArguments?: string[],

    /**
     * Hide statistics. The buttons for theses stats are not shown at all.
     */
    hideStatistics?: StatisticTypes[],

    /**
     * Subcontrols. Add some additional controls to the KPI controls.
     */
    subcontrols?: React.ReactNode,

    /**
     * True if Kpi Controls component is used in Products section (benchmarking tab across all dimensions).
     */
    isCaseStatistics?: boolean,

    /**
     * Labels for the kpi options. If omitted, these default to the labels from the KPI definitions
     */
    kpiOptionLabels?: { [key in KpiTypes]?: string },

    /**
     * Show indentation in the dropdown. Is handled as true by default.
     */
    hasIndentation?: boolean,

}

export function getValidKpiControlSettings(session: SessionType, settings: SettingsType, props: KpiControlsProps): DeepPartial<SettingsType> {
    const result: DeepPartial<SettingsType> = {};
    const allowedKpis = getAllowedKpis(session, settings, props.kpis, props.isCaseStatistics);
    const allowedStatistics = (props.isCaseStatistics ? caseKpiControlsGetAllowedStatistics(session, settings, settings.kpi.selectedKpi, settings.kpi.aggregation) : graphKpiControlsGetAllowedStatistics(settings.kpi.selectedKpi, settings.groupingKey, session, settings))
        ?.filter(s => {
            if (props.hideStatistics !== undefined)
                return !props.hideStatistics?.includes(s);
            return true;
        });

    if (allowedKpis && !allowedKpis.includes(settings.kpi.selectedKpi))
        set(result, "kpi.selectedKpi", allowedKpis[0]);

    if (allowedStatistics && !allowedStatistics.includes(settings.kpi.statistic))
        set(result, "kpi.statistic", allowedStatistics[0]);

    return result;
}

export function KpiControls(props: KpiControlsProps) {

    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const { trackEvent } = useMatomo();

    const quantities = getAssignedQuantities(session.project?.eventKeys, QuantityType.CaseYield, false);

    const allowedKpis = getAllowedKpis(session, settings, props.kpis, props.isCaseStatistics);

    useEffect(() => {
        // If oee is selected and grouping key is different then machine then switch to cycle time as oee is only available for grouping by machine
        if (settings.groupingKey !== GroupingKeys.Machine && settings.groupingKey !== GroupingKeys.MachineValueStream && settings.kpi.selectedKpi === KpiTypes.OverallEquipmentEffectiveness)
            settings.mergeSet({ kpi: { selectedKpi: KpiTypes.CycleTime } });

    }, [settings.groupingKey]);

    const kpiDropdownOptions = allowedKpis.map(v => {
        const kpiDefinition = getKpiDefinition(v, { session, settings});
        const labelKey = props.kpiOptionLabels?.[v] ?? kpiDefinition?.label ?? "";
        const kpiOption = { label: i18n.t(labelKey), value: v, indentationLevel: props.hasIndentation !== false ? kpiDefinition?.indentationLevel : undefined};
        if (kpiDefinition?.indentationLevel !== undefined && allowedKpis.length > 1 && props.hasIndentation !== false) 
            return { label: "", options: [kpiOption] };

        return kpiOption;
    });

    const allowedStatistics = (props.isCaseStatistics ? caseKpiControlsGetAllowedStatistics(session, settings, settings.kpi.selectedKpi, settings.kpi.aggregation) : graphKpiControlsGetAllowedStatistics(settings.kpi.selectedKpi, settings.groupingKey, session, settings))
        ?.filter(s => {
            if (props.hideStatistics !== undefined)
                return !props.hideStatistics?.includes(s);
            return true;
        });

    if (allowedStatistics && !allowedStatistics.includes(settings.kpi.statistic))
        queueMicrotask(() => {
            settings.set(viewSettingsInitialization(session, settings, undefined, {
                statistics: allowedStatistics,
            }));
        });

    return <div className={"section analyzedValues"}>
        <div className="title">
            {props.hasYAxis ? i18n.t("kpi.yAxisKpi") : i18n.t("common.kpi")}
            <Spotlight id={buildControllerSpotlightId(location.pathname, props.spotlightArguments ? ["kpi", ...props.spotlightArguments] : ["kpi"])} className="mls" />
        </div>
        <Dropdown
            options={kpiDropdownOptions}
            testId="dropdown-kpi"
            value={{ value: settings.kpi.selectedKpi, label: i18n.t(props.kpiOptionLabels?.[settings.kpi.selectedKpi] ?? getKpiDefinition(settings.kpi.selectedKpi, { session, settings })?.label ?? "") }}
            onChange={(e) => {
                const analyzedValue = e!.value as KpiTypes;
                settings.setKpiState({ selectedKpi: analyzedValue });

                trackEvent({
                    category: "Interactions",
                    action: "Analyzed value changed",
                    name: analyzedValue.toString(),
                });
            }}
            isSearchable={true}
        />
        <div className="buttons-2col mtLarge">
            {!props.hideStatistics?.includes(StatisticTypes.Mean) && <StatisticButton statistic={StatisticTypes.Mean} disabled={!allowedStatistics?.includes(StatisticTypes.Mean)} />}
            {!props.hideStatistics?.includes(StatisticTypes.Sum) && <StatisticButton statistic={StatisticTypes.Sum} disabled={!allowedStatistics?.includes(StatisticTypes.Sum)} />}
            {!props.hideStatistics?.includes(StatisticTypes.Median) && <StatisticButton statistic={StatisticTypes.Median} disabled={!allowedStatistics?.includes(StatisticTypes.Median)} />}
            {!props.hideStatistics?.includes(StatisticTypes.Variance) && <StatisticButton statistic={StatisticTypes.Variance} disabled={!allowedStatistics?.includes(StatisticTypes.Variance) || settings.kpi.aggregation !== AggregationTypes.Product} />}
        </div>
        {props.addQuantityDropdown && <QuantityDropdown quantities={quantities} />}
        {props.subcontrols !== undefined && props.subcontrols}
    </div>;

}


function StatisticButton(props: { statistic: StatisticTypes, disabled?: boolean }) {

    const settings = useContext(SettingsContext);
    return <button
        disabled={props.disabled}
        className={settings.kpi.statistic === props.statistic ? "active" : ""}
        id={`button-statistic-${props.statistic.toString().toLowerCase()}`}
        onClick={() => {
            settings.setKpiState({ statistic: props.statistic });
        }}>
        {getStatisticLabel(props.statistic)}
    </button>;
}
