import React, { useContext } from "react";
import { StatsRowValue, StatsRowValueSigned } from "../../components/stats-section/StatsRowValue";
import { StatsSection, StatsSubSection } from "../../components/stats-section/StatsSection";
import { SettingsContext } from "../../contexts/SettingsContext";
import { useCaseDeviation } from "../../hooks/UseCaseDeviation";
import { Formatter } from "../../utils/Formatter";
import { SessionContext } from "../../contexts/SessionContext";

export function SideStatisticsProjectDeviation() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const eventFilters = settings.previewFilters ?? settings.filters;

    const hasRoutings = session.project?.uploads?.routings !== undefined;

    const deviationOptions = {
        eventFilters: eventFilters,
        calculateTimeAndFreqStats: true,
    };

    // Disable all requests in case the project has routings available.
    // TODO: REMOVE THIS AGAIN ONCE THE REQUIRED ENDPOINTS SUPPORT ROUTINGS!
    const [allCases, allCasesLoading] = useCaseDeviation({ ...deviationOptions, limit: 0 }, { disable: hasRoutings });
    const [fastCases, fastCasesLoading] = useCaseDeviation({ ...deviationOptions, filterCaseDurationDeviations: "early", limit: 0 }, { disable: hasRoutings });
    const [slowCases, slowCasesLoading] = useCaseDeviation({ ...deviationOptions, filterCaseDurationDeviations: "late", limit: 0 }, { disable: hasRoutings });

    if (hasRoutings)
        return null;

    return <div data-testid="sidePanel">
        <StatsSection title="common.totalProcess" >

            <StatsSubSection title="workflows.planningDeviation.averageDeviationPercent">
                <StatsRowValueSigned label="common.allCases" isLoading={allCasesLoading} unit={Formatter.units.percent}
                    isHighlight={true}
                    value={allCases?.log?.deviation?.relativeToPlanned?.durationStatistics?.mean}
                />
                <StatsRowValueSigned label="workflows.planningDeviation.slowerThanPlanned" isLoading={slowCasesLoading} unit={Formatter.units.percent}
                    value={slowCases?.log?.deviation?.relativeToPlanned?.durationStatistics?.mean}
                />
                <StatsRowValueSigned label="workflows.planningDeviation.fasterThanPlanned" isLoading={fastCasesLoading} unit={Formatter.units.percent}
                    value={fastCases?.log?.deviation?.relativeToPlanned?.durationStatistics?.mean}
                />
            </StatsSubSection>

            <StatsSubSection title="workflows.planningDeviation.averageDeviationDuration">
                <StatsRowValueSigned label="common.allCases" isLoading={allCasesLoading} unit={Formatter.units.durationShort}
                    isHighlight={true}
                    value={allCases?.log?.deviation?.durationStatistics?.mean}
                />
                <StatsRowValueSigned label="workflows.planningDeviation.slowerThanPlanned" isLoading={slowCasesLoading} unit={Formatter.units.durationShort}
                    value={slowCases?.log?.deviation?.durationStatistics?.mean}
                />
                <StatsRowValueSigned label="workflows.planningDeviation.fasterThanPlanned" isLoading={fastCasesLoading} unit={Formatter.units.durationShort}
                    value={fastCases?.log?.deviation?.durationStatistics?.mean}
                />
            </StatsSubSection>

            <StatsSubSection title="common.caseCount">
                <StatsRowValue label="workflows.planningDeviation.slowerThanPlanned" isLoading={slowCasesLoading} unit={Formatter.units.number}
                    value={slowCases?.log?.count}
                />
                <StatsRowValue label="workflows.planningDeviation.fasterThanPlanned" isLoading={fastCasesLoading} unit={Formatter.units.number}
                    value={fastCases?.log?.count}
                />
            </StatsSubSection>

        </StatsSection>
    </div>;
}
