import React, { useMemo } from "react";
import i18n from "../../i18n";
import colors from "../../colors.json";
import ReactSwitch from "react-switch";
import Global from "../../Global";

export type SwitchProps = {
    className?: string;
    labelUnchecked: string;
    labelChecked: string;

    isChecked: boolean;
    isDisabled?: boolean;
    onChange: (isChecked: boolean) => void;
};

/**
 * For the sake of a consistent style, we've encapsulated the switch component with our favorite
 * settings here, similar to what we did with the <Dropdown> component. Enjoy!
 */
export function Switch(props: SwitchProps) {
    const id = useMemo(() => {
        if (Global.isRunningJestTest)
            return "switch-id";

        return `switch-${Math.random().toString()}`;
    }, []);

    return <div className={"twoOptions " + (props.className ? " " + props.className: "")}>
        <label htmlFor={id} dangerouslySetInnerHTML={{ __html: i18n.t(props.labelUnchecked) ?? "" }} />
        <ReactSwitch
            id={id}
            height={16}
            handleDiameter={13}
            width={32}
            checkedIcon={false}
            uncheckedIcon={false}
            offColor={colors["$gray-2"]}
            onColor={colors["$gray-2"]}
            checked={props.isChecked}
            disabled={props.isDisabled}
            onChange={(e: boolean) => {
                props.onChange(e);
            }}
        />
        <label htmlFor={id} dangerouslySetInnerHTML={{__html: i18n.t(props.labelChecked) ?? ""}} />
    </div>;
}
