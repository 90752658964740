import { useMatomo } from "@jonkoops/matomo-tracker-react";
import _ from "lodash";
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { SessionContext, SessionType, isOniqEmployee } from "../../contexts/SessionContext";
import { SettingsContext, SettingsType } from "../../contexts/SettingsContext";
import i18n from "../../i18n";
import { GroupingKeys } from "../../models/Dfg";
import { DeepPartial } from "../../utils/ObjectMerger";
import { isObjectCentricAvailable } from "../../utils/SettingsUtils";
import { buildControllerSpotlightId, classNames } from "../../utils/Utils";
import Dropdown from "../dropdown/Dropdown";
import { Spotlight } from "../spotlight/Spotlight";

export enum VisibilityOptions {
    Hidden,
    Disabled,
    Visible,
}

export type GroupingKeyOptions = Partial<{
    none: VisibilityOptions;
    machine: VisibilityOptions;
    location: VisibilityOptions;
    machineType: VisibilityOptions;
    objectType: VisibilityOptions;
    pass: VisibilityOptions;
}>

export type GroupingKeyControlProps = {
    secondaryGrouping?: VisibilityOptions;
    isXAxisLabel?: boolean;
    options?: GroupingKeyOptions;
    disableOrderSequenceCheckbox?: boolean
};

type SecondGroupOptions = {
    /**
     * The label that should be used for the grouping option.
     */
    label: string;

    /**
     * The grouping key that should be used for the grouping option.
     */
    value: GroupingKeys;

    /**
     * The preliminary label that should be checked for existence in the project event keys.
     */
    preliminaryLabel?: string;

    /**
     * Whether the activity value should be prefixed with the label.
     */
    addLabelAsPrefix?: boolean;

    /**
     * The label that should be used when multiple values are available.
     */
    nonUniqueLabel?: string;
}

export const allPossibleSecondGroups: SecondGroupOptions[] = [
    {
        label: "common.hide",
        value: GroupingKeys.None
    }, {
        label: "common.machine",
        value: GroupingKeys.Machine,
        preliminaryLabel: "machine"
    }, {
        label: "common.machineType",
        value: GroupingKeys.MachineType,
        preliminaryLabel: "machineType"
    }, {
        label: "common.location",
        value: GroupingKeys.Location,
        preliminaryLabel: "location"
    }, {
        label: "common.machine",
        value: GroupingKeys.MachineValueStream,
        preliminaryLabel: "machine"
    }, {
        label: "common.machineType",
        value: GroupingKeys.MachineTypeValueStream,
        preliminaryLabel: "machineType"
    }, {
        label: "common.location",
        value: GroupingKeys.LocationValueStream,
        preliminaryLabel: "location"
    }, {
        label: "common.material",
        value: GroupingKeys.ObjectType,
        preliminaryLabel: "objectType"
    }, {
        label: "common.material",
        value: GroupingKeys.ObjectTypeValueStream,
        preliminaryLabel: "objectType"
    }, {
        label: "common.orderSequence",
        value: GroupingKeys.PassValueStream,
        preliminaryLabel: "passId",
        addLabelAsPrefix: true
    }, {
        label: "common.product",
        value: GroupingKeys.Product,
        preliminaryLabel: "product",
        nonUniqueLabel: "common.multipleProducts"
    },
];

export const possibleSecondGroupsMap = [{
    groupingKey: GroupingKeys.None,
    possibleSecondGroups: allPossibleSecondGroups.filter(v => [GroupingKeys.None, GroupingKeys.Machine, GroupingKeys.Location, GroupingKeys.MachineType, GroupingKeys.Product].includes(v.value)),
    defaultSecondGroup: GroupingKeys.Machine
}, {
    groupingKey: GroupingKeys.NoneValueStream,
    possibleSecondGroups: allPossibleSecondGroups.filter(v => [GroupingKeys.None, GroupingKeys.Machine, GroupingKeys.Location, GroupingKeys.MachineType, GroupingKeys.PassValueStream, GroupingKeys.Product].includes(v.value)),
    defaultSecondGroup: GroupingKeys.Machine
}, {
    groupingKey: GroupingKeys.Machine,
    possibleSecondGroups: allPossibleSecondGroups.filter(v => [GroupingKeys.None, GroupingKeys.Location, GroupingKeys.MachineType, GroupingKeys.ObjectType, GroupingKeys.Product].includes(v.value)),
    defaultSecondGroup: GroupingKeys.MachineType
}, {
    groupingKey: GroupingKeys.MachineValueStream,
    possibleSecondGroups: allPossibleSecondGroups.filter(v => [GroupingKeys.None, GroupingKeys.Location, GroupingKeys.MachineType, GroupingKeys.ObjectType, GroupingKeys.PassValueStream, GroupingKeys.Product].includes(v.value)),
    defaultSecondGroup: GroupingKeys.Location
}, {
    groupingKey: GroupingKeys.MachineObjectType,
    possibleSecondGroups: allPossibleSecondGroups.filter(v => [GroupingKeys.None, GroupingKeys.Location, GroupingKeys.MachineType, GroupingKeys.ObjectType, GroupingKeys.Product].includes(v.value)),
    defaultSecondGroup: GroupingKeys.ObjectType
}, {
    groupingKey: GroupingKeys.MachineObjectTypeValueStream,
    possibleSecondGroups: allPossibleSecondGroups.filter(v => [GroupingKeys.None, GroupingKeys.Location, GroupingKeys.MachineType, GroupingKeys.ObjectType, GroupingKeys.PassValueStream, GroupingKeys.Product].includes(v.value)),
    defaultSecondGroup: GroupingKeys.ObjectType
}, {
    groupingKey: GroupingKeys.MachineTypeValueStream,
    possibleSecondGroups: allPossibleSecondGroups.filter(v => [GroupingKeys.None, GroupingKeys.PassValueStream, GroupingKeys.Product].includes(v.value)),
    defaultSecondGroup: GroupingKeys.PassValueStream
}, {
    groupingKey: GroupingKeys.LocationValueStream,
    possibleSecondGroups: allPossibleSecondGroups.filter(v => [GroupingKeys.None, GroupingKeys.PassValueStream, GroupingKeys.Product].includes(v.value)),
    defaultSecondGroup: GroupingKeys.PassValueStream
}, {
    groupingKey: GroupingKeys.MachineType,
    possibleSecondGroups: allPossibleSecondGroups.filter(v => [GroupingKeys.None, GroupingKeys.Product].includes(v.value)),
    defaultSecondGroup: GroupingKeys.Product
}, {
    groupingKey: GroupingKeys.Location,
    possibleSecondGroups: allPossibleSecondGroups.filter(v => [GroupingKeys.None, GroupingKeys.Product].includes(v.value)),
    defaultSecondGroup: GroupingKeys.Product
}];

export const valueStreamGroupingKeys = [GroupingKeys.NoneValueStream, GroupingKeys.MachineValueStream, GroupingKeys.LocationValueStream, GroupingKeys.MachineTypeValueStream, GroupingKeys.ObjectTypeValueStream, GroupingKeys.MachineObjectTypeValueStream, GroupingKeys.PassValueStream];

export const toValueStreamGroupingKeysMap = new Map<GroupingKeys, GroupingKeys>();
toValueStreamGroupingKeysMap.set(GroupingKeys.None, GroupingKeys.NoneValueStream);  // no equivalent value stream
toValueStreamGroupingKeysMap.set(GroupingKeys.Machine, GroupingKeys.MachineValueStream);
toValueStreamGroupingKeysMap.set(GroupingKeys.MachineType, GroupingKeys.MachineTypeValueStream);
toValueStreamGroupingKeysMap.set(GroupingKeys.Location, GroupingKeys.LocationValueStream);
toValueStreamGroupingKeysMap.set(GroupingKeys.ObjectType, GroupingKeys.ObjectTypeValueStream);
toValueStreamGroupingKeysMap.set(GroupingKeys.MachineObjectType, GroupingKeys.MachineObjectTypeValueStream);
toValueStreamGroupingKeysMap.set(GroupingKeys.PassValueStream, GroupingKeys.PassValueStream);

const fromValueStreamGroupingKeysMap = new Map<GroupingKeys, GroupingKeys>();
toValueStreamGroupingKeysMap.forEach((value, key) => {
    fromValueStreamGroupingKeysMap.set(value, key);
});



export function getValidSecondGroupingLevel(session: SessionType, settings: SettingsType, objectTypeVisibility?: VisibilityOptions): GroupingKeys {
    if (!session.project?.eventKeys)
        return settings.graph.secondGroupingLevel;

    const disableObjectGrouping = objectTypeVisibility === VisibilityOptions.Disabled || !isObjectCentricAvailable(session.project.eventKeys);

    const hideObjectGrouping = objectTypeVisibility === VisibilityOptions.Hidden || !session.objectValues?.length;

    const options = getOptions(settings, session, disableObjectGrouping, hideObjectGrouping, objectTypeVisibility).map(v => v.value);

    const secondGroupSetting = possibleSecondGroupsMap.find(v => v.groupingKey === settings.groupingKey);

    if (secondGroupSetting !== undefined && !options.includes(settings.graph.secondGroupingLevel)) {
        if (options.includes(secondGroupSetting.defaultSecondGroup))
            return secondGroupSetting.defaultSecondGroup;
        if (options.length > 1)
            return options[1] as GroupingKeys;

        return GroupingKeys.None;
    }

    return settings.graph.secondGroupingLevel;
}

export function getValidGroupingKeyControlSettings(session: SessionType, settings: SettingsType, props: GroupingKeyControlProps): DeepPartial<SettingsType> {
    const secondGroupingLevel = getValidSecondGroupingLevel(session, settings, props.options?.objectType);
    if (secondGroupingLevel !== undefined && settings.graph.secondGroupingLevel !== secondGroupingLevel)
        return {
            graph: {
                secondGroupingLevel: getValidSecondGroupingLevel(session, settings, props.options?.objectType),
            }
        };

    return {};
}

/**
 * Generic grouping controls section. Works on settings.groupingKey and settings.graph.nodeGrouping
 */
export function GroupingKeyControls(props: GroupingKeyControlProps) {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const { trackEvent } = useMatomo();

    const secondGroupingLevel = getValidSecondGroupingLevel(session, settings, props.options?.objectType);
    if (settings.graph.secondGroupingLevel !== secondGroupingLevel)
        queueMicrotask(() => settings.setGraph({ secondGroupingLevel }));

    const isOniqUser = isOniqEmployee(session);

    const disableObjectGrouping = props.options?.objectType === VisibilityOptions.Disabled || !isObjectCentricAvailable(session.project?.eventKeys);

    const hideObjectGrouping = props.options?.objectType === VisibilityOptions.Hidden || !session.objectValues?.length;

    const options = getOptions(settings, session, disableObjectGrouping, hideObjectGrouping, props.options?.objectType);

    const isValueStreamGrouping = valueStreamGroupingKeys.includes(settings.groupingKey);

    const location = useLocation();

    const isPerspectiveDependant = !location.pathname.includes("gantt");

    const spotlightDeps: string[] = ["grouping"];
    if (isPerspectiveDependant)
        spotlightDeps.push("case");


    return <div className="section grouping">
        <div className="title">
            {props.isXAxisLabel ? i18n.t("kpi.xAxisAggregation") : i18n.t("common.grouping")}
            <Spotlight id={buildControllerSpotlightId(location.pathname, spotlightDeps)} className="mls" />
        </div>

        {(isValueStreamGrouping) && <div className="buttons-2col">
            <button id="button-grouping-valuestream-machine" hidden={props?.options?.machine === VisibilityOptions.Hidden} disabled={session.project?.eventKeys?.machine === undefined || props.options?.machine === VisibilityOptions.Disabled} onClick={() => { settings.graph.secondGroupingLevel === GroupingKeys.ObjectType ? setGroup(GroupingKeys.MachineObjectTypeValueStream) : setGroup(GroupingKeys.MachineValueStream); }} className={[GroupingKeys.MachineValueStream, GroupingKeys.MachineObjectTypeValueStream].includes(settings.groupingKey) ? "active" : ""}>{i18n.t("common.machine")}</button>
            <button id="button-grouping-valuestream-location" hidden={props?.options?.location === VisibilityOptions.Hidden} disabled={session.project?.eventKeys?.location === undefined || props.options?.location === VisibilityOptions.Disabled} onClick={() => { setGroup(GroupingKeys.LocationValueStream); }} className={settings.groupingKey === GroupingKeys.LocationValueStream ? "active" : ""}>{i18n.t("common.location")}</button>
            <button id="button-grouping-valuestream-machinetype" hidden={props?.options?.machineType === VisibilityOptions.Hidden} disabled={session.project?.eventKeys?.machineType === undefined || props.options?.machineType === VisibilityOptions.Disabled} onClick={() => { setGroup(GroupingKeys.MachineTypeValueStream); }} className={settings.groupingKey === GroupingKeys.MachineTypeValueStream ? "active" : ""}>{i18n.t("common.machineType")}</button>
            <button id="button-grouping-valuestream-pass" hidden={props?.options?.pass === VisibilityOptions.Hidden} disabled={session.project?.eventKeys?.passId === undefined || props.options?.pass === VisibilityOptions.Disabled} onClick={() => { setGroup(GroupingKeys.PassValueStream); }} className={settings.groupingKey === GroupingKeys.PassValueStream ? "active" : ""}>{i18n.t("common.orderSequence")}</button>
            <button id="button-grouping-valuestream-none" hidden={props?.options?.none === VisibilityOptions.Hidden} disabled={props.options?.none === VisibilityOptions.Disabled} onClick={() => { settings.set({ groupingKey: GroupingKeys.NoneValueStream, graph: { ...settings.graph, secondGroupingLevel: GroupingKeys.Machine } }); }} className={settings.groupingKey === GroupingKeys.NoneValueStream ? "active" : ""}>{i18n.t("common.confirmation")}</button>
            {isOniqUser && <button id="button-grouping-valuestream-objectType" hidden={hideObjectGrouping || session.project?.eventKeys?.objectType === undefined} disabled={disableObjectGrouping} onClick={() => { setGroup(GroupingKeys.ObjectTypeValueStream); }} className={settings.groupingKey === GroupingKeys.ObjectTypeValueStream ? "active" : ""}>{i18n.t("common.material")}</button>}
        </div>}
        {!isValueStreamGrouping && <div className="buttons-2col">
            <button id="button-grouping-machine" hidden={props?.options?.machine === VisibilityOptions.Hidden} disabled={session.project?.eventKeys?.machine === undefined || props.options?.machine === VisibilityOptions.Disabled} onClick={() => { settings.graph.secondGroupingLevel === GroupingKeys.ObjectType ? setGroup(GroupingKeys.MachineObjectType) : setGroup(GroupingKeys.Machine); }} className={[GroupingKeys.Machine, GroupingKeys.MachineObjectType].includes(settings.groupingKey) ? "active" : ""}>{i18n.t("common.machine")}</button>
            <button id="button-grouping-location" hidden={props?.options?.location === VisibilityOptions.Hidden} disabled={session.project?.eventKeys?.location === undefined || props.options?.location === VisibilityOptions.Disabled} onClick={() => { setGroup(GroupingKeys.Location); }} className={settings.groupingKey === GroupingKeys.Location ? "active" : ""}>{i18n.t("common.location")}</button>
            <button id="button-grouping-machinetype" hidden={props?.options?.machineType === VisibilityOptions.Hidden} disabled={session.project?.eventKeys?.machineType === undefined || props.options?.machineType === VisibilityOptions.Disabled} onClick={() => { setGroup(GroupingKeys.MachineType); }} className={settings.groupingKey === GroupingKeys.MachineType ? "active" : ""}>{i18n.t("common.machineType")}</button>
            <button id="button-grouping-pass" hidden={props?.options?.pass === VisibilityOptions.Hidden} disabled={session.project?.eventKeys?.passId === undefined || props.options?.pass === VisibilityOptions.Disabled} onClick={() => { setGroup(GroupingKeys.PassValueStream); }} className={settings.groupingKey === GroupingKeys.PassValueStream ? "active" : ""}>{i18n.t("common.orderSequence")}</button>
            <button id="button-grouping-none" hidden={props?.options?.none === VisibilityOptions.Hidden} disabled={props.options?.none === VisibilityOptions.Disabled} onClick={() => { settings.set({ groupingKey: GroupingKeys.None, graph: { ...settings.graph, secondGroupingLevel: GroupingKeys.Machine } }); }} className={settings.groupingKey === GroupingKeys.None ? "active" : ""}>{i18n.t("common.confirmation")}</button>
            {isOniqUser && <button id="button-grouping-objectType" hidden={hideObjectGrouping || session.project?.eventKeys?.objectType === undefined} disabled={disableObjectGrouping} onClick={() => { setGroup(GroupingKeys.ObjectType); }} className={settings.groupingKey === GroupingKeys.ObjectType ? "active" : ""}>{i18n.t("common.material")}</button>}
        </div>}

        <ValueStreamSwitch disabled={props.disableOrderSequenceCheckbox} />

        {props.secondaryGrouping !== VisibilityOptions.Hidden && (options ?? []).length > 1 && <div className="buttons-1col mtLarge">
            <label>{i18n.t("common.secondGrouping")}</label>
            <Dropdown
                testId="dropdown-secondGrouping"
                className="secondaryGrouping"
                value={(options ?? []).find((v) => {
                    return v.value === secondGroupingLevel.toString();
                }) ?? (options ?? [])[0]}
                options={options ?? []}
                onChange={(option, action) => {
                    if (option && action.action === "select-option" && option.value !== undefined && option.value !== settings.graph.secondGroupingLevel) {
                        const newSettings: DeepPartial<SettingsType> = { graph: { secondGroupingLevel: option.value as GroupingKeys }, groupingKey: settings.groupingKey };
                        // When we are switching the second grouping layer for machines to material (objectType), we also need to change the grouping key.
                        if (option.value === GroupingKeys.ObjectType && [GroupingKeys.Machine, GroupingKeys.MachineValueStream].includes(settings.groupingKey))
                            newSettings.groupingKey = isValueStreamGrouping ? GroupingKeys.MachineObjectTypeValueStream : GroupingKeys.MachineObjectType;
                        // When we are switching the second grouping layer and are in objectType grouping, we also need to change the grouping key.
                        if ([GroupingKeys.MachineObjectType, GroupingKeys.MachineObjectTypeValueStream].includes(settings.groupingKey) && option.value !== GroupingKeys.ObjectType)
                            newSettings.groupingKey = isValueStreamGrouping ? GroupingKeys.MachineValueStream : GroupingKeys.Machine;
                        settings.mergeSet(newSettings);
                    }
                }}
            />
        </div>}
    </div>;

    function setGroup(groupingKey: GroupingKeys) {
        if (settings.groupingKey === groupingKey)
            return;

        const secondGroupingLevel = getValidSecondGroupingLevel(session, { ...settings, groupingKey }, props.options?.objectType);

        settings.set({
            groupingKey,
            graph: { ...settings.graph, secondGroupingLevel },
        });

        // Track manual grouping change
        trackEvent({
            category: "Interactions",
            action: "Grouping changed",
            name: settings.groupingKey.toString(),
        });
    }
}

function getOptions(settings: SettingsType, session: SessionType, disableObjectGrouping: boolean, hideObjectGrouping: boolean, objectTypeVisibility: VisibilityOptions | undefined) {
    const isOniqUser = isOniqEmployee(session);
    return (possibleSecondGroupsMap.find(v => v.groupingKey === settings.groupingKey)?.possibleSecondGroups ?? [])
        .filter(v => v.preliminaryLabel === undefined || _.get(session.project?.eventKeys, v.preliminaryLabel) !== undefined)
        // Activate material grouping only for ONIQ users for now.
        .filter(v => v.value !== GroupingKeys.ObjectType || (isOniqUser && !disableObjectGrouping && !hideObjectGrouping || (objectTypeVisibility && ![VisibilityOptions.Hidden, VisibilityOptions.Disabled].includes(objectTypeVisibility))))
        .map(v => {
            return {
                label: i18n.t(v.label),
                value: v.value as string
            };
        });
}

export function ValueStreamSwitch(props: { disabled?: boolean }) {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);

    const isDisabled = settings.groupingKey === GroupingKeys.PassValueStream || props.disabled;

    const isValueStreamGrouping = valueStreamGroupingKeys.includes(settings.groupingKey);

    if (session.project?.eventKeys?.passId === undefined)
        return <></>;

    return <div className={classNames(["repetitions", "checkboxLabel", "mtLarge", isDisabled && "disabled"])}>
        <label>
            <input type="checkbox" id="checkbox-passFocus" disabled={isDisabled} className="checkbox" checked={isValueStreamGrouping} onChange={(e) => { settings.set({ groupingKey: (e.target.checked ? toValueStreamGroupingKeysMap : fromValueStreamGroupingKeysMap).get(settings.groupingKey) || settings.groupingKey }); }} /><label htmlFor="checkbox-passFocus" />
            {i18n.t("common.passFocus")}
        </label>
    </div>;
}

export function isOrderSequenceSeparated(groupingKey: GroupingKeys) {
    return [
        GroupingKeys.MachineValueStream, 
        GroupingKeys.MachineTypeValueStream, 
        GroupingKeys.NoneValueStream,
        GroupingKeys.LocationValueStream,
        GroupingKeys.ObjectTypeValueStream,
        GroupingKeys.MachineObjectTypeValueStream,
        GroupingKeys.PassValueStream].includes(groupingKey);
}