import React, { useContext, useRef } from "react";
import { AutoCenteringModes, getEdgeColor, getEdgeHighlightColor, IDfGraph, nodeHighlightColorMapDefault, ProcessGraph, ZoomControlLocations } from "../../components/dfg/DfGraph";
import { getNodeMarkupStock } from "../../components/dfg/nodes/NodeMarkupFactory";
import { getDimensionParameters } from "../../components/dimension/Dimension";
import EdgeKpiChart from "../../components/kpi-chart/EdgeKpiChart";
import { NodeKpiChart } from "../../components/kpi-chart/NodeKpiChart";
import SideStatisticsOutput from "../../components/side-statistics/SideStatisticsOutput";
import { TabbedView, TabbedViewType } from "../../components/tabbed-view/TabbedView";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext, SettingsType, SortByType } from "../../contexts/SettingsContext";
import { AnalysisType } from "../../hooks/UseGraph";
import { allowedGroupingReduced, allowedGroupingReducedWithoutMachineObject } from "../../utils/GroupingUtils";
import { viewSettingsInitialization } from "../../utils/Initializers";
import { getAssignedQuantities, QuantityType } from "../../utils/Quantities";
import { AggregationTypes, KpiComparisons } from "../../contexts/ContextTypes";
import DfgControls, { getValidDfgControlSettings } from "../../components/controls/DfgControls";
import { getAllowedKpis, graphKpiControlsGetAllowedStatistics } from "../../models/Kpi";
import { getEdgeLabelText, getEdgeStat, getEnabledComparisonsValueStream } from "../../utils/DfgUtils";
import i18n from "../../i18n";
import { DownloadDfgTrayElement } from "../../components/tray/DownloadDfgTrayElement";
import { BackButtonTrayElement } from "../../components/tray/BackButtonTrayElement";
import { isObjectCentricAvailable } from "../../utils/SettingsUtils";
import { KpiPresets, KpiTypes } from "../../models/KpiTypes";
import { ObjectMerger } from "../../utils/ObjectMerger";
import BenchmarkingKpiControls, { getValidBenchmarkingKpiControlSettings } from "../../components/controls/BenchmarkingKpiControls";
import { getMainNodeStat } from "../../utils/MainNodeKpi";
import { ViewSubtitle } from "../../components/tabbed-view/ViewSubtitle";


export function StockProcessView() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const quantities = getAssignedQuantities(session.project?.eventKeys, QuantityType.Yield, false);
    const baseQuantities = quantities.map(q => q.baseQuantity);
    const dfgRef = useRef<IDfGraph>(null);

    const hasObjects = isObjectCentricAvailable(session.project?.eventKeys);


    const views: TabbedViewType[] = [{
        tabTitle: "explorer.dfg",
        tabSlug: "dfg",
        controller: <>
            <DfgControls kpis={KpiPresets.valueStreamInventoryKpis} />
        </>,
        selectionTrigger: (settings: SettingsType) => {
            return settings.selection.node !== undefined || settings.selection.edge !== undefined;
        },
        stats: <SideStatisticsOutput showYield={true} showThroughput={true} showStock={true} />,
        spotlightId: "Output-DFG",
        activator: (preferences) => {
            let temp = viewSettingsInitialization(session,
                settings,
                preferences,
                {
                    aggregationTypes: [AggregationTypes.Product],
                    kpis: getAllowedKpis(session, settings, KpiPresets.valueStreamInventoryKpis),
                    quantities: baseQuantities,
                    groupingKeys: allowedGroupingReduced,
                });

            if (!temp)
                return;

            temp = ObjectMerger.mergeObject(temp, getValidDfgControlSettings(session, temp, { kpis: KpiPresets.valueStreamInventoryKpis }));

            settings.set(viewSettingsInitialization(session, temp, undefined, {
                statistics: graphKpiControlsGetAllowedStatistics(temp.kpi.selectedKpi, temp.groupingKey, session, settings),
            }));
        },
        dimensions: getDimensionParameters(session.projectId, "process/dfg", undefined, []),
        content: <div className="processExplorerGraph" key="processExplorerGraph" >
            <ProcessGraph
                ref={dfgRef}
                legend={{
                    leftLabel: "common.low",
                    rightLabel: "common.high",
                    colormap: nodeHighlightColorMapDefault,
                }}
                isObjectCentric={hasObjects}
                zoomControlLocation={ZoomControlLocations.FarRight}
                analysis={AnalysisType.Output}
                edgeLabelFunc={getEdgeLabelText}
                edgeColorFunc={edge => getEdgeColor(edge, settings, session)}
                nodeHighlightStatFunc={(node) => {
                    return getMainNodeStat(node, settings, session);
                }}
                edgeHighlightStatFunc={(edge) => {
                    return getEdgeStat(edge, settings, session);
                }}
                edgeHighlightColorFunc={(edge, stat, minStatistic, maxStatistic, scale) => {
                    if (scale !== undefined)
                        return getEdgeHighlightColor(edge, scale, settings, session);
                }}
                markupFunc={getNodeMarkupStock}
                centerMode={AutoCenteringModes.CenterIfUninitialized} />
            <BackButtonTrayElement />
            <DownloadDfgTrayElement graph={dfgRef.current} filename={i18n.t("common.processGraph").toString() + "-" + i18n.t("common.inventory").toString()} />
        </div>,
    }, {
        tabTitle: "common.processKpis",
        tabSlug: "benchmarking",
        selectionTrigger: (settings) => {
            return !!settings.selection.node || !!settings.selection.edge;
        },
        controller: <BenchmarkingKpiControls kpis={KpiPresets.valueStreamInventoryBenchmarkingKpis} />,
        stats: <SideStatisticsOutput showYield={true} showThroughput={true} showStock={true} />,
        activator: (preferences) => {
            let temp = viewSettingsInitialization(session, settings, preferences, {
                aggregationTypes: [AggregationTypes.Product],
                quantities: baseQuantities,
                kpis: getAllowedKpis(session, settings, KpiPresets.valueStreamInventoryBenchmarkingKpis),
                sortBy: [SortByType.Kpi, SortByType.Frequency, SortByType.Alphabetical, SortByType.DeviationFromComparison, SortByType.OrderSequences],
                groupingKeys: allowedGroupingReducedWithoutMachineObject,
            });

            if (!temp)
                return;

            temp = ObjectMerger.mergeObject(temp, getValidBenchmarkingKpiControlSettings(session, temp, { kpis: KpiPresets.valueStreamInventoryBenchmarkingKpis }));

            const allowedComparisons = getEnabledComparisonsValueStream(session, temp);
            const newValidation = {
                comparisons: allowedComparisons,
                statistics: graphKpiControlsGetAllowedStatistics(temp.kpi.selectedKpi, temp.groupingKey, session, settings),
                sortBy: allowedComparisons.includes(KpiComparisons.Planning) ?
                    (temp.kpi.selectedKpi === KpiTypes.WorkInProcessInventory ?
                        [SortByType.Kpi, SortByType.Frequency, SortByType.Alphabetical] :
                        [SortByType.Kpi, SortByType.Frequency, SortByType.Alphabetical, SortByType.OrderSequences]) :
                    undefined
            };
            // The allowed comparisons are depending on settings that might have been corrected above,
            // so we need to run this as a two-step process
            settings.set(viewSettingsInitialization(session, temp, undefined, newValidation));
        },
        spotlightId: "Stock-KPI",
        dimensions: getDimensionParameters(session.projectId, "process/benchmarking", undefined, []),
        content: <>
            {[KpiTypes.WorkInProcessInventory, KpiTypes.OrderBacklog].includes(settings.kpi.selectedKpi) && <EdgeKpiChart analysisType={AnalysisType.Output} />}
            {[KpiTypes.WorkInProgressBeforeEventInventory, KpiTypes.OrderBacklogBeforeEvent].includes(settings.kpi.selectedKpi) && <NodeKpiChart analysisType={AnalysisType.Stock} noDataPlaceholder="" pageSlug="output" />}
            <BackButtonTrayElement />
        </>,
    }];

    return <TabbedView
        subtitle={<ViewSubtitle />}
        breadcrumbs={[{
            label: "common.valueStream"
        }]}
        pages={views} />;
}
