import { SessionType } from "../contexts/SessionContext";
import { SettingsType } from "../contexts/SettingsContext";
import i18n from "../i18n";
import { ALL_OBJECT_INDICATOR, ApiGraph, BaseGraph, CASE_TYPE_ID, Edge, END_NODE_ID, END_NODE_ID_INDICATOR, Graph, GroupingKeys, MultiEdge, Node, NodeRoles, START_NODE_ID, START_NODE_ID_INDICATOR } from "../models/Dfg";
import { getShortActivityLabelFromActivityValues } from "./GroupingUtils";
import { getHash } from "./Utils";
import { AggTypes, EquipmentNodeStatisticsSchema, StatsCalculationRequest } from "../models/ApiTypes";
import { get, isObject, sortBy, uniq } from "lodash";
import { Stats } from "../models/Stats";
import { KpiDefinition, getAllowedKpis, getKpiDefinition, getUnit } from "../models/Kpi";
import { KpiComparisons } from "../contexts/ContextTypes";
import { isObjectCentricAvailable, isObjectCentricDeviationAvailable } from "./SettingsUtils";
import { getNodeStatisticName, getStatFromObjectNode } from "./MainNodeKpi";
import stringify from "fast-json-stable-stringify";
import { KpiTypes, StatisticTypes, KpiPresets } from "../models/KpiTypes";

export class DfgUtils {
    static getAncestors(graph: Graph | undefined, nodeId: string) {
        const nodes = graph?.nodes || [];
        const edges = graph?.edges || [];
        const nodeMap: { [id: string]: Node } = {};
        for (const n of nodes)
            nodeMap[n.id] = n;

        const prevEdges = edges.filter(e => e.to === nodeId);
        return prevEdges.map(e => {
            return {
                edge: e,
                node: nodeMap[e.from]
            };
        });
    }

    static getFollowers(graph: Graph | undefined, nodeId: string) {
        const nodes = graph?.nodes || [];
        const edges = graph?.edges || [];
        const nodeMap: { [id: string]: Node } = {};
        for (const n of nodes)
            nodeMap[n.id] = n;

        const nextEdges = edges.filter(e => e.from === nodeId);
        return nextEdges.map(e => {
            return {
                edge: e,
                node: nodeMap[e.to]
            };
        });
    }

    static getNodeLabelById(id: string, nodes: Node[] | undefined, groupKey: GroupingKeys, makePassIdFirst = false) {
        if (nodes === undefined)
            return "";

        const node = nodes.find(n => n.id === id);
        if (node === undefined)
            return undefined;

        return this.getNodeLabel(node, groupKey, makePassIdFirst);
    }

    static getNodeLabel(node: Node, groupKey: GroupingKeys, makePassIdFirst = false) {
        if (node.role === NodeRoles.Start)
            return i18n.t("common.startNodeLabel");

        if (node.role === NodeRoles.End)
            return i18n.t("common.endNodeLabel");

        if (node.role === NodeRoles.Link)
            return i18n.t("common.linkNodeLabel");

        return getShortActivityLabelFromActivityValues(node.activityValues!, groupKey, makePassIdFirst);
    }

    /**
     * Given a number of edges, select the first edge that corresponds to the requested object type.
     */
    static findObjectEdge(multiEdge: MultiEdge, isObjectCentric: boolean, objectType?: string) {
        const edges = multiEdge.edges;
        // If we do not have object types at all we are returning the first element
        if (!objectType || edges.every(edge => !edge.objectType))
            return edges[0];
        // If we are displaying all materials we are returning the first edge that is not of type CASE_TYPE_ID.
        if (objectType === ALL_OBJECT_INDICATOR && isObjectCentric)
            return edges.find(edge => edge.objectType !== CASE_TYPE_ID);
        // In all other cases we are returning the edge that either has the type CASE_TYPE_ID (if not object centric)
        // or the first one of the desired objectType.
        return edges.find(edge => !isObjectCentric ? edge.objectType === CASE_TYPE_ID : edge.objectType === objectType);
    }

    static findObjectNode(node: Node, isObjectCentric: boolean, objectType?: string): Node & { type?: string } {
        if (!isObjectCentric || objectType === CASE_TYPE_ID
            // For customers with objectType Gebinde we always use the CASE statistics.
            || (objectType === "Gebinde"))
            return node;
        const nodeStats = objectType === ALL_OBJECT_INDICATOR ?
            node.objects && node.objects.length > 0 ? node.objects[0] : undefined :
            node.objects?.find(n => n.type === objectType);
        const objectNode = {
            ...nodeStats,
            id: nodeStats !== undefined ? node.id : "",
            role: node.role,
            meta: node.meta,
            activityValues: node.activityValues,
            metaTyped: node.metaTyped
        };
        return objectNode;
    }

    static isObjectStartOrEndNode(node: Node) {
        return (node.role === NodeRoles.Start && node.id !== START_NODE_ID) || (node.role === NodeRoles.End && node.id !== END_NODE_ID);
    }

    static filterObjectStartAndEndNodes(nodes: Node[]) {
        return nodes.filter(DfgUtils.isObjectStartOrEndNode);
    }

    static excludeObjectStartAndEndNodes(nodes: Node[]) {
        return nodes.filter(n => !DfgUtils.isObjectStartOrEndNode(n));
    }

    static filterStartAndEndNodes(nodes: Node[]) {
        return nodes.filter(n => (n.role === NodeRoles.Start || (n.role === NodeRoles.End)));
    }

    static filterNodeRoles(nodes: Node[]) {
        return nodes.filter(n => (n.role !== undefined));
    }

    static excludeStartAndEndNodes(nodes: Node[]) {
        return nodes.filter(n => ((n.role !== NodeRoles.Start && n.id !== START_NODE_ID) && (n.role !== NodeRoles.End && n.id !== END_NODE_ID)));
    }

    static excludeStartAndEndEdges(multiEdges: MultiEdge[], nodes: Node[]) {
        const excludedNodeIds = new Set(DfgUtils.filterStartAndEndNodes(nodes).map(n => n.id));
        return multiEdges.filter(e => !excludedNodeIds.has(e.from) && !excludedNodeIds.has(e.to));
    }

    static excludeObjectStartAndEndEdges<T extends MultiEdge | Edge>(edges: T[], nodes: Node[]): T[] {
        const excludedNodeIds = new Set(DfgUtils.filterObjectStartAndEndNodes(nodes).map(n => n.id));
        return edges.filter(e => !excludedNodeIds.has(e.from) && !excludedNodeIds.has(e.to));
    }

    static filterObjectEdges(edges: MultiEdge[] | undefined, objectType: string | undefined): MultiEdge[] {
        return edges?.filter((multiEdge) => multiEdge.edges.some(e => e.objectType === objectType)) ?? [];
    }

    static filterObjectGraph(graph: BaseGraph, objectType: string): BaseGraph {
        const multiEdges = DfgUtils.filterObjectEdges(graph.multiEdges, objectType);
        const includeNodeIds = new Set(multiEdges.map(multiEdge => [multiEdge.from, multiEdge.to]).flat());
        const nodes = graph.nodes.filter(n => includeNodeIds.has(n.id));
        return {
            ...graph,
            multiEdges,
            nodes
        };
    }

    static filterObjectGraphInContext(graph: BaseGraph, objectType: string | undefined): BaseGraph {
        const excludedNodeIds = new Set(DfgUtils.filterObjectStartAndEndNodes(graph.nodes).map(n => n.id));
        const multiEdges = graph.multiEdges?.filter(e => (!excludedNodeIds.has(e.from) && !excludedNodeIds.has(e.to)) || e.edges.some(e => e.objectType === objectType));
        const result = {
            ...graph,
            multiEdges,
        };
        updateHash(result);
        return result;
    }

    static filterOnlyObjects(graph: BaseGraph): BaseGraph {
        const multiEdges = graph.multiEdges?.filter((multiEdge) => !(
            multiEdge.edges.length === 1 &&
            multiEdge.edges[0].objectType === CASE_TYPE_ID &&
            multiEdge.edges[0].from !== START_NODE_ID &&
            multiEdge.edges[0].to !== END_NODE_ID));
        return {
            ...graph,
            multiEdges
        };
    }

    /**
     * Removes object start- and end nodes and edges connected to these.
     * @param graph the graph instance to filter
     * @param keepOrderStartEndNodes when true, we keep the order/case start- and end nodes and connected edges
     * @returns filtered graph instance
     */
    static filterPureObjectGraph(graph: BaseGraph, keepOrderStartEndNodes?: boolean): BaseGraph {
        graph = this.moveStartEndInfoIntoNodes(graph, keepOrderStartEndNodes);
        // filter out pure case edges (except start/end nodes)
        const multiEdges = graph.multiEdges?.filter(multiEdge => !(multiEdge.edges.length === 1 && multiEdge.edges[0].objectType === CASE_TYPE_ID)
            || (keepOrderStartEndNodes && (multiEdge.edges[0].from === START_NODE_ID || multiEdge.edges[0].to === END_NODE_ID))
        );
        // filter out pure case nodes
        const nodes = graph.nodes.filter(n => !!n.objects?.length
            || (keepOrderStartEndNodes && (n.id === START_NODE_ID || n.id === END_NODE_ID))
        );

        return {
            ...graph,
            multiEdges,
            nodes
        };
    }

    static moveStartEndInfoIntoNodes(graph: BaseGraph, keepOrderStartEndNodes?: boolean): BaseGraph {
        // first remove all start/end nodes and then add the information from the edges into those nodes
        const nodes = graph.nodes.filter(n => !isTerminalNodeId(n.id)
            || (keepOrderStartEndNodes && (n.id === START_NODE_ID || n.id === END_NODE_ID))
        ).map(n => {
            const startEdges = graph.multiEdges?.filter(multiEdge => multiEdge.edges.length === 1 && multiEdge.edges[0].to === n.id).map(multiEdge => multiEdge.edges[0]);
            const endEdges = graph.multiEdges?.filter(multiEdge => multiEdge.edges.length === 1 && multiEdge.edges[0].from === n.id).map(multiEdge => multiEdge.edges[0]);
            return {
                ...n,
                startEdges,
                endEdges
            };
        });

        const multiEdges = graph.multiEdges?.filter(multiEdge => !(multiEdge.edges.length === 1 && isTerminalEdge(multiEdge.edges[0]))
            || (keepOrderStartEndNodes && (multiEdge.edges[0].from === START_NODE_ID || multiEdge.edges[0].to === END_NODE_ID))
        );

        return {
            ...graph,
            nodes,
            multiEdges
        };
    }

    static sortedScores(graph: BaseGraph) {
        const scores = sortBy([...new Set([
            ...(graph.nodes).map(n => n.score || 0),
            ...(graph.multiEdges ?? []).map(me => Math.max(...(me.edges.map(e => e.score || 0))))
        ])]).filter(s => s > 0);
        return scores;
    }

    static filterScore(graph: BaseGraph, cutoffScore: number | undefined) {
        if (cutoffScore === undefined || cutoffScore <= 0)
            return graph;
        const nodes = graph.nodes.filter(n => n.score ? n.score >= cutoffScore : true);
        const multiEdges = graph.multiEdges?.filter(e => e.edges.some(e => e.score ? e.score >= cutoffScore : true));
        return {
            ...graph,
            nodes,
            multiEdges
        };
    }

    static groupStartEndNodes(graph: BaseGraph): BaseGraph {
        // 1. Find nodes that should get an end connection.
        //    These are all nodes that only have an outgoing edge to an end node.
        // 2. Drop all Start and Nodes and their Edges.
        // 3. Add new nodes and edges for the identified nodes that should get an end connection.
        const nodesForEndConnection = [];
        for (const node of graph.nodes) {
            if (node.role === NodeRoles.Start || node.role === NodeRoles.End)
                continue;
            const outgoingNodeIds = (graph.multiEdges ?? []).filter(e => e.from === node.id).map(e => e.to);
            const outgoingNodeRoles = graph.nodes.filter(n => outgoingNodeIds.includes(n.id)).map(n => n.role);
            if (outgoingNodeRoles.every(r => r === NodeRoles.End))
                nodesForEndConnection.push(node);
        }
        const nodes = this.excludeStartAndEndNodes(graph.nodes);
        const multiEdges = this.excludeStartAndEndEdges(graph.multiEdges ?? [], graph.nodes);
        const endNode: Node = { id: END_NODE_ID, role: NodeRoles.End, frequencyStatistics: { sum: nodesForEndConnection.length } };
        nodes.push(...[endNode]);
        const newEndEdges = nodesForEndConnection.map(n => { return { from: n.id, to: END_NODE_ID, edges: [] }; });
        multiEdges.push(...newEndEdges);
        const result = { ...graph, nodes, multiEdges };
        updateHash(result);
        return result;
    }

    /**
     * Merges overlapping edges into single multi-edges each of which contain all overlapping edges.
     *
     * @param edges graph edges that potentially overlap (have the same from and to)
     * @returns An array of multi-edges where each entry has a unique from/to combination.
     */
    static mergeEdges(edges: Edge[]) {
        const edgeMap = new Map<string, Map<string, Edge[]>>();
        for (const edge of edges) {
            const existingToMap = edgeMap.get(edge.from);
            const newToMap = existingToMap === undefined ? new Map<string, Edge[]> : undefined;
            if (newToMap !== undefined)
                edgeMap.set(edge.from, newToMap);
            const toMap = newToMap ?? existingToMap!;

            const existingEdgeList = toMap.get(edge.to);
            const newEdgeList = existingEdgeList === undefined ? [] : undefined;
            if (newEdgeList !== undefined) {
                toMap.set(edge.to, newEdgeList);
            }
            const edgeList = newEdgeList ?? existingEdgeList!;
            edgeList.push(edge);
        }

        const multiEdges: MultiEdge[] = [];
        for (const [from, toMap] of edgeMap) {
            for (const [to, edges] of toMap) {
                multiEdges.push({ from, to, edges });
            }
        }

        return multiEdges;
    }

    /**
     * Generates multi edges from the edges array
     */
    static mergeGraphEdges(graph: Graph | ApiGraph): Graph {
        const result = {
            ...graph,
            multiEdges: DfgUtils.mergeEdges(graph.edges)
        };
        updateHash(result);

        return result;
    }

    static preprocessGraph(graph: Graph | ApiGraph): BaseGraph {
        const multiEdges = DfgUtils.mergeEdges(graph.edges);

        const result: Graph = {
            ...graph,
            multiEdges,
        };
        updateHash(result);

        if (graph.planned !== undefined) {
            result.planned!.multiEdges = DfgUtils.mergeEdges(graph.planned.edges);
            updateHash(result.planned!);
        }

        if (graph.deviation !== undefined)
            result.deviation!.multiEdges = DfgUtils.mergeEdges(graph.deviation.edges);

        return result;
    }

    static getGraphWithOnlyCaseStartEnd(graph: BaseGraph) {
        const nodes = DfgUtils.excludeObjectStartAndEndNodes(graph.nodes || []).filter(n => (
            (n.role === NodeRoles.Start || n.role === NodeRoles.End) ||
            (n.objects !== undefined && n.objects?.length > 0)
        ));
        const edges = DfgUtils.excludeObjectStartAndEndEdges(graph.edges, graph.nodes || []);
        const multiEdges = DfgUtils.mergeEdges(edges);
        return {
            ...graph,
            nodes,
            edges,
            multiEdges
        };
    }

    static dropOnlyCaseEdges(graph: Graph) {
        const multiEdges = graph.multiEdges.filter(multiEdge => multiEdge.from === START_NODE_ID || multiEdge.to === END_NODE_ID || multiEdge.edges.some(e => e.objectType !== CASE_TYPE_ID));
        return {
            ...graph,
            multiEdges
        };
    }

    static isAfterStartOrBeforeEndEdgeNodeMapped(edge: MultiEdge, nodeMap: Map<string, Node>): boolean {
        const fromNode = nodeMap.get(edge.from);
        const toNode = nodeMap.get(edge.to);
        return (fromNode?.role === NodeRoles.Start || toNode?.role === NodeRoles.End);
    }

    /**
     * This method is inefficient. If you call it from within an inner loop,
     * please use isAfterStartOrBeforeEndEdgeNodeMapped instead and pass the nodeMap.
     */
    static isAfterStartOrBeforeEndEdge(edge: MultiEdge, nodes: Node[]): boolean {
        return DfgUtils.isAfterStartOrBeforeEndEdgeNodeMapped(edge, new Map(nodes.map(n => [n.id, n])));
    }

    static getAllObjectTypes(graph: Graph | ApiGraph, addAllObjects?: boolean): string[] {
        const allObjectTypes = uniq(graph.multiEdges?.map(multiEdge => multiEdge.edges.map(e => e.objectType)).flat()).filter(f => f !== undefined && f !== CASE_TYPE_ID) as string[];
        if (allObjectTypes.length === 1)
            return allObjectTypes;
        if (addAllObjects)
            allObjectTypes.push(ALL_OBJECT_INDICATOR);
        return allObjectTypes;
    }


    /**
     * Gets the edge from the graph with all its statistics.
     * @param graph 
     * @param edge 
     * @param isObjectCentric 
     * @param objectType 
     * @returns 
     */
    static getEdgeFromGraph(graph: Graph, edge: MultiEdge | undefined, isObjectCentric: boolean, objectType?: string) {
        if (edge === undefined)
            return undefined;

        const selectedEdge = graph.multiEdges.find(multiEdge => multiEdge.from === edge.from && multiEdge.to === edge.to);

        if (selectedEdge === undefined)
            return undefined;
        return DfgUtils.findObjectEdge(selectedEdge, isObjectCentric, objectType);

    }

    /**
     * Gets the node from the graph with all its statistics.
     */
    static getNodeFromGraph(graph: Graph, node: Node | undefined, isObjectCentric: boolean, objectType?: string) {
        if (node === undefined)
            return undefined;

        const selectedNode = graph.nodes.find(n => n.id === node.id);

        if (selectedNode === undefined)
            return undefined;

        return DfgUtils.findObjectNode(selectedNode, isObjectCentric, objectType);
    }
}

export function getEdgeLabelText(multiEdge: MultiEdge | Edge | undefined, settings: SettingsType, session: SessionType, useStatistic?: boolean) {
    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);
    const edge = get(multiEdge, "edges") !== undefined ?
        DfgUtils.findObjectEdge(multiEdge as MultiEdge, isObjectCentric, settings.graph.objectType) :
        multiEdge;
    if (edge === undefined)
        return undefined;

    // Don't get any statistics for non-frequency edges
    if (settings.kpi.selectedKpi !== KpiTypes.Frequency && (isTerminalNodeId(edge.from) || isTerminalNodeId(edge.to)))
        return undefined;

    const value = getEdgeStat(edge, settings, session);
    const kpiDefinition = getKpiDefinition(settings.kpi.selectedKpi, { session, settings });
    // As a default we always use the sum statistic for the edge. Except it is explicitely defined to use the statistic.
    const unit = getUnit(kpiDefinition?.unit, useStatistic ? settings.kpi.statistic : StatisticTypes.Sum);
    return unit?.formatter(value, { locale: session.numberFormatLocale, baseQuantity: settings.quantity });
}

export function getEdgeStat(multiEdge: MultiEdge | Edge | undefined, settings: SettingsType, session: SessionType, overrides?: { kpiType?: KpiTypes, statistic?: StatisticTypes }) {
    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);
    const edge = get(multiEdge, "edges") !== undefined ?
        DfgUtils.findObjectEdge(multiEdge as MultiEdge, isObjectCentric, settings.graph.objectType) :
        multiEdge;
    const edgePropName = getEdgeStatisticName(session, settings, overrides);
    if (edgePropName === undefined || edge === undefined)
        return undefined;
    return get(edge, edgePropName) as number | undefined;
}

export function getEdgeStatisticName(session: SessionType, settings: SettingsType, overrides?: { kpiType?: KpiTypes, statistic?: StatisticTypes }) {
    const kpiType = overrides?.kpiType ?? settings.kpi.selectedKpi;
    const statistic = overrides?.statistic ?? settings.kpi.statistic;
    const kpiDefinition = getKpiDefinition(kpiType, { session, settings });
    switch (statistic) {
        case StatisticTypes.Mean:
            return isObject(kpiDefinition?.edgeStatisticsPath) ? kpiDefinition?.edgeStatisticsPath.mean : kpiDefinition?.edgeStatisticsPath + ".mean";
        case StatisticTypes.Median:
            return isObject(kpiDefinition?.edgeStatisticsPath) ? kpiDefinition?.edgeStatisticsPath?.variance + ".median" : kpiDefinition?.edgeStatisticsPath + ".median";
        case StatisticTypes.Variance:
            return isObject(kpiDefinition?.edgeStatisticsPath) ? kpiDefinition?.edgeStatisticsPath?.variance : kpiDefinition?.edgeStatisticsPath;
        case StatisticTypes.Sum:
            return isObject(kpiDefinition?.edgeStatisticsPath) ? kpiDefinition?.edgeStatisticsPath.sum : kpiDefinition?.edgeStatisticsPath + ".sum";
        default:
            break;
    }
}

export function isTerminalEdge(edge: Edge | undefined) {
    if (!edge)
        return false;

    return isTerminalNodeId(edge.from) || isTerminalNodeId(edge.to);
}

export function isTerminalNode(node: Node) {
    return node?.role === NodeRoles.Start || node?.role === NodeRoles.End;
}

export function isTerminalNodeId(nodeId: string) {
    return nodeId.endsWith(START_NODE_ID_INDICATOR) || nodeId.endsWith(END_NODE_ID_INDICATOR);
}

/**
 * Checks if the node is a normal (green) node (not a group, box, start/end or other node)
 */
export function isStandardNode(node: Node) {
    return !isTerminalNode(node) && node?.role !== NodeRoles.Group;
}


export type GraphComplexityMeasure = {
    edges: number,
    nodes: number
}

export function updateHash(graph: BaseGraph | undefined) {
    if (!graph)
        return;

    graph.hash = getGraphHash(graph);
}

export function getGraphHash(graph: BaseGraph) {
    const nodeProps = Object.keys(graph.nodes?.find(n => n.role === undefined) ?? {});

    const obj = {
        nodes: graph.nodes.map(n => {
            const r = {
                id: n.id,
                objects: n.objects?.map(o => {
                    return {
                        type: o.type,
                        customKpis: o.customKpis,
                    };
                }),
                customKpis: n.customKpis,
            };

            for (const key of nodeProps) {
                const sum = (n[key] as unknown as any)?.sum;
                if (sum  !== undefined) {
                    (r as unknown as any)[key] = sum;
                }
            }

            return r;
        }),
        multiEdges: graph.multiEdges?.map(m => {
            return {
                from: m.from,
                to: m.to,
                edges: m.edges.map(e => {
                    return {
                        o: e.objectType,
                    };
                }),
            };
        }),
        edges: graph.edges.map(e => {
            return {
                from: e.from,
                to: e.to,
                objectType: e.objectType,
                score: e.score,
            };
        }),
    };
    return getHash(obj);
}

/**
 * We add the calculation of custom kpis for for some analyzed values. So far this is mainly the
 * TimeValuesMean and in case relative times are active. In this case we calculate the relative kpi 
 * with respect to the desired output quantity.
 * @param overrideKpis list of kpis that the request should cover.
 * @returns request options that can be passed on to the dfg endpoint
 */
export function getCustomKpisDfg(settings: SettingsType, session: SessionType, isPlanning = false, overrideKpis?: KpiTypes[]): Partial<StatsCalculationRequest> | undefined {
    if (session.project === undefined)
        return;

    // When we have a time kpi we would like to calculate all other stats as well because they show up in the aside.
    // We therefore check if the selected kpi is a time kpi and if so we add all time kpis to the request.
    const allowedTimeKpis = getAllowedKpis(session, settings, isPlanning ? KpiPresets.valueStreamTimeDeviationKpis : KpiPresets.valueStreamTimeKpis) as KpiTypes[];
    const allowedKpis = overrideKpis ? getAllowedKpis(session, settings, overrideKpis, false, isPlanning) as KpiTypes[] :
        allowedTimeKpis.includes(settings.kpi.selectedKpi) ? allowedTimeKpis : [settings.kpi.selectedKpi];

    const kpiDefinitions = allowedKpis.map(k => getKpiDefinition(k, { session, settings })).filter(k => k !== undefined) as KpiDefinition[];

    const apiParameters = Object.assign({}, ...kpiDefinitions.map(k => k?.apiParameters).filter(k => k !== undefined));

    apiParameters.aggs = [AggTypes.P25, AggTypes.P75, AggTypes.Mean, AggTypes.Median, AggTypes.Min, AggTypes.Max, AggTypes.Sum, AggTypes.Total];

    const nodeCustomKpis = kpiDefinitions.map(k => k?.nodeCustomKpis).filter(k => k !== undefined).flat();
    const edgeCustomKpis = kpiDefinitions.map(k => k?.edgeCustomKpis).filter(k => k !== undefined).flat();

    // Make sure we only request each customKpi only once
    const convertedCustomKpis = [...new Set(nodeCustomKpis.map(k => stringify(k))), ...new Set(edgeCustomKpis.map(k => stringify(k))),];
    const uniqueCustomKpis = convertedCustomKpis.map(k => JSON.parse(k));

    return {
        ...apiParameters,
        customKpis: uniqueCustomKpis
    };
}

/**
 * @param kpis list of kpis that the request should cover.
 * @returns request options that can be passed on to the equipment statistics endpoint
 */
export function getCustomKpisEquipmentStats(settings: SettingsType, session: SessionType, kpis: KpiTypes[]): Partial<StatsCalculationRequest> | undefined {
    if (session.project === undefined)
        return;

    const allowedKpis = getAllowedKpis(session, settings, kpis, false, false);

    const kpiDefinitions = allowedKpis.map(k => getKpiDefinition(k, { session, settings })).filter(k => k !== undefined) as KpiDefinition[];

    const apiParameters = Object.assign({}, ...kpiDefinitions.map(k => k?.apiParameters).filter(k => k !== undefined));
    
    const equipmentNodeCustomKpis = kpiDefinitions.map(k => k?.equipmentNodeCustomKpis).filter(k => k !== undefined).flat();

    // Make sure we only request each customKpi only once
    const convertedCustomKpis = [...new Set(equipmentNodeCustomKpis.map(k => stringify(k)))];
    const uniqueCustomKpis = convertedCustomKpis.map(k => JSON.parse(k));

    return {
        ...apiParameters,
        customKpis: uniqueCustomKpis
    };
}

/**
 * Collects all values that belong to the statistic of a node, considering the kpiType
 * @param settings SettingsType
 * @returns object containing the sum/mean/min/max of the node kpi statistics
 */
export function getNodeKpiStatistics(session: SessionType, settings: SettingsType, node: Node, kpiType: KpiTypes): Stats {
    // For cycle time and throughput rate the min and max values do not make sense if we have multiple machines.
    // In this case we return undefined and just do not show them.
    const varianceProp = [KpiTypes.CycleTime, KpiTypes.ThroughputRate].includes(kpiType) && node.activityValues?.machine?.nUnique !== 1 ?
        undefined :
        getNodeStatisticName(session, settings, { kpiType, statistic: StatisticTypes.Variance });
    const variance = varianceProp ? get(node, varianceProp) as Stats : undefined;
    return {
        sum: getStatFromObjectNode(node, session, settings, { kpiType, statistic: StatisticTypes.Sum }),
        mean: getStatFromObjectNode(node, session, settings, { kpiType, statistic: StatisticTypes.Mean }),
        min: variance ? variance.min : undefined,
        max: variance ? variance.max : undefined,
        median: variance ? variance.median : undefined,
        p25: variance ? variance.p25 : undefined,
        p75: variance ? variance.p75 : undefined,
    };
}

/**
 * Collects all values that belong to the statistic of a node equipment stats, considering the kpiType
 * @param settings SettingsType
 * @returns object containing the mean of the node equipment kpi statistics
 */
export function getEquipmentNodeKpiStatistics(session: SessionType, settings: SettingsType, equipmentStats: EquipmentNodeStatisticsSchema, kpiType: KpiTypes): Stats {
    const kpiDefinition = getKpiDefinition(kpiType, { session, settings });
    if (isObject(kpiDefinition?.equipmentNodeStatsPath))
        return {
            mean: kpiDefinition?.equipmentNodeStatsPath.mean ? get(equipmentStats, kpiDefinition?.equipmentNodeStatsPath?.mean) as number : undefined,
        };
    return {
        mean: kpiDefinition?.equipmentNodeStatsPath ? get(equipmentStats, kpiDefinition?.equipmentNodeStatsPath + ".mean") as number : undefined,
    };
}

/**
 * Collects all values that belong to the statistic of an edge, considering the kpiType
 * @param settings SettingsType
 * @returns object containing the sum/mean/min/max of the edge kpi statistics
 */
export function getEdgeKpiStatistics(session: SessionType, settings: SettingsType, edge: Edge, kpiType: KpiTypes): Stats {
    const kpiDefinition = getKpiDefinition(kpiType, { session, settings });
    if (isObject(kpiDefinition?.edgeStatisticsPath))
        return {
            sum: kpiDefinition?.edgeStatisticsPath.sum ? get(edge, kpiDefinition?.edgeStatisticsPath?.sum) as number : undefined,
            mean: kpiDefinition?.edgeStatisticsPath.mean ? get(edge, kpiDefinition?.edgeStatisticsPath?.mean) as number : undefined,
            median: kpiDefinition?.edgeStatisticsPath.variance ? get(edge, kpiDefinition?.edgeStatisticsPath?.variance + ".median") as number : undefined,
            min: kpiDefinition?.edgeStatisticsPath.variance ? get(edge, kpiDefinition?.edgeStatisticsPath?.variance + ".min") as number : undefined,
            max: kpiDefinition?.edgeStatisticsPath.variance ? get(edge, kpiDefinition?.edgeStatisticsPath?.variance + ".max") as number : undefined,
            p25: kpiDefinition?.edgeStatisticsPath.variance ? get(edge, kpiDefinition?.edgeStatisticsPath?.variance + ".p25") as number : undefined,
            p75: kpiDefinition?.edgeStatisticsPath.variance ? get(edge, kpiDefinition?.edgeStatisticsPath?.variance + ".p75") as number : undefined,
        };
    return {
        sum: kpiDefinition?.edgeStatisticsPath ? get(edge, kpiDefinition?.edgeStatisticsPath + ".sum") as number : undefined,
        mean: kpiDefinition?.edgeStatisticsPath ? get(edge, kpiDefinition?.edgeStatisticsPath + ".mean") as number : undefined,
        median: kpiDefinition?.edgeStatisticsPath ? get(edge, kpiDefinition?.edgeStatisticsPath + ".median") as number : undefined,
        min: kpiDefinition?.edgeStatisticsPath ? get(edge, kpiDefinition?.edgeStatisticsPath + ".min") as number : undefined,
        max: kpiDefinition?.edgeStatisticsPath ? get(edge, kpiDefinition?.edgeStatisticsPath + ".max") as number : undefined,
        p25: kpiDefinition?.edgeStatisticsPath ? get(edge, kpiDefinition?.edgeStatisticsPath + ".p25") as number : undefined,
        p75: kpiDefinition?.edgeStatisticsPath ? get(edge, kpiDefinition?.edgeStatisticsPath + ".p75") as number : undefined,
    };
}

/**
 * Checks what statistics are available for the given kpiType. In addition we check
 * whether the comparison is available for the process perspective and if the data is labelled
 * object centric.
 * @returns List of valid comparisons
 */
export function getEnabledComparisonsValueStream(session: SessionType, settings: SettingsType, addBestProcesses = false) {

    const comparisons: KpiComparisons[] = [KpiComparisons.None];
    if (session.project === undefined || settings.kpi.statistic === StatisticTypes.Variance)
        return comparisons;

    const allowedComparisons = getKpiDefinition(settings.kpi.selectedKpi, { session, settings })?.allowedComparisons;
    const isObjectDeviationAvailable = isObjectCentricDeviationAvailable(session.project);
    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);
    if ((isObjectDeviationAvailable || !isObjectCentric) &&
        allowedComparisons?.includes(KpiComparisons.Planning))
        comparisons.push(KpiComparisons.Planning);
    if (addBestProcesses && allowedComparisons?.includes(KpiComparisons.BestProcesses))
        comparisons.push(KpiComparisons.BestProcesses);
    return comparisons;
}
