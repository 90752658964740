import { useContext } from "react";
import { disableAllCalcOptions, DeviationOptions, GetCaseDeviationResult, GetDeviationRequest } from "../models/ApiTypes";
import { SessionContext } from "../contexts/SessionContext";
import { SettingsContext } from "../contexts/SettingsContext";
import { EventFilter } from "../models/EventFilter";
import { EventKeys } from "../models/EventKeys";
import { Datastores } from "../utils/Datastores";
import { ApiHookOptions, useApi } from "./UseApi";

export function useCaseDeviation(request: { eventFilters?: EventFilter[] } & Partial<DeviationOptions>, options?: ApiHookOptions<GetCaseDeviationResult>): [GetCaseDeviationResult | undefined, boolean] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const currentFilters = (request?.eventFilters as (EventFilter[] | undefined)) ?? settings.previewFilters ?? settings.filters;
    const requestOptions: GetDeviationRequest = {
        // Project may be undefined during initialization. We're checking for that
        // later and disable the useApi hook if so
        actual: {
            uploadId: session.project?.uploadId ?? "",
            eventKeys: session.project?.eventKeys ?? {} as EventKeys,
            eventFilters: currentFilters,
            uploads: session.project?.uploads,
        },
        planned: {
            uploadId: session.project?.uploadIdPlan ?? "",
            eventKeys: session.project?.eventKeysPlan ?? {} as EventKeys,
        },
        consolidatePasses: true,
        ...disableAllCalcOptions,
        ...request,
    };

    return useApi(
        Datastores.getPerCaseDeviationStatistics,
        requestOptions,
        [
            JSON.stringify(requestOptions)
        ], {
            ...options,
            disable: options?.disable || session.project === undefined || session.project.uploadIdPlan === undefined,
        });
}
