import { useContext } from "react";
import { disableAllCalcOptions, PerTimeperiodStatisticsSchema, PerTimeperiodStatisticsParams, TimePeriodFrequencies } from "../models/ApiTypes";
import { SessionContext } from "../contexts/SessionContext";
import { SettingsContext } from "../contexts/SettingsContext";
import { EventKeys } from "../models/EventKeys";
import { Datastores } from "../utils/Datastores";
import { groupSupportsConsolidatePasses } from "../utils/GroupingUtils";
import { ApiHookOptions, useApi } from "./UseApi";

export function useTimeAggregatedCaseStatistics(request: Partial<PerTimeperiodStatisticsParams> = {}, options?: ApiHookOptions<PerTimeperiodStatisticsSchema> & {
    addEnergyStats?: boolean;
}): [PerTimeperiodStatisticsSchema | undefined, boolean] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const requestOptions: PerTimeperiodStatisticsParams = {
        ...disableAllCalcOptions,
        ...request,
        eventFilters: request.eventFilters ?? settings.previewFilters ?? settings.filters,
        eventKeys: request.eventKeys ?? session.project?.eventKeys ?? { } as EventKeys,
        uploadId: request.uploadId ?? session.project?.uploadId ?? "",
        uploads: session.project?.uploads,
        frequency: request.frequency ?? TimePeriodFrequencies.Month,
        tz: request.tz ?? session.timezone ?? "UTC",
        consolidatePasses: request.consolidatePasses ?? groupSupportsConsolidatePasses(request.eventKeys?.activityKeysGroup),    
    };

    return useApi(Datastores.getTimeAggregatedCaseStatistics, requestOptions, [ JSON.stringify(requestOptions) ], {
        ...options,
        disable: options?.disable || !requestOptions.uploadId,
    });
}