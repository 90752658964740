import React, { useContext } from "react";
import { SettingsContext } from "../../../contexts/SettingsContext";
import i18n from "../../../i18n";
import { useKpiCaseStatisticsWithDeviation } from "../../../hooks/UseKpiCaseStatisticsWithDeviation";
import { KpiComparisons } from "../../../contexts/ContextTypes";
import { StatsSection, StatsSubSection } from "../../../components/stats-section/StatsSection";
import { buildKpiSpotlightId, getKpiDefinition, getUnit } from "../../../models/Kpi";
import { StatsRowValue } from "../../../components/stats-section/StatsRowValue";
import { getStats } from "../CaseProcessKpiChart";
import { StatisticTypes } from "../../../models/KpiTypes";
import { SessionContext } from "../../../contexts/SessionContext";

export function CaseStats() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const isPlanningComparison = settings.kpi.comparisons === KpiComparisons.Planning;

    const kpiDefinition = getKpiDefinition(settings.kpi.selectedKpi, { session, settings });
    const unit = getUnit(kpiDefinition?.unit, settings.kpi.statistic);

    const [caseStats, isCaseStatsLoading, caseDeviation, isCaseDeviationLoading] = useKpiCaseStatisticsWithDeviation();

    const selectedCase = isPlanningComparison ?  (caseDeviation?.cases ?? []).find(c => c.id === settings.selection.case)?.actual : (caseStats?.cases ?? []).find(c => c.id === settings.selection.case);
    const selectedCasePlan = (caseDeviation?.cases ?? []).find(c => c.id === settings.selection.case)?.planned;

    const isLoading = isCaseStatsLoading || isCaseDeviationLoading;
    if (!selectedCase && !isLoading)
        return <></>;
    
    const statsTranslations: Map<StatisticTypes, string> = new Map([
        [StatisticTypes.Mean, "common.statistics.mean"],
        [StatisticTypes.Sum, "common.statistics.sum"],
    ]);

    const value = selectedCase ? getStats(selectedCase, session, settings) : undefined;
    const plannedValue = selectedCasePlan ? getStats(selectedCasePlan, session, settings) : undefined;

    return <StatsSection title={i18n.t("common.case") + " " + settings.selection.case}>
        <StatsSubSection title={kpiDefinition?.label} spotlightId={buildKpiSpotlightId(settings.kpi.selectedKpi)}>
            <StatsRowValue label={i18n.t(statsTranslations.get(settings.kpi.statistic) ?? "common.statistics.sum")} isLoading={isLoading} value={value} unit={unit} isHighlight={true}/>
        </StatsSubSection>

        {isPlanningComparison && <StatsSubSection title={kpiDefinition?.labelPlan} spotlightId={buildKpiSpotlightId(settings.kpi.selectedKpi)} >
            <StatsRowValue label={i18n.t(statsTranslations.get(settings.kpi.statistic) ?? "common.statistics.sum")} isLoading={isLoading} value={plannedValue} unit={unit} isHighlight={true}/>
        </StatsSubSection>}

    </StatsSection>
    
    ;
}
