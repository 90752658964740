import React from "react";
import { SimpleCache } from "../utils/SimpleCache";
import sum from "hash-sum";

export type GeneratorFunc<T> = () => T;

/**
 * Caching hook. You can use this instead of useMemo for a better caching experience
 */
export function useCache<T>(gen: GeneratorFunc<T>, dependencies: any[], cacheSize = 100) {
    const cache = React.useRef<SimpleCache<string, T>>(new SimpleCache<string, T>(cacheSize));
    const key = sum(dependencies);

    if (cache.current.has(key))
        return cache.current.get(key)!;

    const value = gen();
    cache.current.set(key, value);

    return value;
}