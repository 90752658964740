import React, { useImperativeHandle, useRef, useMemo, useState } from "react";
import useWindowResize from "../../utils/WindowResizeHook";
import { Layouter } from "../dfg/Layouter";
import { classNames } from "../../utils/Utils";
import i18n from "../../i18n";
import { Portal } from "../portal/Portal";

export type MenuItem = {
    id?: string;

    title?: string;

    tooltip?: string;

    titleHtml?: string;

    /**
     * onClick handler. If you want to prevent the default handlers, just return
     * false here.
     */
    onClick?: () => boolean | void | Promise<boolean | void>;

    disabled?: boolean;

    isLoading?: boolean;

    isHidden?: boolean;
}

export enum MenuPlacementsVertical {
    Above,
    Below,
}

type MenuPropsType = {
    items: MenuItem[];
    children?: JSX.Element;
    className?: string;
    verticalPlacement?: MenuPlacementsVertical;
    onBlur?: () => void;
};

export interface IMenu {
    show(): void;
}

const Menu = React.forwardRef((props: MenuPropsType, ref: React.Ref<IMenu>) => {
    const windowSize = useWindowResize();

    const [isVisible, setIsVisible] = useState<boolean>(false);

    const childRef = useRef<React.RefObject<HTMLElement>>(React.createRef());

    const child = !props.children ? undefined : React.cloneElement(props.children as React.ReactElement<any>, {
        ref: childRef.current,
        onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            show();
            e.stopPropagation();
            e.preventDefault();
        }
    });

    const [dropdownWidth, dropdownHeight] = useMemo(() => {
        let maxWidth = 80;
        let height = 0;

        for (const element of props.items.filter(i => !i.isHidden)) {
            const translation = i18n.t(element.title ?? "");
            const size = Layouter.measureFontSize("dropdownMenuItemMeasure", element.titleHtml ?? translation, element.titleHtml !== undefined);
            height += size.height;
            maxWidth = Math.max(maxWidth, size.width);
        }

        // 32/16 is the padding of the menu, plus 2px for the border
        return [maxWidth + 34, height + 18];
    }, [
        props.items
    ]);

    const menuLocation = useMemo(() => {
        const childRects = childRef.current.current?.getClientRects();
        if (!childRects || !windowSize.width)
            return {};

        const firstRect = childRects[0];
        const parentTop = firstRect?.top || 0;
        const parentLeft = firstRect?.left || 0;
        const parentRight = firstRect?.right || 0;
        const parentBottom = firstRect?.bottom || 0;

        // Check if aligning the dropdown with the left edge would reach out of the
        // window, so let's right-align it instead
        const menuLeft = (parentLeft + dropdownWidth) > windowSize.width ? parentRight - dropdownWidth : parentLeft;


        const result: { top?: number, left: number; width: number, bottom?: number } = {
            left: menuLeft,
            width: dropdownWidth,
        };

        const menuParentOffset = 12; // This value is coming from the wireframe
        const menuBottomIfBelow = parentBottom + menuParentOffset + dropdownHeight;

        if (props.verticalPlacement === MenuPlacementsVertical.Above ||
            menuBottomIfBelow >= window.innerHeight)
            result.top = parentTop - dropdownHeight - menuParentOffset;
        else
            result.top = parentBottom + menuParentOffset;

        return result;
    }, [
        props.items,
        childRef.current,
        windowSize,
        dropdownWidth,
    ]);

    useImperativeHandle(ref, () => ({
        show() { show(); }
    }));

    if (!isVisible)
        return <>{child}</>;

    return <>
        {child}
        <Portal>
            <div className={classNames(["dropdownMenuCover", props.className])} onClick={() => {
                if (props.onBlur)
                    props.onBlur();
                setIsVisible(false);
            }}>
                <div className="menu" style={menuLocation}>
                    {props.items.filter(i => !i.isHidden).map(i => i.title !== undefined ? <div
                        key={i.id ?? i.title}
                        data-testid={i.id}
                        title={i.tooltip}
                        className={getCssClass(i) + (i.isLoading ? "" : " title")}
                        onClick={async (e) => {
                            if (i.disabled || i.isLoading) {
                                e.preventDefault();
                                e.stopPropagation();
                                return;
                            }

                            if (!i.onClick)
                                return;

                            const temp = i.onClick();
                            if ((temp instanceof Promise ? await temp : temp) === false) {
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        }}>
                        {i.isLoading === true && <>
                            <div className="title">{i18n.t(i.title ?? "")}</div>
                            <div className="spin">
                                <div className="dot-pulse" data-testid="spinner" />
                            </div>
                        </>}

                        {i.isLoading !== true && i18n.t(i.title ?? "").toString()}

                    </div> : <div key={i.titleHtml ?? ""} className="dropdownMenuItem" dangerouslySetInnerHTML={{ __html: i.titleHtml ?? "" }} />)}
                </div>
            </div>
        </Portal>
    </>;


    function getCssClass(item: MenuItem) {
        return classNames([
            "dropdownMenuItem",
            item.onClick !== undefined && !item.disabled && "hoverItem",
            (item.disabled || item.isLoading) && "disabled"
        ]);
    }

    function show() {
        setIsVisible(true);
    }
});

export default Menu;
