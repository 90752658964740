import React, { useEffect, useState, useContext } from "react";
import { ignoreCancelledRequest } from "../api/Api";
import { SettingsContext } from "../contexts/SettingsContext";
import { ApiCache } from "../utils/ApiCache";
import { useMountedState } from "./UseMounted";
import { SmartApiCache } from "../utils/SmartApiCache";

export type ApiHookOptions<RESPONSE> = Partial<{
    disable: boolean;
    onData: (data: RESPONSE) => void;
}>;

export function useApi<RequestType, ResponseType>(
    cache: ApiCache<ResponseType, RequestType> | SmartApiCache<ResponseType, RequestType>,
    request: RequestType,
    dependencies: any[],
    options?: ApiHookOptions<ResponseType>,
    addEnergyStatsFunc?: (response: ResponseType) => ResponseType): [ResponseType | undefined, boolean] {

    const settings = useContext(SettingsContext);

    // Subscription ID handling
    const [subscriptionId] = useState(() => { return cache.getSubscriptionId(); });
    useEffect(() => {
        return () => {
            cache.cancelSubscription(subscriptionId);
        };
    }, []);

    const numRequests = React.useRef(0);
    const [isLoading, setIsLoading] = useState<number>(0);
    const [data, setData] = useState<ResponseType | undefined>(undefined);
    const isMounted = useMountedState();

    useEffect(() => {
        if (options?.disable)
            return;

        // Cancel already issued requests
        cache.cancelSubscription(subscriptionId);

        numRequests.current++;
        setIsLoading(numRequests.current);

        cache.get(request, subscriptionId).then((data) => {
            if (isMounted()) {
                if (addEnergyStatsFunc !== undefined)
                    data = addEnergyStatsFunc(data);

                setData(data);

                if (options?.onData)
                    options.onData(data);
            }
        }).catch(ignoreCancelledRequest).finally(() => {
            numRequests.current--;
            if (isMounted())
                setIsLoading(numRequests.current);

        });
    }, [
        ...dependencies,
        settings.apiRetry,
        options?.disable,
    ]);

    return [data, (isLoading > 0) && !options?.disable];
}