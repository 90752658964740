import { get } from "lodash";
import React, { useContext } from "react";
import { KpiComparisons } from "../../../contexts/ContextTypes";
import { SessionContext } from "../../../contexts/SessionContext";
import { SettingsContext } from "../../../contexts/SettingsContext";
import i18n from "../../../i18n";
import { KpiDefinition, TimeperiodApis, getKpiDefinition } from "../../../models/Kpi";
import { Formatter } from "../../../utils/Formatter";
import { toUserTimezone } from "../../../utils/TimezoneUtils";
import AggregatedCaseStats from "./AggregatedCaseStats";
import { KpiTypes } from "../../../models/KpiTypes";
import { useAggregatedTimeperiods } from "../../../hooks/UseAggregatedTimeperiods";

type TimeStatsProps = {
    kpiTypes?: KpiTypes[];
    hasBusyTimeSubStats?: boolean;
    disableKpiStats?: boolean;
}

export default function TimeperiodStats(props: TimeStatsProps) {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);

    const selectedKpiDefinition = getKpiDefinition(settings.kpi.selectedKpi, { session, settings });

    const isDeviationApi = (settings.kpi.comparisons === KpiComparisons.Planning && selectedKpiDefinition?.allowedComparisons.includes(KpiComparisons.Planning)) || !!selectedKpiDefinition?.useDeviationApi;

    let api = selectedKpiDefinition?.timeperiodApi ?? TimeperiodApis.Case;
    if (isDeviationApi)
        api = TimeperiodApis.CaseDeviation;

    const [data, isLoading] = useAggregatedTimeperiods({
        frequency: settings.kpi.timeScale,
        ...selectedKpiDefinition?.apiParameters,
        customKpis: selectedKpiDefinition?.timeperiodCustomKpis ?? selectedKpiDefinition?.productCustomKpis,
    }, api, {
        disable: settings.selection.timeperiod === undefined,
    });

    const selectedTime = data?.timeperiods?.find(t => t.timeperiodStartTime === settings.selection.timeperiod?.timeperiodStartTime);

    if (!selectedTime)
        return null;

    const timestamp = toUserTimezone(selectedTime.timeperiodStartTime, session.timezone);

    const isPlanningComparison = settings.kpi.comparisons === KpiComparisons.Planning;

    const selectionActual = get(selectedTime, "actual");

    const selectionPlan = isPlanningComparison ? get(selectedTime, "planned") : undefined;
    const count = get(selectedTime, "caseCount");

    const kpiDefinition = props.kpiTypes ? props.kpiTypes.map(k => getKpiDefinition(k, { session, settings })).filter(k => k !== undefined) as KpiDefinition[] : 
        getKpiDefinition(settings.kpi.selectedKpi, {session, settings }) !== undefined ? [getKpiDefinition(settings.kpi.selectedKpi, {session, settings })] as KpiDefinition[] : [];

    return <AggregatedCaseStats
        title={Formatter.formatTimePlaceholders(i18n.t("datetime.formats." + settings.kpi.timeScale.toString()), timestamp, session.timezone)}
        kpiDefinitions={!props.disableKpiStats ? kpiDefinition : []}
        isLoading={isLoading}
        selection={selectionActual}
        selectionPlan={selectionPlan}
        count={count}

    />;
}