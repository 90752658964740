import React, { useContext, useEffect } from "react";
import { OrderNumberControls, OrderTrackingControls } from "../../components/controls/OrderTrackingControls";
import { SettingsSpy } from "../../components/deviation-graph/SettingsSpy";
import { AutoCenteringModes, getEdgeColor, getEdgeHighlightColor, getLegendProps, ZoomControlLocations } from "../../components/dfg/DfGraph";
import { getNodeMarkupOrderTracking } from "../../components/dfg/nodes/NodeMarkupFactory";
import OrderTrackingGantt from "../../components/order-tracking/OrderTrackingGantt";
import { OrderTrackingGraph } from "../../components/order-tracking/OrderTrackingGraph";
import SideStatisticsOrderTracking from "../../components/order-tracking/SideStatisticsOrderTracking";
import { TabbedView } from "../../components/tabbed-view/TabbedView";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext, SettingsContextProvider, SettingsContextType, SettingsType } from "../../contexts/SettingsContext";
import { AnalysisType } from "../../hooks/UseGraph";
import { CASE_TYPE_ID, GroupingKeys } from "../../models/Dfg";
import { getEdgeLabelText, getEdgeStat } from "../../utils/DfgUtils";
import { viewSettingsInitialization } from "../../utils/Initializers";
import { KpiTypes, StatisticTypes } from "../../models/KpiTypes";
import { TimeMode } from "../../models/ApiTypes";
import { useColumnValues } from "../../hooks/UseColumnValues";


export function OrderTrackingContextAndView() {
    const session = useContext(SessionContext);
    const globalSettings = useContext(SettingsContext);

    const stateId = getOrderTrackingStateId(session.project?.id, session.project?.uploadIdOrderTracking);
    const initialValues = getOrderTrackingSettingsValues(stateId);
    return <SettingsContextProvider initialValues={initialValues} makeSettingsValid={(session, state) => {
        if (!session.project?.eventKeysOrderTracking)
            return state;
        const isGroupingValid = (state.groupingKey === GroupingKeys.MachineType && session.project?.eventKeysOrderTracking?.machineType) ||
         (state.groupingKey === GroupingKeys.Machine && session.project?.eventKeysOrderTracking?.machine);
        const defaultGrouping = session.project?.eventKeysOrderTracking?.machineType !== undefined ? GroupingKeys.MachineType : GroupingKeys.Machine;
        if (!isGroupingValid)
            state.groupingKey = defaultGrouping;
        state.graph.secondGroupingLevel = defaultGrouping;
        state.gantt.timeMode = TimeMode.Absolute;
        return state;
    }}>
        <SettingsSpy onSettingsChanged={(settings) => setOrderTrackingSettingsValues(stateId, settings)}/>
        <OrderTrackingView globalSettings={globalSettings}/>
    </SettingsContextProvider>;
}

export function OrderTrackingView(props: {globalSettings?: SettingsContextType}) {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);

    const columnLabel = session.project?.eventKeysOrderTracking?.caseId;

    const requestOptions = {
        uploadId: session.project?.uploadIdOrderTracking, 
        eventKeys: session.project?.eventKeysOrderTracking
    };

    const columnInfos = useColumnValues(columnLabel ? [columnLabel] : [], requestOptions) ?? {};

    const ordersInitialized = columnLabel === undefined ? false : columnInfos[columnLabel] !== undefined;

    useEffect(() => {
        if (settings.orderTracking.orderFilter === undefined && ordersInitialized)
            settings.setOrderTracking({orderFilter: columnInfos[columnLabel!][0].value as string});

    }, [
        JSON.stringify(columnInfos),
    ]);

    return <TabbedView
        hideFilter={true}
        pages={[
            {
                tabTitle: "common.orderTracking",
                tabSlug: "dfg",
                controller: <OrderTrackingControls/>,
                selectionTrigger: (settings) => {
                    return settings.selection.node !== undefined || settings.selection.edge !== undefined;
                },
                stats: <SideStatisticsOrderTracking/>,
                statsSpotlightId: settings.selection.node !== undefined ? "OrderTracking-Statistics-Node" :
                    settings.selection.edge !== undefined ? "OrderTracking-Statistics-Edge" :
                        "OrderTracking-Statistics-NoSelection",
                spotlightId: "OrderTracking-DFG",
                activator: (preferences) => {
                    settings.set(viewSettingsInitialization(session, settings, preferences, {
                        kpis: [KpiTypes.ThroughputTime, KpiTypes.ScrapRatio],
                        statistic: [StatisticTypes.Mean]
                    }));
                },
                content: <OrderTrackingGraph
                    legend={getLegendProps(settings.kpi.selectedKpi)}
                    globalSettings={props.globalSettings}
                    isObjectCentric={true}
                    zoomControlLocation={ZoomControlLocations.FarRight}
                    analysis={AnalysisType.Times}
                    markupFunc={getNodeMarkupOrderTracking}
                    edgeLabelFunc={(multiEdge, settings, session) => {
                        // we are just using the value from the first edge we find that is not related to the case
                        // this is fine as long as we are just using object centric within the order tracking view
                        const edge = multiEdge.edges.find(e => e.objectType !== CASE_TYPE_ID);
                        return getEdgeLabelText(edge, settings, session);
                    }}
                    edgeColorFunc={(edge) => getEdgeColor(edge, settings, session)}
                    edgeHighlightStatFunc={(multiEdge) => {
                        const edge = multiEdge.edges.find(e => e.objectType !== CASE_TYPE_ID);
                        return getEdgeStat(edge, settings, session);
                    }}
                    edgeHighlightColorFunc={(edge, stat,  minStatistic, maxStatistic, scale) => {
                        if (scale !== undefined)
                            return getEdgeHighlightColor(edge, scale, settings, session);
                    }}
                    nodeHighlightStatFunc={(node) => {
                        if (settings.groupingKey === GroupingKeys.MachineType && settings.kpi.selectedKpi === KpiTypes.ThroughputTime)
                            return node.throughputTime;
                        if (settings.groupingKey === GroupingKeys.Machine && settings.kpi.selectedKpi === KpiTypes.ThroughputTime)
                            return node.busyTimeStatistics?.mean;
                        if (settings.kpi.selectedKpi === KpiTypes.ScrapRatio) {
                            // TODO: add this also for other quantities
                            return node.kpis?.relativeScrapCount;
                        }
                    }}
                    centerMode={AutoCenteringModes.None}
                />,
            }, {
                tabTitle: "explorer.caseGantt",
                tabSlug: "process-gantt",
                spotlightId: "OrderTracking-Gantt",
                statsSpotlightId: settings.selection.node !== undefined ? "OrderTracking-Statistics-Node" :
                    "OrderTracking-Statistics-NoSelection",
                controller:  <div className="controls">
                    <div className="relative">
                        <div className="controlsContent">
                            <OrderNumberControls/>
                        </div>
                    </div>
                </div>,
                selectionTrigger: (settings) => {
                    return settings.selection.node !== undefined;
                },
                activator: () => {
                    // The gantt chart only handles cases, so we need to switch to MachineType.
                    // Because MachineType is case in this case.
                    settings.set({
                        groupingKey: GroupingKeys.MachineType,
                    });
                },
                stats: <SideStatisticsOrderTracking/>,
                content: <OrderTrackingGantt globalSettings={props.globalSettings!} />,
            }
        ]}
    />;
}


export function getOrderTrackingStateId(projectId: string | undefined, uploadIdOrderTracking: string | undefined) {
    // Save state based on both project and upload id so that when we load the component within the same session,
    // the state is loaded correctly and we don't load invalid state that doesn't fit the project or upload.
    return `orderTracking-project-${projectId}-orderTrackingUpload-${uploadIdOrderTracking}`;
}

export function setOrderTrackingSettingsValues(stateId: string, settings: SettingsType) {
    localStorage.setItem(stateId, JSON.stringify(settings));
}

export function getOrderTrackingSettingsValues(stateId: string) {
    const saved = localStorage.getItem(stateId);
    const initialValues = saved ? JSON.parse(saved) : undefined;
    return initialValues as SettingsType | undefined;
}
