import { useContext } from "react";
import { SessionContext } from "../contexts/SessionContext";
import { SettingsContext } from "../contexts/SettingsContext";
import { CaseStatisticsTraceOptions, GetCaseStatisticsResponse, disableAllCalcOptions } from "../models/ApiTypes";
import { EventKeys } from "../models/EventKeys";
import { Datastores } from "../utils/Datastores";
import { ApiHookOptions, useApi } from "./UseApi";

export const defaultApiLimit = 500;

export function useCaseStatistics(request?: Partial<CaseStatisticsTraceOptions>, options?: ApiHookOptions<GetCaseStatisticsResponse> & {
    addEnergyStats?: boolean;
}): [GetCaseStatisticsResponse | undefined, boolean] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const currentFilters = request?.eventFilters ?? settings.previewFilters ?? settings.filters;
    const requestOptions: CaseStatisticsTraceOptions = {
        ...disableAllCalcOptions,
        uploadId: session.eventUpload?.id ?? "",
        uploads: session.project?.uploads,
        eventFilters: currentFilters,
        eventKeys: session.project?.eventKeys ?? {} as EventKeys,
        offset: 0,
        limit: defaultApiLimit,
        calculateTimeAndFreqStats: true,
        ...request,
    };
    
    return useApi(Datastores.getCaseStatistics,
        requestOptions,
        [
            JSON.stringify(requestOptions)
        ], {
            ...options,
            disable: options?.disable || session.eventUpload?.id === undefined || session.project?.eventKeys === undefined,
        });
}