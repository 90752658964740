import { isArray, isEqual, reject, uniq } from "lodash";
import { CustomKpi, disableAllCalcOptions } from "../models/ApiTypes";
import axios, { CancelToken, CancelTokenSource } from "axios";

export class SmartApiCache<RESPONSE, REQUEST_PARAMS> {
    private responseCache: SmartCache<REQUEST_PARAMS, RESPONSE>;

    private numCacheMisses = 0;
    private numCacheHits = 0;

    private ongoingRequests: {
        request: REQUEST_PARAMS;
        promise: Promise<RESPONSE>;
        cancelToken: CancelTokenSource;
        subscriptions: Set<number>;
    }[] = [];

    constructor(
        private apiFunc: (request: REQUEST_PARAMS, cancelToken: CancelToken | undefined) => Promise<RESPONSE>,
        private matchHandling: MatchHandling<REQUEST_PARAMS>,
        size = 32) {
        this.responseCache = new SmartCache<REQUEST_PARAMS, RESPONSE>(matchHandling, size);
    }

    
    /**
     * Returns some cache internals. For debugging and testing purposes only.
     */
    getDebugInfo() {
        return {
            cache: this.responseCache,
            size: this.responseCache.getDebugInfo().size,
            ongoingRequests: this.ongoingRequests,
            numCacheHits: this.numCacheHits,
            numCacheMisses: this.numCacheMisses,
            numCachePreemptions: this.responseCache.getDebugInfo().preemptions,
        };
    }

    cancelSubscription(subscriptionId: number) {
        for (const ongoingRequest of this.ongoingRequests) {
            if (!ongoingRequest.subscriptions.has(subscriptionId))
                continue;

            ongoingRequest.subscriptions.delete(subscriptionId);

            // Cancel requests noone is waiting for anymore
            if (ongoingRequest.subscriptions.size === 0)
                ongoingRequest.cancelToken.cancel();
        }

        this.ongoingRequests = this.ongoingRequests.filter(r => r.subscriptions.size > 0);
    }

    isCached(request: REQUEST_PARAMS) {
        if (this.responseCache.has(request))
            return true;

        return this.ongoingRequests.some(e => this.matchHandling?.matcher(e.request, request));
    }

    /**
     * Returns the number of currently ongoing requests.
     * @param subscriptionId If provided, the number of requests for this subscription is returned. Otherwise, the total 
     * number of requests is returned.
     */
    getRequestCount(subscriptionId?: number ) {
        if (subscriptionId === undefined)
            return this.ongoingRequests.length;
            
        return this.ongoingRequests.filter(r => r.subscriptions.has(subscriptionId)).length;
    }

    private strip(request: REQUEST_PARAMS) {
        return this.matchHandling?.stripper(JSON.parse(JSON.stringify(request)));
    }

    get(request: REQUEST_PARAMS, subscriptionId: number): Promise<RESPONSE> {
        const cached = this.responseCache.get(request);

        // Check cache, and return a resolved promise if we have a hit
        if (cached !== undefined) {
            this.numCacheHits++;
            return Promise.resolve(cached.value);
        }

        // Check if there is another request that is currently ongoing and that matches
        // this request
        const strippedRequest = this.strip(request);
        const ongoingRequest = this.ongoingRequests.find(e => isEqual(this.strip(e.request), strippedRequest) && this.matchHandling?.matcher(e.request, request));
        if (ongoingRequest) {
            // There is! Add the subscription to the list of subscriptions and return the promise
            ongoingRequest.subscriptions.add(subscriptionId);
            this.numCacheHits++;
            return ongoingRequest.promise;
        }

        // Cache miss, issue request
        this.numCacheMisses++;

        const cancelTokenSource = axios.CancelToken.source();

        const promise = this.apiFunc(request, cancelTokenSource.token);

        this.ongoingRequests.push({
            request,
            promise,
            cancelToken: cancelTokenSource,
            subscriptions: new Set([subscriptionId]),
        });

        promise.then((response) => {
            // Cache the response
            this.responseCache.set(request, response);
            this.ongoingRequests = this.ongoingRequests.filter(r => r.request !== request);
        }).catch((err) => {
            this.ongoingRequests = this.ongoingRequests.filter(r => r.request !== request);

            if (axios.isCancel(err))
                return;
            
            reject(err);
        });

        return promise;
    }

    // #region Subscription management that I could copy from ApiCache
    private nextSubscription = 1;

    getSubscriptionId() {
        const id = this.nextSubscription++;
        return id;
    }

    flush() {
        this.responseCache.flush();
    }
}

export type MatchHandling<REQUEST_PARAMS> = {
    matcher: MatchHandler<REQUEST_PARAMS>,
    stripper: RequestStripper<REQUEST_PARAMS>,
}

/**
 * Checks if the request options of `a` also satisfy those of `b`
 */
export type MatchHandler<REQUEST_PARAMS> = (a: REQUEST_PARAMS, b: REQUEST_PARAMS) => boolean;

/**
 * Modifies an existing request and deletes all properties that are handled by the MatchHandler it is bundled with.
 * The remaining properties are used in the caching key
 */
export type RequestStripper<REQUEST_PARAMS> = (request: REQUEST_PARAMS) => Partial<REQUEST_PARAMS>;

export function getDefaultMatchHandling<REQUEST_PARAMS>(): MatchHandling<REQUEST_PARAMS> {
    return {
        matcher: defaultMatchHandler,
        stripper: defaultMatchStripper,
    };
}

function defaultMatchHandler<REQUEST_PARAMS>(a: REQUEST_PARAMS, b: REQUEST_PARAMS): boolean {
    return calculateOptionMatchHandler<REQUEST_PARAMS>(a, b) && calculateCustomKpiMatchHandler<REQUEST_PARAMS>(a, b);
}

function defaultMatchStripper<REQUEST_PARAMS>(request: REQUEST_PARAMS): Partial<REQUEST_PARAMS> {
    calculateOptionStripper(request);
    calculateCustomKpiStripper(request);
    return request;
}

const allCalculateOptions = Object.keys(disableAllCalcOptions);

function calculateOptionMatchHandler<REQUEST_PARAMS>(a: REQUEST_PARAMS, b: REQUEST_PARAMS): boolean {
    const anyA = a as unknown as any;
    const anyB = b as unknown as any;

    for (const option of allCalculateOptions)
        // all calc options requested by B must be included in A
        if (anyB[option] === true && anyA[option] !== true)
            return false;

    return true;
}

function calculateOptionStripper<REQUEST_PARAMS>(request: REQUEST_PARAMS): Partial<REQUEST_PARAMS> {
    for (const option of allCalculateOptions)
        delete (request as unknown as any)[option];

    return request;
}

function calculateCustomKpiMatchHandler<REQUEST_PARAMS>(a: REQUEST_PARAMS, b: REQUEST_PARAMS): boolean {
    const anyA = a as unknown as any;
    const anyB = b as unknown as any;

    // B does not request any custom KPIs, so that's easy.
    if (anyB.customKpis === undefined || anyB.customKpis?.length === 0)
        return true;

    // B requests custom KPIs, but A doesn't. Also easy, but sad.
    if (anyA.customKpis === undefined || anyB.customKpis?.length === 0)
        return false;

    // just to be sure, otherwise things might go boom
    if (!isArray(anyA.customKpis) || !isArray(anyB.customKpis))
        return false;

    for (const kpiB of (anyB.customKpis as CustomKpi[])) {
        // ID, definition and target need to be identical, otherwise there's little we can do
        const bestMatchInA = (anyA.customKpis as CustomKpi[]).find((kpiA: CustomKpi) => {
            return kpiA.id === kpiB.id &&
                kpiA.definition === kpiB.definition &&
                kpiA.target === kpiB.target;
        });

        if (bestMatchInA === undefined)
            return false;

        // Now check if the statistics von B are included in A
        const aggsA = (bestMatchInA as CustomKpi)?.statistics?.aggs ?? [];
        const aggsB = (kpiB as CustomKpi).statistics?.aggs ?? [];

        const areBStatsIncludedInA = aggsB.every(aggB => aggsA.includes(aggB));
        if (!areBStatsIncludedInA)
            return false;

        // This KPI seems to be okay, let's check the next one
    }

    // All of B's custom KPIs are covered by A. Yay!
    return true;
}

function calculateCustomKpiStripper<REQUEST_PARAMS>(request: REQUEST_PARAMS): Partial<REQUEST_PARAMS> {
    delete (request as unknown as any).customKpis;
    return request;
}



/**
 * This class handles the caching part of the SmartApiCache. It considers only the part of
 * the request that's stripped of the properties it deals with a matcher.
 */
class SmartCache<KEY, VALUE> {
    private age = 0;

    /**
     * The number of entries currently in the cach
     */
    private size = 0;

    private preemptions = 0;

    /**
     * IDs are used to uniquely identiy cache entries.
     * This is the next ID to be used.
     */
    private nextId = 0;

    private entries: {
        [key: string]: {
            /**
             * Used to uniquely identify the cache entry
             */
            id: number;
            bucket: string;
            key: KEY;
            age: number;
            value: VALUE;
        }[]
    } = {};

    constructor(private matchHandler: MatchHandling<KEY>, private maxSize = 32) { }

    getDebugInfo() {
        return {
            size: this.size,
            preemptions: this.preemptions,
        };
    }

    flush() {
        this.entries = {};
        this.size = 0;
        this.age = 0;
    }

    /**
     * Check if the cache contains an entry for the given key.
     * Is not considered as a cache hit, and the entry's age is not updated.
     */
    has(key: KEY) {
        const k = JSON.stringify(this.matchHandler?.stripper(JSON.parse(JSON.stringify(key))));
        const bucket = this.entries[k];
        if (!bucket?.length)
            return false;

        return bucket.some(e => this.matchHandler?.matcher(e.key, key));
    }

    /**
     * Returns a cached entry of undefined if there is none.
     */
    get(key: KEY) {
        const k = JSON.stringify(this.matchHandler?.stripper(JSON.parse(JSON.stringify(key))));
        const bucket = this.entries[k];
        if (!bucket?.length)
            return undefined;

        this.age++;

        const entry = bucket.find(e => this.matchHandler?.matcher(e.key, key));
        if (entry) {
            entry.age = this.age;
            return entry;
        }

        return undefined;
    }

    /**
     * Stores an entry in the cache.
     * @returns Returns true if the entry was newly added, false if it was already present.
     */
    set(key: KEY, value: VALUE) {
        this.age++;

        const k = JSON.stringify(this.matchHandler?.stripper(JSON.parse(JSON.stringify(key))));
        if (!this.entries[k])
            this.entries[k] = [];

        // Check if some content already matches that key
        const bucket = this.entries[k];
        const match = bucket.find(e => this.matchHandler?.matcher(e.key, key));
        if (match) {
            match.age = this.age;
            return false;
        }

        // Delete all entries that are a subset of this entry.
        // And also add the new one.
        const prevLength = bucket.length;
        this.entries[k] = bucket.filter(e => !this.matchHandler?.matcher(key, e.key)).concat([{
            key,
            age: this.age,
            value,
            id: this.nextId++,
            bucket: k,
        }]);

        this.size = this.size + this.entries[k].length - prevLength;
        if (this.size > this.maxSize)
            this.preempt(Math.ceil(this.size / 4));

        return true;
    }

    /**
     * Preempts entries from the cache to make room for new ones.
     * @param count The number of entries to preempt
     */
    private preempt(count: number) {
        this.preemptions++;

        const entriesToRemove = Object.values(this.entries).flatMap(e => e).sort((a, b) => b.age - a.age).slice(0, count);
        const idsToRemove = new Set<number>(entriesToRemove.map(e => e.id));
        const bucketKeys = uniq(entriesToRemove.map(e => e.bucket));
        for (const bucketKey of bucketKeys) {
            const bucket = this.entries[bucketKey];
            if (!bucket)
                continue;

            const bucketLength = bucket.length;
            this.entries[bucketKey] = bucket.filter(e => !idsToRemove.has(e.id));
            this.size = Math.max(0, this.size - bucketLength + this.entries[bucketKey].length);
        }
    }
}