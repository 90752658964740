import React, { useContext, useMemo } from "react";
import { SessionContext, SessionType } from "../../contexts/SessionContext";
import { SettingsContext, SettingsType, MatrixComparison, SortByType } from "../../contexts/SettingsContext";
import { useSetupMatrix } from "../../hooks/UseSetupMatrix";
import { GroupingKeys } from "../../models/Dfg";
import { EventKeys } from "../../models/EventKeys";
import Matrix, { MatrixHeaderEntry } from "../../components/matrix/Matrix";
import { Formatter } from "../../utils/Formatter";
import Spinner from "../../components/spinner/Spinner";
import { SetupMatrixElementSchema, SetupMatrixSchema } from "../../models/ApiTypes";
import { NoDataAvailable } from "../../components/no-data-available/NoDataAvailable";
import i18n from "../../i18n";
import { SortOrder } from "../../models/KpiTypes";
import { MatrixCellProps } from "../../components/matrix/MatrixCell";
import { nodeHighlightColorMapDefault } from "../../components/dfg/DfGraph";
import { DfgLegend } from "../../components/dfg/DfgLegend";
import { Legend } from "../../components/graph/Legend";
import colors from "../../colors.json";
import { MachineSelector } from "../../components/controls/MachineSelector";
import { TrayElement } from "../../components/tray/TrayElement";
import { SelectionPage } from "../../components/selection-page/SelectionPage";

export function SetupMatrix() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const isDurationColoringEnabled = settings.kpiMatrix.comparison === MatrixComparison.None && settings.kpiMatrix.highlightDeviations;

    const [data, isDataLoading, comparisonData, isComparisonDataLoading] = useSetupMatrixActualAndPlanned(session, settings);

    const sortedNodes = useMemo(() => {
        const nodes = data?.nodes ?? [];
        const reverseModifier = settings.kpi.sortOrder === SortOrder.Ascending ? 1 : -1;

        if (settings.kpi.sortBy === SortByType.Alphabetical)
            return [...nodes.sort((a, b) => {
                const valueA = a.activityValues.product.value ?? "";
                const valueB = b.activityValues.product.value ?? "";
                return valueA.localeCompare(valueB) * reverseModifier;
            })];

        // For frequency and KPI, we need to average over the rows. So we have
        // to find all corresponding transitions first.
        const sortValueLookup: { [nodeId: string]: number | undefined } = {};

        // First: create a lookup for transitions originating per node.
        // Otherwise this is slow for large datasets.
        const transitionsFromNode: { [nodeId: string]: SetupMatrixElementSchema[] } = {};
        data?.transitions.forEach(t => {
            if (!transitionsFromNode[t.from])
                transitionsFromNode[t.from] = [t];
            else
                transitionsFromNode[t.from].push(t);
        });

        nodes.forEach(node => {
            const rowTransitions = transitionsFromNode[node.id] ?? [];
            const sortValues = rowTransitions.map(transition => {
                return settings.kpi.sortBy === SortByType.Kpi ?
                    transition?.timeStatistics?.[settings.kpi.statistic] :
                    transition.frequencyStatistics.sum;
            }).filter(v => v !== undefined);

            // sortValue = sum of all existing transitions / number of existing transitions

            // except for frequency we do not want to divide by the number of existing transitions
            // 
            // "existing transitions" are those that are returned by the API. The API does
            // not return transition for all cells, often many are left blank.
            const sortValue = (sortValues.reduce((a, b) => a! + b!, 0) ?? 0) / (settings.kpi.sortBy === SortByType.Kpi ? (sortValues.length || 1) : 1);
            sortValueLookup[node.id] = sortValue;
        });
        return [...nodes.sort((a, b) => {
            const valueA = sortValueLookup[a.id] ?? Number.MAX_SAFE_INTEGER;
            const valueB = sortValueLookup[b.id] ?? Number.MAX_SAFE_INTEGER;
            return (valueA - valueB) * reverseModifier;
        })];
    }, [
        settings.kpi.sortOrder,
        settings.kpi.sortBy,
        data,
        settings.kpi.statistic,
    ]);

    //Header x and Header y are the same so we pass only headerX
    const { headerX, viewData } = useMemo(() => {
        const getVariant = (value: number, hasComparison: boolean) => {
            if (settings.kpiMatrix.comparison === MatrixComparison.None)
                return "default";

            if (!hasComparison)
                return "purple";

            return value <= 0 ? "green" : "red";
        };

        if (!data)
            return {
                headerX: [],
                viewData: [],
            };



        const nodeToIdx: { [id: string]: number } = {};
        data.nodes.forEach((n, idx) => {
            nodeToIdx[n.id] = idx;
        });

        // Build lookup table, and find min/max while doing so
        const transitionLookup: { [key: number]: SetupMatrixElementSchema } = {};
        let min: number | undefined = undefined;
        let max: number | undefined = undefined;
        data.transitions.forEach(t => {
            const key = nodeToIdx[t.from] * data.nodes.length + nodeToIdx[t.to];
            transitionLookup[key] = t;
            const value = t.timeStatistics?.[settings.kpi.statistic];
            if (value !== undefined) {
                min = Math.min(value, min ?? Number.MAX_SAFE_INTEGER);
                max = Math.max(value, max ?? Number.MIN_SAFE_INTEGER);
            }
        });

        const viewData: MatrixCellProps[][] = [];
        const numNodes = sortedNodes.length;
        for (let y = 0; y < numNodes; y++)
            for (let x = 0; x < numNodes; x++) {
                if (viewData[x] === undefined)
                    viewData[x] = [];

                const xNode = sortedNodes[x];
                const yNode = sortedNodes[y];

                // Find corresponding transition
                if (xNode.id === yNode.id) {
                    continue;
                }

                const transition = transitionLookup[nodeToIdx[yNode.id] * data.nodes.length + nodeToIdx[xNode.id]];
                if (!transition) {
                    // blank cell
                    viewData[x][y] = {
                        x,
                        y,
                        title: "-",
                        from: yNode,
                        to: xNode,
                    };
                    continue;
                }

                const comparisonTransition = comparisonData?.transitions.find(t => t.from == yNode.id && t.to === xNode.id);

                const value = transition.timeStatistics?.[settings.kpi.statistic];
                const delta = (value ?? 0) - (comparisonTransition?.timeStatistics?.[settings.kpi.statistic] ?? 0);
                viewData[x][y] = {
                    x,
                    y,
                    title: Formatter.formatDurationShort(value, undefined, session.numberFormatLocale),
                    variant: settings.kpiMatrix.highlightDeviations ? getVariant(delta, comparisonTransition !== undefined) : undefined,
                    info: getFormattedComparison(session, settings, transition, comparisonTransition),
                    transition,
                    comparisonTransition,
                    from: yNode,
                    to: xNode,
                    color: !isDurationColoringEnabled || value === undefined || min === undefined || max === undefined || ((max ?? 0) - (min ?? 0) === 0) ? undefined :
                        nodeHighlightColorMapDefault((value - min) / ((max - min) | 1)),
                };
            }

        return {
            viewData,
            headerX: sortedNodes.map(node => {
                return {
                    title: node.activityValues.product.value,
                } as MatrixHeaderEntry;
            }),
        };
    }, [
        settings.kpiMatrix.highlightDeviations,
        sortedNodes,
        comparisonData,
        session.numberFormatLocale,
        session.locale,
        settings.kpiMatrix.comparison,
    ]);

    const isLoading = isDataLoading ||
        (settings.kpiMatrix.comparison !== MatrixComparison.None && isComparisonDataLoading);

    const isEmpty = data !== undefined && (data.nodes.length === 0 || data.transitions.filter(t => t.from !== t.to).length === 0);

    if (!isLoading && !settings.kpiMatrix.machineName)
        return <SelectionPage
            title={i18n.t("setupMatrix.title").toString()}
            description={i18n.t("setupMatrix.noMachineDescription").toString()}
            selectorComponent={
                <MachineSelector className="dropdownLight mbl sizeConstrainedSelect" />
            } />;

    const startNewAnalysisButton = <div className="bottomLeft">
        <TrayElement>
            <button
                className="shortcutButton"
                onClick={() => {
                    settings.mergeSet({
                        kpiMatrix: {
                            machineName: ""
                        }
                    });
                }
                }>
                {i18n.t("setupMatrix.startNewAnalysis").toString()}
            </button>
        </TrayElement>
    </div>;

    if (!isLoading && isEmpty)
        return <>
            <NoDataAvailable visible={true} title="setupMatrix.errorTitle" message="setupMatrix.noData" />
            {startNewAnalysisButton}
        </>;

    return <div className="fillParentMatrix">
        <Spinner isLoading={isLoading} />
        {!isLoading && !isEmpty && <>
            <div className="growContainer">
                {isDurationColoringEnabled && <DfgLegend
                    style={{ right: 0, marginRight: 10, marginTop: -6 }}
                    colormap={nodeHighlightColorMapDefault}
                    leftLabel="common.durationShort"
                    rightLabel="common.durationLong" />}
                {settings.kpiMatrix.highlightDeviations && settings.kpiMatrix.comparison !== MatrixComparison.None && <Legend
                    className="dfgLegendContainer"
                    style={{ right: 0, marginRight: 10 }}
                    items={[{
                        color: colors.$setupGraphActualFaster,
                        label: "setupMatrix.fasterThanPlanned"
                    }, {
                        color: colors.$setupGraphActualSlower,
                        label: "setupMatrix.slowerThanPlanned"
                    }, {
                        color: colors.$unplanned,
                        label: "common.noPlanningData"
                    }]} />}
            </div>
            <Matrix
                labelHeaderX="setupMatrix.to"
                labelHeaderY="setupMatrix.from"
                title={<div className="title">{i18n.t("setupMatrix.title").toString()}</div>}
                headerX={headerX}
                headerY={headerX}
                data={viewData}
                cellWidth={115}
                cellHeight={40}
                onClick={() => {
                    if (settings.selection.matrixElement)
                        settings.setSelection({});
                }} />
        </>}
        {startNewAnalysisButton}
    </div>;
}

function getFormattedComparison(session: SessionType, settings: SettingsType, transition: SetupMatrixElementSchema | undefined, comparisonTransition: SetupMatrixElementSchema | undefined): string | undefined {
    const getSign = (value: number) => {
        return (value > 0) ? "+" : "";
    };

    if (transition === undefined)
        return undefined;

    const timeStatistic = transition?.timeStatistics?.[settings.kpi.statistic];
    const comparisonTimeStatistic = comparisonTransition?.timeStatistics?.[settings.kpi.statistic];

    switch (settings.kpiMatrix.comparison) {
        case MatrixComparison.None:
            return undefined;

        case MatrixComparison.Planning:
            return Formatter.formatDurationShort(comparisonTimeStatistic, undefined, session.numberFormatLocale);

        case MatrixComparison.AbsoluteDeviation: {
            if (timeStatistic === undefined || comparisonTimeStatistic === undefined)
                return undefined;

            const delta = timeStatistic - comparisonTimeStatistic;
            return getSign(delta) + Formatter.formatDurationShort(delta, undefined, session.numberFormatLocale);
        }

        case MatrixComparison.RelativeDeviation: {
            if (!comparisonTimeStatistic || timeStatistic === undefined)
                return undefined;

            const relative = (timeStatistic - comparisonTimeStatistic) / comparisonTimeStatistic;
            return getSign(relative) + Formatter.formatPercent(relative, 1, 1, session.numberFormatLocale);
        }
    }
}

export function useSetupMatrixActualAndPlanned(session: SessionType, settings: SettingsType): [
    SetupMatrixSchema | undefined,
    boolean,
    SetupMatrixSchema | undefined,
    boolean,
] {
    const [data, isDataLoading] = useSetupMatrix({
        machines: settings.kpiMatrix.machineName ? [settings.kpiMatrix.machineName] : [],
        eventFilters: settings.previewFilters ?? settings.filters,
        uploadId: session.project?.uploadId ?? "",
        eventKeys: {
            ...session.project!.eventKeys,
            activityKeysGroup: GroupingKeys.Machine,
        } as EventKeys,
    }, {
        disable: !settings.kpiMatrix.machineName ||
            !session.project?.uploadId ||
            !session.project?.eventKeys?.passId,
    });

    const [comparisonData, isComparisonDataLoading] = useSetupMatrix({
        machines: settings.kpiMatrix.machineName ? [settings.kpiMatrix.machineName] : [],
        // We do not send the filters here because it might lead to strange behavior.
        // The planning data does not have all labels and it may happend that filters that work
        // for the actual data do not work for the planning data.
        eventFilters: [],
        uploadId: session.project?.uploadIdPlan ?? "",
        eventKeys: {
            ...session.project!.eventKeysPlan,

            // TODO: Fix this hack ¯\_(ツ)_/¯
            product: session.project?.eventKeys?.product,
            activityKeysGroup: GroupingKeys.Machine,
        } as EventKeys,
    }, {
        disable: !settings.kpiMatrix.machineName ||
            !session.project?.uploadIdPlan ||
            !session.project?.eventKeysPlan?.passId ||
            settings.kpiMatrix.comparison === MatrixComparison.None,
    });

    return [data, isDataLoading, comparisonData, isComparisonDataLoading];
}