import { useContext } from "react";
import { DfgRequest, disableAllCalcOptions, EquipmentStatisticsSchema } from "../models/ApiTypes";
import { EventKeys } from "../models/EventKeys";
import { Datastores } from "../utils/Datastores";
import { ApiHookOptions, useApi } from "./UseApi";
import { SettingsContext } from "../contexts/SettingsContext";
import { SessionContext } from "../contexts/SessionContext";

export function useEquipmentStats(request: Partial<DfgRequest>, options?: ApiHookOptions<EquipmentStatisticsSchema>): [EquipmentStatisticsSchema | undefined, boolean] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const eventKeys = request.eventKeys ?? session.project?.eventKeys;

    const requestOptions: DfgRequest = {
        ...disableAllCalcOptions,
        ...request,
        eventFilters: request.eventFilters ?? settings.previewFilters ?? settings.filters,
        eventKeys: eventKeys ?? { } as EventKeys,
        uploadId: request.uploadId ?? session.project?.uploadId ?? "",
        uploads: session.project?.uploads,
    };

    const setDisabled = options?.disable || !eventKeys || !requestOptions.uploadId;

    return useApi(Datastores.getEquipmentNodeStatistics, requestOptions, [ JSON.stringify(requestOptions) ], { ...options, disable: setDisabled });
}