import { isEqual } from "lodash";
import { ColumnInfo, ColumnInfoNg } from "../models/ApiTypes";
import i18n from "../i18n";
import { NodeActivitySchema, GroupingKeys } from "../models/Dfg";
import { ActivityItem, EventKeys } from "../models/EventKeys";

export const allowedGroupingReduced = [
    GroupingKeys.Machine, GroupingKeys.Location, GroupingKeys.MachineType,
    GroupingKeys.MachineValueStream, GroupingKeys.LocationValueStream, GroupingKeys.MachineTypeValueStream,
    GroupingKeys.ObjectType, GroupingKeys.MachineObjectType, GroupingKeys.ObjectTypeValueStream, GroupingKeys.MachineObjectTypeValueStream,
    GroupingKeys.PassValueStream
];

export const allowedGroupingReducedWithoutObjectType = [
    GroupingKeys.Machine, GroupingKeys.Location, GroupingKeys.MachineType,
    GroupingKeys.MachineValueStream, GroupingKeys.LocationValueStream, GroupingKeys.MachineTypeValueStream,
    GroupingKeys.PassValueStream
];

export const allowedGroupingWithoutObjectType = [
    ...allowedGroupingReducedWithoutObjectType,
    GroupingKeys.None, GroupingKeys.NoneValueStream
];

export const allowedGroupingWithoutMachineObject = [...allowedGroupingWithoutObjectType, GroupingKeys.ObjectType, GroupingKeys.ObjectTypeValueStream];
export const allowedGroupingReducedWithoutMachineObject = [...allowedGroupingReducedWithoutObjectType, GroupingKeys.ObjectType, GroupingKeys.ObjectTypeValueStream];

export const groupMapping: {
    groupKey: GroupingKeys,
    attributes: (keyof NodeActivitySchema)[],
    labelAttributes: (keyof NodeActivitySchema)[],
    shortLabelAttributes: (keyof NodeActivitySchema)[],
    translationLabel: string
}[] = [
    {
        groupKey: GroupingKeys.None,
        attributes: ["operation", "timeComponent", "machine", "machineType", "location"],
        labelAttributes: ["operation", "timeComponent", "machine", "location"],
        shortLabelAttributes: ["operation", "timeComponent", "machine"],
        translationLabel: "common.operation"
    }, {
        groupKey: GroupingKeys.Machine,
        attributes: ["machine", "machineType", "location"],
        labelAttributes: ["machine"],
        shortLabelAttributes: ["machine"],
        translationLabel: "common.machine"
    }, {
        groupKey: GroupingKeys.Location,
        attributes: ["location"],
        labelAttributes: ["location"],
        shortLabelAttributes: ["location"],
        translationLabel: "common.location"
    }, {
        groupKey: GroupingKeys.MachineType,
        attributes: ["machineType"],
        labelAttributes: ["machineType"],
        shortLabelAttributes: ["machineType"],
        translationLabel: "common.machineType"
    },
    // Value stream stuff
    {
        groupKey: GroupingKeys.NoneValueStream,
        attributes: ["operation", "timeComponent", "machine", "machineType", "location", "passId"],
        labelAttributes: ["operation", "timeComponent", "machine", "location", "passId"],
        shortLabelAttributes: ["operation", "timeComponent", "machine", "passId"],
        translationLabel: "common.operation"
    },
    {
        groupKey: GroupingKeys.MachineValueStream,
        attributes: ["machine", "machineType", "location", "passId"],
        labelAttributes: ["machine", "passId", "operation"],
        shortLabelAttributes: ["machine", "passId", "operation"],
        translationLabel: "common.machine"
    },
    {
        groupKey: GroupingKeys.MachineTypeValueStream,
        attributes: ["machineType", "passId"],
        labelAttributes: ["machineType", "passId", "operation"],
        shortLabelAttributes: ["machineType", "passId", "operation"],
        translationLabel: "common.machineType"
    },
    {
        groupKey: GroupingKeys.LocationValueStream,
        attributes: ["location", "passId"],
        labelAttributes: ["location", "passId", "operation"],
        shortLabelAttributes: ["location", "passId", "operation"],
        translationLabel: "common.location"
    },
    {
        groupKey: GroupingKeys.PassValueStream,
        attributes: ["passId"],
        labelAttributes: ["passId", "operation"],
        shortLabelAttributes: ["passId", "operation"],
        translationLabel: "common.orderSequence"
    },
    // Object type stuff
    {
        groupKey: GroupingKeys.ObjectType,
        attributes: ["objectType"],
        labelAttributes: ["objectType"],
        shortLabelAttributes: ["objectType"],
        translationLabel: "common.material"
    },
    {
        groupKey: GroupingKeys.ObjectTypeValueStream,
        attributes: ["objectType", "passId"],
        labelAttributes: ["objectType", "passId", "operation"],
        shortLabelAttributes: ["objectType", "passId", "operation"],
        translationLabel: "common.material"
    },
    {
        groupKey: GroupingKeys.MachineObjectType,
        attributes: ["objectType", "machine", "machineType", "location"],
        labelAttributes: ["machine", "objectType"],
        shortLabelAttributes: ["machine", "objectType"],
        translationLabel: "common.machine"
    },
    {
        groupKey: GroupingKeys.MachineObjectTypeValueStream,
        attributes: ["objectType", "machine", "machineType", "location", "passId"],
        labelAttributes: ["machine", "objectType", "passId", "operation"],
        shortLabelAttributes: ["machine", "objectType", "passId", "operation"],
        translationLabel: "common.machine"
    },
];

/**
 * Returns list of column names that reflect the attributes used for grouping
 */
export function groupKeyToAttributes(groupKey: GroupingKeys | undefined, eventKeys: EventKeys) {
    if (groupKey === undefined)
        return [];

    return groupMapping.find(g => g.groupKey == groupKey)?.attributes.map(g => eventKeys[g]).filter(s => s !== undefined) as string[];
}

export function getActivityLabelFromActivityValues(activityValues: NodeActivitySchema, groupKey: GroupingKeys) {
    return groupMapping.find(g => g.groupKey == groupKey)!.labelAttributes.map(g => getNodeActivityValueLabel(activityValues, g)).filter(s => s !== undefined).join(", ");
}

export function getShortActivityLabelFromActivityValues(activityValues: NodeActivitySchema | undefined, groupKey: GroupingKeys, makePassIdFirst?: boolean) {
    const shortLabelAttributes = [...groupMapping.find(g => g.groupKey == groupKey)!.shortLabelAttributes];
    if (makePassIdFirst && shortLabelAttributes.includes("passId")) {
        const passIdIdx = shortLabelAttributes.findIndex(v => v === "passId");
        shortLabelAttributes.splice(passIdIdx, 1);
        shortLabelAttributes.unshift("passId");
    }
    return shortLabelAttributes.map(g => getNodeActivityValueLabel(activityValues, g)).filter(s => s !== undefined).join(", ");
}

function getNodeActivityValueLabel(activityValues: NodeActivitySchema | undefined, key: keyof NodeActivitySchema) {
    const value = (activityValues && activityValues[key]) ? activityValues[key]!.value : undefined;
    if (key === "timeComponent")
        return translateTimeComponentActivityValue(value);
    if (value !== undefined && key === "passId")
        return i18n.t("common.orderSequenceShort") + " " + value;
    return value;
}

export function getActivityLabelFromColumnInfo(columnInfo: ColumnInfo | ColumnInfoNg, attributes: (keyof NodeActivitySchema)[], groupKey?: GroupingKeys) {
    if (groupKey === undefined)
        return columnInfo.id;

    const labelAttributes = groupMapping.find(g => g.groupKey == groupKey)!.labelAttributes;
    return labelAttributes.map(labelAttribute => {
        const attributeIndex = attributes.findIndex(attribute => attribute === labelAttribute);
        if (attributeIndex < 0)
            return;

        const value = (columnInfo.value as string[])[attributeIndex];
        if (value)
            return attributeToLabel(labelAttribute, value);
    }).filter(s => s !== undefined).join(", ");
}

export function getActivityLabelFromActivityItem(activityItem: ActivityItem, groupKey?: GroupingKeys) {
    if (groupKey !== undefined)
        return groupMapping.find(g => g.groupKey == groupKey)!.labelAttributes.map(g => {
            return attributeToLabel(g, activityItem.values[activityItem.keys.findIndex(v => v === g)]);
        }).filter(s => s !== undefined && s != "").join(", ");

    const result: string[] = [];
    for (const idx in activityItem.keys) {
        const key = activityItem.keys[idx] as keyof NodeActivitySchema;
        const value = activityItem.values[idx];

        result.push(attributeToLabel(key, value));
    }

    return result.join(", ");
}

function attributeToLabel(key: string, value: string | undefined) {
    if (value === undefined)
        return "";

    // Time components need an extra translation step here
    const keyTranslations: { [key: string]: string } = {
        "pass_change": "common.passChange",
        "setup": "common.setup",
        "failure": "common.failure",
        "maintenance": "common.maintenance",
        "interruption": "common.interruption",
        "production": "common.production",
        "unknown": "common.subTimeOther",
    };
    if (key === "timeComponent")
        return i18n.t(keyTranslations[value]).toString();
    if (key === "passId")
        return i18n.t("common.orderSequenceShort") + " " + value;
    return value;
}

const groupingKeyToApiMap = new Map<string | undefined, GroupingKeys>();
for (const value of Object.values(GroupingKeys)) {
    groupingKeyToApiMap.set(value, value);
}

export function groupingKeyToApi(groupingKey?: GroupingKeys) {
    return groupingKeyToApiMap.get(groupingKey) as string | undefined;
}

export function apiToGroupingKey(groupingKey?: string) {
    return groupingKeyToApiMap.get(groupingKey);
}

export function groupSupportsConsolidatePasses(groupKey: GroupingKeys | undefined) {
    const activityKeys = groupMapping.find(g => g.groupKey == groupKey)?.attributes ?? [];
    return !activityKeys.includes("operation");
}

/**
 * Returns the grouping key associated with a given set of attributes.
 * There's probably a better way to do what you have in mind. Unless you're
 * working on the attribute- or sequence filter, of course.
 */
export function attributesToGroupingKey(activities: (keyof NodeActivitySchema)[], eventKeys: EventKeys | undefined) {
    const activitiesMapping = groupMapping.map((g) => {
        return {
            groupKey: g.groupKey,
            groupEventKeys: groupKeyToActivityKeys(g.groupKey, eventKeys)
        };
    });

    const candidates = activitiesMapping.filter(a => a.groupEventKeys.length > 0 && isEqual(a.groupEventKeys, activities));

    // As the grouping is ordered from common to specific, we're
    // using the last matching candidate.
    return candidates[candidates.length - 1]?.groupKey;
}

export function groupKeyToActivityKeys(groupKey: GroupingKeys, eventKeys: EventKeys | undefined) {
    return groupMapping.find(g => g.groupKey == groupKey)!.attributes.filter(s => eventKeys && eventKeys[s] !== undefined);
}

export function groupingKeyToTranslationLabel(groupingKey: GroupingKeys) {
    return groupMapping.find(g => g.groupKey === groupingKey)?.translationLabel ?? "";
}

export function translateTimeComponentActivityValue(value: string | undefined) {
    if (value === undefined)
        return value;
    return i18n.t({
        "production": "common.production",
        "interruption": "common.interruption",
        "setup": "common.setup",
        "failure": "common.failure",
        "unknown": "common.subTimeOtherConfirmations",
        "passChange": "common.passChange",
        "pureObject": "common.material",
        // Make sure we can handle non-spec conform backend response as well (to be fixed in backend)
        "pass_change": "common.passChange",
        "pure_object": "common.material",
    }[value] ?? value);
}

export function translateActivityKey(value: string | undefined) {
    if (value === undefined)
        return value;
    return i18n.t({
        "operation": "common.confirmation",
        "location": "common.location",
        "machine": "common.machine",
        "machineType": "common.machineType",
        "passId": "common.orderSequence",
        "objectType": "common.material"
    }[value] ?? value);
}
