import { useContext } from "react";
import { SessionContext } from "../contexts/SessionContext";
import { DeviationOptions } from "../models/ApiTypes";
import { BaseGraph, GroupingKeys } from "../models/Dfg";
import { useGraph } from "./UseGraph";
import { useDeviationGraphs } from "./UseGraphDeviation";

export enum ApiModes {
    PlanningData = "planningData",
    Routings = "routings",
}

/**
 * Hook for accessing deviation graph statistics. Either uses the /api/processmining/deviations/dfgs
 * or the /api/processmining/dfgs endpoint, depending on weather the project uses routings or not.
 * @param disable if true, the hook will not do anything. It's used in scenarios where the data is
 * needed conditionally, and the hook count should be kept constant.
 */
export function useUnifiedDeviationGraphs(requestOptions: Partial<DeviationOptions> & { groupingKey?: GroupingKeys }, options?: Partial<{
    disable: boolean,
}>): [BaseGraph | undefined, BaseGraph | undefined, BaseGraph | undefined, boolean, ApiModes] {
    const session = useContext(SessionContext);
    const apiMode = session.project?.uploads?.routings !== undefined ? ApiModes.Routings : ApiModes.PlanningData;

    const [deviationGraph, isLoading] = useDeviationGraphs(requestOptions, undefined, options?.disable || apiMode !== ApiModes.PlanningData);

    const graph = useGraph({
        ...requestOptions,
        calculateDeviations: true,
        calculatePlanned: true,
    }, undefined, false, options?.disable || apiMode === ApiModes.PlanningData);

    const planned = apiMode === ApiModes.PlanningData? deviationGraph?.planned : graph?.planned;
    const deviation = apiMode === ApiModes.PlanningData? deviationGraph?.deviation : graph?.deviation;
    const actual = apiMode === ApiModes.PlanningData? deviationGraph?.actual : graph;

    return [
        actual,
        planned,
        deviation,
        isLoading,
        apiMode,
    ];
}
