import { useContext, useEffect } from "react";
import { SessionContext } from "../../contexts/SessionContext";
import { RcaSettingsType, SettingsContext } from "../../contexts/SettingsContext";
import { defaultProductLimit } from "../../Global";
import { AnalysisType, useGraph } from "../../hooks/UseGraph";
import { useProductCaseAggregations } from "../../hooks/UseProductCaseAggregations";
import { GroupingKeys } from "../../models/Dfg";
import { buildRcaSelectionFilter, getRcaSelectionSyncUpdate, RcaSelectionSyncData } from "./RcaUtils";

/**
 * Convenience hook for getting a basic graph with restrictions tailored to the RCA.
 *
 * The RCA only uses machine level data, so we restrict the grouping and expects
 * explicit rca filters instead of the global filters (otherwise node/edge names might disappear)
 */
export function useRcaGraph(options: {rca?: RcaSettingsType}) {
    const rca = options.rca;

    return useGraph({
        groupingKey: GroupingKeys.Machine,
        calculateTimeAndFreqStats: true,
        calculateActivityValues: true,
        calculateNodes: true,
        calculateEdges: true,
        eventFilters: rca?.rcaFilters,
    }, AnalysisType.Times, false, rca === undefined);
}

/**
 * Get the graph filters that are associated with the currently selected feature.
 */
export function useRcaSelectionFilters(options: {
    rca?: RcaSettingsType,
}) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const rca = options.rca;
    const graph = useRcaGraph({rca});  // use RCA filters instead of main filters otherwise node/edge names might disappear
    const filter = buildRcaSelectionFilter(settings.selection, session, graph);
    return (rca?.rcaFilters || []).concat(filter ? [filter] : []);
}

export function useSyncedRcaSelection(options: {rca?: RcaSettingsType}) {
    const settings = useContext(SettingsContext);
    const rca = options.rca;
    const rcaSelectionSyncData = useRcaSelectionSyncData({rca});
    const isDataAvailable = rcaSelectionSyncData !== undefined;

    useEffect(() => {
        // Any time we visit the component or update the RCA, we need to make sure the selection is synchronized
        // with any other selections that might have been set previously independently of the RCA.
        if (rcaSelectionSyncData === undefined)
            return;

        const selectionSyncUpdate = getRcaSelectionSyncUpdate(rca?.result?.featureImportance || [], settings.selection, rcaSelectionSyncData);
        if (selectionSyncUpdate === undefined)
            return;
        settings.setSelection(selectionSyncUpdate);
    }, [rca, isDataAvailable]);
}

/**
 * Obtain all data needed for synchronizing RCA selection with other selections
 */
export function useRcaSelectionSyncData(options: {
    rca?: RcaSettingsType,
}): RcaSelectionSyncData | undefined {
    const session = useContext(SessionContext);
    const graph = useRcaGraph({rca: options.rca});

    // Not restricting this request to increase chances of
    // it being cached
    const [productsResponse] = useProductCaseAggregations({
        limit: defaultProductLimit
    });

    const productColumn = session.project?.eventKeys?.product;
    // if no products are labeled, it's ok to not have any products, otherwise
    // we need to wait for the response to have data.
    const products = productColumn === undefined ? [] : productsResponse?.products;

    if (graph !== undefined && products !== undefined)
        return {
            graph,
            products: products,
            productColumn
        };
}
