import React, { useContext, useMemo, useRef } from "react";
import { get, isEqual, isFunction } from "lodash";
import { CalculateOptions, TimePeriodFrequencies, TimeperiodDeviationStatisticsSchema } from "../../models/ApiTypes";
import { Bar, GroupGraph } from "../../components/graph/GroupGraph";
import Spinner from "../../components/spinner/Spinner";
import { Alignments } from "../../components/spotlight/Spotlight";
import { SessionContext, hasDownloadPermission } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import i18n from "../../i18n";
import { Formatter } from "../../utils/Formatter";
import { Timestamp, addStep, timestampSort, toUserTimezone } from "../../utils/TimezoneUtils";
import { ChartProps, getAxisLabel, getBarColor, getDefaultComparisonProp, getDefaultValueProp } from "../product-chart/ProductChart";
import { GraphLine, commonSelectionLineProps } from "../graph/GraphCommon";
import DownloadFile, { TemplateType } from "../download-file/DownloadFile";
import { TimeperiodApis, getKpiDefinition as getKpiDefinition, getUnit } from "../../models/Kpi";
import { KpiComparisons } from "../../contexts/ContextTypes";
import { useAggregatedTimeperiods } from "../../hooks/UseAggregatedTimeperiods";
import { getLegend } from "../../views/process-kpi-chart/ProductProcessKpiChart";
import { useStatistics } from "../../hooks/UseStatistics";
import { getMessage } from "../../views/process-kpi-chart/ProcessKpiChart";

export type TimeperiodProps = ChartProps<CalculateOptions>;

export function TimeperiodChart(props: TimeperiodProps & {
    addEnergyStats?: boolean,
}) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const kpiDefinition = getKpiDefinition(settings.kpi.selectedKpi, { session, settings });

    const container = useRef<HTMLDivElement>(null);

    const isDeviationApi = (settings.kpi.comparisons === KpiComparisons.Planning && kpiDefinition?.allowedComparisons.includes(KpiComparisons.Planning)) || !!kpiDefinition?.useDeviationApi;

    let api = kpiDefinition?.timeperiodApi ?? TimeperiodApis.Case;
    if (isDeviationApi)
        api = TimeperiodApis.CaseDeviation;

    const [data, isLoading] = useAggregatedTimeperiods({
        frequency: settings.kpi.timeScale,
        ...props.requestOptions,
        ...kpiDefinition?.apiParameters,
        customKpis: kpiDefinition?.timeperiodCustomKpis ?? kpiDefinition?.productCustomKpis,
    }, api, {
        disable: session.project === undefined,
        addEnergyStats: props.addEnergyStats,

    });

    const [stats,] = useStatistics(undefined);

    const { chartData } = useMemo(() => {
        if (!data)
            return {};

        const tsToData: { [key: string]: TimeperiodDeviationStatisticsSchema } = {};
        let minTime: Timestamp | undefined = undefined;
        let maxTime: Timestamp | undefined = undefined;

        for (const timeperiod of data?.timeperiods ?? []) {
            if (timeperiod.timeperiodStartTime === undefined)
                continue;
            const ts = toUserTimezone(timeperiod.timeperiodStartTime, session.timezone);

            tsToData[ts.toString()] = timeperiod;
            if (minTime === undefined || ts < minTime)
                minTime = ts;

            if (maxTime === undefined || ts > maxTime)
                maxTime = ts;
        }

        const isComparisonHighlightingEnabled = settings.kpi.comparisons === KpiComparisons.Planning && settings.kpi.highlightDeviations;

        const chartData: Bar<TimeperiodDeviationStatisticsSchema>[][] = [];

        // Generate chart data
        if (minTime !== undefined) {
            let itr = Timestamp.clone(minTime!);

            const valuePropName = props.getValueProp?.(session, settings, true) ?? getDefaultValueProp(session, settings, true);
            const comparisonPropName = props.getComparisonProp?.(settings, true) ?? getDefaultComparisonProp(session, settings, true);
            do {
                const element = tsToData[itr.toString()];
                const label = Formatter.formatTime(settings.kpi.timeScale, itr, session.timezone);
                const tooltip = settings.kpi.timeScale !== TimePeriodFrequencies.Day ?
                    Formatter.formatTime(TimePeriodFrequencies.Day, itr, session.timezone) + " - " + Formatter.formatTime(TimePeriodFrequencies.Day, addStep(itr, session.timezone, settings.kpi.timeScale, true), session.timezone) :
                    undefined;

                const value = valuePropName ? get(element, valuePropName) : undefined;

                const bar = [{
                    label,
                    value,
                    data: element,
                    tooltip,
                } as Bar<TimeperiodDeviationStatisticsSchema>];

                const comparisonValue: number | undefined = comparisonPropName ? get(element, comparisonPropName) : undefined;

                if (comparisonValue !== undefined && isFinite(comparisonValue))
                    bar.push({
                        label,
                        value: comparisonValue,
                        data: element,
                        tooltip,
                    } as Bar<TimeperiodDeviationStatisticsSchema>);

                if (isComparisonHighlightingEnabled)
                    bar[0].barColor = getBarColor(kpiDefinition, value, comparisonValue);


                chartData.push(bar);
                itr = addStep(itr, session.timezone, settings.kpi.timeScale);
            } while (timestampSort(itr, maxTime!) <= 0);
        }

        return {
            chartData,
        };
    }, [
        session.locale,
        session.timezone,
        session.numberFormatLocale,
        settings.kpi.selectedKpi,
        settings.kpi.statistic,
        settings.kpi.comparisons,
        settings.kpi.timeScale,
        settings.quantity,
        settings.kpi.highlightDeviations,
        settings.kpi.relativeToThroughputTime,
        data,
    ]);

    // This removes the selection in case the user excluded the currently selected timeperiod
    useMemo(() => {
        if (!chartData || !settings.selection.timeperiod)
            return;

        if (!chartData?.some(c => c[0].data?.timeperiodStartTime === settings.selection.timeperiod?.timeperiodStartTime))
            queueMicrotask(() => {
                settings.setSelection({});
            });
    }, [
        settings.selection.timeperiod,
        chartData,
    ]);

    const selectedBarIdx = chartData?.findIndex(e => isEqual(e[0].data?.timeperiodStartTime, settings.selection.timeperiod?.timeperiodStartTime));

    const unit = getUnit(kpiDefinition?.unit, settings.kpi.statistic);

    const isValidData = (chartData ?? []).length > 0;

    const enableDownload = settings.kpi.comparisons === KpiComparisons.Planning || settings.kpi.comparisons === KpiComparisons.None;

    const downloadAllowed = hasDownloadPermission(session);

    const title = isFunction(props.title) ? props.title(settings) : props.title;

    const selectedTime = settings.selection.timeperiod;

    const selectionLine = useMemo(() => {

        const selectedTime = data?.timeperiods?.find(t => t.timeperiodStartTime === settings.selection.timeperiod?.timeperiodStartTime);

        if (selectedTime === undefined)
            return;

        let selectedElementLine: GraphLine[] = [];
        let minTime: Timestamp | undefined;
        const tsToData: { [key: string]: TimeperiodDeviationStatisticsSchema } = {};
        const ts = selectedTime ? toUserTimezone(selectedTime?.timeperiodStartTime, session.timezone) : undefined;

        if (ts !== undefined){

            tsToData[ts.toString()] = selectedTime as TimeperiodDeviationStatisticsSchema;
            if (minTime === undefined || ts < minTime)
                minTime = ts;
            const itr = Timestamp.clone(minTime!);
            const valuePropName = props.getValueProp?.(session, settings, true) ?? getDefaultValueProp(session, settings, true);

            const element = tsToData[itr.toString()];

            const value = valuePropName ? get(element, valuePropName) : undefined;
            selectedElementLine = value !== undefined ? [{ ...{ value: value, ...commonSelectionLineProps } }] : [];
    
        }
        
        return selectedElementLine;
    }, [
        data,
        settings.kpi.statistic,
        settings.kpi.selectedKpi,
        selectedTime
    ]);


    return <>
        <Spinner isLoading={isLoading} showProjectLoadingSpinner={true} />

        {!isLoading && <>
            {!isValidData && getMessage(i18n.t("kpi.noDataAllCasesEqual"), stats?.numFilteredTraces, isDeviationApi)}
            <div ref={container}>
                {isValidData && isFinite(props.width) && isFinite(props.height) && chartData !== undefined && <GroupGraph
                    initialOffset={{
                        alignment: Alignments.Right,
                        offset: 0,
                    }}
                    horizonalLines={isLoading ? undefined : settings.selection.timeperiod ? selectionLine : []}
                    width={props.width}
                    height={props.height}
                    data={chartData}
                    showYAxisLines={true}
                    showBarValues={true}
                    title={i18n.t(title ?? "").toString()}
                    barPadding={10}
                    minGroupPadding={50}
                    yAxisLabel={getAxisLabel(settings.kpi.selectedKpi, settings.kpi.statistic, session, settings)}
                    onLabelSelected={(groupIdx) => {
                        const timeperiod = chartData[groupIdx][0].data!;
                        settings.setSelection({
                            timeperiod: settings.selection.timeperiod == timeperiod ? undefined : timeperiod,
                        });
                    }}
                    onSelected={(groupIdx, barIdx, data) => {
                        settings.setSelection({
                            timeperiod: (settings.selection.timeperiod === data) ? undefined : data
                        });
                    }}
                    selectedGroupBarIdx={0}
                    selectedGroupIdx={selectedBarIdx}
                    legend={getLegend(settings, settings.kpi.highlightDeviations)}
                    padding={{
                        bottom: 100,
                        left: 60,
                        top: 85,
                    }}
                    yAxisUnit={unit}
                    valueFormatter={(value) => {
                        if (unit)
                            return unit.formatter(value, {
                                numDigits: 1,
                                locale: session.numberFormatLocale,
                                baseQuantity: settings.quantity
                            });
                        return "";
                    }}
                    formatterParams={{
                        numDigits: 1,
                    }}
                />}
            </div>
            {enableDownload && <DownloadFile
                data={chartData ?? []}
                planningData={settings.kpi.comparisons === KpiComparisons.Planning}
                template={TemplateType.Time}
                meta={unit}
                allowed={downloadAllowed}
                title={i18n.t(title ?? "").toString()} />}
        </>
        }
    </>;
}

export function exportTimeData(data: Bar<TimeperiodDeviationStatisticsSchema>[][], planningData?: boolean) {
    const result = [];

    //if planning comparison is selected then we add "Plan" column to the excel sheet
    for (const bar of data) {
        const item = {
            [i18n.t("common.date")]: bar[0]?.label,
            [i18n.t("common.actual")]: bar[0]?.value,
        };

        if (planningData)
            item[i18n.t("common.plan")] = bar[1]?.value;

        result.push(item);
    }

    return result;
}