import { HttpStatusCode } from "axios";
import { flatten } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ApiError, GanttEntry, GanttStatistics, TimeMode } from "../../models/ApiTypes";
import colors from "../../colors.json";
import GanttChart, { GanttElement } from "../../components/gantt-chart/GanttChart";
import { I18nLinks } from "../../components/i18n-links/I18nLinks";
import Shortcuts, { ShortcutContexts } from "../../components/shortcuts/Shortcuts";
import Spinner from "../../components/spinner/Spinner";
import Toast, { ToastTypes } from "../../components/toast/Toast";
import { SessionContext, SessionContextType } from "../../contexts/SessionContext";
import { SettingsContext, SettingsContextType } from "../../contexts/SettingsContext";
import { useGantt } from "../../hooks/UseGantt";
import { useGraph } from "../../hooks/UseGraph";
import i18n from "../../i18n";
import { Graph, GroupingKeys, NodeActivitySchema } from "../../models/Dfg";
import { getTickInterval, startOfTime } from "../../utils/Formatter";
import { getActivityLabelFromActivityValues, translateTimeComponentActivityValue } from "../../utils/GroupingUtils";
import useWindowResize from "../../utils/WindowResizeHook";
import { formatTime, getStatistics } from "./CommonGantt";
import { valueStreamGroupingKeys } from "../../components/controls/GroupingKeyControls";

type UserData = {
    startDate: number,
    endDate: number,
    count: number,
    activity: string,
    activityValues: NodeActivitySchema;
}

export type GanttState = {
    timeMode: TimeMode,
};

export default function ProcessGantt() {
    const settings = useContext(SettingsContext);
    const [dataTooLarge, setDataTooLarge] = useState(false);

    const [gantt, isLoading] = useGantt(undefined, false, (e) => {
        if (!e || e.response?.status !== HttpStatusCode.UnprocessableEntity)
            return;

        const error = (e?.response?.data as ApiError | undefined);
        if (!error || !error.detail.some(d => d.type === "DataTooLargeError"))
            return;

        // OK, data is too large obviously. Let's notify the user
        setDataTooLarge(true);
    });

    useEffect(() => {
        if (isLoading)
            setDataTooLarge(false);
    }, [
        isLoading
    ]);

    const groupLabel = getGanttGroupLabel(settings.groupingKey);
    const graph = useGraph({
        calculateUnknownStats: true,
        useActivityPasses: true,
        calculateNodes: true,
        calculateSetupStats: true,
        calculateBusyStats: true,
        calculateFailureStats: true,
        calculateInterruptionStats: true,
        calculateProductionStats: true,
        calculateMaintenanceStats: true,
        calculateActivityValues: true,
        calculateTimeAndFreqStats: true,
    }, undefined, false, dataTooLarge);

    return <>
        {!dataTooLarge && <RenderProcessGantt
            gantt={gantt}
            isLoading={isLoading}
            bottom={<Shortcuts stack={true} handledSelections={[ShortcutContexts.Node]} />}
            ganttGroupLabel={groupLabel}
            graph={graph}
        />}
        {dataTooLarge && <div className="center fillParent">
            <Toast className="dfgTooLargeToast" visible={true} type={ToastTypes.Info}>
                <I18nLinks id="gantt.processDataTooLargeError" mapping={{
                    filter: {
                        onClick: () => {
                            // Add filter
                            settings.set({
                                filterEditor: {
                                    showFilterEditor: true,
                                    editFilter: undefined,
                                    editFilterIndex: undefined,
                                    editFilterPage: 0,
                                },
                            });
                        },
                    },
                }} />
            </Toast>
        </div>}
    </>;
}

type RenderProcessGanttProps = {
    gantt: GanttEntry[] | undefined,
    isLoading: boolean,
    ganttGroupLabel: string | undefined,
    graph: Graph | undefined
    colormap?: (index: number) => string;
    bottom?: JSX.Element | JSX.Element[];
    noDataPlaceholder?: JSX.Element;
};

export function RenderProcessGantt(props: RenderProcessGanttProps) {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);

    // Set selected row according to current node selection
    const [selectedRow, setSelectedRow] = useState<number | undefined>(undefined);

    useEffect(() => {
        if (settings.selection.node?.id === undefined)
            setSelectedRow(undefined);
        else
            for (const idx in props.gantt ?? []) {
                if (props.gantt![idx].activity === settings.selection.node?.id) {
                    setSelectedRow(+idx);
                    break;
                }
            }
    }, [
        settings.selection.node,
        props.gantt
    ]);

    useWindowResize();

    const statistics = getStatistics(props.gantt);

    const isValueStreamGrouping = valueStreamGroupingKeys.includes(settings.groupingKey);

    const rows = props.gantt?.map(d => getActivityLabelFromActivityValues(d.activityValues, settings.groupingKey ?? GroupingKeys.None)) ?? [];

    const { startTime, tickInterval, columnHeadersMarkup, numHeaderRows } = getGanttColumnHeaders(statistics, settings, session);

    // Fetch bar data
    const data = useMemo(() => {
        const result: GanttElement<UserData>[] = [];

        // Render content bars
        const flatData = flatten(props.gantt ?? []);

        flatData.forEach((row, rowIdx) => {
            row.events.forEach((interval) => {
                const intervalStart = +interval[0];
                const intervalEnd = +interval[1];

                const element: GanttElement<UserData> = {
                    start: (intervalStart - startTime) / (tickInterval.seconds),
                    end: intervalEnd ? (intervalEnd - startTime) / (tickInterval.seconds) : undefined,
                    rowIndex: rowIdx,
                    color: settings.selection.node?.id === row.activity? colors.$white : colors.$actual,
                    data: {
                        startDate: intervalStart,
                        endDate: intervalEnd,
                        count: +interval[2],
                        activity: row.activity,
                        activityValues: row.activityValues
                    }
                };

                result.push(element);
            });
        });
        return result;
    }, [
        props.gantt,
        settings.selection.node?.id
    ]);

    if (props.isLoading || props.gantt === undefined)
        return <Spinner isLoading={true} showProjectLoadingSpinner={true} />;

    if (data.length > 0 || props.noDataPlaceholder === undefined)
        return <div className="ganttExplorer">
            <div className="ganttContainer">
                {props.ganttGroupLabel && <div className="ganttGroupLabel">{i18n.t(props.ganttGroupLabel)}</div>}

                <GanttChart
                    data={data}
                    columnHeader={columnHeadersMarkup}
                    rowHeader={rows}
                    selectedRow={selectedRow}
                    columnHeaderHeight={[35, 55][numHeaderRows - 1]}
                    onRowClicked={(e) => {
                        if (!props.gantt || !props.graph)
                            return;

                        const node = props.graph.nodes.find(n => n.id === props.gantt![e].activity);
                        settings.setSelection({
                            node: settings.selection.node?.id === node?.id ? undefined : node
                        });
                    }}
                    tooltipHandler={(element) => {
                        return getTooltipByGroupingKeySelected(settings.groupingKey, element, isValueStreamGrouping);
                    }}
                />
            </div>

            {props.bottom}
        </div>;

    return props.noDataPlaceholder;
}

export function getGanttColumnHeaders(statistics: GanttStatistics, settings: SettingsContextType, session: SessionContextType) {
    const maxTimeDelta = statistics.maxTime !== 0 ? (statistics.maxTime - statistics.minTime) : 0;

    const tickInterval = settings.gantt.timeMode === TimeMode.WeekRelative ? getTickInterval(3600 * 24 * 7, 7, session.locale) : getTickInterval(maxTimeDelta, 7, session.locale);
    const startTime = settings.gantt.timeMode === TimeMode.Absolute ? startOfTime(statistics.minTime, tickInterval.seconds, session.timezone) : Math.floor(statistics.minTime / tickInterval.seconds) * tickInterval.seconds;
    const deltaTime = statistics.maxTime - startTime;
    const numTicks = Math.ceil(deltaTime / tickInterval.seconds);

    const headerCells: number[] = [];
    for (let i = 0; i < numTicks; i++)
        headerCells.push(startTime + i * tickInterval.seconds);

    // Header cells are only centered for "week relative" views
    const timeMode = settings.gantt.timeMode ?? TimeMode.Absolute;
    const columnHeadersMarkup = headerCells.map((cell, idx) => {
        return <div key={`header${idx}`} className={settings.gantt.timeMode == TimeMode.WeekRelative ? "center" : undefined} data-testid={"dateHeader"}>
            {formatTime(session.timezone, session.locale, settings.gantt.timeMode, tickInterval, cell, true, idx === 0)}
        </div>;
    });

    const numHeaderRows = timeMode == TimeMode.Absolute ? (tickInterval.ganttHeaderRowsAbsolute ?? 1) : (tickInterval.ganttHeaderRowsRelative ?? 1);
    return { startTime, tickInterval, columnHeadersMarkup, numHeaderRows };
}

function getGanttGroupLabel(grouping: GroupingKeys) {
    switch (grouping) {
        case GroupingKeys.None:
            return "common.activity";
        case GroupingKeys.Machine:
            return "common.machine";
        case GroupingKeys.MachineType:
            return "common.machineType";
        case GroupingKeys.Location:
            return "common.location";

        default:
            break;
    }
}

export function getTooltipByGroupingKeySelected(groupingKey: GroupingKeys, element: GanttElement<Partial<UserData>>, isValueStreamGrouping: boolean) {
    return (
        <table>
            <tbody>
                {element.data?.activityValues?.timeComponent?.value !== undefined &&
                    [GroupingKeys.None, GroupingKeys.NoneValueStream].includes(groupingKey) && <tr>
                    <th>{i18n.t("common.type")}</th>
                    <td>{translateTimeComponentActivityValue(element.data?.activityValues?.timeComponent?.value)}</td>
                </tr>}

                {element.data?.activityValues?.operation?.value !== undefined &&
                    [GroupingKeys.None, GroupingKeys.NoneValueStream].includes(groupingKey) && <tr>
                    <th>{i18n.t("common.confirmation")}</th>
                    <td>{element.data.activityValues.operation.value}</td>
                </tr>}

                {element.data?.activityValues?.machine?.value !== undefined &&
                    [GroupingKeys.Machine, GroupingKeys.MachineValueStream, GroupingKeys.None, GroupingKeys.NoneValueStream].includes(groupingKey) && <tr>
                    <th>{i18n.t("common.machine")}</th>
                    <td>{element.data.activityValues.machine.value}</td>
                </tr>}

                {element.data?.activityValues?.machineType?.value !== undefined &&
                    ![GroupingKeys.Location, GroupingKeys.LocationValueStream].includes(groupingKey) && <tr>
                    <th>{i18n.t("common.machineType")}</th>
                    <td>{element.data.activityValues.machineType.value}</td>
                </tr>}

                {element.data?.activityValues?.location?.value !== undefined &&
                    ![GroupingKeys.MachineType, GroupingKeys.MachineTypeValueStream].includes(groupingKey) && <tr>
                    <th>{i18n.t("common.location")}</th>
                    <td>{element.data.activityValues.location.value}</td>
                </tr>}

                {element.data?.activityValues?.passId?.value !== undefined && isValueStreamGrouping && <tr>
                    <th>{i18n.t("common.orderSequence")}</th>
                    <td>{element.data?.activityValues?.passId?.value}</td>
                </tr>}
            </tbody>
        </table>
    );
}
