import React, { useContext, useEffect, useRef, useState } from "react";
import { SessionContext } from "../../contexts/SessionContext";
import Global from "../../Global";
import i18n from "../../i18n";

/**
 * React spinner component based on MIT-licensed SpinKit,
 * https://github.com/tobiasahlin/SpinKit
 */

export type SpinnerPropsType = {
    isLoading: number | boolean;
    predelay?: number;
    className?: string;

    showProjectLoadingSpinner?: boolean;

    text?: string;

    /**
     * If true, the background of the spinner will not be toned down
     */
    opaque?: boolean;
};

export default function Spinner(props: SpinnerPropsType) {
    const session = useContext(SessionContext);

    const overlayRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        // No delays during tests
        const predelay = (Global.isRunningJestTest || Global.isRunningStorybook) ? 0 : (props.predelay ?? 200);
        // Stop spinner if we have given up on our requests already
        if (!props.isLoading || session.apiError.isFaulty)
            return;

        const timeout = setTimeout(() => {
            if (overlayRef.current && props.isLoading)
                overlayRef.current.classList.add("spinnerAnimate");
        }, predelay);

        return () => {
            clearTimeout(timeout);
        };
    }, [props.isLoading]);

    const [isFirstRequest, setIsFirstRequest] = useState(false);

    useEffect(() => {
        if (!props.isLoading)
            return;

        if (!props.showProjectLoadingSpinner || Global.projectLoadingSpinnerHasBeenShown)
            return;

        Global.projectLoadingSpinnerHasBeenShown = true;
        setIsFirstRequest(true);
    }, [props.isLoading]);

    // Reset the internal state when the spinner is removed again, because otherwise
    // it would keep showing the message intended for the first load until the
    // spinner instance is finally destroyed.
    useEffect(() => {
        if (props.isLoading)
            return;

        setIsFirstRequest(false);
    }, [
        !!props.isLoading,
    ]);

    if (!props.isLoading) {
        return <></>;
    }

    return <div ref={overlayRef} className={"spinner " + (props.opaque ? "spinnerOpaque" : "") + (props.className ? " " + props.className : "")} data-testid={"spinner"}>
        <div className="spinnerStack">
            <div className="sk-chase">
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
            </div>

            {isFirstRequest && <div className="projectLoadingSpinner">
                {i18n.t("common.projectLoading")}
            </div>}

            {props.text !== undefined && <div className="projectLoadingSpinner">
                {i18n.t(props.text)}
            </div>}
        </div>
    </div>;
}
