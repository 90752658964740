import { useContext } from "react";
import { disableAllCalcOptions, DfgRequest, GetNodesByCasesResponse } from "../models/ApiTypes";
import { SessionContext } from "../contexts/SessionContext";
import { SettingsContext } from "../contexts/SettingsContext";
import { EventKeys } from "../models/EventKeys";
import { Datastores } from "../utils/Datastores";
import { groupSupportsConsolidatePasses } from "../utils/GroupingUtils";
import { ApiHookOptions, useApi } from "./UseApi";

export function useNodesByCases(request: Partial<DfgRequest> = {}, options?: ApiHookOptions<GetNodesByCasesResponse>): [GetNodesByCasesResponse | undefined, boolean] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const requestOptions: DfgRequest = {
        ...disableAllCalcOptions,
        ...request,
        eventFilters: request.eventFilters ?? settings.previewFilters ?? settings.filters,
        eventKeys: request.eventKeys ?? session.project?.eventKeys ?? { } as EventKeys,
        uploadId: request.uploadId ?? session.project?.uploadId ?? "",
        uploads: session.project?.uploads,
        consolidatePasses: request.consolidatePasses ?? groupSupportsConsolidatePasses(request.eventKeys?.activityKeysGroup),
    };

    const setDisabled = options?.disable || requestOptions.eventKeys === undefined || requestOptions.uploadId === undefined;

    return useApi(Datastores.getNodesByCases, requestOptions, [ JSON.stringify(requestOptions) ], { ...options, disable: setDisabled });
}