import { useContext } from "react";
import { disableAllCalcOptions, HistogramNumericParams, TimedeltaHistogramSchema } from "../models/ApiTypes";
import { SessionContext } from "../contexts/SessionContext";
import { SettingsContext } from "../contexts/SettingsContext";
import { EventKeys } from "../models/EventKeys";
import { Datastores } from "../utils/Datastores";
import { ApiHookOptions, useApi } from "./UseApi";

export function useNumericCaseHistogram(request: Partial<HistogramNumericParams>, options?: ApiHookOptions<TimedeltaHistogramSchema>): [TimedeltaHistogramSchema | undefined, boolean] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const requestOptions: HistogramNumericParams = {
        ...disableAllCalcOptions,
        uploadId: session.project?.uploadId ?? "",
        uploads: session.project?.uploads,
        eventFilters: settings.previewFilters ?? settings.filters ?? [],
        eventKeys: session.project?.eventKeys ?? {} as EventKeys,
        statistic: "",
        ...request,
    };

    return useApi(Datastores.getNumericCaseHistograms, requestOptions, [
        JSON.stringify(requestOptions)
    ], {
        ...options,
        disable: options?.disable || requestOptions.statistic === "" || session.project === undefined,
    });
}