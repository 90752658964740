import { useAuth0 } from "@auth0/auth0-react";
import { createInstance, useMatomo, MatomoProvider } from "@jonkoops/matomo-tracker-react";
import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SessionContext } from "../contexts/SessionContext";
import Global from "../Global";


const uuidRegex = new RegExp("[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}");
const workflowRegex = new RegExp("/([\\w|-]*)/analyses/([\\w|-]*)/");

export function CustomMatomoProvider(props: React.PropsWithChildren<unknown>) {
    const auth0 = useAuth0();

    const instance = createInstance({
        urlBase: "https://oniq.matomo.cloud/",
        // We have two environments set up for matomo:
        // * 1: production domain
        // * 2: all other environments (staging, and review apps)
        siteId: Global.apiEndpoint === "https://iqa.oniq.com" ? 1 : 2,
        userId: auth0.user?.sub,
    });
    return <MatomoProvider value={instance}>{props.children}</MatomoProvider>;
}

export function trackMatomoEvents() {
    const auth0 = useAuth0();
    const location = useLocation();
    const session = useContext(SessionContext);
    const { trackPageView, trackEvent, pushInstruction } = useMatomo();

    const customDimensions = [{
        id: 1,
        value: (session.user?.organization && session.user.organization !== "") ? session.user?.organization : "Not defined" 
    }, {
        id: 2,
        value: (session.user?.isCustomer !== undefined) ? session.user?.isCustomer : "Not defined" 
    }];

    pushInstruction("setUserId", auth0.user?.sub);

    useEffect(() => {
        const reducedUrl = location.pathname.replace(uuidRegex, "id");
        trackPageView({
            href: reducedUrl,
            customDimensions
        });
        // in case we have a match regarding the workflow the regex returns an array of 3
        // the first entry is the match and the other two are our groups that we use for tracking
        const workflowArgs = workflowRegex.exec(location.pathname);
        if (workflowArgs?.length === 3) {
            trackEvent({
                category: "Workflow",
                action: workflowArgs[1] + "/" + workflowArgs[2]
            });
        }
    }, [location.pathname]);
}
