import React, { useContext, useRef } from "react";
import DfgControls, { getValidDfgControlSettings } from "../../components/controls/DfgControls";
import { NodeKpiControls, getValidNodeKpiControlSettings } from "../../components/controls/NodeKpiControls";
import { AutoCenteringModes, IDfGraph, ProcessGraph, ZoomControlLocations, getEdgeColor, getLegendProps } from "../../components/dfg/DfGraph";
import { getNodeMarkupEnergy } from "../../components/dfg/nodes/NodeMarkupFactory";
import { getDimensionParameters } from "../../components/dimension/Dimension";
import { NodeKpiChart } from "../../components/kpi-chart/NodeKpiChart";
import SideStatisticsEnergy from "../../components/side-statistics/SideStatisticsEnergy";
import { TabbedView } from "../../components/tabbed-view/TabbedView";
import { BackButtonTrayElement } from "../../components/tray/BackButtonTrayElement";
import { DownloadDfgTrayElement } from "../../components/tray/DownloadDfgTrayElement";
import { AggregationTypes, KpiComparisons } from "../../contexts/ContextTypes";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext, SortByType } from "../../contexts/SettingsContext";
import { AnalysisType } from "../../hooks/UseGraph";
import i18n from "../../i18n";
import { getAllowedKpis, graphKpiControlsGetAllowedStatistics } from "../../models/Kpi";
import { KpiPresets, StatisticTypes } from "../../models/KpiTypes";
import { getEdgeLabelText, getEnabledComparisonsValueStream } from "../../utils/DfgUtils";
import { allowedGroupingReduced, allowedGroupingReducedWithoutMachineObject } from "../../utils/GroupingUtils";
import { viewSettingsInitialization } from "../../utils/Initializers";
import { getMainNodeStat } from "../../utils/MainNodeKpi";
import { ObjectMerger } from "../../utils/ObjectMerger";
import { QuantityType, getAssignedQuantities } from "../../utils/Quantities";
import { isObjectCentricAvailable } from "../../utils/SettingsUtils";
import { NodeVarianceChart } from "../../components/kpi-chart/NodeVarianceChart";
import { ViewSubtitle } from "../../components/tabbed-view/ViewSubtitle";

export function CarbonProcessView() {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const yieldQuantities = getAssignedQuantities(session.project?.eventKeys, QuantityType.Yield, false);
    const baseQuantities = yieldQuantities.map(q => q.baseQuantity);
    const dfgRef = useRef<IDfGraph>(null);
    const hasObjects = isObjectCentricAvailable(session.project?.eventKeys);

    return <TabbedView
        subtitle={<ViewSubtitle />}
        breadcrumbs={[{
            label: "common.valueStream"
        }]}
        pages={[{
            tabTitle: "explorer.dfg",
            tabSlug: "dfg",
            selectionTrigger: (settings) => {
                return settings.selection.node !== undefined || settings.selection.edge !== undefined;
            },
            stats: <SideStatisticsEnergy />,
            controller: <DfgControls kpis={KpiPresets.valueStreamSustainabilityKpis} />,
            activator: (preferences) => {
                let temp = viewSettingsInitialization(session, settings, preferences, {
                    aggregationTypes: [AggregationTypes.Product],
                    quantities: baseQuantities,
                    kpis: getAllowedKpis(session, settings, KpiPresets.valueStreamSustainabilityKpis),
                    groupingKeys: allowedGroupingReduced,
                });

                if (!temp)
                    return;

                temp = ObjectMerger.mergeObject(temp, getValidDfgControlSettings(session, temp, { kpis: KpiPresets.valueStreamSustainabilityKpis }));

                settings.set(viewSettingsInitialization(session, temp, undefined, {
                    statistics: graphKpiControlsGetAllowedStatistics(temp.kpi.selectedKpi, temp.groupingKey, session, settings),
                }));
            },
            dimensions: getDimensionParameters(session.projectId, "process/dfg", undefined, []),
            content: <div className="processExplorerGraph" key="processExplorerGraph" >
                <ProcessGraph
                    legend={getLegendProps(settings.kpi.selectedKpi)}
                    ref={dfgRef}
                    isObjectCentric={hasObjects}
                    zoomControlLocation={ZoomControlLocations.FarRight}
                    analysis={AnalysisType.Energy}
                    edgeLabelFunc={(edge, settings, session) => getEdgeLabelText(edge, settings, session, true)}
                    edgeColorFunc={edge => getEdgeColor(edge, settings, session)}
                    nodeHighlightStatFunc={(node) => {
                        return getMainNodeStat(node, settings, session);
                    }}
                    markupFunc={getNodeMarkupEnergy}
                    centerMode={AutoCenteringModes.CenterIfUninitialized} />
                <BackButtonTrayElement />
                <DownloadDfgTrayElement graph={dfgRef.current} filename={i18n.t("common.processGraph").toString() + "-" + i18n.t("common.carbonFootprint").toString()} />
            </div>,
        }, {
            tabTitle: "common.processKpis",
            tabSlug: "benchmarking",
            selectionTrigger: (settings) => {
                return settings.selection.node !== undefined;
            },
            stats: <SideStatisticsEnergy />,
            activator: (preferences) => {
                const allowedComparisons = getEnabledComparisonsValueStream(session, settings);
                let temp = viewSettingsInitialization(session, settings, preferences, {
                    aggregationTypes: [AggregationTypes.Product],
                    quantities: baseQuantities,
                    kpis: getAllowedKpis(session, settings, KpiPresets.valueStreamSustainabilityKpis),
                    comparisons: allowedComparisons,
                    groupingKeys: allowedGroupingReducedWithoutMachineObject,
                    sortBy: allowedComparisons?.includes(KpiComparisons.Planning) ? [SortByType.Kpi, SortByType.Frequency, SortByType.Alphabetical, SortByType.DeviationFromComparison, SortByType.OrderSequences]
                        : [SortByType.Kpi, SortByType.Frequency, SortByType.Alphabetical, SortByType.OrderSequences]
                });

                if (!temp)
                    return;

                temp = ObjectMerger.mergeObject(temp, getValidNodeKpiControlSettings(session, temp, { kpis: KpiPresets.valueStreamSustainabilityKpis }));

                settings.set(viewSettingsInitialization(session, temp, undefined, {
                    statistics: graphKpiControlsGetAllowedStatistics(temp.kpi.selectedKpi, temp.groupingKey, session, settings),
                }));

            },
            dimensions: getDimensionParameters(session.projectId, "process/benchmarking", undefined, []),
            controller: <NodeKpiControls kpis={KpiPresets.valueStreamSustainabilityKpis} />,
            content: <>
                {settings.kpi.statistic === StatisticTypes.Variance &&
                    <NodeVarianceChart analysisType={AnalysisType.Energy} noDataPlaceholder="" pageSlug="carbon" />}
                {settings.kpi.statistic !== StatisticTypes.Variance && <NodeKpiChart
                    analysisType={AnalysisType.Energy}
                    noDataPlaceholder={""}
                    pageSlug="carbon"
                />}
                <BackButtonTrayElement />
            </>,
        }]}
    />;
}
