import distinctColors from "distinct-colors";
import React, { useContext, useMemo } from "react";
import GanttChart, { GanttElement } from "../../components/gantt-chart/GanttChart";
import Shortcuts, { ShortcutContexts } from "../../components/shortcuts/Shortcuts";
import Spinner from "../../components/spinner/Spinner";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { useCaseGantt } from "../../hooks/UseCaseGantt";
import i18n from "../../i18n";
import { CaseGanttStats, formatCaseStat, getStatType } from "../../models/Gantt";
import useWindowResize from "../../utils/WindowResizeHook";
import { getStatistics } from "./CommonGantt";
import { getGanttColumnHeaders, getTooltipByGroupingKeySelected } from "./ProcessGantt";
import { useGraph } from "../../hooks/UseGraph";
import { NodeActivitySchema } from "../../models/Dfg";
import { valueStreamGroupingKeys } from "../../components/controls/GroupingKeyControls";
import { CaseGanttCase, TimeMode } from "../../models/ApiTypes";

type UserData = {
    activity?: string;
    activityValues?: NodeActivitySchema;
    count?: number;
    startDate: number,
    endDate: number,
    row: CaseGanttCase,
}

export default function CaseGantt() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const [gantt, isLoading] = useCaseGantt();
    const graph = useGraph({
        calculateActivityValues: true,
        calculateNodes: true,
    });

    useWindowResize();

    const distinctColormap = distinctColors({
        count: gantt?.activities?.length ?? 1,
        // We want to avoid colors that are too grey, so we set the minimum chroma to 75%.
        chromaMin: 75,
        // We want to avoid colors that are too light, so we set the minimum lightness to 10%.
        lightMin: 10,
        // We either use the default sample number of 800 or 5 times the number of activities, whichever is larger.
        samples: Math.max(800, 5 * (gantt?.activities?.length ?? 1))
    });

    const statistics = getStatistics(gantt?.cases);
    const { startTime, tickInterval, columnHeadersMarkup } = getGanttColumnHeaders(statistics, settings, session);

    const data = useMemo(() => {
        const result: GanttElement<UserData>[] = [];

        // Render content bars
        (gantt?.cases ?? []).forEach((row, rowIdx) => {
            row.events.forEach((interval) => {
                const intervalStart = +interval[0];
                const intervalEnd = +interval[1];
                const activity = typeof interval[2] === "object" ? gantt?.activities[interval[2][0]] : undefined;
                const activityValues = graph?.nodes.find(n => n.id === activity)?.activityValues;

                const element: GanttElement<UserData> = {
                    rowIndex: rowIdx,
                    start: (intervalStart - startTime) / (tickInterval.seconds),
                    end: intervalEnd ? (intervalEnd - startTime) / (tickInterval.seconds) : undefined,
                    color: typeof interval[2] === "object" ? String(distinctColormap[interval[2][0]]) : undefined,
                    data: {
                        startDate: intervalStart,
                        endDate: intervalEnd,
                        count: typeof interval[2] === "number" ? interval[2] : undefined,
                        activity: activity,
                        activityValues: activityValues,
                        row,
                    }
                };

                result.push(element);
            });
        });
        return result;
    }, [
        graph,
        gantt?.cases,
        session.locale,
    ]);

    const statType = getStatType(settings);
    const rowHeaders = useMemo(() => {
        return (gantt?.cases ?? []).map(c => {
            return <div className="rowHeaderElements" key={c.caseId}>
                <div>{formatCaseStat(c, statType, session)}</div>
                <div>{c.caseId?.toString() ?? ""}</div>
            </div>;
        });
    }, [
        gantt?.cases,
        session.locale,
        settings.gantt.caseGanttSettings,
    ]);

    const isValueStreamGrouping = valueStreamGroupingKeys.includes(settings.groupingKey);

    const selectedRow = useMemo(() => {
        return (data ?? []).find(d => d.data?.row.caseId === settings.selection.case)?.rowIndex;
    }, [
        settings.selection.case,
        data,
    ]);

    const topLabel = <div className="topLabel">
        <div>{i18n.t(CaseGanttStats.find((c) => c.statType === statType)?.label ?? "")}</div>
        <div>{i18n.t("common.caseId")}</div>
    </div>;

    if (isLoading || gantt === undefined)
        return <Spinner isLoading={true} showProjectLoadingSpinner={true} />;

    return <div className="ganttExplorer">
        <div className="ganttContainer">
            <GanttChart
                data={data}
                columnHeaderHeight={settings.gantt.timeMode === TimeMode.Absolute ? 55 : 35}
                topLabel={topLabel}
                columnHeader={columnHeadersMarkup}
                rowHeader={rowHeaders}
                selectedRow={selectedRow}
                onRowClicked={(rowIdx) => {
                    if (!gantt)
                        return;

                    const caseId = data.find(d => d.rowIndex === rowIdx)?.data?.row.caseId;

                    if (settings.selection.case === caseId)
                        settings.setSelection({});
                    else
                        settings.setSelection({ case: caseId });
                }}
                tooltipHandler={(element) => {
                    return getTooltipByGroupingKeySelected(settings.groupingKey, element, isValueStreamGrouping);
                }}
            />
        </div>
        <Shortcuts stack={true} handledSelections={[ShortcutContexts.Case]} />
    </div>;
}
