import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import colors from "../../colors.json";
import { Stats } from "../../models/Stats";
import { UnitMetadata } from "../../utils/Formatter";
import Spinner from "../spinner/Spinner";
import { StatsRowValue, StatsRowValueSigned } from "../stats-section/StatsRowValue";
import { get } from "lodash";
import { SettingsContext } from "../../contexts/SettingsContext";
import { StatisticTypes } from "../../models/KpiTypes";
import { LabelPlacement } from "../graph/GraphCommon";
import { Layouter } from "../dfg/Layouter";

// Width of the handle indicating min, max or averages
const handleWidth = 1;

// Height of the handle indicating min, max or averages
const handleHeight = 20;

// Height of the zero deviation marker
const zeroHeight = 16;

// Margin of the component
const leftMargin = 20;
const rightMargin = 5;


type VarianceStatisticsPropsType = {
    variance: Stats | undefined;
    mean?: number | undefined;
    sum?: number | undefined;
    unit?: UnitMetadata | { sum: UnitMetadata, mean: UnitMetadata };
    labelLow?: string;
    labelHigh?: string;
    addSign?: boolean;
    isLoading?: boolean;

    /**
     * Used to initialize the singleValue prop
     */
    count?: number;

    disableMedian?: boolean;
};

export default function VarianceStatistics(props: VarianceStatisticsPropsType) {
    const containerRef = useRef<HTMLDivElement>(null);
    const settings = useContext(SettingsContext);
    const [availableWidth, setWidth] = useState<number>(0);

    useLayoutEffect(() => {
        checkWidth();
    }, [containerRef.current]);

    const noData = (props.variance === undefined || [
        props.variance.min,
        props.variance.max,
        props.variance.median,
        props.variance.p25,
        props.variance.p75].some(v => v === undefined));

    const q1 = props.variance?.p25;
    const q3 = props.variance?.p75;

    const width = (availableWidth !== undefined) ? availableWidth - leftMargin - rightMargin : undefined;

    const placement = new LabelPlacement(width ?? 0);

    const maxValue = Math.max(...[props.variance?.min, q1, q3, props.variance?.max].filter(v => v !== undefined).map(v => Math.abs(v!)));
    const minValue = Math.min(...[props.variance?.min, q1, q3, props.variance?.max].filter(v => v !== undefined).map(v => Math.abs(v!)));

    const showZero = Math.min(props.variance?.min ?? 0, props.variance?.max ?? 0) < 0 && Math.max(props.variance?.min ?? 0, props.variance?.max ?? 0) > 0;

    // Y-location of the bar where everything is aligned on
    const barY = showZero ? 44.5 : 28.5;

    const labelLow = props.labelLow ?? "";
    const labelHigh = props.labelHigh ?? "";

    const isLoading = props.isLoading ?? false;

    const meanUnit = get(props.unit, "mean") ?? props.unit as UnitMetadata;
    const sumUnit = get(props.unit, "sum") ?? props.unit as UnitMetadata;

    const ValueComponent = props.addSign ? StatsRowValueSigned : StatsRowValue;

    const isSingleValue = props.count === 1;
    const hasNoVariance = isSingleValue || props.variance?.min === props.variance?.max;

    const medianLineProps = noData ? undefined : {
        x1: fixX(leftMargin + getX(props.variance!.median!, minValue, maxValue, width!)),
        x2: fixX(leftMargin + getX(props.variance!.median!, minValue, maxValue, width!)),
        y1: barY - zeroHeight,
        y2: barY + zeroHeight,
    };


    return <div className="varianceStatistics" ref={containerRef}>
        {containerRef.current !== null && availableWidth > 0 && <>
            {!hasNoVariance && <div className="bar">
                <Spinner isLoading={isLoading} opaque={true} />
                <svg className="bar">
                    {!noData && <>
                        {bar(q1, q3, colors["$grayish-400"])}

                        {/* Zero marker */}
                        {showZero && handle(0, colors.$black)}
                        {showZero && label(0, "0", -1, false, colors.$black)}

                        {/* Median */}
                        {!props.disableMedian && <line
                            {...medianLineProps}
                            style={{
                                stroke: colors["$white"],
                                strokeWidth: 4.5,
                            }}
                        />}
                        {!props.disableMedian && <line
                            {...medianLineProps}
                            style={{
                                stroke: colors["$primary-500"],
                                strokeWidth: 2.5,
                            }}
                        />}

                        {/* Median */}
                        {!props.disableMedian && label(props.variance!.median, "med", 1, true, colors.$black)}

                        {/* Minimum */}
                        {handle(props.variance!.min, colors.$black)}
                        {label(props.variance!.min, "min", 1, true, colors.$black)}

                        {/* Maximum */}
                        {handle(props.variance!.max, colors.$black)}
                        {label(props.variance!.max, "max", 1, true, colors.$black)}

                        {/* X-Axis */}
                        <line x1={leftMargin} x2={availableWidth! - rightMargin} y1={barY} y2={barY} style={{
                            stroke: "black",
                            strokeWidth: 1,
                        }} />

                        {labelLow && <text fill={colors["$grayish-300"]} x={5} y={0} alignmentBaseline="hanging">❮ {labelLow}</text>}
                        {labelHigh && <text fill={colors["$grayish-300"]} x={availableWidth - 3} y={0} textAnchor="end" alignmentBaseline="hanging">{labelHigh} ❯</text>}
                    </>}
                </svg>
            </div>}

            {!isSingleValue && <>
                <div className="table">
                    {props.mean !== undefined && isFinite(props.mean) && <ValueComponent label="common.statistics.mean" isLoading={isLoading} unit={meanUnit} value={props.mean} isHighlight={settings.kpi.statistic === StatisticTypes.Mean} />}

                    {!props.disableMedian && <ValueComponent label="common.statistics.median" isLoading={isLoading} unit={meanUnit} value={props.variance?.median} isHighlight={[StatisticTypes.Median, StatisticTypes.Variance].includes(settings.kpi.statistic)} />}
                    {!hasNoVariance && <ValueComponent label="common.statistics.min" isLoading={isLoading} unit={meanUnit} value={props.variance?.min} />}
                    {!hasNoVariance && <ValueComponent label="common.statistics.max" isLoading={isLoading} unit={meanUnit} value={props.variance?.max} />}
                    {props.sum !== undefined && isFinite(props.sum) && <ValueComponent label="common.statistics.sum" isLoading={isLoading} unit={sumUnit} value={props.sum} isHighlight={settings.kpi.statistic === StatisticTypes.Sum} />}
                </div>
            </>}

            {isSingleValue && <div className="table">
                <ValueComponent label="common.value" isLoading={isLoading} unit={meanUnit} value={props.mean ?? props.variance?.mean} isHighlight={true} />
            </div>}
        </>}
    </div>;

    function bar(from?: number, to?: number, color?: string) {
        if (from === undefined || to === undefined)
            return <></>;

        const xFrom = fixX(leftMargin + getX(from!, minValue, maxValue, width!));
        const xTo = fixX(leftMargin + getX(to!, minValue, maxValue, width!));


        const y1 = barY - handleHeight / 2;

        return <rect x={xFrom} width={xTo - xFrom} y={y1} height={handleHeight} fill={color} />;
    }

    function handle(position?: number, color?: string) {
        if (position === undefined)
            return <></>;

        return <rect
            width={handleWidth}
            height={handleHeight}
            x={fixX(leftMargin + getX(position!, minValue, maxValue, width!) - (handleWidth / 2))}
            y={barY - handleHeight / 2}
            style={{
                fill: color,
                strokeWidth: 0.5,
            }}
        />;
    }

    function checkWidth() {
        const clientWidth = containerRef.current?.clientWidth;

        if (clientWidth !== undefined && clientWidth > 0) {
            setWidth(clientWidth ?? 0);
        } else {
            setTimeout(() => {
                checkWidth();
            }, 0);
        }
    }

    function label(position: number | undefined, label: string, aboveOrBelow: 1 | -1, rotate: boolean, color?: string) {
        if (position === undefined)
            return <></>;

        const x = fixX(leftMargin + getX(position!, minValue, maxValue, width!) - (handleWidth / 2));
        const y = fixX(barY + aboveOrBelow * (handleHeight / 2 + 8));

        const anchor = aboveOrBelow === 1 ? "end" : "start";

        const size = Layouter.measureFontSize("varianceStatisticsLabel", label);
        const labelWidth = !rotate ? size.width : size.height + 2;

        if (aboveOrBelow > 0) {
            if (!placement.isFree(x, labelWidth))
                return null;

            placement.take(x, labelWidth);
        }

        return <text
            x={x}
            y={y}
            textAnchor={anchor}
            transform={rotate ? `rotate(-45,${x},${y})` : ""}
            style={{
                fill: color,
            }}>
            {label}
        </text>;
    }
}

function getX(value: number, min: number, max: number, width: number) {
    const delta = max - min;
    if (value < min)
        value = min;

    if (value > max)
        value = max;

    const dx = value - min;
    const scale = dx / delta;

    return scale * width;
}

function fixX(value: number) {
    return Math.round(value) + 0.5;
}
