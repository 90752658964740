import { defaultProductLimit } from "../Global";
import { StatsCalculationRequest, allTimeOptions } from "../models/ApiTypes";
import { useProductCaseAggregations } from "./UseProductCaseAggregations";
import { useProductDeviation } from "./UseProductDeviation";
import { EventFilter } from "../models/EventFilter";


export function useProductStats(useDeviationApi: boolean, requestOptions: Partial<StatsCalculationRequest> & {
    eventFilters?: EventFilter[] | undefined;
    sort?: string[];
    limit?: number | undefined;
}, options: {
    addEnergyStats?: boolean;
    disable?: boolean;
}) {
    const requestProps = {
        ...allTimeOptions,
        ...requestOptions,
        limit: requestOptions.limit ?? defaultProductLimit,
    };
    const [productCaseAggregations, isProductCaseAggregationsLoading] = useProductCaseAggregations(
        requestProps,
        useDeviationApi || options.disable);

    const [productDeviationAggregations, isProductDeviationAggregationsLoading] = useProductDeviation(
        requestProps,
        !useDeviationApi || options.disable);

    const isLoading = isProductCaseAggregationsLoading || isProductDeviationAggregationsLoading;

    return [productCaseAggregations, productDeviationAggregations, isLoading] as const;
}
