import React, { useContext } from "react";
import { productionTimeOnlyCalcOptions } from "../../models/ApiTypes";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { LegacyAnalyzedValues } from "../../contexts/ContextTypes";
import { useCaseStatistics } from "../../hooks/UseCaseStatistics";
import i18n from "../../i18n";
import { CASE_TYPE_ID, GroupingKeys } from "../../models/Dfg";
import { DfgUtils, isTerminalNode } from "../../utils/DfgUtils";
import { Formatter } from "../../utils/Formatter";
import { getShortActivityLabelFromActivityValues } from "../../utils/GroupingUtils";
import { StatsRowString } from "../stats-section/StatsRowString";
import { StatsRowValue } from "../stats-section/StatsRowValue";
import { StatsSection, StatsSubSection } from "../stats-section/StatsSection";
import { useOrderTrackingArguments, useOrderTrackingGraph } from "./OrderTrackingGraph";


export default function SideStatisticsOrderTracking() {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);

    const orderTrackingArguments = useOrderTrackingArguments();

    const graph = useOrderTrackingGraph();

    const [caseStatistics, areCasesLoading] = useCaseStatistics({
        limit: 0,
        ...orderTrackingArguments,
        ...productionTimeOnlyCalcOptions
    });

    if (graph === undefined)
        return <div></div>;

    const label = settings.selection.edge
        ? `${DfgUtils.getNodeLabelById(settings.selection.edge.from, graph.nodes, settings.groupingKey)} ➞ ${DfgUtils.getNodeLabelById(settings.selection.edge.to, graph.nodes, settings.groupingKey)}`
        : settings.selection.node
            ? getShortActivityLabelFromActivityValues(settings.selection.node?.activityValues, settings.groupingKey)
            : undefined;

    const isTerminalNodeSelected = settings.selection.node !== undefined && isTerminalNode(settings.selection.node);

    const hasSelection = (settings.selection.node || settings.selection.edge );

    const node = settings.selection.node;

    const edge = settings.selection.edge ? settings.selection.edge.edges.find(e => e.objectType !== CASE_TYPE_ID) : undefined;

    const optionsThroughputTime ={
        isLoading: areCasesLoading,
        unit: Formatter.units.durationShort,
    };

    const nodeScrapStatsRow = (node && node.kpis?.relativeScrapCount) ? <StatsRowValue label="quality.relativeScrap" value={node.kpis?.relativeScrapCount} isHighlight={settings.kpi.analyzedValue === LegacyAnalyzedValues.QualityQuota} unit={Formatter.units.percent} /> : null;

    const noOrderDataExplanation = <div className="explanation">{i18n.t("orderTracking.noOrderInfo")}</div>;

    // WARNING: Note that setting location = product and machineType = case is a hack
    // misusing dfg groupings for order networks. This should be changed if
    // possible
    const isProductLabeled = session.project?.eventKeysOrderTracking?.location !== undefined;
    const productValue = node?.activityValues?.location?.value;
    const caseValue = node?.activityValues?.machineType?.value;

    return <>
        <div data-testid="sidePanel">
            {(!hasSelection || isTerminalNodeSelected) && <StatsSection title={i18n.t("common.shippingOrder") + " " + settings.orderTracking.orderFilter}>
                <StatsSubSection>
                    <StatsRowValue label="common.throughputTime" value={caseStatistics?.log?.durationStatistics?.mean} isHighlight={true} {...optionsThroughputTime} />
                </StatsSubSection>
            </StatsSection>}

            {!!node && !isTerminalNodeSelected && <>
                {settings.groupingKey === GroupingKeys.MachineType && <StatsSection title={i18n.t("common.case") + " " + label}>
                    {isProductLabeled && <StatsSubSection title={"common.caseInformation"}>
                        <StatsRowString label="common.product" value={productValue} />
                    </StatsSubSection>}
                    {node.throughputTime && <StatsSubSection>
                        <StatsRowValue label="common.throughputTime" value={node.throughputTime} isHighlight={settings.kpi.analyzedValue === LegacyAnalyzedValues.TimeValuesMean} {...optionsThroughputTime} />
                        {nodeScrapStatsRow}
                    </StatsSubSection>}
                    {!node.throughputTime && noOrderDataExplanation}
                </StatsSection>}
                {settings.groupingKey === GroupingKeys.Machine && <StatsSection title={i18n.t("common.machine") + " " + label}>
                    <StatsSubSection title={"common.caseInformation"}>
                        <StatsRowString label="common.case" value={caseValue} />
                        {isProductLabeled && <StatsRowString label="common.product" value={productValue} />}
                    </StatsSubSection>
                    {node.busyTimeStatistics?.mean && <StatsSubSection>
                        <StatsRowValue label="common.busyTime" value={node.busyTimeStatistics?.mean} isHighlight={settings.kpi.analyzedValue === LegacyAnalyzedValues.TimeValuesMean} {...optionsThroughputTime} />
                        {nodeScrapStatsRow}
                    </StatsSubSection>}
                    {!node.busyTimeStatistics?.mean && noOrderDataExplanation}
                </StatsSection>}
            </>}

            {!!edge && <StatsSection title={label}>
                <StatsSubSection>
                    <StatsRowValue label="common.transitionTime" value={edge.timeStatistics?.mean} isHighlight={true} {...optionsThroughputTime} />
                </StatsSubSection>
            </StatsSection>
            }




        </div>
    </>;
}
