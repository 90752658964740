export enum SortOrder {
    Ascending = "ascending",
    Descending = "descending",
}

export enum StatisticTypes {
    Mean = "mean",
    Median = "median",
    Variance = "variance",
    Sum = "sum",
}

/**
 * The long list of kpis
 */
export enum KpiTypes {
    // Time
    // absolute values
    ThroughputTime = "throughputTime",
    QueuingTime = "queuingTime",
    BusyTime = "busyTime",
    ProductionTime = "productionTime",
    SetupTime = "setupTime",
    SetupGap = "setupGap",
    InterruptionTime = "interruptionTime",
    UnitDownTime = "unitDownTime",
    FailureTime = "failureTime",
    TechnicalLosses = "technicalLosses",
    OrganizationalLosses = "organizationalLosses",
    ProcessLosses = "processLosses",
    QualityLosses = "qualityLosses",

    CycleTime = "cycleTime",

    // relative values
    ProductionProcessRatio = "productionProcessRatio",

    // deviation values
    DeviationThroughputTime = "deviationThroughputTime",
    DeviationRelativeThroughputTime = "deviationRelativeThroughputTime",

    // Output (quantities)
    OrderCount = "orderCount",
    ProductionPasses = "productionPasses",
    BatchQuantity = "batchQuantity",
    GoodQuantity = "goodQuantity",
    GoodQuantityTransport = "goodQuantityTransport",    // Alias for GoodQuantity (Output edges)
    ProducedQuantity = "producedQuantity",
    ProductCount = "productCount",

    // Output (performance)
    ThroughputRate = "throughputRate",
    ThroughputRateTransport = "throughputRateTransport", // Alias for ThroughputRate (Output edges)

    //frequency
    Frequency = "frequency",
    FrequencyTransport = "frequencyTransport", // Alias for Frequency (Output edges)

    // Quality
    ScrapQuantity = "scrapQuantity",
    ScrapRatio = "scrapRatio",

    // Inventory
    WorkInProcessInventory = "workInProcessInventory",
    WorkInProgressBeforeEventInventory = "workInProgressBeforeEventInventory", // Alias for WorkInProgress (Stock WIP for nodes)
    OrderBacklog = "orderBacklog",
    OrderBacklogBeforeEvent = "orderBacklogBeforeEvent", // Alias for OrderBacklog for nodes

    // Sustainability
    Carbon = "carbon",
    CarbonPerOutput = "carbonPerOutput",
    Energy = "energy",
    EnergyPerOutput = "energyPerOutput",

    // Supply chain view
    ReplenishmentLeadTime = "replenishmentLeadTime",
    OrderInterval = "orderInterval",
    ComponentShare = "componentShare",
    StorageTime = "storageTime",
    OnTimeDelivery = "onTimeDelivery",
    DelayTime = "delayTime",
    NumberOfDelayedOrders = "numberOfDelayedOrders",

    // OEE
    OverallEquipmentEffectiveness = "overallEquipmentEffectiveness",
    Availability = "availibility",
    Effectiveness = "Effectiveness",
    QualityRate = "Quality rate"

}


export class KpiPresets {
    /**
     * The KPI types that are used in the time dimension for products.
     */
    static productTimeKpis = [
        KpiTypes.ThroughputTime,
        KpiTypes.BusyTime,
        KpiTypes.ProductionTime,
        KpiTypes.SetupTime,
        KpiTypes.InterruptionTime,
        KpiTypes.FailureTime,
        KpiTypes.TechnicalLosses,
        KpiTypes.OrganizationalLosses,
        KpiTypes.ProcessLosses,
        KpiTypes.QualityLosses,
        KpiTypes.QueuingTime,
        KpiTypes.ProductionProcessRatio,
        KpiTypes.DeviationThroughputTime,
        KpiTypes.DeviationRelativeThroughputTime,
        KpiTypes.OnTimeDelivery,
        KpiTypes.DelayTime,
    ];

    /**
     * The KPI types that are used in the output dimension for products.
     */
    static productOutputKpis = [
        KpiTypes.GoodQuantity,
        KpiTypes.ProducedQuantity,
        KpiTypes.ThroughputRate,
        KpiTypes.OrderCount,
    ];

    /**
     * The KPI types that are used in the inventory dimension for products.
     */
    static productInventoryKpis = [
        KpiTypes.WorkInProcessInventory
    ];

    /**
     * The KPI types that are used in the quality dimension for products.
     */
    static productQualityKpis = [
        KpiTypes.ScrapRatio,
        KpiTypes.ScrapQuantity,
    ];

    /**
     * The KPI types that are used in the sustainability dimension for products.
     */
    static productSustainabilityKpis = [
        KpiTypes.Carbon,
        KpiTypes.Energy,
    ];

    /**
     * The KPI types that are used in the time dimension for the value stream.
     */
    static valueStreamTimeKpis = [
        KpiTypes.ThroughputTime,
        KpiTypes.BusyTime,
        KpiTypes.ProductionTime,
        KpiTypes.SetupTime,
        KpiTypes.InterruptionTime,
        KpiTypes.FailureTime,
        KpiTypes.TechnicalLosses,
        KpiTypes.OrganizationalLosses,
        KpiTypes.ProcessLosses,
        KpiTypes.QualityLosses,
        KpiTypes.Frequency,
    ];

    /**
     * The KPI types that are used in the schedule deviation for the value stream.
     */
    static valueStreamTimeDeviationKpis = [
        KpiTypes.ThroughputTime,
        KpiTypes.BusyTime,
        KpiTypes.ProductionTime,
        KpiTypes.SetupTime,
        KpiTypes.Frequency,
    ];

    /**
     * The KPI types that are used for edges in the value stream.
     */
    static valueStreamTimeEdgeKpis = [
        KpiTypes.QueuingTime,
        KpiTypes.Frequency,
    ];

    /**
     * The KPI types that are used for the benchmarking tab
     */
    static valueStreamTimeBenchmarkingKpis = [
        KpiTypes.ThroughputTime,
        KpiTypes.BusyTime,
        KpiTypes.ProductionTime,
        KpiTypes.SetupTime,
        KpiTypes.InterruptionTime,
        KpiTypes.FailureTime,
        KpiTypes.TechnicalLosses,
        KpiTypes.OrganizationalLosses,
        KpiTypes.ProcessLosses,
        KpiTypes.QualityLosses,
        KpiTypes.QueuingTime,
        KpiTypes.Frequency,
    ];

    /**
     * The KPI types that are used in the output dimension for the value stream.
     */
    static valueStreamOutputKpis = [
        KpiTypes.GoodQuantity,
        KpiTypes.ProducedQuantity,
        KpiTypes.ThroughputRate,
        KpiTypes.Frequency,
        KpiTypes.ProductCount,
    ];

    /**
     * The KPI types that are used in the output dimension for the value stream (transitions).
     */
    static valueStreamOutputEdgeKpis = [
        KpiTypes.GoodQuantityTransport,
        KpiTypes.ThroughputRateTransport,
        KpiTypes.FrequencyTransport,
    ];

    static valueStreamOutputBenchmarkingKpis = [
        KpiTypes.GoodQuantity,
        KpiTypes.ProducedQuantity,
        KpiTypes.ThroughputRate,
        KpiTypes.Frequency,
        KpiTypes.GoodQuantityTransport,
        KpiTypes.ThroughputRateTransport,
        KpiTypes.FrequencyTransport,
        KpiTypes.ProductCount,
    ];

    /**
     * The KPI types used in the inventory dimension for the dfg.
     */
    static valueStreamInventoryKpis = [
        KpiTypes.WorkInProcessInventory,
        KpiTypes.OrderBacklog,
    ];

    /**
     * The KPI types that are used in the inventory dimension for the value stream.
     */
    static valueStreamInventoryBenchmarkingKpis = [
        KpiTypes.WorkInProcessInventory,
        KpiTypes.WorkInProgressBeforeEventInventory,
        KpiTypes.OrderBacklog,
        KpiTypes.OrderBacklogBeforeEvent,
    ];

    /**
     * The KPI types that are used in the quality dimension for the value stream.
     */
    static valueStreamQualityKpis = [
        KpiTypes.ScrapRatio,
        KpiTypes.ScrapQuantity,
    ];

    /**
    * The KPI types that are used in the sustainability dimension for the value stream.
    */
    static valueStreamSustainabilityKpis = [
        KpiTypes.Carbon,
        KpiTypes.Energy,
    ];

    /**
     * The KPI types that are used in the dedicated value stream/takt times analysis.
     */
    static valueStreamTaktTimeKpis = [
        KpiTypes.CycleTime,
        KpiTypes.OverallEquipmentEffectiveness,
        KpiTypes.ThroughputTime,
        KpiTypes.SetupTime,
        KpiTypes.ProducedQuantity,
        KpiTypes.ScrapRatio,
        KpiTypes.Frequency,
        KpiTypes.ProductCount,
        KpiTypes.WorkInProgressBeforeEventInventory,
    ];

    /**
     * The KPI types that are used in the supply chain view.
     */
    static supplyChainViewKpis = [
        KpiTypes.ReplenishmentLeadTime,
        KpiTypes.OrderInterval,
        KpiTypes.ThroughputTime,
    ];
}

export const valueStreamMarkupKpis = [
    {type: KpiTypes.CycleTime, statistic: StatisticTypes.Mean},
    {type: KpiTypes.OverallEquipmentEffectiveness, statistic: StatisticTypes.Mean, label: "workflows.valueStream.OEE"},
    {type: KpiTypes.ThroughputTime, statistic: StatisticTypes.Mean},
    {type: KpiTypes.SetupTime, statistic: StatisticTypes.Mean},
    {type: KpiTypes.ProducedQuantity, statistic: StatisticTypes.Mean, label: "workflows.valueStream.batchSize"},
    {type: KpiTypes.ScrapRatio, statistic: StatisticTypes.Mean},
    {type: KpiTypes.Frequency, statistic: StatisticTypes.Sum},
    {type: KpiTypes.ProductCount, statistic: StatisticTypes.Sum},
];
