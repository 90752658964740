import React, { useContext } from "react";
import { SettingsContext } from "../../contexts/SettingsContext";
import { StatsSection, StatsSubSection } from "../../components/stats-section/StatsSection";
import { StatsRowString } from "../../components/stats-section/StatsRowString";
import { StatsRowValue } from "../../components/stats-section/StatsRowValue";
import { Formatter } from "../../utils/Formatter";
import { SessionContext } from "../../contexts/SessionContext";
import { toUserTimezone } from "../../utils/TimezoneUtils";
import i18n from "../../i18n";

export function SetupStatistics() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    if (settings.selection.setupTransition === undefined)
        return null;

    const startTime = toUserTimezone(settings.selection.setupTransition.actual.startTime, session.timezone);
    const endTime = toUserTimezone(settings.selection.setupTransition.actual.endTime, session.timezone);

    const timeFormatString = i18n.t("datetime.condensed").toString();

    const durationActual = settings.selection.setupTransition.actual.duration;
    const durationPlanned = settings.selection.setupTransition.planned;
    
    const relativeDeviation = durationPlanned ? (durationActual - durationPlanned) / durationPlanned : undefined;

    return <div data-testid="sidePanel">
        <StatsSection title={`${settings.selection.setupTransition.fromProduct} ➞ ${settings.selection.setupTransition.toProduct}`}>
            <StatsSubSection title="common.setup">
                <StatsRowValue label="common.setupTime" unit={Formatter.units.durationShort} value={settings.selection.setupTransition.actual.duration} isHighlight={true}/>
                {settings.selection.setupTransition.planned !== undefined && <>
                    <StatsRowValue label="common.plannedSetupTime" unit={Formatter.units.durationShort} value={settings.selection.setupTransition.planned} />
                    <StatsRowValue label="common.scheduleDeviation" unit={Formatter.units.durationShort} value={durationActual - durationPlanned!} />
                    {relativeDeviation !== undefined && <StatsRowValue label="common.relativeScheduleDeviation" unit={Formatter.units.percent} value={relativeDeviation} />}
                </>}

                <StatsRowString label="common.startTime" value={Formatter.formatTimePlaceholders(timeFormatString, startTime, session.timezone)}/>
                <StatsRowString label="common.endTime" value={Formatter.formatTimePlaceholders(timeFormatString, endTime, session.timezone)}/>

            </StatsSubSection>
        </StatsSection>
    </div>;
}