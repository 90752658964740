import React, { useContext, useEffect } from "react";
import { SessionContext, SessionType } from "../../contexts/SessionContext";
import { SettingsContext, SettingsType } from "../../contexts/SettingsContext";
import { KpiComparisons } from "../../contexts/ContextTypes";
import i18n from "../../i18n";
import { isFunction, set } from "lodash";
import { Spotlight } from "../spotlight/Spotlight";
import { buildControllerSpotlightId } from "../../utils/Utils";
import { useLocation } from "react-router-dom";
import Dropdown from "../dropdown/Dropdown";
import { DeepPartial } from "../../utils/ObjectMerger";

type KpiComparisonControlProps = {
    /**
     * List of comparisons that are enabled. Can also be a callback, and you can nicely plug
     * getEnabledComparisons in here.
     */
    enabledComparisons?: KpiComparisons[] | ((session: SessionType, settings: SettingsType) => KpiComparisons[]);
};

export function getValidKpiComparisonControlSettings(session: SessionType, settings: SettingsType, props: KpiComparisonControlProps): DeepPartial<SettingsType> {
    const result: DeepPartial<SettingsType> = {};
    const enabledComparisons = (props.enabledComparisons ?
        (isFunction(props.enabledComparisons) ? props.enabledComparisons(session, settings) : props.enabledComparisons) :
        [KpiComparisons.BestProcesses, KpiComparisons.Planning, KpiComparisons.None]).filter(a => a !== KpiComparisons.Planning || session.project?.uploadIdPlan !== undefined);

    if (!enabledComparisons.includes(settings.kpi.comparisons))
        set(result, "kpi.comparisons", enabledComparisons[0] );

    return result;
}

/**
 * The kpi comparison controls allow the user to select the comparison type.
 * If no planning data exists, the planning option is automatically removed from visibleComparisons.
 */
export function KpiComparisonControls(props: KpiComparisonControlProps) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const location = useLocation();

    const hasPlanning = session.project?.uploadIdPlan !== undefined ||
        session.project?.uploads?.routings !== undefined;

    const enabledComparisons = (props.enabledComparisons ?
        (isFunction(props.enabledComparisons) ? props.enabledComparisons(session, settings) : props.enabledComparisons) :
        [KpiComparisons.BestProcesses, KpiComparisons.Planning, KpiComparisons.None]).filter(a => a !== KpiComparisons.Planning || hasPlanning);

    const options = enabledComparisons.map(v => { return { label: i18n.t(`kpiComparisons.${v.toString()}`), value: v }; });
    const isSpotlightAggregationDependant = location.pathname.includes("/kpis/");

    useEffect(() => {
        if (!enabledComparisons.includes(settings.kpi.comparisons))
            settings.setKpiState({ comparisons: enabledComparisons[0] });
    }, [enabledComparisons, settings.kpi.comparisons]);

    if (options.length > 0)
        return <div className="section comparison controls" data-testid="comparison-controls">
            <div className="title">
                {i18n.t("common.kpiComparison")}
                <Spotlight
                    id={buildControllerSpotlightId(
                        location.pathname,
                        isSpotlightAggregationDependant ?
                            ["comparison", settings.kpi.aggregation.toString()] :
                            ["comparison"]
                    )}
                    className="mls" />
            </div>
            <Dropdown 
                options={options}
                value={{ value: settings.kpi.comparisons, label: i18n.t(`kpiComparisons.${settings.kpi.comparisons.toString()}`) }}
                onChange={(e) => {
                    const comparison = e!.value as KpiComparisons;
                    settings.setKpiState({ comparisons: comparison });
                }}
            />
        </div>;

    return null;
}
