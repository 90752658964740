import React, { useContext, useMemo } from "react";
import { StatsSection, StatsSubSection } from "../../components/stats-section/StatsSection";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { getKpiDefinition, getKpiSpotlightId, getUnit } from "../../models/Kpi";
import { KpiTypes, StatisticTypes } from "../../models/KpiTypes";
import { useSupplyChainGraphs } from "../../hooks/UseSupplyChainGraphs";
import { StatsRowString } from "../../components/stats-section/StatsRowString";
import { StatsRowValue } from "../../components/stats-section/StatsRowValue";
import { AggregationTypes } from "../../contexts/ContextTypes";
import { buildSpotlightId } from "../../utils/Utils";
import { NodeRoles } from "../../models/Dfg";

export function CriticalProductStats() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const kpiDef = getKpiDefinition(KpiTypes.ReplenishmentLeadTime, { settings, session, target: "case" });

    const [graph, isGraphLoading] = useSupplyChainGraphs({
        customKpis: kpiDef?.productCustomKpis,
        ...kpiDef?.apiParameters,
    }, {
        disable: !settings.supplyChain.selectedProduct,
    });

    const criticalProduct = useMemo(() => {
        if (!graph || isGraphLoading)
            return undefined;

        const sorted = graph.nodes.filter(n => n?.role !== NodeRoles.Inventory).sort((a, b) => {
            return (b.customKpis?.replenishmentLeadTimeMean?.value ?? 0) - (a.customKpis?.replenishmentLeadTimeMean?.value ?? 0);
        });
        return sorted[0];
    }, [
        graph,
    ]);

    const replenishmentLeadTimeDef = getKpiDefinition(KpiTypes.ReplenishmentLeadTime, { settings, session, target: "case" });

    return <div data-testid="sidePanel">
        <StatsSection title="supplyChain.criticalProduct">
            <StatsSubSection title="common.product" spotlightId={buildSpotlightId(location.pathname, ["ProductCategories"])}>
                <StatsRowString label="browser.name" value={criticalProduct?.name} isHighlight={true} isLoading={isGraphLoading} />

                {Object.keys(criticalProduct?.categories ?? {}).map((name) => {
                    const value = criticalProduct?.categories?.[name];
                    return <StatsRowString key={name} label={name} value={value} isLoading={isGraphLoading} />;
                })}
            </StatsSubSection>


            <StatsSubSection title={replenishmentLeadTimeDef!.label} spotlightId={getKpiSpotlightId(session, KpiTypes.ReplenishmentLeadTime, AggregationTypes.Product)}>
                <StatsRowValue label="common.mean" value={criticalProduct?.customKpis?.replenishmentLeadTimeMean?.value} unit={getUnit(replenishmentLeadTimeDef?.unit, StatisticTypes.Mean)} isHighlight={true} isLoading={isGraphLoading} />
            </StatsSubSection>

        </StatsSection>

    </div>;
}