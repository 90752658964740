import { isNumber } from "lodash";
import { DateTime } from "luxon";
import { BaseQuantityType, TimePeriodFrequencies } from "../models/ApiTypes";
import i18n from "../i18n";
import { EventKeys } from "../models/EventKeys";
import { getWeekNumber, Timestamp } from "./TimezoneUtils";

export type UnitScale = {
    name: string;
    size: number;

    /** This time unit is reasonably divided by these multiples.
     * E.g. an hour can reasonably be divided by 4, 6 or 12
     * Used for axis ticks. */
    steps?: number[];

    numDigits?: number;

    isLastUnit?: boolean;
};

export type TickInterval = {
    /**
     * Number of seconds that a tick with this interval covers
     */
    seconds: number;

    /**
     * Array of formatters for absolute time. The first is used for the first label to render,
     * everything after is formatted using the second element
     */
    absoluteFormatter: ((dt: DateTime) => string)[];
    relativeFormat: string;
    startOfSetting?: string;

    /**
     * Number of gantt header rows typically needed for a tick label of this interval when using absolute formatter
     * Defaults to 1.
     */
    ganttHeaderRowsAbsolute?: number;

    /**
     * Number of gantt header rows typically needed for a tick label of this interval when using relative formatter.
     * Defaults to 1.
     */
    ganttHeaderRowsRelative?: number;
}

export type Digits = {
    minDigits?: number,
    maxDigits?: number,
}

export type UnitParams = {
    /**
     * Used for energy units
     */
    baseQuantity?: BaseQuantityType;
}

export type FormatterParams = UnitParams & {
    /**
     * locale, mandatory
     */
    locale: string;

    /**
     * Used for formatting percent values
     */
    of?: number;

    numDigits?: number;

    /**
     * Used for duration formatting, see formatDurationShort for details
     */
    followUnits?: number;
}

export type DefaultFormatterType = (value: number | undefined, params: FormatterParams) => string;

export type UnitsGetterType = (params: UnitParams) => UnitScale[];

type UnitMetadataBase = {
    getUnits: UnitsGetterType;
    formatter: DefaultFormatterType;
}

export type UnitMetadata = UnitMetadataBase & {
    name: string;
}

export class Formatter {
    static defaultUnit: UnitMetadata = {
        name: "",
        getUnits: () => [{
            name: "",
            size: 1,
            isLastUnit: true,
        }],
        formatter: (value, params) => Formatter.formatNumber(value, params.numDigits, params.locale),
    };

    static units = asUnitMetadataMap({
        count: {
            formatter: (value, params) => Formatter.formatCount(value, params.numDigits, params.locale),
            getUnits: () => Formatter.unitsCountLong,
        },
        durationLong: {
            formatter: (value, params) => Formatter.formatDurationLong(value, params.followUnits, params.locale),
            getUnits: () => Formatter.unitsDurationLong,
        },
        durationShort: {
            formatter: (value, params) => Formatter.formatDurationShort(value, params.followUnits, params.locale),
            getUnits: () => Formatter.unitsDurationShort,
        },
        metricLength: {
            formatter: (value, params) => Formatter.formatMetricLength(value, params.numDigits, params.locale),
            getUnits: () => Formatter.unitsMetricLength,
        },
        energykWh: {
            formatter: (value, params) => Formatter.formatEnergykWh(value, params.numDigits, params.locale),
            getUnits: () => Formatter.unitsEnergykWh,
        },
        yieldPerTime: {
            formatter: (value, params) => {
                const quantity = params.baseQuantity ?? "mass";
                if (quantity === "mass")
                    return Formatter.formatMassFlow(value, params.numDigits, params.locale);

                if (quantity === "count")
                    return Formatter.formatCountFlow(value, params.numDigits, params.locale);

                if (quantity === "length")
                    return Formatter.formatSpeed(value, params.numDigits, params.locale);

                return Formatter.formatNumber(value, params.numDigits, params.locale);
            },
            getUnits: (params) => {
                const quantity = params.baseQuantity ?? "mass";
                if (quantity === "mass")
                    return Formatter.getUnitsCountFlow();

                if (quantity === "count")
                    return Formatter.getUnitsCountFlow();

                if (quantity === "length")
                    return Formatter.unitsSpeed;

                return Formatter.unitsCountLong;
            }
        },
        timePerYield: {
            formatter: (value, params) => Formatter.formatTimePerYield(value, params.baseQuantity ?? "mass", params.followUnits, params.locale),
            getUnits: (params) => {
                const quantity = params.baseQuantity ? {
                    mass: i18n.t("units.kilograms_short"),
                    count: i18n.t("quantities.count"),
                    length: i18n.t("units.meters_short"),
                }[params.baseQuantity] : "";

                return Formatter.unitsDurationShort.map(u => {
                    return {
                        ...u,
                        name: i18n.t("units.timePerQuantityTemplate", {
                            duration: i18n.t(u.name),
                            quantity
                        }),
                    };
                });
            }
        },
        carbonPerYield: {
            formatter: (value, params) => Formatter.formatCarbonPerYield(value, params.baseQuantity ?? "mass", params.numDigits, params.locale),
            getUnits: (params) => Formatter.getUnitCarbonPerYield(params.baseQuantity ?? "mass"),
        },
        energyPerYield: {
            formatter: (value, params) => Formatter.formatEnergyPerYield(value, params.baseQuantity ?? "mass", params.numDigits, params.locale),
            getUnits: (params) => Formatter.getUnitsEnergyPerYield(params.baseQuantity ?? "mass"),
        },
        speed: {
            formatter: (value, params) => Formatter.formatSpeed(value, params.numDigits, params.locale),
            getUnits: () => Formatter.unitsSpeed,
        },
        massFlow: {
            formatter: (value, params) => Formatter.formatMassFlow(value, params.numDigits, params.locale),
            getUnits: () => Formatter.getUnitsMassFlow(),
        },
        countFlow: {
            formatter: (value, params) => Formatter.formatCountFlow(value, params.numDigits, params.locale),
            getUnits: () => Formatter.getUnitsCountFlow(),
        },
        weight: {
            formatter: (value, params) => Formatter.formatWeight(value, params.numDigits, params.locale),
            getUnits: () => Formatter.unitsWeight,
        },
        roundedNumber: {
            formatter: (value, params) => Formatter.formatNumberShort(value ? Math.round(value) : undefined, params.numDigits, params.locale),
            getUnits: () => Formatter.unitsNumberShort,
        },
        numberShort: {
            formatter: (value, params) => Formatter.formatNumberShort(value, params.numDigits, params.locale),
            getUnits: () => Formatter.unitsNumberShort,
        },
        number: {
            formatter: (value: number | undefined, params: FormatterParams) => Formatter.formatNumber(value, params.numDigits, params.locale),
            getUnits: () => { return [{ name: "", size: 1 }]; },
        },
        fileSize: {
            formatter: (value, params) => Formatter.formatFileSize(value, params.numDigits, params.locale),
            getUnits: () => Formatter.unitsFileSize,
        },
        percentDynamic: {
            formatter: (value, params) => Formatter.formatPercentDynamic(value, params.of, params.locale),
            getUnits: Formatter.defaultUnit.getUnits,
        },
        percent: {
            formatter: (value, params) => Formatter.formatPercent(value, params.of, {
                maxDigits: params.numDigits ?? 1,
                minDigits: 0,
            }, params.locale, "%"),
            getUnits: () => {
                return [{
                    name: "%",
                    size: 1,
                    isLastUnit: true,
                }];
            }
        },
        percentWithoutSymbol: {
            formatter: (value, params) => Formatter.formatPercent(value, params.of, params.numDigits, params.locale, ""),
            getUnits: Formatter.defaultUnit.getUnits,
        }
    });

    // #region Duration
    private static unitsDurationShort: UnitScale[] = [
        {
            name: "units.years_short",
            size: 3_153_6000,
            steps: [12, 6, 4, 3, 2, 1]
        },
        {
            name: "units.days_short",
            size: 86_400,
            steps: [12, 6, 4, 2, 1],
        }, {
            name: "units.hours_short",
            size: 3_600,
            steps: [30, 12, 6, 4, 2, 1],
        }, {
            name: "units.minutes_short",
            size: 60,
            steps: [60, 30, 20, 15, 10, 5, 2, 1],
        }, {
            name: "units.seconds_short",
            size: 1,
        }, {
            name: "units.milliseconds_short",
            size: 0.001,
            isLastUnit: true,
        }
    ];

    private static unitsCountLong: UnitScale[] = [{
        name: "units.M_count",
        size: 1_000_000,
        steps: [10, 5, 2.5, 1],
    }, {
        name: "units.k_count",
        size: 1_000,
        steps: [10, 5, 2.5, 1],
    }, {
        name: "units.count",
        size: 1,
        steps: [100, 50, 25, 20, 10, 5, 1],
    }, {
        name: "units.count",
        size: 1,
        numDigits: 2,
        isLastUnit: true,
    }];

    private static unitsDurationLong: UnitScale[] = [
        {
            name: "units.years",
            size: 3_153_6000,
            steps: [12, 6, 4, 3, 2, 1]
        },
        {
            name: "units.days",
            size: 86_400,
            steps: [12, 6, 4, 2, 1],
        }, {
            name: "units.hours",
            size: 3_600,
            steps: [30, 12, 6, 4, 2, 1],
        }, {
            name: "units.minutes",
            size: 60,
            steps: [60, 30, 20, 15, 10, 5, 2, 1],
        }, {
            name: "units.seconds",
            size: 1,
        }, {
            name: "units.milliseconds_short",
            size: 0.001,
            isLastUnit: true,
        }
    ];

    static formatDurationShort(seconds: number | undefined, followUnits = 2, locale?: string) {
        return Formatter.formatDuration(seconds, followUnits, locale ?? "de", "units.seconds_short", Formatter.unitsDurationShort);
    }

    static formatDurationLong(seconds: number | undefined, followUnits = 2, locale?: string) {
        return Formatter.formatDuration(seconds, followUnits, locale ?? "de", "units.seconds", Formatter.unitsDurationLong);
    }

    private static formatDuration(seconds: number | undefined, followUnits: number, locale: string, zeroUnit: string, scales: UnitScale[]) {
        if (seconds === undefined)
            return "";
        if (seconds === 0)
            return 0 + i18n.t(zeroUnit);

        let numUnits = followUnits ?? 2;

        let value = Math.abs(seconds);
        const parts: string[] = [];
        let foundUnit = false;
        for (let i = 0; i < scales.length; i++) {
            const unit = scales[i];
            if (numUnits === 0)
                break;

            const isLast = numUnits === 1;

            if (Math.floor(value / unit.size) >= 1) {
                foundUnit = true;

                const unitCount = isLast ? Math.round(value / unit.size) : Math.floor(value / unit.size);
                value -= unitCount * unit.size;

                parts.push(`${Formatter.formatNumber(unitCount, 0, locale)}${i18n.t(unit.name)}`);
            }

            if (foundUnit)
                numUnits--;
        }

        const sign = seconds < 0 ? "-" : "";

        return sign + parts.join(", ");
    }
    // #endregion

    // #region Metric Length
    private static unitsMetricLength: UnitScale[] = [
        {
            size: 1_000,
            name: "units.kilometers_short"
        }, {
            size: 1,
            name: "units.meters_short",
        }, {
            size: 0.01,
            name: "units.centimeters_short"
        }, {
            size: 0.001,
            name: "units.millimeters_short",
            isLastUnit: true,
        }
    ];

    static formatMetricLength(value: number | undefined, numDigits = 1, locale?: string | undefined) {
        return Formatter.formatUnit(Formatter.unitsMetricLength, value, numDigits, undefined, undefined, locale);
    }
    // #endregion

    // #region Energy
    private static unitsEnergykWh: UnitScale[] = [
        {
            size: 1_000_000,
            name: "units.gigawatthours_short",
        }, {
            size: 1_000,
            name: "units.megawatthours_short",
        }, {
            size: 1,
            name: "units.kilowatthours_short",
        }, {
            size: 0.001,
            name: "units.watthours_short",
            isLastUnit: true,
        }
    ];

    /**
     * Formats energy
     * @param value energy in kWh
     * @param numDigits Number of digits
     * @returns Formatted string
     */
    static formatEnergykWh(value: number | undefined, numDigits = 1, locale?: string) {
        return Formatter.formatUnit(Formatter.unitsEnergykWh, value, numDigits, undefined, undefined, locale);
    }
    // #endregion

    // #region Energy per Product
    private static getUnitsEnergyPerYield = (baseQuantity: BaseQuantityType | undefined): UnitScale[] => {

        const productUnit = baseQuantity ? {
            mass: i18n.t("units.kilograms_short"),
            count: i18n.t("quantities.countShort"),
            length: i18n.t("units.meters_short"),
        }[baseQuantity] : "";

        return [
            {
                size: 1_000_000,
                name: i18n.t("units.gigawatthours_short") + "\u200b/" + productUnit
            }, {
                size: 1_000,
                name: i18n.t("units.megawatthours_short") + "\u200b/" + productUnit
            }, {
                size: 1,
                name: i18n.t("units.kilowatthours_short") + "\u200b/" + productUnit
            }, {
                size: 0.001,
                name: i18n.t("units.watthours_short") + "\u200b/" + productUnit,
                isLastUnit: true,
            }
        ];
    };

    /**
     * Formats energy per product quantity
     * @param value energy consumption per base quantity
     * @param baseQuantity baseQuantity as string
     * @param numDigits Number of digits
     * @returns Formatted string
     */
    static formatEnergyPerYield(value: number | undefined, baseQuantity: BaseQuantityType | undefined, numDigits = 1, locale?: string) {
        return Formatter.formatUnit(Formatter.getUnitsEnergyPerYield(baseQuantity), value, numDigits, undefined, undefined, locale);
    }
    // #endregion


    static formatCount(value: number | undefined, numDigits: number | undefined, locale?: string) {
        return Formatter.formatUnit(Formatter.unitsCountLong, value, numDigits, undefined, undefined, locale);
    }

    // #region Emissions per Product
    private static getUnitCarbonPerYield(baseQuantity: BaseQuantityType | undefined) {
        const productUnit = baseQuantity ? {
            mass: i18n.t("units.kilograms_short"),
            count: i18n.t("quantities.countShort"),
            length: i18n.t("units.meters_short"),
        }[baseQuantity] : "";

        return [
            {
                size: 1_000_000,
                name: i18n.t("units.kilotonnes_short") + "\u200b/" + productUnit
            }, {
                size: 1_000,
                name: i18n.t("units.tonnes_short") + "\u200b/" + productUnit
            }, {
                size: 1,
                name: i18n.t("units.kilograms_short") + "\u200b/" + productUnit
            }, {
                size: 0.001,
                name: i18n.t("units.grams_short") + "\u200b/" + productUnit,
                isLastUnit: true,
            }
        ];
    }

    static formatTimePerYield(value: number | undefined, baseQuantity: BaseQuantityType | undefined, followUnits = 2, locale?: string) {
        const quantity = baseQuantity ? {
            mass: i18n.t("units.kilograms_short"),
            count: i18n.t("quantities.count"),
            length: i18n.t("units.meters_short"),
        }[baseQuantity] : "";

        const duration = Formatter.formatDurationShort(value, followUnits, locale);
        return i18n.t("units.timePerQuantityTemplate", {
            duration,
            quantity
        });
    }

    /**
     * Formats carbon emissions per product quantity
     * @param value energy consumption per base quantity
     * @param baseQuantity baseQuantity as string
     * @param numDigits Number of digits
     * @returns Formatted string
     */
    static formatCarbonPerYield(value: number | undefined, baseQuantity: BaseQuantityType | undefined, numDigits = 1, locale?: string) {
        return Formatter.formatUnit(Formatter.getUnitCarbonPerYield(baseQuantity), value, numDigits, undefined, undefined, locale);
    }
    // #endregion

    // #region Speed
    private static unitsSpeed: UnitScale[] = [
        {
            size: 1 / 3.6,
            name: i18n.t("units.kilometers_short") + "\u200b/" + i18n.t("units.hours_short")
        }, {
            size: 1 / 3_600,
            name: i18n.t("units.meters_short") + "\u200b/" + i18n.t("units.hours_short")
        }, {
            size: 1 / 3_600_00,
            name: i18n.t("units.centimeters_short") + "\u200b/" + i18n.t("units.hours_short")
        }, {
            size: 1 / 3_600_000,
            name: i18n.t("units.millimeters_short") + "\u200b/" + i18n.t("units.hours_short"),
            isLastUnit: true,
        }
    ];

    /**
     * Formats speed
     * @param value Speed in meters per second
     * @param numDigits Number of digits
     * @returns Formatted string
     */
    static formatSpeed(value: number | undefined, numDigits = 1, locale?: string | undefined) {
        return Formatter.formatUnit(Formatter.unitsSpeed, value, numDigits, undefined, 1, locale);
    }
    // #endregion

    // #region Mass flow
    private static getUnitsMassFlow = (): UnitScale[] => [
        {
            size: 1 / 3.6,
            name: i18n.t("units.tonnes_short") + "\u200b/" + i18n.t("units.hours_short")
        }, {
            size: 1 / 3_600,
            name: i18n.t("units.kilograms_short") + "\u200b/" + i18n.t("units.hours_short")
        }, {
            size: 1 / 3_600_000,
            name: i18n.t("units.grams_short") + "\u200b/" + i18n.t("units.hours_short")
        },
        {
            size: 1 / 3_600_000_000,
            name: i18n.t("units.milligrams_short") + "\u200b/" + i18n.t("units.hours_short"),
            isLastUnit: true,
        }
    ];

    /**
     * Formats mass flow
     * @param value Mass flow in kg/s
     * @param numDigits Number of digits
     * @returns Formatted string
     */
    static formatMassFlow(value: number | undefined, numDigits = 1, locale?: string | undefined) {
        return Formatter.formatUnit(Formatter.getUnitsMassFlow(), value, numDigits, " ", 1, locale);
    }
    // #endregion

    // #region Count Flow
    private static getUnitsCountFlow = (): UnitScale[] => [
        {
            size: 1_000_000 / 3_600,
            name: i18n.t("units.M_countShort") + "\u200b/" + i18n.t("units.hours_short"),
        }, {
            size: 1_000 / 3_600,
            name: i18n.t("units.k_countShort") + "\u200b/" + i18n.t("units.hours_short"),
        }, {
            size: 1 / 3_600,
            name: i18n.t("quantities.countShort") + "\u200b/" + i18n.t("units.hours_short"),
        }, {
            size: 1 / (3_600 * 24),
            name: i18n.t("quantities.countShort") + "\u200b/" + i18n.t("units.days_short"),
            isLastUnit: true,
        }
    ];

    /**
     * Formats count flow
     * @param value Count flow in units per second
     * @param numDigits Number of digits
     * @returns Formatted string
     */
    static formatCountFlow(value: number | undefined, numDigits = 1, locale?: string | undefined) {
        return Formatter.formatUnit(Formatter.getUnitsCountFlow(), value, numDigits, undefined, 2, locale);
    }
    // #endregion

    // #region Weight
    static unitsWeight: UnitScale[] = [
        {
            size: 1_000_000_000,
            name: "units.megatonnes_short",
        },
        {
            size: 1_000_000,
            name: "units.kilotonnes_short",
        }, {
            size: 1_000,
            name: "units.tonnes_short",
        }, {
            size: 1,
            name: "units.kilograms_short",
        }, {
            size: 0.001,
            name: "units.grams_short",
            isLastUnit: true,
        }
    ];

    /**
     * Formats weights
     * @param value Weight in kg
     * @param numDigits Number of digits
     * @returns Formatted string
     */
    static formatWeight(value: number | undefined, numDigits = 1, locale?: string | undefined) {
        return Formatter.formatUnit(Formatter.unitsWeight, value, numDigits, undefined, undefined, locale);
    }
    // #endregion

    // #region Number
    static formatNumber(value: number | undefined, maxDigits?: number, locale?: string) {
        if (value === undefined)
            return "";

        if (locale)
            return value.toLocaleString(locale, {
                maximumFractionDigits: maxDigits === undefined ? 2 : maxDigits,
            });
        else
            return value.toFixed(maxDigits);
    }

    private static unitsNumberShort: UnitScale[] = [
        {
            size: 1_000_000,
            name: "units.suffix_1000000",
        }, {
            size: 1_000,
            name: "units.suffix_1000",
        }, {
            size: 1,
            name: "",
        }, {
            size: 1,
            name: "",
            numDigits: 2,
            isLastUnit: true,
        }
    ];

    static formatNumberShort(value: number | undefined, numDigits = 1, locale?: string | undefined) {
        return Formatter.formatUnit(Formatter.unitsNumberShort, value, numDigits, "", undefined, locale);
    }
    // #endregion

    // #region File Size
    private static unitsFileSize: UnitScale[] = [
        {
            size: 1024 * 1024 * 1024 * 1024,
            name: "TB"
        }, {
            size: 1024 * 1024 * 1024,
            name: "GB"
        }, {
            size: 1024 * 1024,
            name: "MB"
        }, {
            size: 1024,
            name: "kB"
        }, {
            size: 1,
            name: "bytes",
        }
    ];


    static formatFileSize(size: number | undefined, numDigits = 2, locale?: string | undefined) {
        return Formatter.formatUnit(Formatter.unitsFileSize, size, numDigits, undefined, undefined, locale);
    }
    // #endregion

    static formatDate(date: Date | string | number | undefined, defaultValue = "", locale: string | undefined = undefined, timezone: string | undefined = undefined) {
        let value: Date | undefined = undefined;
        if (typeof date === "string")
            value = new Date(date);
        else if (typeof date === "number")
            value = new Date(date * 1000);
        else
            value = date;

        if (!value)
            return defaultValue;

        return value.toLocaleDateString(locale, {
            timeZone: timezone
        });
    }

    static formatDateTime(date: Date | string | number | undefined, defaultValue = "", locale: string | undefined = undefined, timezone: string | undefined = undefined) {
        let value: Date | undefined = undefined;
        if (typeof date === "string")
            value = new Date(date);
        else if (typeof date === "number")
            value = new Date(date * 1000);
        else
            value = date;

        if (!value)
            return defaultValue;

        return value.toLocaleString(locale, {
            timeZone: timezone
        });
    }

    /**
     * Displays two digits when the number is below 1, one if below 10
     * and none otherwise
     */
    static formatPercentDynamic(value: number | undefined, of = 1, locale?: string) {
        if (!of || value === undefined)
            return "";

        const scaled = 100 * (value / of);

        const abs = Math.abs(scaled);

        if (abs < 0.000000000000001)
            return "0 %";

        if (abs < 1)
            return Formatter.formatPercent(value, of, 2, locale);

        if (abs < 10)
            return Formatter.formatPercent(value, of, 1, locale);

        return Formatter.formatNumberShort(scaled, 0, locale) + " %";
    }

    static formatPercent(value: number | undefined, of = 1, numDigits: number | Digits = 2, locale: string | undefined = undefined, symbol = "%") {
        if (!of || value === undefined)
            return "";

        const percent = 100 * value / of;
        return percent.toLocaleString(locale, isNumber(numDigits) ? {
            minimumFractionDigits: numDigits,
            maximumFractionDigits: numDigits,
        } : {
            minimumFractionDigits: numDigits.minDigits,
            maximumFractionDigits: numDigits.maxDigits,
        }) + " " + symbol;
    }

    public static getUnit(units: UnitScale[], value: number, defaultUnitIx: number | undefined = undefined) {
        if (value === undefined)
            return undefined;

        const absValue = Math.abs(value);
        if (absValue === 0)
            // Return default unit
            return defaultUnitIx !== undefined ? units[defaultUnitIx] : units.find(u => u.size === 1) ?? units[0];


        for (const unit of units) {
            const numDigits = unit.numDigits ?? (unit.isLastUnit ? 1 : 0);
            const digitsMultiplier = 10 ** numDigits; // The multiplyer to make a number with numDigits digits an integer
            const numUnits = absValue * digitsMultiplier / unit.size;

            if (Math.floor(numUnits) >= 1)
                return unit;
        }

        return units[units.length - 1];
    }

    public static getFormattedValue(unitScale: UnitScale, value: number | undefined, numDigits: number, locale: string | undefined) {
        if (value === undefined)
            return "";

        const pValue = Math.abs(value);
        const sign = value < 0 ? "-" : "";

        const digits = unitScale.numDigits ?? numDigits;

        const scaledValue = (pValue / unitScale.size);
        const roundedValue = Math.round(scaledValue);
        const decimalMagnitude = Math.abs(scaledValue - roundedValue);
        const minDecimalMagnitude = (10 ** -digits);

        // if the number of digits would be 0 anyway, no need to add digits
        const useDigits = decimalMagnitude >= (0.5 * minDecimalMagnitude);

        if (scaledValue === 0)
            return "0";

        const smallestValueDisplayable = 1 / (10 ** digits);
        if (scaledValue < smallestValueDisplayable) {
            const comparator = value < 0 ? ">" : "<";
            return comparator + sign + (+smallestValueDisplayable.toFixed(digits)).toLocaleString(locale);
        }

        if (scaledValue < minDecimalMagnitude && scaledValue > 0)
            return sign + (+scaledValue.toFixed(digits)).toLocaleString(locale);
        else
            return sign + (+scaledValue.toFixed(useDigits ? digits : 0)).toLocaleString(locale);
    }

    private static formatUnit(units: UnitScale[], value: number | undefined, numDigits = 2, separator = " ", defaultUnitIx: number | undefined = undefined, locale?: string | undefined) {
        if (value === undefined)
            return "";

        const bestFit = Formatter.getUnit(units, value, defaultUnitIx);
        const unitToUse = bestFit ?? units.find(u => u.size === 1) ?? units[0];
        return this.formatSpecificUnit(unitToUse, value, numDigits, separator, locale);
    }

    public static formatSpecificUnit(unitToUse: UnitScale, value: number | undefined, numDigits: number, separator = " ", locale: string | undefined) {
        if (value === undefined)
            return "";

        const stringValue = Formatter.getFormattedValue(unitToUse, value, numDigits, locale);
        return stringValue + separator + i18n.t(unitToUse.name);
    }

    static formatTime(scale: TimePeriodFrequencies, time: Timestamp, timezone: string) {
        const formatString = {
            [TimePeriodFrequencies.Day]: i18n.t("datetime.formats.day"),
            [TimePeriodFrequencies.Week]: i18n.t("datetime.formats.weekYear"),
            [TimePeriodFrequencies.Month]: i18n.t("datetime.formats.month"),
            [TimePeriodFrequencies.Year]: i18n.t("datetime.formats.year"),
        }[scale];

        return Formatter.formatTimePlaceholders(formatString, time, timezone);
    }

    static formatTimePlaceholders(formatString: string, time: Timestamp, timezone: string) {
        const months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];

        const replacements = {
            yy: makeLength(time.year % 100, 2),
            yyyy: makeLength(time.year, 4),
            M: time.month.toString(),
            MM: makeLength(time.month, 2),
            MMM: i18n.t("datetime.monthsShort." + months[time.month - 1]),
            MMMM: i18n.t("datetime.months." + months[time.month - 1]),
            d: time.day.toString(),
            dd: makeLength(time.day, 2),
            WW: makeLength(getWeekNumber(time, timezone), 2),
            mhh: makeLength(time.hour % 12, 2),
            meridiem: time.hour < 12 ? "AM" : "PM",
            hh: makeLength(time.hour, 2),
            mm: makeLength(time.minute, 2),
            ss: makeLength(time.second, 2),
        } as { [key: string]: string };

        // Replace elements of format string by each replacement's key, by descending length
        Object.keys(replacements).sort((a, b) => b.length - a.length).forEach(key => {
            formatString = formatString.replace(`{${key}}`, replacements[key]);
        });

        return formatString;
    }
}

/**
 * Add name to unit metadata and infer keys for result type.
 *
 * This helper function is mainly to ensure that the resulting object retains
 * the names of it's keys so that downstream usages check for key presence correctly
 */
function asUnitMetadataMap<T>(obj: { [K in keyof T]: UnitMetadataBase }): { [K in keyof T]: UnitMetadata } {
    const entries = Object.entries<UnitMetadataBase>(obj);
    const unitMetadataEntries = entries.map(([key, value]) => [key, { ...(value), name: key }]);
    return Object.fromEntries(unitMetadataEntries);
}

function makeLength(value: number, length: number, prefix = "0") {
    let result = value.toString();

    while (result.length < length)
        result = prefix + result;

    return result;
}

export function getTickInterval(totalIntervalSize: number, numTicks: number, locale: string): TickInterval {
    // For a list of Luxon tokens go here:
    // https://moment.github.io/luxon/#/formatting?id=table-of-tokens
    const dateFormatter = (d: DateTime) => d.toLocaleString({
        ...DateTime.DATE_SHORT,
    }, {
        locale,
    });
    const timeFormatter = (d: DateTime) => d.toLocaleString({
        ...DateTime.TIME_SIMPLE,
    }, {
        locale,
    });
    const dateTimeFormatter = (d: DateTime) => d.toLocaleString({
        ...DateTime.DATETIME_SHORT,
    }, {
        locale,
    });

    const possibleIntervals = [
        {
            seconds: 3600 * 24 * 7 * 30,
            absoluteFormatter: [dateFormatter, dateFormatter],
            relativeFormat: "M",
            ganttHeaderRows: 1
        },
        {
            seconds: 3600 * 24 * 14,
            absoluteFormatter: [dateFormatter, dateFormatter],
            relativeFormat: `'${i18n.t("units.day")}' d`,
            startOfSetting: "",
            ganttHeaderRows: 1
        },
        {
            seconds: 3600 * 24 * 10,
            absoluteFormatter: [dateFormatter, dateFormatter],
            relativeFormat: `'${i18n.t("units.day")}' d`,
            ganttHeaderRows: 1
        },
        {
            seconds: 3600 * 24 * 7,
            absoluteFormatter: [dateFormatter, dateFormatter],
            relativeFormat: `'${i18n.t("units.day")}' d`,
            ganttHeaderRows: 1
        },
        {
            seconds: 3600 * 24 * 4,
            absoluteFormatter: [dateFormatter, dateFormatter],
            relativeFormat: `'${i18n.t("units.day")}' d`,
            ganttHeaderRows: 1
        },
        {
            seconds: 3600 * 24 * 2,
            absoluteFormatter: [dateFormatter, dateFormatter],
            relativeFormat: `'${i18n.t("units.day")}' d`,
            ganttHeaderRows: 1
        },
        {
            seconds: 3600 * 24,
            absoluteFormatter: [dateFormatter, dateFormatter],
            relativeFormat: `'${i18n.t("units.day")}' d`,
            ganttHeaderRows: 1
        },
        {
            seconds: 3600 * 12,
            absoluteFormatter: [dateTimeFormatter, dateTimeFormatter],
            relativeFormat: `'${i18n.t("units.day")}' d hh':'mm`,
            ganttHeaderRowsAbsolute: 2,
        },
        {
            seconds: 3600 * 6,
            absoluteFormatter: [dateTimeFormatter, dateTimeFormatter],
            relativeFormat: `'${i18n.t("units.day")}' d hh':'mm`,
            ganttHeaderRowsAbsolute: 2
        },
        {
            seconds: 3600 * 3,
            absoluteFormatter: [dateTimeFormatter, timeFormatter],
            relativeFormat: `'${i18n.t("units.day")}' d hh':'mm`,
            ganttHeaderRowsAbsolute: 2,
        },
        {
            seconds: 3600 * 2,
            absoluteFormatter: [dateTimeFormatter, timeFormatter],
            relativeFormat: "hh':'mm",
            ganttHeaderRowsAbsolute: 2,
        },
        {
            seconds: 3600,
            absoluteFormatter: [dateTimeFormatter, timeFormatter],
            relativeFormat: "hh':'mm",
            ganttHeaderRowsAbsolute: 2,
        },
        {
            seconds: 900,
            absoluteFormatter: [dateTimeFormatter, timeFormatter],
            relativeFormat: "hh':'mm",
            ganttHeaderRowsAbsolute: 2,
        },
        {
            seconds: 300,
            absoluteFormatter: [dateTimeFormatter, timeFormatter],
            relativeFormat: "hh':'mm",
            ganttHeaderRowsAbsolute: 2,
        },
        {
            seconds: 60,
            absoluteFormatter: [dateTimeFormatter, timeFormatter],
            relativeFormat: "hh':'mm",
            ganttHeaderRowsAbsolute: 2,
        },
        {
            seconds: 5,
            absoluteFormatter: [dateTimeFormatter, timeFormatter],
            relativeFormat: "hh':'mm':'ss",
            ganttHeaderRowsAbsolute: 2,
        },
    ];
    const secondsPerTick = totalIntervalSize / numTicks;
    for (const interval of possibleIntervals) {
        const numUnits = secondsPerTick / interval.seconds;

        if (numUnits >= 1)
            return interval;
    }

    return possibleIntervals[0];
}

export function getTickValues(minSeconds: number, maxSeconds: number, numTicks: number, locale: string) {
    const totalIntervalSize = maxSeconds - minSeconds;
    const tickInterval = getTickInterval(totalIntervalSize, numTicks, locale);
    const ticks: number[] = [];
    for (let i = minSeconds; i < maxSeconds; i = i + tickInterval.seconds) {
        ticks.push(i);
    }
    return { ticks: ticks, tickInterval: tickInterval };
}

export function startOfTime(utcSeconds: number, secondsPerTick: number, timezone: string) {
    if (secondsPerTick < 3600)
        return Math.floor(utcSeconds / secondsPerTick) * secondsPerTick;
    const unit = secondsPerTick < 24 * 3600 ? "hour" : "day";
    return DateTime.fromSeconds(utcSeconds, { zone: "utc" }).setZone(timezone).startOf(unit).toUTC().toSeconds();
}

export function getColumnLabel(elementName: string, eventKeys?: EventKeys) {
    const keys = Object.keys(eventKeys ?? {});
    for (const key of keys) {
        const translationLabel = key.startsWith("is") ? key.substr(2).toLowerCase() : key;
        if (((eventKeys as any)[key] as string) === elementName)
            return i18n.t("common." + translationLabel);
    }

    return undefined;
}

// Get the default unit for different quantities
export function getQuantityUnit(quantity: string | undefined) {
    if (quantity === "mass")
        return Formatter.units.weight;

    if (quantity === "length")
        return Formatter.units.metricLength;

    if (quantity === "count")
        return Formatter.units.numberShort;

    // fallback
    return Formatter.defaultUnit;
}

export function unitToSigned(unitMeta: UnitMetadata | undefined) {
    if (unitMeta !== undefined)
        return {
            ...unitMeta,
            formatter: formatterToSigned(unitMeta.formatter)
        };
}

export function formatterToSigned<F extends (value: number | undefined, params: FormatterParams) => string>(fn: F): F {
    return <F> function (value: number | undefined, params: FormatterParams) {
        const unsigned = fn(value, params);
        if (unsigned.length > 0 && !unsigned.startsWith("-")) {
            if (value === 0)
                return `+/-${unsigned}`;
            return `+${unsigned}`;
        }
        return unsigned;
    };
}

export function getLongUnit(unit: UnitMetadata) {
    if (unit.name === Formatter.units.durationShort.name)
        return Formatter.units.durationLong;

    if (unit.name === Formatter.units.numberShort.name)
        return Formatter.units.number;

    return unit;
}