import { noop } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { FeatureImportance, GetRootCauseAnalysisResponse, TimeperiodDeviationStatisticsSchema, TimePeriodFrequencies, RcaDrilldownType, RcatypeTargets, TimeperiodCaseAggregationStatisticsSchema, CaseGanttSetting, TimeMode, BaseQuantityType, SetupTransitionSelectionSchema, ViewConfigurations } from "../models/ApiTypes";
import { GroupingKeys, MultiEdge, Node, NodeDetailLevel } from "../models/Dfg";
import { EventFilter, FilterEditState } from "../models/EventFilter";
import { makeSettingsValid, MakeSettingsValidType } from "../utils/Initializers";
import { DeepPartial, ObjectMerger } from "../utils/ObjectMerger";
import { Vector } from "../utils/Vector";
import { SessionContext } from "./SessionContext";
import Pako from "pako";
import { setPreferences } from "../Global";
import { AggregationTypes, KpiComparisons, RcaType } from "./ContextTypes";
import { LegacyAnalyzedValues } from "./ContextTypes";
import { KpiTypes, SortOrder, StatisticTypes } from "../models/KpiTypes";
import { MatrixCellProps } from "../components/matrix/MatrixCell";
import { NavigateFunction } from "react-router-dom";

export enum OutputTypes {
    Output = "output",
    Yield = "yield",
    Scrap = "scrap"
}

export function isRelativeAnalyzedValue(v: LegacyAnalyzedValues) {
    return [LegacyAnalyzedValues.QualityQuota].includes(v);
}

export function isOutputAnalyzedValue(v: LegacyAnalyzedValues) {
    return [LegacyAnalyzedValues.OutputValuesMean, LegacyAnalyzedValues.OutputValuesSum, LegacyAnalyzedValues.OutputRate, LegacyAnalyzedValues.OutputAverageStock].includes(v);
}

export function isQualityAnalyzedValue(v: LegacyAnalyzedValues) {
    return [LegacyAnalyzedValues.QualityValuesMean, LegacyAnalyzedValues.QualityValuesSum, LegacyAnalyzedValues.QualityRate, LegacyAnalyzedValues.QualityQuota].includes(v);
}

export function isCarbonAnalyzedValue(v: LegacyAnalyzedValues) {
    return [LegacyAnalyzedValues.EnergyConsumption, LegacyAnalyzedValues.EnergyPerYield, LegacyAnalyzedValues.CarbonEmissions, LegacyAnalyzedValues.CarbonPerYield].includes(v);
}

export enum GraphOrientation {
    horizontal = "LR",
    vertical = "TB"
}

export type GraphSettingsType = {
    nodeDetailLevel: NodeDetailLevel;
    highlight: boolean;
    objectType: string | undefined;
    orientation: GraphOrientation;
    secondGroupingLevel: GroupingKeys;
    showObjectContext: boolean;
    complexityCutoffScore: number | undefined;
    visualization: VisualizationType;
};

export type GanttSettingsType = {
    timeMode: TimeMode;
    sortOrder: SortOrder;
    caseGanttSettings: CaseGanttSetting;
};

export enum SortByType {
    Kpi,
    Frequency,
    Alphabetical,
    DeviationFromComparison,
    OrderSequences,
    Percentiles,
    Median,
}

export type KpiSettingsType = {
    highlightDeviations: boolean;
    analyzedValue: LegacyAnalyzedValues;
    selectedKpi: KpiTypes;
    statistic: StatisticTypes;
    sortOrder: SortOrder;
    sortBy: SortByType;
    relativeToThroughputTime: boolean;
    aggregation: AggregationTypes;
    comparisons: KpiComparisons;
    timeScale: TimePeriodFrequencies;
    showCustomerTakt: boolean;
};

export type SupplyChainSettingsType = {
    selectedProduct: string | undefined;
};

export enum DeviationTypes {
    RelativeDeviation,
    AbsoluteDeviation,
    AverageRelative,
    AverageDuration,
    TotalDuration,
    FrequencyDeviation
}

export enum DeviationAnalysis {
    Cases,
    Products,
    Machine,
}

export enum DeviationTime {
    All,
    Early,
    Late,
}

export enum VisualizationType {
    Single,
    SideBySide
}

export type ProcessPathWorkflow = {
    analyzedValue: LegacyAnalyzedValues;
};

export enum Statistics {
    Max = "max",
    Min = "min",
    Mean = "mean",
    Median = "median",
    Sum = "sum",
    Total = "total",
    Percentiles = "percentiles",
}

export enum MatrixComparison {
    None = 0,
    Planning = 1,
    RelativeDeviation = 2,
    AbsoluteDeviation = 3,
}

export enum MachineSortedBy {
    Workplaces = "workplaces",
    WorkplaceTypes = "workplaceTypes",
    OrderSequences = "orderSequences",
}

export type KpiMatrixSettingsType = {
    machines: string[],

    /**
     * This is used by the setup matrix / events over time
     */
    machineName: string | undefined,

    comparison: MatrixComparison,

    /**
     * If true, the deviation bars are shown in the setup matrix. If false, only actual values are bars,
     * the deviation is drawn as a line
     */
    useDeviationBars: boolean,

    /**
     * If true, deviations will be highlighted
     */
    highlightDeviations: boolean,
}

export type ProcessPathSettingsType = {
    processPathSelectedIds: string[];
};

/**
 * This is needed for recovering the RCA configuration after a page reload, or if the
 * user shares a link with a certain config. Some properties (e.g. quantity) is stored
 * in settings.quantity already, while everything that's missing was moved here 
 * in issue #2728.
 */
export type CreateRcaSettingsType = {
    product: string;
    sortOrder: SortOrder;
    usePlanningData: boolean;
};

export type RcaSettingsType = {
    id?: string;
    status?: "started" | "failed" | "finished";
    alertUser?: boolean;
    showResults: boolean;
    rcaFilters?: EventFilter[] | undefined;
    result?: GetRootCauseAnalysisResponse;
    sortOrder: SortOrder,
    rcaType: RcaType,
    quantity?: BaseQuantityType | undefined;
    analyzedValue?: LegacyAnalyzedValues;
    resultsUrl?: string;
    maximize?: boolean;
    drilldown?: RcaDrilldownType;

    targetType: RcatypeTargets;

    /**
     * Formatter used for the RCA error
     */
    errorFormatter?: (value: number | undefined, locale: string) => string;
}

export type OrderTrackingType = {
    grouping?: GroupingKeys;
    orderFilter?: string;
}

export type HistoryElement = Partial<SettingsType> & {
    url: string;
};

export type ProductIdentifier = {
    id: string;
    name: string;
}

export type SelectionType = {
    node?: Node;
    edge?: MultiEdge;
    case?: string;
    product?: ProductIdentifier;
    category?: string;
    categoryValue?: string;
    machine?: string;
    timeperiod?: TimeperiodDeviationStatisticsSchema | TimeperiodCaseAggregationStatisticsSchema;
    matrixElement?: MatrixCellProps;
    setupTransition?: SetupTransitionSelectionSchema;

    /**
     * RCA Feature
     */
    feature?: FeatureImportance;
}


export type SettingsType = {
    /**
     * Stores everything related to the dashboard configuration.
     */
    dashboard: DashboardSettingsType | undefined;

    /**
     * Grouping that is used by the graph (e.g. machine, location).
     */
    groupingKey: GroupingKeys,

    /**
     * Filters that are currently set in the project.
     */
    filters: EventFilter[],

    /**
     * If set, these filters override filters. Used by the event filter editor.
     */
    previewFilters: EventFilter[] | undefined,

    quantity: BaseQuantityType | undefined,

    /**
     * Stores all rendering information related to the graph.
     */
    graph: GraphSettingsType;

    /**
     * Stores all settings related to the gantt views.
     */
    gantt: GanttSettingsType;

    /**
     * Selection that is displayed in the statistics aside or also can be used for filter shortcuts.
     */
    selection: SelectionType;

    /**
     * State of the filter editor.
     */
    filterEditor: FilterEditState;

    /**
     * All configuration related to KPIs and how they are displayed.
     */
    kpi: KpiSettingsType;

    processPath: ProcessPathSettingsType;

    /**
     * Settings needed for the supply chain view.
     */
    supplyChain: SupplyChainSettingsType;

    rca: CreateRcaSettingsType;

    /**
     * RCA states. The most recent drilldown RCA is the last in the array. Usually, this array should at most be
     * two elements long
     */
    rcaStates: Partial<Record<RcaType, RcaSettingsType[]>>;

    /**
     * Settings related to how the kpi matrix is displayed.
     */
    kpiMatrix: KpiMatrixSettingsType;

    /**
     * The navigation history that can be used to jump back in the analysis.
     */
    history: HistoryElement[];

    /**
     * Settings that are special to the order tracking view.
     */
    orderTracking: OrderTrackingType;

    /**
     * This value is increased whenever failed requests should be re-executed.
     * Make your useEffect that hits the API depending on this and you're done!
     */
    apiRetry: number;

    /**
     * This is read-only and only needed for telling UserPilot about the aside
     * that is currently shown.
     */
    sidePanelPage: number;
};

export type DfgLocationDescriptor = {
    zoom: number;
    viewportSize: Vector;
    references: {
        nodeId: string;
        distanceToCenterSq: number;
        centerTransformed: Vector;
    }[],
}

export type SettingsContextType = SettingsType & {
    set(data: Partial<SettingsType> | undefined): void,
    mergeSet(data: DeepPartial<SettingsType>): void,
    setGraph(data: Partial<GraphSettingsType>): void,
    setGanttState(data: Partial<GanttSettingsType>): void,
    setKpiState(data: Partial<KpiSettingsType>): void,
    setFilterEditState(data: Partial<FilterEditState>): void,
    setSelection(data: Partial<SelectionType>): void,
    setProcessPath(data: Partial<ProcessPathSettingsType>): void,
    setOrderTracking(data: Partial<OrderTrackingType>): void,
};

export const defaultGraph: GraphSettingsType = {
    nodeDetailLevel: NodeDetailLevel.Simple,
    highlight: true,
    orientation: GraphOrientation.horizontal,
    secondGroupingLevel: GroupingKeys.PassValueStream,
    objectType: undefined,
    showObjectContext: true,
    complexityCutoffScore: undefined,
    visualization: VisualizationType.SideBySide,
};

export const emptyRcaState: RcaSettingsType = {
    id: undefined,
    status: undefined,
    alertUser: false,
    showResults: true,
    rcaFilters: undefined,
    sortOrder: SortOrder.Descending,
    quantity: undefined,
    rcaType: RcaType.Time,
    analyzedValue: undefined,
    targetType: "caseScrapMass",
};

export type DashboadTileSettings = {
    kpiType: KpiTypes;
    quantity?: BaseQuantityType;
    statistic: StatisticTypes;
};

export type DashboardSettingsType = {
    tiles: DashboadTileSettings[],
    frequency: TimePeriodFrequencies | undefined,
    showPlanningData: boolean,
};

export const defaultValues: SettingsType = {
    dashboard: undefined,
    groupingKey: GroupingKeys.MachineValueStream,
    filters: [],
    previewFilters: undefined,
    quantity: undefined,
    gantt: {
        timeMode: TimeMode.Absolute,
        sortOrder: SortOrder.Descending,
        caseGanttSettings: CaseGanttSetting.Duration,
    },
    rca: {
        product: "",
        sortOrder: SortOrder.Descending,
        usePlanningData: false,
    },
    kpi: {
        highlightDeviations: false,
        analyzedValue: LegacyAnalyzedValues.TimeValuesMean,
        selectedKpi: KpiTypes.ThroughputTime,
        statistic: StatisticTypes.Median,
        sortOrder: SortOrder.Descending,
        sortBy: SortByType.Kpi,
        relativeToThroughputTime: false,
        aggregation: AggregationTypes.Product,
        comparisons: KpiComparisons.Planning,
        timeScale: TimePeriodFrequencies.Month,
        showCustomerTakt: true,
    },
    supplyChain: {
        selectedProduct: undefined,
    },
    graph: defaultGraph,
    selection: {},
    kpiMatrix: {
        machines: [],
        machineName: undefined,
        comparison: MatrixComparison.None,
        useDeviationBars: false,
        highlightDeviations: true,
    },
    filterEditor: {
        editFilter: undefined,
        editFilterIndex: undefined,
        showFilterEditor: false,
    },
    processPath: {
        processPathSelectedIds: []
    },
    rcaStates: {
        [RcaType.Quality]: [],
        [RcaType.Time]: [],
        [RcaType.Throughput]: [],
        [RcaType.ThroughputTime]: [],
        [RcaType.Bottleneck]: [],
        [RcaType.OrgLosses]: [],
    },
    orderTracking: {
        grouping: GroupingKeys.MachineType,
        orderFilter: undefined,
    },
    history: [],
    apiRetry: 0,
    sidePanelPage: 0
};

export const SettingsContext = React.createContext<SettingsContextType>({
    ...defaultValues,

    // empty functions here
    set: noop,
    mergeSet: noop,
    setGraph: noop,
    setFilterEditState: noop,
    setGanttState: noop,
    setKpiState: noop,
    setSelection: noop,
    setProcessPath: noop,
    setOrderTracking: noop,
});

export function SettingsContextProvider(props: React.PropsWithChildren<{
    initialValues?: Partial<SettingsType>,
    makeSettingsValid?: MakeSettingsValidType
}>) {
    const session = useContext(SessionContext);

    const [state, setState] = useState<SettingsContextType>(() => {
        const defaults: SettingsContextType = {
            set: noop,
            mergeSet: noop,
            setGraph: noop,
            setFilterEditState: noop,
            setGanttState: noop,
            setKpiState: noop,
            setSelection: noop,
            setProcessPath: noop,
            setOrderTracking: noop,
            ...defaultValues,
        };

        return ObjectMerger.mergeObject(defaults, props.initialValues ?? {});
    });

    // Make sure the initial configuration is valid,
    // e.g. pre-select valid quantities...
    useEffect(() => {
        if (!session.project)
            return;

        const newSettings = props.makeSettingsValid !== undefined ? props.makeSettingsValid(session, state) : makeSettingsValid(session, state);
        if (newSettings) {
            setState((s) => {
                return {
                    ...s,
                    ...newSettings
                };
            });
        }
    }, [
        session.project
    ]);

    return <SettingsContext.Provider value={{
        ...state,
        set(data: Partial<SettingsType> | undefined) {
            if (data === undefined)
                return;

            setState((s) => {
                const newState = {
                    ...s,
                    ...data,
                };

                return newState;
            });
        },
        mergeSet(data: DeepPartial<SettingsType>) {
            if (Object.getOwnPropertyNames(data).length === 0)
                return;

            setState((s) => {
                return { ...s, ...ObjectMerger.mergeObject(s, data)};
            });
        },
        setGraph(data: Partial<GraphSettingsType>) {
            setState((s) => {
                const newState = {
                    ...s,
                    graph: {
                        ...s.graph,
                        ...data,
                    },
                };

                return newState;
            });
        },
        setSelection(data: SelectionType) {
            setState((s) => {
                return {
                    ...s,
                    selection: data
                };
            });
        },
        setGanttState(data: Partial<GanttSettingsType>) {
            setState((s) => {
                return {
                    ...s,
                    gantt: {
                        ...s.gantt,
                        ...data,
                    }

                };
            });
        },
        setKpiState(data: Partial<KpiSettingsType>) {
            setState((s) => {
                return {
                    ...s,
                    kpi: {
                        ...s.kpi,
                        ...data,
                    }
                };
            });
        },
        setFilterEditState(data: Partial<FilterEditState>) {
            setState((s) => {
                return {
                    ...s,
                    filterEditor: {
                        ...s.filterEditor,
                        ...data,
                    }
                };
            });
        },
        setProcessPath(data: Partial<ProcessPathSettingsType>) {
            setState((s) => {
                return {
                    ...s,
                    processPath: {
                        ...s.processPath,
                        ...data
                    }
                };
            });
        },
        setOrderTracking(data: Partial<OrderTrackingType>) {
            setState((s) => {
                return {
                    ...s,
                    orderTracking: {
                        ...s.orderTracking,
                        ...data
                    }
                };
            });
        },
    }}>
        {props.children}
    </SettingsContext.Provider>;
}

/**
 * Returns the most recent RCA state for the given RCA type.
 * @param rcaType RCA type
 * @param settings Settings context
 */
export function getRecentRcaByType(rcaType: RcaType, settings: SettingsType) {
    const rca = settings.rcaStates[rcaType];

    if (rca === undefined || rca.length === 0)
        return emptyRcaState;

    return rca[rca?.length - 1];
}

/**
 * Updates or adds a new RCA state for the given RCA type. Then the `update` flag is set to `true`,
 * the most recent RCA state is updated, otherwise a new RCA state is added.
 */
export function updateRecentRcaByType(rcaType: RcaType, settings: SettingsContextType, data: Partial<RcaSettingsType>, update = true) {
    const rca = settings.rcaStates[rcaType];

    const add = rca === undefined || rca.length === 0 || !update;

    if (add)
        rca?.push({
            ...emptyRcaState,
            ...data,
        });
    else
        rca[rca?.length - 1] = {
            ...rca[rca?.length - 1],
            ...data,
        };

    settings.set({
        rcaStates: {
            ...settings.rcaStates,
            [rcaType]: rca
        },
    });
}

export function getPushedHistory(url: string, settings: SettingsType) {
    return [...(settings.history ?? []), {
        filters: settings.filters,
        previewFilters: settings.previewFilters,
        processPath: settings.processPath,
        selection: settings.selection,
        rcaStates: settings.rcaStates,
        url,
    } as HistoryElement];
}

/**
 * Serializes the settings object into something that can be appended into an URL.
 * This is done by first converting the settings into a JSON string, then compressing
 * it using the deflate algorithm and finally converting it into a base64 string.
 * @param settings Settings object
 * @param path Path to the current page. Used to determine wether we're on a dashboard page or not.
 * If not, the dashboard settings will not be serialized. This is done to prevent saved favorites/
 * bookmarked URLs not to overwrite the most recently used dashboard settings. Smells like a hack?
 * THAT'S BECAUSE IT IS!!!
 */
export function serializeSettings(settings: SettingsType, path: string) {
    const isDashboardView = path.endsWith("/dashboard");

    const strippedSettings = JSON.parse(JSON.stringify(settings));
    delete strippedSettings.graph.viewState;
    delete strippedSettings.history;
    delete strippedSettings.apiRetry;

    if (!isDashboardView)
        delete strippedSettings.dashboard;

    const json = JSON.stringify(strippedSettings);
    const binary = Pako.deflate(json, {
        level: 9, // best compression
    });

    // Convert to base64
    return window.btoa(String.fromCharCode(...binary));
}

/**
 * De-serializes the settings from the given base64 string. We're catching and ignoring
 * errors here, because the user may have injected all kind of bad stuff into it
 */
export function deserializeSettings(base64: string | undefined): Partial<SettingsType> | undefined {
    try {
        if (!base64)
            return;

        // decode base64 into an array of binary numbers
        const binary = window.atob(base64).split("").map(c => c.charCodeAt(0));
        const buffer = new Uint8Array(binary);

        const json = Pako.inflate(buffer, {
            to: "string"
        });

        return JSON.parse(json) as Partial<SettingsType>;
    } catch (e) {
        // Do nothing
    }
}

export function applySettingsHash(hash: string, settings: SettingsContextType) {
    if (!hash || hash.length < 2)
        return;

    const deserializedSettings = deserializeSettings(hash.substring(1));

    if (deserializedSettings) {
        const newState: SettingsType = ObjectMerger.mergeObject(settings, deserializedSettings);

        // Write settings to local storage. When the user navigates to the application from a shared
        // link, we want to use the settings from the link, not the ones from the local storage.
        setPreferences(newState);
        settings.set(newState);
    }
}

/**
 * Applies a favorite view configuration to the current URL and updates the settings accordingly.
 * @param favorite - The favorite view configuration to apply.
 * @param settings - The current settings context.
 * @param navigate - The navigate function from the router.
 */
export function applyViewConfiguration(favorite: ViewConfigurations, settings: SettingsContextType, navigate: NavigateFunction) {
    const favoriteUrl = `${new URL(window.location.href).origin}/projects/${favorite.projectId}/${favorite.viewId}#${serializeSettings(settings, window.location.pathname)}`;
    const newUrl = new URL(favoriteUrl);

    // This is the key used to store and update the preferences. That key includes
    // only the pathname, without origin or fragment (e.g. /projects/UUID/timings/process/dfg)
    const preferenceKey = newUrl.toString().substring(newUrl.origin.length, newUrl.pathname.length + newUrl.origin.length);

    // Merge current state with the one extracted from the favorite
    const newState: SettingsType = ObjectMerger.mergeObject(settings, favorite.settings);
    newState.history = [];

    // Write settings to local storage. When the user navigates to the application from a favorite
    // URL, we want to use the settings from the link, not the ones from the local storage.
    setPreferences(newState, preferenceKey);
    settings.set(newState);

    navigate(preferenceKey.toString(), { replace: true });
}
