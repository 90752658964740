import React, { useContext } from "react";
import { SettingsContext } from "../../../contexts/SettingsContext";
import { SessionContext } from "../../../contexts/SessionContext";
import { useCaseStatistics } from "../../../hooks/UseCaseStatistics";
import AggregatedCaseStats from "./AggregatedCaseStats";
import { getKpiDefinition } from "../../../models/Kpi";
import { KpiTypes } from "../../../models/KpiTypes";

export function LogStats(props: {addEnergyStats?: boolean, overrideKpi?: KpiTypes}) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const kpiDefinition = getKpiDefinition(props.overrideKpi ?? settings.kpi.selectedKpi, { session, settings });
    const apiParameters = kpiDefinition?.apiParameters;
    const customKpis = kpiDefinition?.caseCustomKpis ? kpiDefinition.caseCustomKpis : undefined;

    const [caseStats, isCaseStatsLoading] = useCaseStatistics({
        ...apiParameters,
        customKpis,
        limit: 0,
    }, {addEnergyStats: props.addEnergyStats});

    const log = caseStats?.log;
    if (kpiDefinition === undefined)
        return null;

    return <AggregatedCaseStats
        title={"common.allSelectedCases"}
        kpiDefinitions={[kpiDefinition]}
        selection={log}
        count={log?.count}
        isLoading={isCaseStatsLoading}
        useLogPath={true}
    />;
}
