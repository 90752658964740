import { AggregationTypes, KpiComparisons } from "../contexts/ContextTypes";
import { SessionType } from "../contexts/SessionContext";
import { SettingsType } from "../contexts/SettingsContext";
import { EventKeys } from "../models/EventKeys";
import { Project } from "../models/Project";
import { KpiDefinition, getKpiDefinition } from "../models/Kpi";
import { get, isString } from "lodash";
import { StatsTypes } from "../models/Stats";
import { StatisticTypes } from "../models/KpiTypes";

export function getDefaultEnabledComparisons(session: SessionType, settings: SettingsType) {
    const definition = getKpiDefinition(settings.kpi.selectedKpi, {
        session, settings
    });
    if (!definition)
        return [];

    const hasPlanning = session.project?.uploadIdPlan !== undefined &&
        session.project?.eventKeysPlan !== undefined &&
        definition?.allowedComparisons.includes(KpiComparisons.Planning);

    const result: KpiComparisons[] = [KpiComparisons.None];
    if (definition.allowedComparisons.includes(KpiComparisons.Planning) && hasPlanning && !(settings.kpi.statistic === StatisticTypes.Variance))
        result.push(KpiComparisons.Planning);

    // "Best Process" is only available when the statistic is "Mean" or "Median" and the aggregation is "Product"
    if (definition.allowedComparisons.includes(KpiComparisons.BestProcesses) &&
        [StatisticTypes.Mean, StatisticTypes.Median].includes(settings.kpi.statistic) && settings.kpi.aggregation === AggregationTypes.Product)
        result.push(KpiComparisons.BestProcesses);

    return result;
}

export function isObjectCentricAvailable(eventKeys: EventKeys | undefined) {
    if (eventKeys === undefined)
        return false;
    return eventKeys.object !== undefined && eventKeys.objectType !== undefined;
}

export function isObjectCentricDeviationAvailable(project: Project) {
    return (
        project.eventKeys !== undefined && isObjectCentricAvailable(project?.eventKeys) &&
        project.eventKeysPlan !== undefined && isObjectCentricAvailable(project.eventKeysPlan)
    );
}

/**
 * This function's purpose is to hint at where to get product data from. It returns a string that describes
 * the path to the product data. This path can be used with lodash's get function to retrieve the values.
 * 
 * The path refers to elements in the product statistics response (see Api.ProductCaseAggregationStatistics).
 * @param statsProperty 
 * @returns 
 */
export function getProductPathFromDefinition(settings: SettingsType, kpiDefinition: KpiDefinition | undefined, statsProperty?: StatsTypes) {
    if (isString(kpiDefinition?.productStatisticsPath)) {
        if (settings.kpi.statistic === StatisticTypes.Variance)
            return kpiDefinition?.productStatisticsPath;
        return `${kpiDefinition?.productStatisticsPath}.${statsProperty ?? settings.kpi.statistic}`;
    }
    if (statsProperty)
        return `${get(kpiDefinition?.productStatisticsPath, "variance")}.${statsProperty}`;
    return get(kpiDefinition?.productStatisticsPath, settings.kpi.statistic);
}
