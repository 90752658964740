import { useContext } from "react";
import { SessionContext } from "../contexts/SessionContext";
import { SettingsContext } from "../contexts/SettingsContext";
import { DeviationOptions, GetDeviationRequest, PerProductDeviationStatisticsSchema, disableAllCalcOptions } from "../models/ApiTypes";
import { EventFilter } from "../models/EventFilter";
import { Datastores } from "../utils/Datastores";
import { useApi } from "./UseApi";
import { EventKeys } from "../models/EventKeys";

export function useProductDeviation(options?: {
    eventFilters?: EventFilter[],
} & Partial<DeviationOptions>, disable = false): [PerProductDeviationStatisticsSchema | undefined, boolean] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const { eventFilters, ...nonFilterOptions } = options ?? {};
    const filters = eventFilters ?? settings.previewFilters ?? settings.filters;

    const requestOptions: GetDeviationRequest = {
        ...disableAllCalcOptions,
        ...nonFilterOptions,
        actual: {
            uploadId: session.project?.uploadId ?? "",
            uploads: session.project?.uploads,
            eventKeys: session.project?.eventKeys ?? {} as EventKeys,
            eventFilters: filters,
        },
        planned: {
            uploadId: session.project?.uploadIdPlan ?? "",
            eventKeys: session.project?.eventKeysPlan ?? {} as EventKeys,
        },
        consolidatePasses: true,
    };

    return useApi(
        Datastores.getPerProductCaseDeviationStatistics,
        requestOptions,
        [
            JSON.stringify(requestOptions)
        ], {
            ...options,
            disable: disable || session.project?.uploadIdPlan === undefined || session.project === undefined || session.project.eventKeysPlan === undefined,
        });
}
