import React, { useContext } from "react";
import { KpiTypes } from "../../models/KpiTypes";
import { buildControllerSpotlightId } from "../../utils/Utils";
import { GroupingKeyControls, VisibilityOptions, getValidGroupingKeyControlSettings, getValidSecondGroupingLevel, isOrderSequenceSeparated } from "./GroupingKeyControls";
import { KpiComparisonControls, getValidKpiComparisonControlSettings } from "./KpiComparisonControls";
import { BenchmarkingKpiSortBy, getValidBenchmarkingKpiSortBySettings } from "./KpiControlPrimitives";
import { KpiControls, getValidKpiControlSettings } from "./KpiControls";
import { ObjectControls, getValidObjectControlsSettings } from "./ObjectControls";
import { getEnabledComparisonsValueStream } from "../../utils/DfgUtils";
import { SettingsContext, SettingsType } from "../../contexts/SettingsContext";
import { SessionType } from "../../contexts/SessionContext";
import { DeepPartial, ObjectMerger } from "../../utils/ObjectMerger";
import { set } from "lodash";
import { KpiHighlightControls } from "./HighlightControls";
import { KpiComparisons } from "../../contexts/ContextTypes";

type NodeKpiControlsProps = {
    /**
     * Check KpiPresets if there is something that suits your needs
     */
    kpis: KpiTypes[],
}

export function getValidNodeKpiControlSettings(session: SessionType, settings: SettingsType, props: NodeKpiControlsProps): DeepPartial<SettingsType> {
    const result: DeepPartial<SettingsType> = {};
    const secondGroupingLevel = getValidSecondGroupingLevel(session, settings);
    if (secondGroupingLevel !== undefined && settings.graph.secondGroupingLevel !== secondGroupingLevel)
        set(result, "graph.secondGroupingLevel", secondGroupingLevel);

    return ObjectMerger.mergeObjects([
        result,
        getValidKpiControlSettings(session, settings, {
            kpis: props.kpis,
            hasYAxis: false,
            addQuantityDropdown: true,
        }),
        getValidObjectControlsSettings(session, settings),
        getValidGroupingKeyControlSettings(session, settings, { options: { none: VisibilityOptions.Hidden }, secondaryGrouping: VisibilityOptions.Hidden, isXAxisLabel: true }),
        getValidKpiComparisonControlSettings(session, settings, { enabledComparisons: getEnabledComparisonsValueStream }),
        getValidBenchmarkingKpiSortBySettings(session, settings, { isValueStream: true, enableOrderSequencesSorting: true }),
    ]);
}

export function NodeKpiControls(props: NodeKpiControlsProps) {
    const settings = useContext(SettingsContext);

    return <div className="controls">

        <KpiControls kpis={props.kpis} hasYAxis={true} addQuantityDropdown={true} />

        <ObjectControls showContextSwitch={false} />

        <GroupingKeyControls
            options={{
                none: VisibilityOptions.Hidden,
            }}
            secondaryGrouping={VisibilityOptions.Hidden}
            isXAxisLabel={true}
        />

        <KpiComparisonControls enabledComparisons={getEnabledComparisonsValueStream} />

        {settings.kpi.comparisons === KpiComparisons.Planning && <KpiHighlightControls />}
        
        <BenchmarkingKpiSortBy spotlightId={buildControllerSpotlightId(location.pathname, ["SortBy"])} isValueStream={true} enableOrderSequencesSorting={ isOrderSequenceSeparated(settings.groupingKey)  } />

    </div>;
}
