import { useContext } from "react";
import { SessionContext } from "../contexts/SessionContext";
import { SettingsContext } from "../contexts/SettingsContext";
import { PerTimeperiodEdgeStatisticsParams, PerTimeperiodEdgeStatisticsSchema, TimePeriodFrequencies, disableAllCalcOptions } from "../models/ApiTypes";
import { EventKeys } from "../models/EventKeys";
import { Datastores } from "../utils/Datastores";
import { useApi } from "./UseApi";
import { defaultApiLimit } from "./UseCaseStatistics";

export function useEdgeAggregationTimeperiods(request?: Partial<PerTimeperiodEdgeStatisticsParams>, options?: {
    disable?: boolean,
}): [PerTimeperiodEdgeStatisticsSchema | undefined, boolean] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    if (request?.edges === undefined)
        throw new Error("request.edges must be defined");

    const requestOptions: PerTimeperiodEdgeStatisticsParams = {
        ...disableAllCalcOptions,
        ...request,
        edges: request.edges ?? [],
        eventFilters: request?.eventFilters ?? settings.previewFilters ?? settings.filters,
        eventKeys: request?.eventKeys ?? session.project?.eventKeys ?? {} as EventKeys,
        frequency: request?.frequency ?? TimePeriodFrequencies.Day,
        uploadId: request?.uploadId ?? session.project?.uploadId ?? "",
        uploads: request?.uploads ?? session.project?.uploads,
        tz: request?.tz ?? session.timezone,
        limit: request?.limit ?? defaultApiLimit,
    };

    return useApi(
        Datastores.getEdgeAggregationTimeperiods,
        requestOptions,
        [
            JSON.stringify(requestOptions)
        ], {
            ...options,
            disable: options?.disable || 
                session.project === undefined,
        });
}
