import React, { useContext } from "react";
import { getDimensionParameters } from "../../components/dimension/Dimension";
import { TabbedView } from "../../components/tabbed-view/TabbedView";
import { AggregationTypes } from "../../contexts/ContextTypes";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext, SortByType } from "../../contexts/SettingsContext";
import { viewSettingsInitialization } from "../../utils/Initializers";
import { getAssignedQuantities, QuantityType } from "../../utils/Quantities";
import { ProcessKpiChart } from "../process-kpi-chart/ProcessKpiChart";
import { KpiPresets } from "../../models/KpiTypes";
import BenchmarkingControls, { getValidBenchmarkingControlSettings } from "../../components/controls/BenchmarkingControls";
import ProductSideStatistics from "../process-kpi-chart/stats/ProductSideStatistics";
import { getDefaultEnabledComparisons } from "../../utils/SettingsUtils";
import { BackButtonTrayElement } from "../../components/tray/BackButtonTrayElement";
import { caseKpiControlsGetAllowedStatistics } from "../../models/Kpi";
import { ObjectMerger } from "../../utils/ObjectMerger";
import { LogStats } from "../process-kpi-chart/stats/LogStats";
import { ViewSubtitle } from "../../components/tabbed-view/ViewSubtitle";

export function CarbonKpisView() {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const yieldQuantities = getAssignedQuantities(session.project?.eventKeys, QuantityType.CaseYield, false).map(q => q.baseQuantity);

    return <TabbedView
        subtitle={<ViewSubtitle />}
        breadcrumbs={[{
            label: "common.products"
        }]}
        pages={[{
            tabTitle: "common.processKpis",
            tabSlug: "process",
            selectionTrigger: (settings) => {
                return settings.selection.product !== undefined ||
                    settings.selection.case !== undefined ||
                    settings.selection.timeperiod !== undefined;
            },
            stats: <ProductSideStatistics
                unmarkedAside={<LogStats addEnergyStats={true} />}
            />,
            controller: <BenchmarkingControls kpis={KpiPresets.productSustainabilityKpis} />,
            activator: (preferences) => {
                let temp = viewSettingsInitialization(session, settings, preferences, {
                    sortBy: [SortByType.Kpi, SortByType.Frequency, SortByType.Alphabetical, SortByType.DeviationFromComparison],
                    quantities: yieldQuantities,
                    kpis: KpiPresets.productSustainabilityKpis,
                    aggregationTypes: [AggregationTypes.Product, AggregationTypes.Case, AggregationTypes.Time],
                });

                if (!temp)
                    return;

                temp = ObjectMerger.mergeObject(temp, getValidBenchmarkingControlSettings(session, temp, { kpis: KpiPresets.productSustainabilityKpis }));

                const allowedStatistics = caseKpiControlsGetAllowedStatistics(session, settings, temp.kpi.selectedKpi, temp.kpi.aggregation);

                settings.set(viewSettingsInitialization(session, temp, undefined, {
                    statistics: allowedStatistics,
                    comparisons: getDefaultEnabledComparisons(session, temp),
                }));
            },
            dimensions: getDimensionParameters(session.projectId, "kpis/process"),
            content: <>
                <ProcessKpiChart
                    noProductDataPlaceholder="kpi.noDataProductsRequired"
                    noCaseDataPlaceholder=""
                    noTimeDataPlaceholder="kpi.noDataInTimeRange"
                    pageSlug="carbon"
                    addEnergyStats={true} />
                <BackButtonTrayElement />
            </>,
        }]}
    >
        <BackButtonTrayElement />
    </TabbedView>;
}