import { isArray, isEqual } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { SessionContext } from "../../../../contexts/SessionContext";
import { SettingsContext } from "../../../../contexts/SettingsContext";
import { useGroupEvents } from "../../../../hooks/UseGroupEvents";
import i18n from "../../../../i18n";
import { NodeActivitySchema } from "../../../../models/Dfg";
import { EventFilter, isFilterEqual } from "../../../../models/EventFilter";
import { buildActivityFilter } from "../../../../utils/FilterBuilder";
import { getActivityLabelFromColumnInfo } from "../../../../utils/GroupingUtils";
import Dropdown from "../../../dropdown/Dropdown";
import Spinner from "../../../spinner/Spinner";

type ActivityFilterEditorPropsType = {
    initialValue?: EventFilter;
    onUpdate?: (e: EventFilter | undefined) => void;
};
type ActivityFilterEditorStateType = {
    include: boolean;
    selectedId: string;
};

export default function ActivityFilterEditor(props: ActivityFilterEditorPropsType) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const selectRef = useRef<HTMLDivElement>(null);

    const initialFilterEntry = props.initialValue?.filters?.length ? props.initialValue?.filters[0] : undefined;
    const filterGroupingMode = initialFilterEntry?.activity?.groupingKey;

    // We need to exclude the current filter in case initialValue is set, because otherwise an excluding
    // filter would remove the activity it's excluding, and the dropdown could not be initialized with it.
    const filters = settings.filters.filter((f, idx) => f !== props.initialValue && idx !== settings.filterEditor.editFilterIndex);

    // Returns attributes that are column-mapped. Attributes like timeComponent are NOT
    // included in the response and need special care.
    const [activityGroup, attributes, isLoading] = useGroupEvents(filterGroupingMode ?? settings.groupingKey, filters);

    const sortedActivities = [...(activityGroup ?? [])].sort((a, b) => {
        return (isArray(a.value) ? a.value : [a.value]).join(", ").localeCompare((isArray(b.value) ? b.value : [b.value]).join(", "));
    });

    const activity = initialFilterEntry?.activity?.ne ??
        initialFilterEntry?.activity?.eq;

    const include = !initialFilterEntry?.activity?.ne;

    const [state, setState] = useState<ActivityFilterEditorStateType>({
        selectedId: "",
        include
    });

    // Initialize attributes
    useEffect(() => {
        if (!attributes)
            return;

        // As attributes and activityGroup are loaded now, we can finally
        // initialize the selected element
        if (initialFilterEntry && activity?.length) {
            const entryElements: (string | undefined)[] = [];

            for (const attribute of attributes) {
                const filterIdx = activity[0].keys.findIndex(a => a === attribute);
                const value = activity[0].values[filterIdx];
                entryElements.push(value);
            }

            const element = activityGroup?.find(a => isEqual(a.value, entryElements));
            if (element)
                setState({
                    ...state,
                    selectedId: element.id,
                });
        }
    }, [attributes]);

    useEffect(() => {
        emitFilter(state);
    }, [state]);

    if (!session.project?.eventKeys)
        return null;

    const options = [{
        label: i18n.t("common.pleaseSelect"),
        value: "",
    }].concat(sortedActivities.map((a) => {
        return {
            label: getActivityLabelFromColumnInfo(a, attributes ?? [], filterGroupingMode ?? settings.groupingKey),
            value: a.id,
        };
    }));

    return <>
        <Spinner isLoading={isLoading}></Spinner>
        {!isLoading && <div className="tabPage activityFilterEditor light">
            <h3>{i18n.t("filters.activityFilter")}</h3>
            <Dropdown
                isSearchable={true}
                className="dropdownLight"
                maxMenuHeight={selectRef.current?.clientHeight ?? 240}
                value={options.find(o => o.value === state.selectedId)!}
                options={options}
                onChange={(e) => {
                    if (!e)
                        return;

                    handleChange({
                        ...state,
                        selectedId: e.value as string,
                    });
                }}
            />

            {/* This area is used to calculate the maxMenuHeight */}
            <div className="expansionSpace" ref={selectRef} />

            <div className="checkboxes">
                <label>
                    <input
                        type="checkbox"
                        className="checkbox"
                        checked={!state.include}
                        id="checkbox-exclude"
                        data-testid="checkbox-exclude"
                        onChange={(e) => {
                            handleChange({ ...state, include: !e.target.checked });
                        }} />
                    <label htmlFor="checkbox-exclude" />
                    {i18n.t("filters.excludeActivityLabel")}
                </label>
            </div>
        </div>}
    </>;

    function handleChange(state: ActivityFilterEditorStateType) {
        setState(state);
        emitFilter(state);
    }

    function emitFilter(state: ActivityFilterEditorStateType) {
        if (!props.onUpdate)
            return;

        const info = activityGroup?.find(a => a.id === state.selectedId);
        if (info === undefined)
            props.onUpdate(undefined);
        else {
            const filter = buildActivityFilter({
                id: state.selectedId,
                keys: attributes as (keyof NodeActivitySchema)[],
                values: info.value
            }, !state.include, filterGroupingMode ?? settings.groupingKey);

            if (isFilterEqual(props.initialValue, filter))
                props.onUpdate(undefined);
            else
                props.onUpdate(filter);
        }
    }
}