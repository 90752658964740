import { isString } from "lodash";
import React from "react";
import i18n from "../../i18n";
import { ValueSpinner } from "../value-spinner/ValueSpinner";
import { Spotlight } from "../spotlight/Spotlight";

export type StatsSectionProps = {
    /**
     * Section title. If not provided, no title will be displayed.
     * Supports translation strings.
     */
    title?: string | JSX.Element | JSX.Element[];

    /**
     * Sometimes the title needs to be fetched from the API. Set this
     * property to `true` while that's the case.
     */
    isTitleLoading?: boolean;

    children: React.ReactNode;
}

/**
 * This component renders a stats section as it is commonly used in asides.
 * It just wraps the main headline into corresponding markup. Nothing fancy, really.
 */
export function StatsSection(props: StatsSectionProps) {
    return <div className="section">
        <div className="valueSpinnerTextHighlightPlaceholder title">
            <ValueSpinner isLoading={props.isTitleLoading === true}>
                <>{isString(props.title) && !props.title.toString().includes(" ") ? i18n.t(props.title ?? "") : props.title}</>
            </ValueSpinner>
        </div>

        {props.children}
    </div>;
}

/**
 * This component renders a stats subsection as it is commonly used in asides.
 * It's main purpose is to encapsulate the styling so you don't have to look it
 * up each time. This will hopefully make our lives easier and the application's
 * appearance more consistent.
 */
export function StatsSubSection(props: StatsSectionProps & { disableTable?: boolean, spotlightId?: string }) {
    return <div className="subsection">
        {props.title !== undefined && <div className="valueSpinnerTextHighlightPlaceholder title">
            <ValueSpinner isLoading={props.isTitleLoading === true}><>
                {isString(props.title) ? i18n.t(props.title).toString() : props.title}
                {props.spotlightId && <Spotlight id={props.spotlightId} className="mls" />}
            </></ValueSpinner>
        </div>}

        <div className="sectionBody">
            {!props.disableTable &&
                <div className="table">
                    {props.children}
                </div>}
            {props.disableTable &&
                <>{props.children}</>
            }
        </div>
    </div>;
}