import interpolate from "color-interpolate";
import React, { useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import colors from "../../colors.json";
import { SessionContext, SessionType } from "../../contexts/SessionContext";
import { DfgLocationDescriptor, GraphOrientation, SelectionType, SettingsContext, SettingsType } from "../../contexts/SettingsContext";
import Global from "../../Global";
import { analysisGraphMapping, AnalysisType, useGraph, calculateGraphDisplayArguments } from "../../hooks/UseGraph";
import { defaultGraphFilter, GraphLayoutState, preferredNodeCountLimit, useGraphLayout } from "../../hooks/UseGraphLayout";
import { useMountedState } from "../../hooks/UseMounted";
import { useResizeObserver } from "../../hooks/UseResizeObserver";
import i18n from "../../i18n";
import { ALL_OBJECT_INDICATOR, BaseGraph, CASE_TYPE_ID, Edge, END_NODE_ID, GroupingKeys, Layout, MultiEdge, Node, NodeRoles, Point, START_NODE_ID } from "../../models/Dfg";
import { BoundingBox } from "../../utils/BoundingBox";
import { DfgUtils, getCustomKpisDfg, isStandardNode, isTerminalNode } from "../../utils/DfgUtils";
import { Formatter } from "../../utils/Formatter";
import { Matrix } from "../../utils/Matrix";
import { Vector } from "../../utils/Vector";
import { I18nLinks } from "../i18n-links/I18nLinks";
import { NotificationService } from "../notification/NotificationService";
import Shortcuts, { ShortcutContexts } from "../shortcuts/Shortcuts";
import Spinner from "../spinner/Spinner";
import { hideAllSpotlights } from "../spotlight/Spotlight";
import Toast, { ToastTypes } from "../toast/Toast";
import { ChartEdge } from "./ChartEdge";
import { DfgLegend, DfgLegendProps } from "./DfgLegend";
import { DfGraphComplexity } from "./DfGraphComplexitySlider";
import { DfGraphControls } from "./DfGraphControls";
import { Leg } from "./Leg";
import { getNodeWarnings } from "./nodes/NodeMarkupFactory";
import NodeRenderer, { NodeMarkupGroup, NodeMarkupRow } from "./nodes/NodeRenderer";
import { debounce, intersection } from "lodash";
import { KpiPresets } from "../../models/KpiTypes";
import { getMainNodeKpi } from "../../utils/MainNodeKpi";
import { classNames, saveToFile } from "../../utils/Utils";
import { isObjectCentricAvailable } from "../../utils/SettingsUtils";
import { KpiTypes } from "../../models/KpiTypes";

const MAX_SLIDER_VALUE = 200;

const ZOOM_SPEED = 1000;

export enum GraphChangeBehavior {
    Default,
    AlwaysFitOnChange,
    FitOnNodeCountChange,
}

// we split node and edge coloring
// edge coloring starts with grey, node coloring starts with the default color
// both start with their default color
export type HighlightFunc = (index: number) => string;
export const nodeHighlightColorMapDefault = interpolate([colors.$graphNodeSlightBlue, colors.$graphNodeBlue]);
export const nodeHighlightColorMapReverse = interpolate([colors.$graphNodeBlue, colors.$graphNodeSlightBlue]);

export const edgeHighlightColorMapDefault = interpolate([colors.$graphEdgeDefaultColor, colors.$graphNodeBlue]);
export const edgeHighlightColorMapReverse = interpolate([colors.$graphNodeBlue, colors.$graphEdgeDefaultColor]);

export const nodeHighlightColorMapLate = interpolate([colors.$graphNodeSlightRed, colors.$graphNodeRed]);
export const nodeHighlightColorMapEarly = interpolate([colors["$green-100"], colors.$positive]);
export const edgeHighlightColorMapLate = interpolate([colors.$graphEdgeDefaultColor, colors.$graphNodeRed]);
export const edgeHighlightColorMapEarly = interpolate([colors.$graphEdgeDefaultColor, colors.$graphNodeGreen]);

export const deviationLegendProps: DfgLegendProps = {
    leftLabel: "common.tooFast",
    rightLabel: "common.tooSlow",
    leftColormap: nodeHighlightColorMapEarly,
    rightColormap: nodeHighlightColorMapLate,
};


export const deviationLegendFrequencyProps: DfgLegendProps = {
    leftLabel: "common.tooRare",
    rightLabel: "common.tooOften",
    leftColormap: nodeHighlightColorMapEarly,
    rightColormap: nodeHighlightColorMapLate,
};

export const nodeLegendProps: DfgLegendProps = {
    leftLabel: "common.low",
    rightLabel: "common.high",
    colormap: nodeHighlightColorMapDefault,
};

export const nodeLegendDurationProps: DfgLegendProps = {
    ...nodeLegendProps,
    leftLabel: "common.durationShort",
    rightLabel: "common.durationLong",
};

export const nodeLegendFrequencyProps: DfgLegendProps = {
    ...nodeLegendProps,
    leftLabel: "common.frequencyRare",
    rightLabel: "common.frequencyOften",
};


/**
 * Returns appropriate legend properties for the selected kpi
 */
export function getLegendProps(kpi: KpiTypes) {
    if (kpi === KpiTypes.Frequency)
        return nodeLegendFrequencyProps;
    if (KpiPresets.valueStreamTimeKpis.includes(kpi) || kpi === KpiTypes.CycleTime)
        return nodeLegendDurationProps;
    return nodeLegendProps;
}

export type NodeDataQualityWarningsFunc = (node: Node, settings: SettingsType, session: SessionType) => string[] | undefined;
export type MarkupFunc = (node: Node, settings: SettingsType, session: SessionType) => (NodeMarkupRow | NodeMarkupGroup)[];
export type EdgeLabelFunc = (edge: MultiEdge, settings: SettingsType, session: SessionType) => string | undefined;
export type EdgeColorFunc = (edge: MultiEdge) => string;
export type EdgeStatFunc = (edge: MultiEdge, settings: SettingsType, session: SessionType) => number | undefined;
export type EdgeColorScaleFunc = (edge: MultiEdge, stat: number | undefined, minStatistic: number, maxStatistic: number, scale: number | undefined) => string | undefined;
export type NodeStatFunc = (node: Node, settings: SettingsType) => number | undefined;
export type NodeClassFunc = (node: Node, settings: SettingsType) => string | undefined;
export type NodeColorScaleFunc = (node: Node, stat: number | undefined, minStatistic: number, maxStatistic: number, scale: number | undefined) => string | undefined;

export interface IDfGraph {
    fitGraph: () => void;
    centerNode: (nodeId: string | undefined) => void;
    setZoom: (zoom: number, animate: boolean, targetNode?: Node, targetEdge?: MultiEdge) => void;

    save: (filename: string) => void;

    setViewState: (viewState: Partial<PanZoomState>, orientation?: GraphOrientation, animate?: boolean) => void;
    getViewState: () => { viewState: PanZoomState, orientation: GraphOrientation };

    /**
     * Triggers a redraw
     */
    invalidate: () => void;
}

export type NodeMetrics = {
    width: number;
    height: number;
    top: number;
    left: number;
    right: number;
    bottom: number;
}

export type NodeProps = {
    isSelected: boolean;
    isGroup: boolean;
    node: Node;
    className?: string;
    statisticValue: number | undefined;
    statistic: string | number | undefined;
    dom?: NodeDomProps;
    handlers?: NodeHandlers;
}

export type NodeDomProps = {
    style: {
        top: number;
        left: number;
        width: number;
        height: number;
        backgroundColor: string;
        opacity?: number;
    };
    key: string;
};

export type NodeHandlers = {
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onDoubleClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

enum UiStates {
    Idle = 0,
    Panning = 1,
    ZoomControl = 2,
    Touch = 3,
}

export enum AutoCenteringModes {
    None,
    CenterOnLoad,
    CenterIfUninitialized,
}

export type PanZoomState = {
    zoom: number;
    moveDistance?: number;

    /**
     * When a drag gesture is currently ongoing, this is the origin point. undefined
     * if not currently dragging.
     */
    dragStart?: Vector | undefined;
    transform: Matrix;

    uiState: UiStates;
}

type EdgeFuncs = Partial<{
    edgeHighlightStatFunc: EdgeStatFunc | undefined,
    edgeColorFunc: EdgeColorFunc | undefined,
    edgeHighlightColorFunc: EdgeColorScaleFunc | undefined,
    edgeLabelFunc: EdgeLabelFunc | undefined,
    edgeThicknessFunc: ((edges: MultiEdge, stat: number, minStatistic: number, maxStatistic: number, scale: number) => number) | undefined,
}>;

export type GraphPropsType = EdgeFuncs & {
    /**
     * When you have multiple <Graph> instances in your view, be sure to set
     * a unique ID per instance
     */
    id?: string;
    isLoading?: boolean;
    className?: string;
    analysis?: AnalysisType;
    minZoom?: number;
    maxZoom?: number;
    centerMode?: AutoCenteringModes;
    markupFunc: MarkupFunc;
    nodeDataQualityWarningsFunc?: NodeDataQualityWarningsFunc;

    // set to true when the project loading spinner should show up
    showProjectLoadingSpinner?: boolean;

    /**
     * KpiTypes for which the data should be requested.
     */
    kpiTypes?: KpiTypes[];

    /**
     * If false, the complexity slider will be hidden
     */
    showComplexitySlider?: boolean;

    legend?: DfgLegendProps;

    /**
     * If omitted, this setting is pulled from the settings
     */
    highlightNodes?: boolean;

    /**
     * If omitted, this setting is pulled from the settings
     */
    highlightEdges?: boolean;

    zoomControlLocation?: ZoomControlLocations;

    /**
     * Triggered when the graph orientation changes
     */
    onOrientationChanged?: (orientation: GraphOrientation) => void;

    /**
     * Determines what happens when the graph changes
     */
    graphChangeBehavior?: GraphChangeBehavior;

    nodeHighlightStatFunc?: NodeStatFunc;
    nodeClassFunc?: NodeClassFunc;
    nodeHighlightColorFunc?: NodeColorScaleFunc;
    onViewChanged?: (viewState: PanZoomState) => void,
    onSelected?: (selection: SelectionType) => void,

    isObjectCentric?: boolean;
};

export const ProcessGraph = React.forwardRef((props: DfgLayoutPropsType, ref: React.Ref<IDfGraph>) => {

    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const analysisArguments = analysisGraphMapping.find((a) => a.analysisType === props.analysis)?.arguments;

    const graphOptions = {
        ...analysisArguments,
        ...getCustomKpisDfg(settings, session, false, props.kpiTypes),
        ...calculateGraphDisplayArguments,
    };
    const graph = useGraph({
        ...graphOptions,
        ...((settings.groupingKey === GroupingKeys.Machine) ? {
            // These are needed for the node warning checks
            calculateInterruptionStats: true,
            calculateFailureStats: true,
            calculateUnknownStats: true,
            calculateSetupStats: true,
            calculateProductionStats: true,
            calculateBusyStats: true,
            calculateOutputStats: true,
            calculateTimeAndFreqStats: true,
        } : {}),
    }, props.analysis ?? AnalysisType.Times);

    return <div className="fillParent">
        <DfGraphLayout
            {...props}
            ref={ref}
            graph={graph}
            preferredNodeCount={preferredNodeCountLimit}

            // We only show warnings on the workplace (Machine) level for now. This holds also for separated orders (MachineValueStream).
            nodeDataQualityWarningsFunc={getNodeWarnings}
        />
        <Shortcuts handledSelections={[ShortcutContexts.Node, ShortcutContexts.Edge]} graph={graph} />
    </div>;
});

export enum ZoomControlLocations {
    Left,
    Right,
    FarRight,
}

export type DfgLayoutPropsType = GraphPropsType & {
    graph?: BaseGraph,
    preferredNodeCount?: number,
};

export type DfgPropsType = GraphPropsType & { graph?: BaseGraph, graphLayout: GraphLayoutState };

export const DfGraphLayout = React.forwardRef((props: DfgLayoutPropsType, ref: React.Ref<IDfGraph>) => {
    const session = useContext(SessionContext);
    const hasEventId = session.project?.eventKeys?.eventId !== undefined;

    const graphLayout = useGraphLayout({
        graph: props.graph,
        preferredNodeCount: props.preferredNodeCount,
        preLayoutGraphFilterFunc: defaultGraphFilter,
        postLayoutGraphFilterFunc: filterGraphPostLayout,
        markupFunc: props.markupFunc,
        edgeLabelFunc: props.edgeLabelFunc,
        isObjectCentric: props.isObjectCentric || false,
        hasEventId,
        // When no complexity slider is shown, don't reduce the complexity
        complexityCutoffScore: props.showComplexitySlider === false ? 0 : undefined,
        disabled: props.graph === undefined,
        onScoreLimited: (percent) => {
            NotificationService.add({
                id: "restricted-complexity",
                className: "light default-accent",
                icon: "radix-exclamation-triangle",
                autoCloseDelay: Global.defaultNotificationDelay,
                summary: "common.restrictedComplexityTitle",
                message: i18n.t("common.restrictedComplexityMessage", {
                    percent: Formatter.formatPercent(percent, 100, 0, session.numberFormatLocale)
                }).toString(),
            });
        }
    });

    return <DfGraphRender
        {...props}
        ref={ref}
        graphLayout={graphLayout}
    />;
});

export const DfGraphRender = React.forwardRef((props: DfgPropsType, ref: React.Ref<IDfGraph>) => {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);

    const viewStateRef = useRef<PanZoomState>({
        transform: Matrix.createIdentityMatrix(),
        zoom: 1,
        uiState: UiStates.Idle,
    });

    const [redraw, setRedraw] = useState<number>(0);

    const [borderSize, setBorderSize] = useState(1);

    const transformReceiverRef = useRef<HTMLDivElement>(null);

    const graphLayout = props.graphLayout;
    const numNodes = graphLayout.layoutedGraph?.nodes?.length ?? 0;
    const [previousNodeCount, setPreviousNodeCount] = useState(numNodes);

    const isMounted = useMountedState();

    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);
    const highlightEdges = props.highlightEdges || settings.graph.highlight;

    // Location descriptor, that is used to keep the same location in view when the layout
    // changes. Only set when the graph has been panned
    const [locationDesc, setLocationDesc] = useState<DfgLocationDescriptor | undefined>(Global.locationDescriptor);

    const touchState = useRef<{
        startTouches: Point[];
        startTransform: Matrix;
        startZoom: number;
    }>({
        startTouches: [],
        startTransform: Matrix.createIdentityMatrix(),
        startZoom: 1,
    });

    const touchClickState = useRef<{
        started: number,
        e: React.TouchEvent<HTMLDivElement>,
    } | undefined>();

    const minZoom = props.minZoom ?? 0.1;
    const maxZoom = props.maxZoom ?? 4;
    const [zoomSliderValue, setZoomSliderValue] = useState<number>(zoomValueToSlider(viewStateRef.current.zoom));

    const [isInitializationComplete, setIsInitializationComplete] = useState<boolean>(false);

    const containerRef = useRef<HTMLDivElement>(null);
    const containerSize = useResizeObserver(containerRef);

    // This hack is necessary to prevent quirky mouse events generated by some trackpads.
    // For whatever reason, we sometimes get events with the event.crtlKey property set to true,
    // which the browser then handles with scaling the entire page.
    // See issue https://gitlab.com/oniqofficial/general/oniq/-/issues/1000.
    useEffect(() => {
        containerRef.current?.addEventListener("wheel", dropEventHandler);
        return () => {
            containerRef.current?.removeEventListener("wheel", dropEventHandler);
        };
    }, [
        containerRef.current,
    ]);

    const nodes = (graphLayout.isGraphTooLarge || !graphLayout.layout || !graphLayout.layoutedGraph) ? [] : graphLayout.layoutedGraph.nodes ?? [];
    const nodeMap = useMemo(() => {
        return new Map<string, Node>(nodes.map(n => [n.id, n]));
    }, [nodes]);

    /*
    - When auto-fit is mode is enabled refit the graph to the available space any time the space (for example, when opening or closing the filter bar)
    - Any manual zooming or panning will disable auto-fit mode
    - Any loading of a "new graph" should re-enable auto-fit mode.
    */
    const [isAutoFitEnabled, setIsAutoFitEnabled] = useState(true);

    // Fit graph if the container size changed (and autoFit is enabled)
    useEffect(() => {
        const didNodeCountChange = previousNodeCount !== numNodes;

        const isDefaultBehavior = props.graphChangeBehavior === GraphChangeBehavior.Default || !props.graphChangeBehavior;
        if ((isAutoFitEnabled && !locationDesc && isDefaultBehavior) ||
            (props.graphChangeBehavior === GraphChangeBehavior.FitOnNodeCountChange && didNodeCountChange) ||
            props.graphChangeBehavior === GraphChangeBehavior.AlwaysFitOnChange)
            fitGraph();
    }, [
        containerSize?.width,
        containerSize?.height,
    ]);

    useEffect(() => {
        if (!props.graphLayout.layout)
            return;

        const didNodeCountChange = previousNodeCount !== numNodes;

        if (props.graphChangeBehavior === GraphChangeBehavior.AlwaysFitOnChange ||
            props.graphChangeBehavior === GraphChangeBehavior.FitOnNodeCountChange && didNodeCountChange) {
            fitGraph();
            return;
        }

        // When the layout changed, fit graph and re-enable automatic resizing
        if (props.graphLayout)
            if (locationDesc)
                applyLocationDescriptor(props.graph, props.graphLayout.layout, locationDesc);
            else
                fitGraph();
    }, [
        props.graphLayout.layout,
    ]);

    // Expose "fitGraph"
    useImperativeHandle(ref, () => ({
        fitGraph() {
            if (graphLayout.layout) {
                fitGraph();
            }
        },
        centerNode: (nodeId: string | undefined) => {
            centerNode(nodeId);
        },
        setZoom(zoom: number, animate: boolean, targetNode?: Node, targetEdge?: Edge) {
            setZoom(zoom, animate, targetNode, targetEdge);
        },
        getViewState() {
            return { viewState: { ...viewStateRef.current! }, orientation: settings.graph.orientation };
        },
        setViewState(viewState: Partial<PanZoomState>, orientation?: GraphOrientation, animate = false) {
            if (viewState.zoom !== undefined)
                setZoomSliderValue(zoomValueToSlider(viewState.zoom));

            // Copy over view state (but don't modify the ui state)
            if (animate) {
                smooth();
                debounce(unsmooth, 500);
            } else
                unsmooth();

            viewStateRef.current = { ...viewStateRef.current, ...viewState, uiState: UiStates.Idle };
            if (transformReceiverRef.current)
                transformReceiverRef.current.style.transform = Matrix.toString(viewStateRef.current.transform);

            if (orientation !== undefined && settings.graph.orientation !== orientation) {
                settings.setGraph({ orientation });
            }
        },
        save(filename: string) {
            saveDfg(containerRef.current!, filename);
        },
        invalidate() {
            setRedraw(redraw + 1);
        },
    }));

    const canvasSize = getCanvasSize(graphLayout);
    const nodeDeps = [
        graphLayout.layoutedGraph?.hash,
        graphLayout.layout?.hash,
        settings.kpi.selectedKpi,
        settings.kpi.statistic,
        settings.quantity,
        settings.graph.nodeDetailLevel,
        settings.groupingKey,
        session.locale,
        session.numberFormatLocale,
        settings.graph.highlight,
        props.highlightNodes,
        settings.graph.secondGroupingLevel,
        settings.selection?.node?.id,
        redraw,
        borderSize,
    ];

    // Render nodes, edges and edge labels
    const nodeGroupMarkup = useMemo(() => {
        if (graphLayout.isGraphTooLarge)
            return [undefined, undefined];

        return renderGroups(graphLayout.layout);
    }, nodeDeps);

    const nodeMarkup = useMemo(() => {
        if (graphLayout.isGraphTooLarge)
            return [undefined, undefined];

        return renderNodes(nodes, graphLayout.layout, props.markupFunc, props.nodeClassFunc);
    }, nodeDeps);

    const edgeLabels = useMemo(() => {
        if (graphLayout.isGraphTooLarge || !graphLayout.layout)
            return [undefined, undefined];

        return renderEdgeLabels(graphLayout.layout?.edges);
    }, [
        graphLayout.layout?.hash,
        graphLayout.layoutedGraph?.hash,
        settings.selection,
        session.locale,
        session.numberFormatLocale,
        graphLayout.layout,
        settings.groupingKey,
        settings.kpi.selectedKpi,
        settings.kpi.statistic,
        settings.quantity,
        settings.graph.secondGroupingLevel,
        props.highlightEdges,
        settings.graph.highlight,
        redraw,
    ]);

    useEffect(() => {
        // make sure that the information stored in the selection for nodes and edges fits to the graph
        if (settings.selection.node !== undefined && props.graph !== undefined)
            settings.setSelection({ node: props.graph?.nodes.find((n) => n.id === settings!.selection!.node!.id) });
        if (settings.selection.edge !== undefined && props.graph !== undefined)
            settings.setSelection({ edge: props.graph?.multiEdges!.find((e) => e.from === settings!.selection!.edge!.from && e.to === settings!.selection!.edge!.to) });
    }, [props.graph, settings.selection.node, settings.selection.edge]);

    const isLoading = props.isLoading === true || graphLayout.isLayouting ||
        (!graphLayout.isGraphTooLarge && props.graph === undefined) ||
        (!graphLayout.isGraphTooLarge && graphLayout.layout === undefined);

    const idleHandlers = {
        onClick: () => {
            if (props.onSelected)
                props.onSelected({});

            settings.setSelection({});
        },
        onWheel: zoomMouseWheelHandler,
        onWheelCapture: zoomMouseWheelHandler,
        onDoubleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            fitGraph();
            event.preventDefault();
            event.stopPropagation();
        },
        onMouseDown: idleMouseDownHandler,
        onMouseMove: panMouseMoveHandler,
        onMouseUpCapture: panMouseUpHandler,
        onTouchStartCapture: touchStartHandler,
    };

    // Callback handlers used for panning/zooming
    const panHandlers = {
        onMouseDown: idleMouseDownHandler,
        onMouseUpCapture: panMouseUpHandler,
        onWheel: zoomMouseWheelHandler,
        onWheelCapture: zoomMouseWheelHandler,
        onMouseMove: panMouseMoveHandler,
        onClickCapture: clickCapture,
        onTouchEnd: touchEndHandler,
        onTouchMove: touchMoveHandler,
        onTouchStart: touchStartHandler,
        onTouchEndCapture: touchEndHandler,
        onTouchMoveCapture: touchMoveHandler,
        onTouchStartCapture: touchStartHandler,
        onTouchCancel: touchEndHandler,
    };

    const handlers = viewStateRef.current.uiState === UiStates.Idle ? idleHandlers : panHandlers;

    const dfGraphClasses = ["dfGraph"];

    const isFirefox = navigator.userAgent.toLowerCase().includes("firefox");
    if (isFirefox)
        dfGraphClasses.push(`ff${fixBorderWidth(borderSize)}`);

    // Hack: Delay display of "graph too large" warning by 1s
    const [showGraphTooLarge, setShowGraphTooLarge] = useState(false);
    useEffect(() => {
        if (graphLayout.isGraphTooLarge) {
            const timeout = setTimeout(() => {
                setShowGraphTooLarge(true);
            }, 1000);

            return () => {
                clearInterval(timeout);
            };
        }

        setShowGraphTooLarge(false);
    }, [
        graphLayout.isGraphTooLarge
    ]);

    const { minStatistic, maxStatistic } = useMemo(() => {
        if (!graphLayout?.layout)
            return { minStatistic: 0, maxStatistic: 0 };

        return getEdgeMinMax(highlightEdges, graphLayout.layout.edges, props.edgeHighlightStatFunc, settings, session);
    }, [
        redraw,
        highlightEdges,
        props.edgeHighlightStatFunc,
        redraw,
        graphLayout.layout?.edges,
        settings.graph.highlight,
    ]);

    // Update edge selection
    const container = document.getElementById(props.id ?? "edges");
    useEffect(() => {
        if (!container)
            return;

        ChartEdge.blurSelection(container);

        if (settings.selection.edge) {
            const chartEdge = getChartEdgeFromEdge(graphLayout.layout, settings.selection.edge);
            chartEdge?.select(container);
        }
    }, [
        container,
        settings.selection.edge
    ]);

    useEffect(() => {
        if (!transformReceiverRef.current || !nodes || !graphLayout.layout?.edges)
            return;

        // generate container svg element
        const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svg.setAttribute("class", "edges");
        svg.setAttribute("id", props.id ?? "edges");
        svg.setAttribute("data-testid", "edges");
        svg.setAttribute("style", `width: ${canvasSize?.max.x || 0}px; height: ${canvasSize?.max.y || 0}px`);

        transformReceiverRef.current?.appendChild(svg);

        generateEdgeElements(svg,
            nodeMap,
            graphLayout.layout!.edges,
            props,
            highlightEdges,
            isObjectCentric,
            edgeClickHandler,
            minStatistic,
            maxStatistic,
            settings,
            session);

        return () => {
            transformReceiverRef.current?.removeChild(svg);
        };
    }, [
        redraw,
        nodes,
        redraw,
        minStatistic,
        maxStatistic,
        graphLayout.layout?.edges,
        props.highlightEdges,
        settings.graph.highlight,
        isObjectCentric,
    ]);


    return <>{props.legend !== undefined && settings.graph.highlight && <DfgLegend {...props.legend} />}
        <div className={classNames(["dfGraphContainer", props.className])}>
            <Spinner isLoading={isLoading} showProjectLoadingSpinner={props.showProjectLoadingSpinner ?? true} />

            {!graphLayout.isGraphTooLarge && <>
                <div
                    className={dfGraphClasses.join(" ")}
                    data-testid="dfGraph"
                    style={{
                        cursor: viewStateRef.current.uiState === UiStates.Panning ? "grabbing" : "inherit",
                        opacity: isInitializationComplete ? 1 : 0,
                    }}

                    ref={containerRef}
                    {...handlers}>

                    <div
                        ref={transformReceiverRef}
                        style={{
                            width: 0,
                            height: 0,
                            transformOrigin: "top left",
                            transform: Matrix.toString(viewStateRef.current.transform),
                        }}
                        onClick={(e) => {
                            // This handler deals with clicks that go near
                            // an edge. pickEdge determines which edge is
                            // in the vicinity of the clicked position.
                            const rect = containerRef.current!.getBoundingClientRect();
                            const viewX = e.pageX - rect.left;
                            const viewY = e.pageY - rect.top;

                            // Transform to object space
                            const inverse = Matrix.invert(viewStateRef.current.transform);
                            const view = new Vector(viewX, viewY);
                            const world = view.transform(inverse!);

                            const edge = pickEdge(graphLayout.layout!, world);

                            if (settings.selection.edge?.from === edge?.from &&
                                settings.selection.edge?.to === edge?.to) {
                                settings.setSelection({});
                                if (props.onSelected)
                                    props.onSelected({});
                            }
                            else {
                                settings.setSelection({ edge });
                                if (props.onSelected)
                                    props.onSelected({ edge });
                            }

                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        <div className="nodes">
                            {edgeLabels}
                            {nodeGroupMarkup}
                            {nodeMarkup}
                        </div>
                    </div>
                </div>

                <DfGraphControls
                    zoom={viewStateRef.current.zoom}
                    anchor={props.zoomControlLocation}
                    onZoomIn={() => zoomStep(1)}
                    onZoomOut={() => zoomStep(-1)}
                    onFitGraph={() => {
                        fitGraph();
                    }}
                    onOrientationChanged={props.onOrientationChanged}
                    hasBeenPanned={locationDesc !== undefined}
                />
            </>}

            {showGraphTooLarge && <Toast type={ToastTypes.Info} className="dfgTooLargeToast" visible={true}>
                <I18nLinks id="explorer.dfgTooLarge" mapping={{
                    filter: {
                        onClick: () => {
                            // Add filter
                            settings.set({
                                filterEditor: {
                                    showFilterEditor: true,
                                    editFilter: undefined,
                                    editFilterIndex: undefined,
                                    editFilterPage: 0,
                                },
                            });
                        },
                    },
                    grouping: {
                        onClick: () => {
                            // Switch to controls panel
                            Global.sidePanelRef.current?.showPage(0);
                        }
                    },

                }} />
            </Toast>}

            <DfGraphComplexity
                graphLayout={props.graphLayout}
                hidden={props.showComplexitySlider === false} />
        </div>
    </>;

    function fixBorderWidth(width: number) {
        const clipped = Math.max(1, Math.min(8, width));

        const steps = [1, 2, 4, 8];
        for (const step of steps)
            if (clipped <= step)
                return step;

        return 8;
    }

    // #region [ Pan and Zoom ]------------------------------------------------
    function setZoom(targetScale: number, animate: boolean, targetNode?: Node, targetEdge?: Edge) {

        if (!animate)
            viewStateRef.current.uiState = UiStates.ZoomControl;

        if (targetNode || targetEdge) {
            const focusPoint = targetNode ? getNodeCenter(graphLayout.layout, targetNode.id) :
                targetEdge ? getEdgeCenter(targetEdge) :
                    undefined;

            if (focusPoint)
                // Transformation matrices have the scale factor at diagonal values (m11, m22),
                // at least as long as it is cartesian (which it is).
                zoomToWorldspaceCoordinate(targetScale / viewStateRef.current.transform.m11, focusPoint);
        } else {
            const options = graphLayout.layout?.nodes[settings.selection?.node?.id ?? ""];
            if (options?.position !== undefined && options.size !== undefined && containerRef.current) {
                const worldSpaceFocus = new Vector(options.position.x, options.position.y);
                zoomToWorldspaceCoordinate(targetScale / viewStateRef.current.transform.m11, worldSpaceFocus);
            } else {
                const rect = containerRef.current!.getBoundingClientRect();
                zoomChartScreenSpace(targetScale / viewStateRef.current.transform.m11, rect.width / 2, rect.height / 2);
            }
        }

        if (isMounted())
            leaveInteraction();

        viewStateRef.current.uiState = UiStates.Idle;

        setBorderSize(zoomToBorderSize(targetScale));
    }

    /**
     * Prepares anything for user interactions (panning, zooming):
     *  - Adds a cover to capture mouse events
     *  - Adds/removes the "smooth" css class from the graph element. (That's smoothing
     *    the zooming btw)
     */
    function enterInteraction(uiState: UiStates) {
        if (uiState !== UiStates.ZoomControl)
            unsmooth();
        else
            smooth();

        if ([UiStates.Panning, UiStates.Touch].includes(uiState)) {
            const cover = document.createElement("div");
            cover.className = "cover";
            transformReceiverRef.current!.parentElement?.insertBefore(cover, transformReceiverRef.current);
        }

        viewStateRef.current.uiState = uiState;
    }

    /**
     * Removes the cover generated by enterInteraction and sets the "smooth" class again.
     */
    function leaveInteraction() {
        const covers = document.getElementsByClassName("cover");
        while (covers.length > 0)
            covers[0].parentElement?.removeChild(covers[0]);
    }

    function smooth() {
        const cl = transformReceiverRef.current!.parentElement!.classList;
        if (!cl.contains("smooth"))
            cl.add("smooth");
    }

    /**
     * Removes the "smooth" css class
     */
    function unsmooth() {
        const cl = transformReceiverRef.current!.parentElement!.classList;
        if (cl.contains("smooth"))
            cl.remove("smooth");
    }

    function getEdgeCenter(edge: Edge | undefined): Vector | undefined {
        if (!edge || !graphLayout.layout?.edges)
            return undefined;

        const chartEdge = graphLayout.layout.edges.find(e => e.edge.from === edge.from && e.edge.to === edge.to);
        if (!chartEdge)
            return undefined;

        const bbox = new BoundingBox();
        for (const leg of chartEdge.legs)
            for (const point of leg.controlPoints)
                bbox.addPoint(point);

        return bbox.center;
    }

    function getNodeCenter(layout: Layout | undefined, nodeId: string | undefined): Vector | undefined {
        if (!nodeId || !layout)
            return undefined;

        const layoutOptions = layout.nodes[nodeId];
        if (!layoutOptions || !layoutOptions.position || !layoutOptions.size)
            return undefined;

        return new Vector(
            layoutOptions.position.x + layoutOptions.size.width / 2,
            layoutOptions.position.y + layoutOptions.size.height / 2
        );
    }

    /**
     * Ends a drag event
     * @param event Mouse up event
     */
    function panMouseUpHandler(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (viewStateRef.current.uiState === UiStates.Panning) {
            // Prevent handling the mouse up when we're just ending a pan gesture
            event.stopPropagation();
            event.preventDefault();
        }

        // Switch back to idle state
        viewStateRef.current.uiState = UiStates.Idle;
        leaveInteraction();

        // Check if we actually panned the view
        if (viewStateRef.current.moveDistance && viewStateRef.current.moveDistance > 10) {
            // We have. Update location descriptor!
            updateLocationDesc();
        }

        viewStateRef.current.dragStart = undefined;
    }

    /**
     * Invoked when the user pushes the mouse button
     * @param event mouse event
     */
    function idleMouseDownHandler(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        // Check if this is a left click, and ignore if it isn't
        if (event.button !== 0)
            return;

        event.preventDefault();

        hideAllSpotlights();
        unsmooth();

        viewStateRef.current.moveDistance = 0;
        viewStateRef.current.dragStart = new Vector(event.clientX, event.clientY);

        viewStateRef.current.uiState = UiStates.Panning;
    }

    /**
     * Handles panning gestures
     * @param event mouse event
     */
    function panMouseMoveHandler(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        event.preventDefault();

        if (viewStateRef.current.uiState !== UiStates.Panning || !viewStateRef.current.dragStart)
            return;

        const dx = (event.clientX - viewStateRef.current.dragStart.x) / viewStateRef.current.zoom;
        const dy = (event.clientY - viewStateRef.current.dragStart.y) / viewStateRef.current.zoom;

        const moveDistance = (viewStateRef.current.moveDistance || 0) + Math.sqrt(dx * dx + dy * dy);

        if ((viewStateRef.current.moveDistance ?? 0) < 10 && moveDistance >= 10) {
            // OK, we're definitely panning
            enterInteraction(UiStates.Panning);
            setIsAutoFitEnabled(false);
        }

        const translateMatrix = Matrix.createTranslationMatrix(dx, dy);
        const transform = Matrix.multiply(viewStateRef.current.transform, translateMatrix);

        setGraphViewState({
            transform: transform,
            moveDistance: moveDistance,
            dragStart: new Vector(event.clientX, event.clientY)
        });
    }

    /**
     * Mouse wheel handler
     * @param event wheel event
     */
    function zoomMouseWheelHandler(event: React.WheelEvent<HTMLDivElement>) {
        if (!containerRef?.current)
            return;

        enterInteraction(UiStates.ZoomControl);
        hideAllSpotlights();

        // negative deltaY zooms in, positive out
        const factor = 1.1;
        const mag = Math.abs(event.deltaY) / ZOOM_SPEED;
        const direction: number = (event.deltaY < 0) ? factor + mag : 1 / (factor + mag);
        const zoom = viewStateRef.current.zoom * direction;

        if (props.maxZoom && zoom > props.maxZoom)
            return;
        if (props.minZoom && zoom < props.minZoom)
            return;

        // Translate mouse position relative to canvas element
        const rect = containerRef.current!.getBoundingClientRect();
        const x = event.pageX - rect.left;
        const y = event.pageY - rect.top;

        zoomChartScreenSpace(zoom / viewStateRef.current.transform.m11, x, y);

        setZoomSliderValue(zoomValueToSlider(zoom));

        setIsAutoFitEnabled(false);

        // When the "smooth" class is removed, the view immediately snaps into
        // it's end position, rather than transitioning there. As the transition
        // takes 500ms (see DfGraph.scss, .smooth), we're debouncing the removal
        // that long.
        debounce(() => { unsmooth(); }, 500);
    }

    /**
     * cx/cy are the coordinates in screen space (top left of the DfGraph is 0/0)
     * to center around.
     * @param zoomBy Zoom factor
     * @param cx x-Coordinate of the zoom center
     * @param cy y-Coordinate of the zoom center
     */
    function zoomChartScreenSpace(zoomBy: number, cx = 0, cy = 0, fromTransform = viewStateRef.current.transform) {
        // Convert screen coordinate to world space coordinates
        const inverse = Matrix.invert(fromTransform);
        const worldSpaceCenterPoint = new Vector(cx, cy).transform(inverse!);

        zoomToWorldspaceCoordinate(zoomBy, worldSpaceCenterPoint, fromTransform);
    }

    function zoomToWorldspaceCoordinate(zoomBy: number, worldSpaceCenterPoint: Vector, fromTransform = viewStateRef.current.transform) {
        // Negative translationKey for centering the point specified
        const backwardTranslationMatrix = Matrix.createTranslationMatrix(worldSpaceCenterPoint.x, worldSpaceCenterPoint.y);
        const scaleMatrix = Matrix.createScaleMatrix(zoomBy);
        const forwardTranslationMatrix = Matrix.createTranslationMatrix(-worldSpaceCenterPoint.x, -worldSpaceCenterPoint.y);

        const transform = Matrix.multiply(fromTransform, backwardTranslationMatrix).multiply(scaleMatrix).multiply(forwardTranslationMatrix);

        setGraphViewState({
            zoom: transform.m11,
            transform: transform
        });

        const zoom = transform.m11;
        setBorderSize(zoomToBorderSize(zoom));

        updateLocationDesc();
    }

    function updateLocationDesc() {
        Global.locationDescriptor = calculateLocationDescriptor(props.graph, props.graphLayout.layout);
        setLocationDesc(Global.locationDescriptor);
    }

    function clickCapture(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if ((viewStateRef.current.moveDistance || 0) > 10) {
            viewStateRef.current.moveDistance = undefined;

            e.preventDefault();
            e.stopPropagation();
        }
    }
    // #endregion

    // #region [ Touch handlers ]----------------------------------------------
    function touchStartHandler(e: React.TouchEvent<HTMLDivElement>) {
        const newTouches = touchesToPoints(e.touches);

        // This might just as well be a click. We'll check in the touchEndHandler
        touchClickState.current = (newTouches.length === 1) ? {
            e,
            ...newTouches[0],
            started: new Date().getTime(),
        } : undefined;

        if (viewStateRef.current.uiState !== UiStates.Touch) {
            enterInteraction(UiStates.Touch);
            setIsAutoFitEnabled(false);
        }

        touchState.current.startTouches = newTouches;
        touchState.current.startTransform = (viewStateRef.current.transform ?? Matrix.createIdentityMatrix()).clone();
        touchState.current.startZoom = viewStateRef.current.zoom ?? 1;

        unsmooth();

        e.stopPropagation();
    }


    function touchEndHandler(e: React.TouchEvent<HTMLDivElement>) {
        // A single touch that ended quickly? That's a click!
        if (touchClickState.current &&
            e.touches.length === 0 &&
            (new Date().getTime() - touchClickState.current.started) < 300) {
            leaveInteraction();

            // Programmatically click on the original event's target
            const target = (e.nativeEvent.target ?? e.nativeEvent.currentTarget) as HTMLDivElement;
            target.click();

            e.preventDefault();
            e.stopPropagation();
            touchClickState.current = undefined;
            return;
        }

        const currentTouches = touchesToPoints(e.touches);
        touchState.current.startTouches = currentTouches;
        if (!currentTouches.length) {
            // Ending all touch gestures, reverting to idle state
            leaveInteraction();
            viewStateRef.current.uiState = UiStates.Idle;
            setIsAutoFitEnabled(true);

            // We have panned the view. Update location descriptor.
            updateLocationDesc();
        } else {
            // Downgrade from zoom- to pan gesture
            touchState.current.startTransform = viewStateRef.current.transform;
            touchState.current.startZoom = viewStateRef.current.zoom;
        }

        e.stopPropagation();
    }


    function touchMoveHandler(e: React.TouchEvent<HTMLDivElement>) {
        const prevTouches = touchState.current.startTouches;
        const currentTouches = touchesToPoints(e.touches);

        // Handle pinch zoom gesture
        if (prevTouches.length === 2 && currentTouches.length === 2) {
            const currentCenter = center(currentTouches);
            const prevCenter = center(prevTouches);

            const d = (currentCenter === undefined || prevCenter === undefined) ? undefined : {
                x: currentCenter.x - prevCenter.x,
                y: currentCenter.y - prevCenter.y,
            };

            // Also handle panning while zooming
            const dTransform = d === undefined ? Matrix.createIdentityMatrix() :
                Matrix.createTranslationMatrix(d.x, d.y);

            const scale = (prevTouches.length !== 2 || currentTouches.length !== 2) ? 2 :
                length(currentTouches[0], currentTouches[1]) / (length(prevTouches[0], prevTouches[1]) || 1);

            zoomChartScreenSpace(scale, currentCenter!.x, currentCenter!.y, Matrix.multiply(dTransform, touchState.current.startTransform));

            e.preventDefault();
            return;
        }

        // Handle drag gesture
        if (prevTouches.length === 1 && currentTouches.length === 1) {
            // Delta in screen space
            const dx = currentTouches[0].x - prevTouches[0].x;
            const dy = currentTouches[0].y - prevTouches[0].y;

            // Convert to world space units
            const dwx = dx / viewStateRef.current.zoom;
            const dwy = dy / viewStateRef.current.zoom;

            const translateMatrix = Matrix.createTranslationMatrix(dwx, dwy);
            const transform = Matrix.multiply(touchState.current.startTransform, translateMatrix);

            setGraphViewState({
                transform,
            });

            e.preventDefault();
            return;
        }

        // No idea what you're doing with all those fingers...
    }

    function length(a: Point, b: Point) {
        const dx = b.x - a.x;
        const dy = b.y - a.y;

        return Math.sqrt(dx * dx + dy * dy);
    }

    function center(points: Point[]) {
        if (!points.length)
            return undefined;

        const sum = { ...points[0] };
        for (let i = 1; i < points.length; i++) {
            sum.x += points[i].x;
            sum.y += points[i].y;
        }

        return {
            x: sum.x / points.length,
            y: sum.y / points.length,
        };
    }

    function touchesToPoints(touchList: React.TouchList) {
        const rect = containerRef.current!.getBoundingClientRect();

        const touches: Point[] = [];
        for (let i = 0; i < touchList.length; i++)
            touches.push({
                x: touchList.item(i).clientX - rect.left,
                y: touchList.item(i).clientY - rect.top,
            });

        return touches;
    }
    // #endregion


    // #region [ Node rendering helpers ] -------------------------------------

    function renderGroups(layout: Layout | undefined) {
        return Object.entries(layout?.groups || {}).map(([id, renderOptions]) => {
            const label = renderOptions.label ?? "";
            return <div
                className={"group"}
                key={`nodeGroup-${id}`}
                style={{
                    top: renderOptions.position?.y ?? 0,
                    left: renderOptions.position?.x ?? 0,
                    width: renderOptions.size?.width ?? 200,
                    height: renderOptions.size?.height ?? 50,
                    backgroundColor: colors["$gray-4"],
                }}
            >
                <div
                    style={settings.graph.orientation === GraphOrientation.vertical ? {
                        transform: "translate(-" + renderOptions?.size?.height + "px, 0) rotate(-90deg)",
                        width: renderOptions?.size?.height
                    } : {}}
                    title={label}
                    className="groupLabel">
                    {label}
                </div>
            </div>;
        });
    }

    function renderNodes(nodes: Node[], layout: Layout | undefined, markupFunc: MarkupFunc, nodeClassFunc?: NodeClassFunc) {
        if (layout === undefined)
            return <></>;

        let maxStatistic = 0;
        let minStatistic = 0;
        if (props.highlightNodes || settings.graph.highlight) {
            const nodeStatistics = nodes.filter((n) => ![NodeRoles.Start, NodeRoles.End, NodeRoles.Group, NodeRoles.Inventory].includes(n.role!)).map((n) => {
                return props.nodeHighlightStatFunc !== undefined ? (props.nodeHighlightStatFunc(n, settings)) : undefined;
            }).filter(v => v !== undefined) as number[];

            maxStatistic = Math.max(...nodeStatistics);
            minStatistic = Math.min(...nodeStatistics);

            if (!isFinite(maxStatistic) || !isFinite(minStatistic))
                maxStatistic = minStatistic = 0;
        }

        return (nodes || []).filter(node => node.role !== NodeRoles.Group || settings.graph.secondGroupingLevel !== GroupingKeys.None).map(node => {
            const renderOptions = layout.nodes[node.id];
            const key = "node-" + node.id;

            // We make use of a stacked layout only if multiple machines are involved.
            const isGroup = (node?.activityValues?.machine?.nUnique ?? 0) > 1;

            const isSelected = settings.selection?.node?.id === node.id;

            let color = getNodeColor(node, settings, isObjectCentric);
            if ((props.highlightNodes || settings.graph.highlight) && isStandardNode(node)) {
                const stat = props.nodeHighlightStatFunc !== undefined ? props.nodeHighlightStatFunc(node, settings) : undefined;
                if (stat !== undefined) {
                    const scale = (stat - minStatistic) / ((maxStatistic - minStatistic) || 1);
                    color = (props.nodeHighlightColorFunc !== undefined ? props.nodeHighlightColorFunc(node, stat, minStatistic, maxStatistic, scale) : nodeHighlightColorMapDefault(scale)) ?? colors.$graphNodeBackground;
                } else {
                    // The node color function might return a color value for missing stats values
                    const newColor = props.nodeHighlightColorFunc !== undefined ? props.nodeHighlightColorFunc(node, undefined, minStatistic, maxStatistic, undefined) : undefined;
                    if (newColor !== undefined)
                        color = newColor;
                }
            }

            if (renderOptions) {
                const domProps: NodeDomProps = {
                    key,
                    style: {
                        top: renderOptions.position?.y ?? 0,
                        left: renderOptions.position?.x ?? 0,
                        width: renderOptions.size?.width ?? 200,
                        height: renderOptions.size?.height ?? 50,
                        backgroundColor: color,
                    },
                };

                const handlers: NodeHandlers = {
                    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        const selection = (settings.selection.node?.id === node?.id) ? {} : { node };
                        settings.setSelection(selection);
                        if (props.onSelected)
                            props.onSelected(selection);

                        e.preventDefault();
                        e.stopPropagation();
                    },
                    onDoubleClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        e.preventDefault();
                        e.stopPropagation();
                    },
                };


                if (node.role === NodeRoles.Group)
                    return <div className={"group"} {...domProps}>
                        <div
                            style={settings.graph.orientation === GraphOrientation.vertical ? {
                                transform: "translate(-" + renderOptions?.size?.height + "px, 0) rotate(-90deg)",
                                width: renderOptions?.size?.height
                            } : {}}
                            title={node.id.slice(1, 31)}
                            className="groupLabel">
                            {node.id.slice(1, 31)}
                        </div>
                    </div>;

                const nodeProps: NodeProps = {
                    isSelected,
                    isGroup,
                    node,
                    className: nodeClassFunc ? nodeClassFunc(node, settings) : undefined,
                    statisticValue: props.nodeHighlightStatFunc !== undefined ? props.nodeHighlightStatFunc(node, settings) : undefined,
                    statistic: getMainNodeKpi(node, settings, session).formattedValue,
                    dom: domProps,
                    handlers
                };

                // Render start- and end node
                if (isTerminalNode(node)) {
                    const label = getTerminalNodeLabel(node);

                    return <div
                        className={classNames(["nodeStartEnd", isSelected && "nodeSelected", nodeProps.className, "circleBorder"])}
                        data-testid={isSelected ? "selected" : ""}
                        key={key + "-selected"}
                        {...nodeProps.dom} {...nodeProps.handlers}
                    >
                        <div className="main">{label}</div>
                        {(settings.kpi.selectedKpi === KpiTypes.Frequency) && <div className="extra">{getMainNodeKpi(node, settings, session).formattedValue}</div>}
                        {isGroup && <div className="nodeStack"></div>}
                    </div>;
                }

                // Render link node
                if (node.role === NodeRoles.Link) {
                    return <div className={"nodeLink"} {...domProps} key={key}>
                        <svg className="svg-icon medium"><use xlinkHref="#parallel-gateway" /></svg>
                    </div>;
                }

                const markup = markupFunc(node, settings, session);

                return <NodeRenderer
                    {...nodeProps}
                    warnings={props.nodeDataQualityWarningsFunc ? props.nodeDataQualityWarningsFunc(node, settings, session) : undefined}
                    key={key}
                    markup={markup}
                    isGroup={isGroup}
                    role={node.role}
                />;
            }
        });
    }

    function getTerminalNodeLabel(node: Node) {
        let label = "";
        if (node.role === NodeRoles.Start)
            label = i18n.t("common.startNodeLabel");
        if (node.role === NodeRoles.End)
            label = i18n.t("common.endNodeLabel");
        if (settings.graph.showObjectContext && isObjectCentric && (node.id === "__CASE_START_PLACEHOLDER__" || node.id === "__CASE_END_PLACEHOLDER__"))
            label = i18n.t("common.case") + " " + label;
        return label;
    }

    /**
     * Centers specified node selection in the view. Zoom
     * will remain as it is.
     * @returns true if the node is centered, otherwise false
     */
    function centerNode(nodeId: string | undefined) {
        if (!graphLayout.layout || !nodeId)
            return false;

        const center = getNodeCenter(graphLayout.layout, nodeId);
        if (center) {
            viewStateRef.current.transform = getCenterTransform(center, viewStateRef.current.zoom);
            transformReceiverRef.current!.style.transform = Matrix.toString(viewStateRef.current.transform);
            return true;
        }

        return false;
    }

    /**
     * Generates JSX elements that render edge labels
     */
    function renderEdgeLabels(chartEdges: ChartEdge[] | undefined) {
        const labelElements: JSX.Element[] = [];
        for (const chartEdge of chartEdges || []) {
            // Labels
            if (!checkAfterStartOrBeforeEnd(chartEdge.edge) || settings.kpi.selectedKpi === KpiTypes.Frequency) {
                const labelLocation = chartEdge.edge.renderOptions?.labelLocation;
                if (!labelLocation)
                    continue;

                const label = props.edgeLabelFunc ? props.edgeLabelFunc(chartEdge.edge, settings, session) : undefined;
                const isSelected = (settings.selection?.edge?.from === chartEdge.edge.from && settings.selection?.edge?.to === chartEdge.edge.to);
                if (label) {
                    labelElements.push(<div onClick={(e) => {
                        edgeClickHandler(chartEdge.edge);
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    key={`label-${chartEdge.edge.from}-${chartEdge.edge.to}`}
                    className={classNames(["edgeLabel", isSelected && "edgeLabelSelected"])}
                    data-testid="edgeLabel"
                    style={{
                        left: labelLocation!.x,
                        top: labelLocation!.y
                    }}>
                        {label}
                    </div>);
                }
            }
        }

        return labelElements;
    }

    // #endregion

    function edgeClickHandler(edge: MultiEdge) {
        const selection = (edge.from === settings.selection.edge?.from &&
            edge.to === settings.selection.edge?.to) ? {} : { edge };

        settings.setSelection(selection);
        if (props.onSelected)
            props.onSelected(selection);
    }


    function checkAfterStartOrBeforeEnd(edge: MultiEdge): boolean {
        return DfgUtils.isAfterStartOrBeforeEndEdge(edge, nodes);
    }

    function fitGraph() {
        if (graphLayout.isGraphTooLarge)
            return;
        if (Global.isRunningJestTest) {
            // just do a matrix operation to spy on during testing
            Matrix.createScaleMatrix(1);
            return;
        }

        const graphSize = getCanvasSize(graphLayout);
        if (!graphSize || !containerRef.current)
            return;

        setPreviousNodeCount(numNodes);
        // Reset panning state
        Global.locationDescriptor = undefined;
        setLocationDesc(undefined);

        // Get desired dimensions of our graph
        const center = graphSize.max.add(graphSize.min).multiply(0.5);

        // Get viewport dimensions
        const viewportWidth = containerRef.current.clientWidth;
        const viewportHeight = containerRef.current.clientHeight;

        // Calculate scale
        const scaleX = viewportWidth / graphSize.sizeMargin.x;
        const scaleY = viewportHeight / graphSize.sizeMargin.y;
        const scale = Math.min(scaleX, scaleY);

        // Firefox hack
        setBorderSize(zoomToBorderSize(scale));

        // Calculate offset
        const offset = new Vector(viewportWidth / 2, viewportHeight / 2).
            multiply(1 / scale).subtract(center);

        // Build transformation matrix
        const translationMatrix = Matrix.createTranslationMatrix(offset.x, offset.y);
        const scaleMatrix = Matrix.createScaleMatrix(scale);
        const transformationMatrix = scaleMatrix.multiply(translationMatrix);


        if (transformationMatrix) {
            setGraphViewState({
                zoom: scale,
                transform: transformationMatrix,
                uiState: UiStates.Idle,
            });

            setIsInitializationComplete(true);
        }

        setZoomSliderValue(zoomValueToSlider(scale));
        setIsAutoFitEnabled(true);
    }

    function getCenterTransform(center: Vector, zoom: number) {
        const rect = containerRef.current!.getBoundingClientRect();
        const halfRect = new Vector(rect.width / 2, rect.height / 2);

        const scale = Matrix.createScaleMatrix(zoom);
        const negCenter = center.multiply(-1);
        const translation = new Matrix(1, 0, 0, 1, negCenter.x, negCenter.y);

        const inverseScale = Matrix.invert(scale);
        const halfRectInverse = halfRect.transform(inverseScale!);
        const screenCenterTranslation = new Matrix(1, 0, 0, 1, halfRectInverse.x, halfRectInverse.y);
        const transform = scale.multiply(
            translation.multiply(screenCenterTranslation)
        );

        return transform;
    }

    function zoomStep(direction: number) {
        const sliderValue = Math.max(0, Math.min(MAX_SLIDER_VALUE, zoomSliderValue + direction * 5));

        setZoomSliderValue(sliderValue);
        setZoom(sliderToZoom(sliderValue), true, settings.selection.node, settings.selection.edge);

        queueMicrotask(() => {
            updateLocationDesc();
        });
    }

    function sliderToZoom(position: number) {
        const ratio = position / (MAX_SLIDER_VALUE / 2);
        const result = (ratio < 1) ?
            (1 - minZoom) * ratio + minZoom :
            (maxZoom - 1) * (ratio - 1) + 1;

        return result;
    }

    function zoomValueToSlider(zoom: number) {
        if (zoom <= minZoom) {
            return 0;
        }

        if (zoom >= maxZoom) {
            return MAX_SLIDER_VALUE;
        }

        if (zoom <= 1) {
            const factor = (zoom - minZoom) / (1 - minZoom);
            return (MAX_SLIDER_VALUE / 2) * factor;
        }

        // zoom > 1
        const factor = (zoom - 1) / (maxZoom - 1);
        return (MAX_SLIDER_VALUE / 2) + factor * (MAX_SLIDER_VALUE / 2);
    }

    function setGraphViewState(viewState: Partial<PanZoomState>) {
        if (props.onViewChanged)
            props.onViewChanged({
                ...viewStateRef.current,
                ...viewState
            });

        viewStateRef.current = { ...viewStateRef.current, ...viewState };
        transformReceiverRef.current!.style.transform = Matrix.toString(viewStateRef.current.transform);
    }

    function applyLocationDescriptor(graph: BaseGraph | undefined, layout: Layout | undefined, locationDescriptor: DfgLocationDescriptor | undefined) {
        const zoom = locationDesc?.zoom ?? 1;
        viewStateRef.current.zoom = zoom;
        viewStateRef.current.transform.m11 = zoom;
        viewStateRef.current.transform.m22 = zoom;

        // This is called when we detect that our graph location tracking failed
        // for one or the other reason and we cannot match the old view to the new one.
        // This is usually the case when the graph changed significantly.
        function fallback() {
            // Did the user select a node? If so, center that, without animations
            if (settings.selection.node?.id && centerNode(settings.selection.node.id)) {
                setIsInitializationComplete(true);
                return;
            }

            // Otherwise we'll just fit the graph to the viewport.
            Global.locationDescriptor = undefined;
            setLocationDesc(undefined);
            fitGraph();
            return;
        }

        function isNodeVisible(nodeId: string | undefined, transform: Matrix = viewStateRef.current.transform) {
            if (!nodeId)
                return false;
            return isNodeInViewport(nodeId,
                layout,
                Global.isRunningJestTest ? 100 : containerRef.current?.clientWidth,
                Global.isRunningJestTest ? 100 : containerRef.current?.clientHeight,
                transform);
        }

        const prev = locationDescriptor?.references;
        const current = calculateLocationDescriptor(graph, layout)?.references;

        const viewportWidth = Global.isRunningJestTest ? 100 : containerRef.current?.clientWidth;
        const viewportHeight = Global.isRunningJestTest ? 100 : containerRef.current?.clientHeight;
        const screenSpaceShift = (!locationDesc || !containerRef.current) ? new Vector(0, 0) : new Vector(
            (locationDesc.viewportSize.x - viewportWidth!) / 2,
            (locationDesc.viewportSize.y - viewportHeight!) / 2
        );

        const selectionWasVisible = isNodeVisible(settings.selection.node?.id);

        // Find set of points that we find in both prev and current
        const nodeIds = intersection((prev ?? []).map(p => p.nodeId), (current ?? []).map(p => p.nodeId));
        const nonStartEndNodes = nodeIds.filter(id => id !== START_NODE_ID && id !== END_NODE_ID);

        // If too little nodes match, we'll just fit the graph to the viewport
        const matchRatio = (nonStartEndNodes.length ?? 0) / Math.max(prev?.length || 1, current?.length || 1);
        if (prev === undefined || current === undefined ||
            nonStartEndNodes.length === 0 ||
            matchRatio <= 0.2) {
            fallback();
            return;
        }

        // Find closest x nodes in DfgLocationDescriptor, that are also part of the current layout
        const closestNodesPrev = prev.filter(p => nodeIds.includes(p.nodeId)).slice(0, 3);
        const closestNodeIdsPrev = new Set(closestNodesPrev.map(p => p.nodeId));
        const closestNodesCurrent = current.filter(p => closestNodeIdsPrev.has(p.nodeId));

        const prevCenter = Vector.getCenter(closestNodesPrev.map(p => p.centerTransformed));
        const currentCenter = Vector.getCenter(closestNodesCurrent.map(p => p.centerTransformed));
        const offset = currentCenter.subtract(prevCenter).add(screenSpaceShift);

        const translationMatrix = Matrix.createTranslationMatrix(-offset.x, -offset.y);

        // Apply to current transform
        const currentTransform = viewStateRef.current.transform ?? Matrix.createIdentityMatrix();
        const transform = Matrix.multiply(translationMatrix, currentTransform);

        // Check if the transform is any good
        const isSomethingVisible = current.some(p => isNodeInViewport(p.nodeId,
            layout,
            Global.isRunningJestTest ? 1000 : containerRef.current?.clientWidth,
            Global.isRunningJestTest ? 1000 : containerRef.current?.clientHeight,
            transform));
        const isSelectionVisible = isNodeVisible(settings.selection.node?.id ?? "", transform);

        // Invoke fallback if either nothing is visible now or the selection
        // was moved out of view.
        if (!isSomethingVisible ||
            (selectionWasVisible && !isSelectionVisible)) {
            fallback();
            return;
        }

        // Pause animations for a while
        setGraphViewState({
            transform,
        });

        setIsInitializationComplete(true);
    }

    function calculateLocationDescriptor(graph: BaseGraph | undefined, layout: Layout | undefined) {
        const viewportWidth = Global.isRunningJestTest ? 100 : containerRef.current?.clientWidth;
        const viewportHeight = Global.isRunningJestTest ? 100 : containerRef.current?.clientHeight;

        if (graph === undefined || layout === undefined || !graph.nodes.length || !viewportWidth || !viewportHeight)
            return undefined;

        const screenCenter = new Vector(viewportWidth / 2, viewportHeight / 2);

        // Get list of node IDs of the nodes that are currently visible on screen
        const layoutedNodeIds = graph.nodes.filter(n => layout.nodes[n.id] !== undefined).map(n => n.id);

        // Calculate screen space location and distance to center for each node
        return {
            viewportSize: { x: viewportWidth, y: viewportHeight },
            zoom: viewStateRef.current.zoom,
            references: layoutedNodeIds.map(nodeId => {
                const renderOptions = layout.nodes[nodeId];
                const center = new Vector(
                    renderOptions!.position!.x + renderOptions!.size!.width / 2,
                    renderOptions!.position!.y + renderOptions!.size!.height / 2,
                );

                const nodeCenterScreenSpace = center.transform(viewStateRef.current.transform!);

                return {
                    nodeId,
                    centerTransformed: nodeCenterScreenSpace,
                    distanceToCenterSq: nodeCenterScreenSpace.subtract(screenCenter).lengthSq(),
                };
            }).sort((a, b) => a.distanceToCenterSq - b.distanceToCenterSq),
        } as DfgLocationDescriptor;
    }
});

/**
 * Checks if a given node is visible
 * @param nodeId Node ID
 * @param transform Usually settings.graph.viewState.transform
 * @returns true if node is intersecting with the viewport, otherwise false.
 */
function isNodeInViewport(nodeId: string, layout: Layout | undefined, viewportWidth: number | undefined, viewportHeight: number | undefined, transform: Matrix) {
    if (!layout || !viewportWidth || !viewportHeight)
        return false;

    const options = layout.nodes[nodeId];
    if (!options || !options.position || !options.size)
        return false;

    const topLeft = new Vector(options.position.x, options.position.y).transform(transform);
    const bottomRight = new Vector(options.position.x + options.size.width, options.position.y + options.size.height).transform(transform);

    return bottomRight.x >= 0 && bottomRight.y >= 0 &&
        topLeft.x <= viewportWidth && topLeft.y <= viewportHeight;
}


/**
 * Returns the size of the canvas that spans all nodes and edges.
 * Run this after layouting the graph, otherwise the canvas will not cover
 * all nodes.
 * @param nodes Node array
 * @param chartEdges ChartEdge instances. We need ChartEdges here rather than Edges,
 * because we want to consider the edge's path also, so we need rendering information
 * attached
 */
function getCanvasSize(layoutState: GraphLayoutState | undefined, margin = 100) {
    const layout = layoutState?.layout;

    const nodes = layoutState?.layoutedGraph?.nodes;
    const edges = layoutState?.layoutedGraph?.edges;

    if (!layout || !nodes || !edges)
        return undefined;

    const bbox = new BoundingBox();
    for (const nodeId of nodes.map(n => n.id)) {
        const render = layout.nodes[nodeId];
        if (render?.position === undefined ||
            render?.size === undefined)
            continue;

        bbox.add(render.position.x, render.position.y);
        bbox.add(render.position.x + render.size.width, render.position.y + render.size.height);
    }

    // Sample edge paths using four points
    for (const edge of layout.edges ?? [])
        for (const pt of edge.edge.renderOptions?.points ?? [])
            bbox.addPoint(pt);

    // This can happen if the nodes have no position yet
    if (bbox.min === undefined ||
        bbox.max === undefined)
        return undefined;

    // Add margin
    const vecMargin = new Vector(margin, margin);
    return {
        min: bbox.min!.subtract(vecMargin),
        max: bbox.max!.add(vecMargin),
        size: bbox.max!.subtract(bbox.min!),
        sizeMargin: bbox.max!.subtract(bbox.min!).add(vecMargin).add(vecMargin)
    };
}

/**
 * Returns the closest edge, that is nearer than maxDistance pixels.
 * If there is no edge close enough, it returns undefined.
 * @param pt the point to which the selected edge must be close to
 * @param maxDistance maximum distance allowed
 */
function pickEdge(layout: Layout, pt: Vector, maxDistance = 20) {
    const distanceThreshold = maxDistance + Leg.accuracy;
    const closeEdges = layout.edges.filter(e => e.worstCaseDistanceTo(pt) < distanceThreshold);

    let result: ChartEdge | undefined = undefined;
    let minDistance = Number.MAX_SAFE_INTEGER;

    for (const edge of closeEdges) {
        const distance = edge.distanceTo(pt);
        if (distance < minDistance) {
            minDistance = distance;
            result = edge;
        }
    }
    return result?.edge;
}

function dropEventHandler(e: any) {
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();
}

export function getEdgeColor(multiEdge: MultiEdge, settings: SettingsType, session: SessionType) {
    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);
    const edge = DfgUtils.findObjectEdge(multiEdge, isObjectCentric, settings.graph.objectType);
    if (!isObjectCentric)
        return colors.$graphEdgeDefaultColor;
    if (edge === undefined) {
        // TODO: figure out if start/end nodes have a special place, since we only
        // show case start/end nodes regardless of whether case is selected as the
        // object or not.
        if (multiEdge.from === START_NODE_ID || multiEdge.to === END_NODE_ID)
            return colors.$graphEdgeDefaultStartOrEndColor;
        return colors.$graphEdgeDefaultDeemphasizedColor;
    }
    return colors.$graphEdgeDefaultColor;
}

export function getEdgeHighlightColor(multiEdge: MultiEdge, stat: number, settings: SettingsType, session: SessionType) {
    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);
    if (settings.graph.showObjectContext && isObjectCentric) {
        if (!multiEdge.edges.some(e => e.objectType === settings.graph.objectType) && !(settings.graph.objectType === ALL_OBJECT_INDICATOR))
            return getEdgeColor(multiEdge, settings, session);
    }

    return edgeHighlightColorMapDefault(stat);
}

export function getNodeColor(node: Node, settings: SettingsType, isObjectCentric: boolean) {
    if (node.role === NodeRoles.Start || node.role === NodeRoles.End)
        return colors.$graphNodeStartEndBackground;

    if (settings.graph.showObjectContext && isObjectCentric) {
        return node.objects?.some(o => o.type === settings.graph.objectType) || settings.graph.objectType === ALL_OBJECT_INDICATOR ? colors.$graphNodeBackground : colors["$gray-4"];
    }
    return colors.$graphNodeBackground;
}

/**
 * Firefox is unable to properly interpolate borders with sub-pixel precision. For that
 * reason, we need to adjust the border size as we zoom out. Otherwise the border will
 * be invisible at some point. See issue
 * https://gitlab.com/oniqofficial/general/oniq/-/issues/1411
 *
 * Related bugs of Firefox:
 *  - https://bugzilla.mozilla.org/show_bug.cgi?id=1476379
 *  - https://bugzilla.mozilla.org/show_bug.cgi?id=1481307
 *  - https://bugzilla.mozilla.org/show_bug.cgi?id=1490361
 * @param zoom
 * @returns target border width
 */
function zoomToBorderSize(zoom: number) {
    // Detect if the user is using Firefox
    const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
    if (!isFirefox)
        return 1;

    return Math.max(1, Math.round(1 / zoom));
}

function filterGraphPostLayout(graph: BaseGraph | undefined, settings: SettingsType, isObjectCentric: boolean) {
    // introduce a reduced graph for the graph in context mode
    if (graph !== undefined && settings.graph.objectType && isObjectCentric)
        return DfgUtils.filterObjectGraphInContext(graph, settings.graph.objectType);
    return graph;
}



// #region DFG file export
/**
 * Saves the DFG to a file
 * @param container DFG container element
 * @param fileName filename to save to
 */
async function saveDfg(container: HTMLDivElement, fileName: string) {
    const dfg = getDfgMarkup(container);
    const size = getElementBounds(dfg);

    // Be sure to list all icons that can occur in the dfg markup here.
    // You might have to add a corresponding css rule, too.
    fixIcons(dfg, await preloadIcons(["exclamation", "inventory"]));

    const margin = 30;

    const html = `<!DOCTYPE html>\n<html>
    <head>
        <meta charset="UTF-8">
        <style>
            @media print {
                .nodeContentContainer {
                    * {
                        border-width: 1px !important;
                        border-color: rgba(0, 0, 0, 0.5) !important;
                    }
                }

                .group {
                    border: 1px solid #ccc;
                }
            }

            body {
                overflow: auto !important;
            }

            body>div {
                overflow: visible !important;
                inset: ${margin}px !important;
                display: block !important;
                width: ${size.width + margin * 2}px;
                height: ${size.height + margin * 2}px;
            }

            .exclamation {
                display: inline-block;
                flex-grow: 0;
                height: 17px;
                width: 17px;
                flex-basis: 17px;
            }

            .inventory {
                height: 40px;
                stroke: black;
                fill: white;
            }
        </style>
    </head>
    <body>
        ${new XMLSerializer().serializeToString(dfg!)}
    </body>\n</html>`;

    saveToFile(html, fileName, "text/html");
}

async function preloadIcons(icons: string[]) {
    async function loadIcon(src: string) {
        const response = await fetch(src);
        const data = await response.text();
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(data, "image/svg+xml");
        return svgDoc.documentElement;
    }

    const result: { [path: string]: HTMLElement } = {};
    for (const icon of icons) {
        const path = `/assets/${icon}.svg`;
        result[path] = await loadIcon(path);
    }

    return result;
}

function fixIcons(element: Element, icons: { [path: string]: HTMLElement }) {
    if (element.tagName.toLowerCase() === "img") {
        const parent = element.parentElement;
        const src = element.getAttribute("src");
        const icon = src?.split("/").pop()?.split(".")[0];

        if (parent && src && icons[src]) {
            const img = `<img class="${icon}" src="data:image/svg+xml;base64,${Buffer.from(icons[src].outerHTML).toString("base64")}">`;
            element.parentElement!.innerHTML = img;
            return;
        }
    }

    for (const child of element.children)
        fixIcons(child, icons);
}

/**
 * Retrieves the graph markup contained in the element provided. Also adapts
 * adds relevant styles to the markup.
 * @param container
 * @returns HTMLDivElement suitable for printing or saving as HTML
 */
function getDfgMarkup(container: HTMLDivElement) {
    const element = container.cloneNode(true) as HTMLDivElement;

    // include styles from http://localhost:3000/styles.css
    const style = document.createElement("style");

    // Copy all relevant styles over
    const rules = document.styleSheets[0].cssRules;
    const whitelist = [".dfGraph", ".node", ".nodeDetails", ".variantNode", ".progress", ".edgeLabel", ".twoCols"];
    for (let i = 0; i < rules.length; i++) {
        const rule = rules[i];
        if (!rule ||
            !(rule instanceof CSSStyleRule) ||
            !whitelist.some(w => rule.selectorText.includes(w)))
            continue;

        style.appendChild(document.createTextNode(rule.cssText));
    }
    element.appendChild(style);

    element.children.item(0)?.removeAttribute("style");

    return element;
}


/**
 * Returns the maximum bottom and right coordinates of the element and its children.
 * @param element The element to get the bounds of.
 * @returns An object containing the maximum bottom and right coordinates.
 */
function getElementBounds(element: Element) {
    function rectFromStyle(style: CSSStyleDeclaration | undefined) {
        if ([style?.top, style?.left, style?.width, style?.height].some(s => !s || !s.endsWith("px")))
            return undefined;

        const top = parseInt(style!.top.slice(0, -2));
        const left = parseInt(style!.left.slice(0, -2));
        const width = parseInt(style!.width.slice(0, -2));
        const height = parseInt(style!.height.slice(0, -2));

        return {
            width: left + width,
            height: top + height,
        };
    }

    function rectFromPath(element: SVGPathElement) {
        const d = element.attributes.getNamedItem("d")?.textContent;
        if (!d)
            return undefined;

        const pathRegex = /([a-zA-Z])([^a-zA-Z]*)/g;
        const x: number[] = [];
        const y: number[] = [];

        let match;
        while ((match = pathRegex.exec(d))) {
            const params = match[2]?.trim().split(/\s+/).map(parseFloat);
            if (!params || isNaN(params[0]) || isNaN(params[1]))
                continue;

            x.push(params[0]);
            y.push(params[1]);
        }

        return {
            width: Math.max(0, ...x),
            height: Math.max(0, ...y),
        };
    }

    let height = 0;
    let width = 0;
    for (let i = 0; i < element.children.length; i++) {
        const child = element.children.item(i);
        if (!child)
            continue;

        const rect = child.tagName === "path" ?
            rectFromPath(child as SVGPathElement) :
            rectFromStyle((child as unknown as any)?.style as CSSStyleDeclaration | undefined);

        if (rect) {
            height = Math.max(height, rect.height);
            width = Math.max(width, rect.width);
        }

        if (child.children.length) {
            const m = getElementBounds(child);
            height = Math.max(height, m.height);
            width = Math.max(width, m.width);
        }
    }

    return {
        height,
        width,
    };
}

// #endregion

/**
 * This function generates DOM elements for the edges provided and inserts it into the container.
 */
function generateEdgeElements(container: SVGSVGElement, nodeMap: Map<string, Node>, chartEdges: ChartEdge[], funcs: EdgeFuncs, highlightEdges: boolean, isObjectCentric: boolean, edgeClickHandler: (edge: MultiEdge) => void, minStatistic: number, maxStatistic: number, settings: SettingsType, session: SessionType) {
    if (!chartEdges?.length)
        return [];

    for (const chartEdge of chartEdges || []) {
        const { stroke, width, dashed, isSelected } = getEdgeStyle(funcs, chartEdge, highlightEdges, settings, session, minStatistic, maxStatistic, nodeMap, isObjectCentric);
        chartEdge.append(container, isSelected, stroke, width, dashed, edgeClickHandler);
    }
}

function getEdgeStyle(funcs: EdgeFuncs, chartEdge: ChartEdge, highlightEdges: boolean, settings: SettingsType, session: SessionType, minStatistic: number, maxStatistic: number, nodeMap: Map<string, Node>, isObjectCentric: boolean, isSelected: boolean | undefined = undefined) {
    const minWidth = 3;
    const maxWidth = 10;
    const dashedGap = 5;

    let width = minWidth;
    let stroke = (funcs.edgeColorFunc !== undefined ? funcs.edgeColorFunc(chartEdge.edge) : undefined) ?? colors.$graphEdgeDefaultColor;
    if (highlightEdges) {
        const stat = funcs.edgeHighlightStatFunc !== undefined ? funcs.edgeHighlightStatFunc(chartEdge.edge, settings, session) : undefined;
        if (stat !== undefined) {
            const relativeStatistic = (stat - minStatistic) / ((maxStatistic - minStatistic) || 1);
            const newColor = funcs.edgeHighlightColorFunc !== undefined ? funcs.edgeHighlightColorFunc(chartEdge.edge, stat, minStatistic, maxStatistic, relativeStatistic) : edgeHighlightColorMapDefault(relativeStatistic);
            stroke = newColor ?? stroke;
            width = funcs.edgeThicknessFunc !== undefined ? funcs.edgeThicknessFunc(chartEdge.edge, stat, minStatistic, maxStatistic, relativeStatistic) : minWidth + (maxWidth - minWidth) * relativeStatistic;
        } else {
            const newColor = funcs.edgeHighlightColorFunc !== undefined ? funcs.edgeHighlightColorFunc(chartEdge.edge, stat, minStatistic, maxStatistic, undefined) : undefined;
            if (newColor !== undefined)
                stroke = newColor;

            width = minWidth;
        }
    }

    const isEdgeSelected = isSelected ??
        (settings.selection?.edge?.from === chartEdge.edge.from &&
            settings.selection?.edge?.to === chartEdge.edge.to);

    const isAfterStartOrBeforeEnd = DfgUtils.isAfterStartOrBeforeEndEdgeNodeMapped(chartEdge.edge, nodeMap);
    const isOnlyCaseEdge = isObjectCentric && settings.graph.showObjectContext &&
        chartEdge.edge.edges.length === 1 && chartEdge.edge.edges[0].objectType === CASE_TYPE_ID;
    const hasCarbonRole = nodeMap.get(chartEdge.edge.from)?.role === NodeRoles.CarbonScope3;

    const dashed = isAfterStartOrBeforeEnd || isOnlyCaseEdge ? dashedGap : hasCarbonRole ? 3 : 0;
    return { stroke, width, dashed, isSelected: isEdgeSelected };
}

function getEdgeMinMax(highlightEdges: boolean, chartEdges: ChartEdge[], edgeHighlightStatFunc: EdgeStatFunc | undefined, settings: SettingsType, session: SessionType) {
    let maxStatistic = 0;
    let minStatistic = 0;
    if (highlightEdges) {
        const edgeStatistics = chartEdges.map((e) => (edgeHighlightStatFunc !== undefined ? edgeHighlightStatFunc(e.edge, settings, session) : undefined)).filter(v => v !== undefined) as number[];
        maxStatistic = Math.max(...edgeStatistics);
        minStatistic = Math.min(...edgeStatistics);

        if (!isFinite(maxStatistic) || !isFinite(minStatistic))
            minStatistic = maxStatistic = 0;
    }
    return { minStatistic, maxStatistic };
}


function getChartEdgeFromEdge(layout: Layout | undefined, edge: MultiEdge | Edge | undefined) {
    return layout?.edges?.find(e => e.from === edge?.from && e.to === edge?.to);
}