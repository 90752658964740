import React, { useEffect, useRef, useState } from "react";
import colors from "../../colors.json";
import { useScrollFade } from "../../hooks/UseScrollFade";
import { isFunction } from "lodash";
import { BoundingBox } from "../../utils/BoundingBox";
import { getTouchCenter } from "../../utils/Utils";

export type ScrollHostRect = {
    width: number;
    height: number;
};

export type ScrollHostDimensions = {
    /**
     * Width of the y-axis header and height of the x-axis header
     */
    headerSize: ScrollHostRect;

    /**
     * Dimensions the xy-scrollable content pane should get
     */
    contentSize: ScrollHostRect;
};

interface ScrollHostPropsType extends React.ComponentPropsWithRef<"div"> {
    dimensions: ScrollHostDimensions;
    xHeader: JSX.Element[] | JSX.Element | undefined;
    yHeader: JSX.Element[] | JSX.Element | undefined;
    content: ((top: number, left: number, bottom: number, right: number, contentWidth: number, contentHeight: number) => JSX.Element[] | JSX.Element) |
    JSX.Element[] | JSX.Element | undefined;

    contentScrollFade?: boolean;

    topLabel?: JSX.Element;
    onScrolled?: (top: number, left: number, bottom: number, right: number, contentWidth: number, contentHeight: number) => void;
}

/**
 * This component takes all the space it gets, gets it's dimensions and
 * feeds those into the onMesaure callback.
 */
export const ScrollHost = React.forwardRef<HTMLDivElement, ScrollHostPropsType>(function ScrollHost(props: ScrollHostPropsType, ref) {
    const xHeaderRef = useRef<HTMLDivElement>(null);
    const yHeaderRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    const contentHostRef = useRef<HTMLDivElement>(null);
    const [bounds, setBounds] = useState<BoundingBox | undefined>();

    useEffect(() => {
        const target = contentHostRef.current as HTMLDivElement;
        const left = target.scrollLeft;
        const top = target.scrollTop;

        const width = target.clientWidth;
        const height = target.clientHeight;

        setBounds(BoundingBox.fromPoints([{
            x: left,
            y: top,
        }, {
            x: left + width,
            y: top + height,
        }]));
    }, [contentHostRef.current]);

    const temp = useScrollFade(contentHostRef);
    const scrollFadeClass = props.contentScrollFade ? temp : "";

    const content = !isFunction(props.content) ? props.content :
        bounds === undefined ? [] :
            props.content(bounds.min!.y, bounds.min!.x, bounds.max!.y, bounds.max!.x, props.dimensions.contentSize.width, props.dimensions.contentSize.height);

    // #region Dragging
    const dragStart = useRef<{ x: number, y: number } | undefined>({x:0, y:0});
    const [dragState, setDragState] = useState<{
        isDragging: boolean;
        hasBeenDragged: boolean;
    }>({
        isDragging: false,
        hasBeenDragged: false,
    });

    const idleHandlers = {
        onMouseDown,
        onTouchStart,
        onMouseMove,
        onTouchMove,
        onMouseUp,
        onTouchEnd,
    };

    const dragHandlers = {
        onMouseUp,
        onMouseOut: onMouseUp,
        onMouseMove,
        onTouchMove,
        onTouchEnd,
    };

    function onTouchStart(e: React.TouchEvent<HTMLDivElement | SVGElement>) {
        if (e.touches.length > 1 || dragState.isDragging)
            return;

        const center = getTouchCenter(e.touches);
        if (!center) return;
        setDragState({
            isDragging: true,
            hasBeenDragged: false,
        });
        dragStart.current = { x: center.clientX, y: center.clientY };    
    }

    function onMouseDown(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (dragState.isDragging)
            return;

        // Start drag gesture
        setDragState({
            isDragging: true,
            hasBeenDragged: false,
        });
        dragStart.current = { x: e.clientX, y: e.clientY };
    }

    function onTouchEnd(e: React.TouchEvent<HTMLDivElement>) {
        if (e.touches.length > 0)
            return;

        onMouseUp();
    }

    function onMouseUp() {
        if (!dragState.isDragging)
            return;

        // End drag gesture
        setDragState({
            ...dragState,
            isDragging: false,
            hasBeenDragged: false,
        });
    }

    function onTouchMove(e: React.TouchEvent<HTMLDivElement | SVGSVGElement>) {
        const centers = getTouchCenter(e.touches);
        if (centers)
            moveHandler(centers);
    }

    function onMouseMove(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        moveHandler(e);
    }

    function moveHandler(e: {
        clientX: number,
        clientY: number,
    }) {
        if (!dragState.isDragging)
            return;

        const deltaX = e.clientX - dragStart.current!.x;
        const deltaY = e.clientY - dragStart.current!.y;

        const target = contentHostRef.current as HTMLDivElement;
        target.scrollLeft -= deltaX;
        target.scrollTop -= deltaY;

        setDragState({ 
            isDragging: true, 
            hasBeenDragged: dragState.hasBeenDragged || deltaX != 0 || deltaY != 0, 
        });

        dragStart.current = { x: e.clientX, y: e.clientY };
    }
    // #endregion

    return <>
        <div
            ref={ref}
            {...idleHandlers}
            className="scrollHost"
            style={{
                cursor: dragState.isDragging ? "grabbing" : "default",
            }}>
            {dragState.isDragging && dragState.hasBeenDragged && <div className="dragOverlay"  {...dragHandlers} />}
            <div className="topLabelContainer" style={{
                top: "0px", width: makeValid(props.dimensions.headerSize.width),
                height: makeValid(props.dimensions.headerSize.height)
            }}>
                {props.topLabel}
            </div>

            <div
                ref={yHeaderRef}
                className="yHeader"
                style={{
                    top: makeValid(props.dimensions.headerSize.height),
                    width: makeValid(props.dimensions.headerSize.width),
                    bottom: 0,
                }}>
                <div
                    className="relative"
                    style={{
                        width: makeValid(props.dimensions.headerSize.width),
                        height: makeValid(props.dimensions.contentSize.height) + colors.$scrollbarWidth,
                    }}>
                    {props.yHeader}
                </div>
            </div>

            <div
                className="xHeader"
                ref={xHeaderRef}
                style={{
                    left: makeValid(props.dimensions.headerSize.width),
                    height: makeValid(props.dimensions.headerSize.height),
                }}>
                <div
                    className="relative"
                    style={{
                        width: makeValid(props.dimensions.contentSize.width) + colors.$scrollbarWidth,
                        height: makeValid(props.dimensions.headerSize.height),
                    }}>
                    {props.xHeader}
                </div>
            </div>

            <div
                ref={contentHostRef}
                className={"contentHost" + scrollFadeClass}
                onScroll={scrollHandler}
                style={{
                    left: makeValid(props.dimensions.headerSize.width),
                    top: makeValid(props.dimensions.headerSize.height),
                }}>
                <div
                    ref={contentRef}
                    className="relative"
                    style={{
                        width: makeValid(props.dimensions.contentSize.width),
                        height: makeValid(props.dimensions.contentSize.height),
                    }}>
                    {content}
                </div>
            </div>
        </div>
    </>;

    function makeValid(val: number, defaultValue = 0) {
        if (val === undefined ||
            isNaN(val) ||
            !isFinite(val))
            return defaultValue;

        return +val;
    }

    function scrollHandler(e: React.UIEvent<HTMLDivElement, UIEvent>) {
        const target = e.target as HTMLDivElement;
        const left = target.scrollLeft;
        const top = target.scrollTop;

        const width = target.clientWidth;
        const height = target.clientHeight;

        if (xHeaderRef.current)
            xHeaderRef.current.scrollTo(left, 0);

        if (yHeaderRef.current)
            yHeaderRef.current.scrollTo(0, top);

        if (props.onScrolled !== undefined)
            props.onScrolled(top, left, top + height, left + width, props.dimensions.contentSize.width, props.dimensions.contentSize.height);

        setBounds(BoundingBox.fromPoints([{
            x: left,
            y: top,
        }, {
            x: left + width,
            y: top + height,
        }]));
    }
});