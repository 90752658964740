import React, { useContext, useMemo, useRef } from "react";
import { SessionContext, SessionType, hasDownloadPermission } from "../../contexts/SessionContext";
import { SettingsContext, SettingsType, SortByType } from "../../contexts/SettingsContext";
import { KpiComparisons } from "../../contexts/ContextTypes";
import { analysisGraphMapping, AnalysisType, useGraph } from "../../hooks/UseGraph";
import { useResizeObserver } from "../../hooks/UseResizeObserver";
import i18n from "../../i18n";
import { Edge, Graph, MultiEdge } from "../../models/Dfg";
import { getKpiDefinition, getUnit } from "../../models/Kpi";
import { DfgUtils, getEdgeStat, getCustomKpisDfg } from "../../utils/DfgUtils";
import { Bar } from "../graph/Graph";
import { ContainerModes, GraphLine, commonSelectionLineProps } from "../graph/GraphCommon";
import { GroupGraph } from "../graph/GroupGraph";
import Shortcuts, { ShortcutContexts } from "../shortcuts/Shortcuts";
import Spinner from "../spinner/Spinner";
import { ensureProperCountFormatting, sortByAlphabetical, sortByDeviation, sortByFrequency, sortByKpi } from "./NodeKpiChart";
import DownloadFile, { TemplateType } from "../download-file/DownloadFile";
import { getAnalysisTitle, getAxisLabel, getBarColor } from "../product-chart/ProductChart";
import { SortOrder } from "../../models/KpiTypes";
import { isObjectCentricAvailable } from "../../utils/SettingsUtils";
import { getLegend } from "../../views/process-kpi-chart/ProductProcessKpiChart";
import { useUnifiedDeviationGraphs } from "../../hooks/UseUnifiedDeviationGraph";
import { addAverageStockToGraphNodes } from "../../utils/AverageStock";

export type EdgeKpiChartProps = {
    analysisType: AnalysisType;
    title?: string;
}

export default function EdgeKpiChart(props: EdgeKpiChartProps) {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);

    const analysisArguments = analysisGraphMapping.find((a) => a.analysisType === props.analysisType)?.arguments;

    const kpiDefinition = getKpiDefinition(settings.kpi.selectedKpi, { session, settings });

    const graphOptions = { 
        ...analysisArguments, 
        ...getCustomKpisDfg(settings, session, false),
        calculateNodes: true,
        calculateEdges: true,
        calculateRoles: true,
        calculateTimeAndFreqStats: true,
        calculateActivityValues: true,
    };

    const graph = useGraph(graphOptions, props.analysisType);

    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);

    const isPlanningComparison = settings.kpi.comparisons === KpiComparisons.Planning;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [ actual, planned, _, isDeviationGraphLoading ] = useUnifiedDeviationGraphs({
        ...graphOptions,
        calculateNodes: true,
        calculateEdges: true,
    }, { disable: !isPlanningComparison });

    if (props.analysisType === AnalysisType.Output || 
        props.analysisType === AnalysisType.Stock) {
        if (actual !== undefined)
            addAverageStockToGraphNodes(actual);
        if (planned !== undefined)
            addAverageStockToGraphNodes(planned);
    }


    // When planning data is available, use the deviation graph edges, otherwise
    // fall back to the regular graph edges.
    const candidateEdges = (isPlanningComparison ? actual?.edges : graph?.edges) ?? [];

    const isComparisonHighlightingEnabled = settings.kpi.comparisons === KpiComparisons.Planning && settings.kpi.highlightDeviations;

    const { graphData, maxY } = useMemo(() => {
        const plannedEdgeMap: { [edgeId: string]: MultiEdge } = {};
        for (const edge of planned?.multiEdges ?? []) {
            const id = getEdgeId(edge);
            if (id)
                plannedEdgeMap[id] = edge;
        }

        const result: Bar<Edge>[][] = [];
        let maxY = 0;

        // Exclude edges that are connected to start- or end nodes
        const excludedNodeIds = new Set(DfgUtils.filterStartAndEndNodes(graph?.nodes ?? []).map(n => n.id));
        const edges = (graph?.multiEdges ?? []).filter(e => !excludedNodeIds.has(e.from) && !excludedNodeIds.has(e.to));

        for (const actualEdge of edges) {
            const objectEdge = DfgUtils.findObjectEdge(actualEdge, isObjectCentric, settings.graph.objectType);
            if (objectEdge === undefined)
                continue;

            const element: number[] = [];

            const actual = getEdgeStat(objectEdge, settings, session);
            if (actual === undefined)
                // This should never happen, but let's play it safe.
                continue;

            element.push(actual);

            // Find corresponding planned edge
            if (isPlanningComparison) {
                const id = getEdgeId(actualEdge);
                const plannedEdge = id ? plannedEdgeMap[id] : undefined;
                const plannedObjectEdge = plannedEdge ? DfgUtils.findObjectEdge(plannedEdge, isObjectCentric, settings.graph.objectType) : undefined;
                const planned = plannedObjectEdge ? getEdgeStat(plannedObjectEdge, settings, session, undefined) : undefined;

                if (planned !== undefined)
                    element.push(planned);
            }

            maxY = Math.max(maxY, ...element);

            const label = `${DfgUtils.getNodeLabelById(actualEdge.from, graph?.nodes, settings.groupingKey)} ➞ ${DfgUtils.getNodeLabelById(actualEdge.to, graph?.nodes, settings.groupingKey)}`;

            const resultElement: Bar<Edge>[] = element.map(value => {
                return {
                    value,
                    label,
                    data: objectEdge
                };
            });

            if (isComparisonHighlightingEnabled)
                resultElement[0].barColor = getBarColor(kpiDefinition, element[0], element[1]);

            result.push(resultElement);
        }

        const sorted = sortBySelectedOption(settings, result);

        const reversed = settings.kpi.sortOrder === SortOrder.Descending ? sorted.reverse() : sorted;

        return {
            graphData: reversed,
            maxY,
        };
    }, [
        graph,
        actual,
        planned,
        session.numberFormatLocale,
        settings.graph.objectType,
        settings.kpi.sortOrder,
        settings.kpi.sortBy,
        settings.kpi.selectedKpi,
        settings.kpi.statistic,
        settings.kpi.comparisons,
        settings.quantity,
        isComparisonHighlightingEnabled,
    ]);

    const chartContainerRef = useRef<HTMLDivElement>(null);
    const resizeState = useResizeObserver(chartContainerRef);
    const downloadAllowed = hasDownloadPermission(session);

    const isInitializing = isDeviationGraphLoading ||
        graph === undefined ||
        resizeState?.width === undefined ||
        resizeState?.height === undefined;

    const onSelected = (groupIdx: number | undefined, barIdx: number | undefined, data: Edge | undefined) => {
        const edges = candidateEdges.filter(e => e.from === data?.from && e.to === data?.to);

        if (!edges?.length ||
            (settings.selection.edge?.from === edges[0].from && settings.selection.edge?.to === edges[0].to)) {
            settings.setSelection({});
            return;
        }

        settings.setSelection({
            edge: {
                ...edges[0],
                edges,
            }
        });
    };

    const selectedIndex = settings.selection.edge !== undefined ? graphData.findIndex(c => c[0].data?.from === settings.selection.edge?.from && c[0].data?.to === settings.selection.edge?.to) : undefined;

    const unit = getUnit(kpiDefinition?.unit, settings.kpi.statistic);

    const title = getAnalysisTitle(session, settings);

    const hasData = graphData.some(e => e.length > 0 && e[0].value !== undefined);

    return <div className="kpiChartCollection fillParentMargin">
        <Spinner isLoading={isInitializing} showProjectLoadingSpinner={true} />

        {graph !== undefined && !hasData && !isInitializing && <div className="noKpisAvailable">
            {i18n.t("common.noKpisAvailable")}
            <div>
                {i18n.t("common.noEdgesFound")}
            </div>
        </div>}

        <div className={"chartContainer" + (isInitializing ? " hide" : "")} ref={chartContainerRef}>
            {resizeState?.width !== undefined && resizeState.height !== undefined && hasData && <GroupGraph
                legend={getLegend(settings, isComparisonHighlightingEnabled)}
                title={i18n.t(title).toString()}
                horizonalLines={drawSelectionLine(graph, settings, session)}
                yAxisUnit={unit}
                selectedGroupIdx={selectedIndex}
                selectedGroupBarIdx={0}
                max={maxY}
                barPadding={10}
                minGroupPadding={50}
                data={graphData}
                showBarValues={true}
                yAxisLabel={getAxisLabel(settings.kpi.selectedKpi, settings.kpi.statistic, session, settings)}
                showYAxisLines={true}
                padding={{
                    top: 85,
                    left: 60,
                    bottom: 100,
                }}
                onSelected={onSelected}
                onLabelSelected={(groupIdx: number) => {
                    if (groupIdx === selectedIndex) {
                        settings.setSelection({});
                        return;
                    }
                    const actualEdge = graphData[groupIdx][0].data;
                    const edges = candidateEdges.filter(e => e.from === actualEdge?.from && e.to === actualEdge?.to);

                    if (!edges?.length)
                        return;

                    settings.setSelection({
                        edge: {
                            ...edges[0],
                            edges,
                        }
                    });
                }}
                containerMode={ContainerModes.Constrained}
                width={resizeState!.width}
                height={resizeState!.height}
                formatterParams={{
                    numDigits: 1,
                }}
                valueFormatter={(value) => {
                    return ensureProperCountFormatting(value, unit, session, settings.quantity);
                }}
            />}
        </div>
        <DownloadFile
            data={graphData}
            planningData={settings.kpi.comparisons === KpiComparisons.Planning}
            template={TemplateType.Edge}
            meta={unit}
            allowed={downloadAllowed}
            title={title} />
        <Shortcuts handledSelections={[ShortcutContexts.Edge]} graph={graph} />
    </div>;
}

function getEdgeId(edge?: MultiEdge | Edge) {
    if (edge === undefined)
        return undefined;

    return `edge-${edge.from}-${edge.to}`;
}

export function exportEdgeData(data: Bar<Edge>[][], planningData?: boolean) {
    const formattedData = [];

    for (const bar of data) {
        const item = {
            [i18n.t("common.from")]: bar[0]?.data?.from,
            [i18n.t("common.to")]: bar[0]?.data?.to,
            [i18n.t("common.actual")]: bar[0]?.value,
        };
        if (planningData)
            item[i18n.t("common.plan")] = bar[1]?.value;

        formattedData.push(item);
    }

    return formattedData;
}

function sortBySelectedOption(settings: SettingsType, barArray: Bar<Edge>[][]) {

    switch (settings.kpi.sortBy) {
        case SortByType.Kpi:
            return sortByKpi(barArray) as Bar<Edge>[][];

        case SortByType.Frequency:
            return sortByFrequency(barArray) as Bar<Edge>[][];

        case SortByType.Alphabetical:
            return sortByAlphabetical(barArray) as Bar<Edge>[][];

        case SortByType.DeviationFromComparison:
            return sortByDeviation(barArray) as Bar<Edge>[][];

    }
    return barArray.sort((a, b) => a[0].value - b[0].value);

}

function drawSelectionLine(graph: Graph | undefined, settings: SettingsType, session: SessionType): GraphLine[] {
    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);
    const selectedEdge = settings.selection.edge ? graph?.multiEdges.find(e => e.from === settings.selection.edge?.from && e.to === settings.selection.edge?.to) : undefined;

    const objectEdge = selectedEdge ? DfgUtils.findObjectEdge(selectedEdge, isObjectCentric, settings.graph.objectType) : undefined;
    const value = objectEdge ? getEdgeStat(objectEdge, settings, session) : undefined;
    const selectedElementLine: GraphLine[] = value !== undefined ? [{ ...{ value: value, ...commonSelectionLineProps } }] : [];
    return selectedElementLine;
}
