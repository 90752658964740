import { DateTime, Duration } from "luxon";
import i18n from "../../i18n";
import { TickInterval } from "../../utils/Formatter";
import { GanttEntry, CaseGanttCase, GanttStatistics, TimeMode } from "../../models/ApiTypes";

export function getStatistics(data: GanttEntry[] | CaseGanttCase[] | undefined): GanttStatistics {
    const result = {
        minTime: Infinity,
        maxTime: 1,
        minCount: Infinity,
        maxCount: 1,
        minInterval: Infinity,
        maxInterval: 1
    };

    for (const row of data ?? []) {
        for (const event of row.events) {
            const from = +event[0];
            const to = +event[1];
            const duration = to - from;

            result.minTime = Math.min(result.minTime, from);
            result.maxTime = Math.max(result.maxTime, to);

            if (typeof event[2] === "number") {
                const count = +event[2];
                result.minCount = Math.min(result.minCount, count);
                result.maxCount = Math.max(result.maxCount, count);
            }

            result.minInterval = Math.min(result.minInterval, duration);
            result.maxInterval = Math.max(result.maxInterval, duration);
        }
    }

    return result;
}

export function formatTime(timezone: string, locale: string, timeMode: TimeMode, tickInterval: TickInterval, seconds: number | undefined, tick: boolean, isFirst: boolean) {
    if (seconds === undefined)
        return "";

    if (timeMode == TimeMode.Absolute) {
        const d = DateTime.fromSeconds(seconds, { zone: "utc" }).setZone(timezone);
        return tick ? tickInterval.absoluteFormatter[isFirst ? 0 : 1](d) : d.toFormat("f", { locale });
    }
    else if (timeMode == TimeMode.WeekRelative) {
        const format = (tick && tickInterval.seconds === 24 * 3600) ? "ccc" : "ccc HH':'mm";
        return DateTime.fromObject({ year: 2021, month: 3, day: 1}, { zone: timezone }).plus({ seconds }).toFormat(format, { locale });
    }
    else {
        // Case Relative
        const hasDays = seconds >= (24 * 60 * 60);
        const hasHours = (seconds % (3600 * 24)) >= 3600;
        const hasMinutes = (seconds % 3600) >= 60;
        const format: string[] = [];
        
        if (hasDays)
            format.push(`d'${i18n.t("units.days_short")}'`);

        if (hasHours)
            format.push(`hh'${i18n.t("units.hours_short")}'`);

        if (hasMinutes)
            format.push(`mm'${i18n.t("units.minutes_short")}'`);
        
        if (format.length == 0 && tick === false)
            format.push(`m'${i18n.t("units.minutes_short")}'`);

        return Duration.fromMillis((seconds) * 1000, { locale }).toFormat(format.join(" "));
    }
}
