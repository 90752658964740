import React, { useContext } from "react";
import { KpiControls } from "../../components/controls/KpiControls";
import { TabbedView } from "../../components/tabbed-view/TabbedView";
import { BackButtonTrayElement } from "../../components/tray/BackButtonTrayElement";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { CaseAggregationStatistics } from "../../models/Case";
import { NodeRoles } from "../../models/Dfg";
import { getKpiDefinition } from "../../models/Kpi";
import { KpiPresets, KpiTypes, StatisticTypes } from "../../models/KpiTypes";
import { viewSettingsInitialization } from "../../utils/Initializers";
import { QuantityType, getAssignedQuantities } from "../../utils/Quantities";
import AggregatedCaseStats from "../process-kpi-chart/stats/AggregatedCaseStats";
import { BillOfMaterialsPage } from "./BillOfMaterialsPage";
import { CriticalProductStats } from "./CriticalProductStats";
import { SupplyChainEdgeStatistics } from "./SupplyChainEdgeStatistics";
import i18n from "../../i18n";

export function SupplyChainView() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const quantities = getAssignedQuantities(session.project?.eventKeys, QuantityType.CaseYield, false).map(q => q.baseQuantity);

    const subtitle = settings.supplyChain.selectedProduct ? i18n.t("supplyChain.subtitle", {product: settings.supplyChain.selectedProduct}).toString() : undefined;

    return <TabbedView
        subtitle={<div className="text">{subtitle}</div>}
        breadcrumbs={[{
            label: "supplyChain.menuTitle"
        }]}
        pages={[
            {
                tabTitle: "supplyChain.bomTitle",
                tabSlug: "bom",
                controller: <div className="controls">
                    <KpiControls
                        kpis={KpiPresets.supplyChainViewKpis}
                        hasYAxis={false}
                        addQuantityDropdown={true}
                        hideStatistics={[StatisticTypes.Variance, StatisticTypes.Sum]}
                    />
                </div>,
                selectionTrigger: (settings) => {
                    return settings.selection.node !== undefined || settings.selection.edge !== undefined;
                },
                stats: <>
                    {settings.selection.node !== undefined && settings.selection.node.role === NodeRoles.Machine && <AggregatedCaseStats
                        selection={settings.selection.node as unknown as CaseAggregationStatistics}
                        isLoading={false}
                        title={(settings.selection?.node as unknown as any)?.name}
                        count={(settings.selection?.node as unknown as any)?.count}
                        kpiDefinitions={[KpiTypes.ReplenishmentLeadTime, KpiTypes.OrderInterval, KpiTypes.ThroughputTime, KpiTypes.GoodQuantity].map(t => getKpiDefinition(t, { session, settings, target: "case" })!)}
                    />}
                    {settings.selection.node !== undefined && settings.selection.node.role === NodeRoles.Inventory && <AggregatedCaseStats
                        selection={settings.selection.node as unknown as CaseAggregationStatistics}
                        isLoading={false}
                        title={(settings.selection?.node as unknown as any)?.name}
                        count={(settings.selection?.node as unknown as any)?.count}
                        countLabel={"common.count"}
                        kpiDefinitions={[KpiTypes.StorageTime].map(t => getKpiDefinition(t, { session, settings, target: "case" })!)}
                    />}
                    {settings.selection.edge !== undefined && <SupplyChainEdgeStatistics />}
                    {settings.selection.node === undefined && settings.selection.edge === undefined && !!settings.supplyChain.selectedProduct && <CriticalProductStats />}
                </>,
                spotlightId: "SupplyChain",
                activator: (preferences) => {
                    settings.set(viewSettingsInitialization(session, settings, preferences, {
                        kpis: [KpiTypes.ReplenishmentLeadTime],
                        statistics: [StatisticTypes.Mean],
                        quantities,
                    }));
                },
                content: <><BillOfMaterialsPage />
                    <BackButtonTrayElement />
                </>,
            },
        ]}
    />;
}

