import { useContext } from "react";
import { GetCaseDeviationResult, GetCaseStatisticsResponse, StatsCalculationRequest, timeAndThroughputCalcOptions } from "../models/ApiTypes";
import { SettingsContext, SettingsType, SortByType } from "../contexts/SettingsContext";
import { useCaseDeviation } from "./UseCaseDeviation";
import { defaultApiLimit, useCaseStatistics } from "./UseCaseStatistics";
import { SortOrder } from "../models/KpiTypes";
import { EventFilter } from "../models/EventFilter";
import { getKpiDefinition } from "../models/Kpi";
import { KpiComparisons } from "../contexts/ContextTypes";
import { SessionContext, SessionType } from "../contexts/SessionContext";


export function useKpiCaseStatisticsWithDeviation(addEnergyStats?: boolean, requestProps?: Partial<StatsCalculationRequest> & {
    eventFilters?: EventFilter[] | undefined;
    limit?: number | undefined;
}, options?: {
    disable?: boolean;
}): [GetCaseStatisticsResponse | undefined, boolean, GetCaseDeviationResult | undefined, boolean] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const orderProp = settings.kpi.sortOrder === SortOrder.Ascending ? "" : "-";
    const sortProp = getPropName(session, settings);
    const kpiDefinition = getKpiDefinition(settings.kpi.selectedKpi, { session, settings });
    const apiParameters = kpiDefinition?.apiParameters;
    const customKpis = kpiDefinition?.caseCustomKpis ? kpiDefinition.caseCustomKpis : undefined;

    const isDeviationApi = settings.kpi.comparisons === KpiComparisons.Planning || kpiDefinition?.useDeviationApi;

    const [caseStats, isCaseStatsLoading] = useCaseStatistics({
        ...timeAndThroughputCalcOptions,
        ...apiParameters,
        ...requestProps,
        customKpis,
        sort: [orderProp + sortProp],
        limit: requestProps?.limit ?? defaultApiLimit,
    }, {
        disable: sortProp === undefined || options?.disable || isDeviationApi,
        addEnergyStats,
    });

    const prefix = kpiDefinition?.useDeviationApi ? "" : settings.kpi.sortBy === SortByType.DeviationFromComparison ? "deviation." : "actual.";

    const [caseDeviation, isCaseDeviationLoading] = useCaseDeviation({
        ...timeAndThroughputCalcOptions,
        ...apiParameters,
        ...requestProps,
        customKpis,
        sort: [orderProp + prefix + sortProp],
        limit: requestProps?.limit ?? defaultApiLimit,
    }, {
        disable: sortProp === undefined || options?.disable || !isDeviationApi,
    });

    return [caseStats, isCaseStatsLoading, caseDeviation, isCaseDeviationLoading];
}

export function getPropName(session: SessionType, settings: SettingsType) {
    const kpiDefinition = getKpiDefinition(settings.kpi.selectedKpi, { session, settings });
    return kpiDefinition?.caseStatisticsPath;
}