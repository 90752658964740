import React, { useContext } from "react";
import { ProductIdentifier, SettingsContext } from "../../contexts/SettingsContext";
import { SetupMatrixElementSchema, NodeMatrixSchema } from "../../models/ApiTypes";

export type MatrixCellVariantTypes = "default" | "green" | "yellow" | "orange" | "red" | "purple";

export interface MatrixCellProps {
    variant?: MatrixCellVariantTypes;
    title?: string;
    value?: number;
    info?: string;
    transition?: SetupMatrixElementSchema;
    comparisonTransition?: SetupMatrixElementSchema;
    x: number;
    y: number;
    from: NodeMatrixSchema | string | ProductIdentifier;
    to: NodeMatrixSchema | string;
    color?: string;
}

export default function MatrixCell(props: MatrixCellProps) {
    const settings = useContext(SettingsContext);

    const isCellSelected = settings.selection.matrixElement?.transition !== undefined;
    const isSelected = isCellSelected && 
        (settings.selection.matrixElement?.transition?.from === props.transition?.from &&
        settings.selection.matrixElement?.transition?.to === props.transition?.to);

    return (
        <div
            style={{
                top: `calc(var(--matrix-cell-box-height) * ${props.y})`,
                left: `calc(var(--matrix-cell-box-width) * ${props.x})`,
                backgroundColor: props.color,
            }}
            className={`matrixCell matrixCell--variant-${props.variant}${props.transition !== undefined ? " clickable" : ""}${isSelected ? " matrixCell--selected" : ""}`}
            onClick={(e) => {
                e.stopPropagation();

                if (!props.transition) {
                    // Blank cell
                    settings.setSelection({});
                    return;
                }

                if (!props || 
                    settings.selection.matrixElement?.transition === props.transition ||
                    props.from === undefined ||
                    props.to === undefined) {
                    settings.setSelection({});
                    return;
                }

                settings.setSelection({
                    matrixElement: props
                });
            }}>
            {props.title && <div className="matrixCell__title">{props.title}</div>}
            {props.info && <div className="matrixCell__info">{props.info}</div>}
        </div>
    );
}
