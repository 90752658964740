import React, { useContext } from "react";
import { getDimensionParameters } from "../../components/dimension/Dimension";
import { TabbedView } from "../../components/tabbed-view/TabbedView";
import { AggregationTypes } from "../../contexts/ContextTypes";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext, SortByType } from "../../contexts/SettingsContext";
import { viewSettingsInitialization } from "../../utils/Initializers";
import { getAssignedQuantities, QuantityType } from "../../utils/Quantities";
import { ProcessKpiChart } from "../process-kpi-chart/ProcessKpiChart";
import { KpiErrorMessage } from "./QualityKpisView";
import BenchmarkingControls, { getValidBenchmarkingControlSettings } from "../../components/controls/BenchmarkingControls";
import { KpiPresets } from "../../models/KpiTypes";
import ProductSideStatistics from "../process-kpi-chart/stats/ProductSideStatistics";
import { getDefaultEnabledComparisons } from "../../utils/SettingsUtils";
import { BackButtonTrayElement } from "../../components/tray/BackButtonTrayElement";
import { BaseQuantityType } from "../../models/ApiTypes";
import { caseKpiControlsGetAllowedStatistics } from "../../models/Kpi";
import { ObjectMerger } from "../../utils/ObjectMerger";
import { LogStats } from "../process-kpi-chart/stats/LogStats";
import { ViewSubtitle } from "../../components/tabbed-view/ViewSubtitle";

export function OutputKpisView() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const caseYieldQuantities = getAssignedQuantities(session.project?.eventKeys, QuantityType.CaseYield, false).map(q => q.baseQuantity);
    const errorMessage = session.project !== undefined && (!caseYieldQuantities.includes(settings.quantity as BaseQuantityType)) ?
        "kpi.noDataCaseYieldRequired" : undefined;

    return <TabbedView
        subtitle={<ViewSubtitle />}
        breadcrumbs={[{
            label: "common.products"
        }]}
        pages={[{
            spotlightId: "Output-KPI-Process",
            tabTitle: "common.processKpis",
            tabSlug: "process",
            controller: <BenchmarkingControls kpis={KpiPresets.productOutputKpis} />,
            content: <>
                <KpiErrorMessage message={errorMessage} />
                {!errorMessage && <ProcessKpiChart
                    noProductDataPlaceholder="kpi.noDataProductsAndCaseYieldRequired"
                    noCaseDataPlaceholder="kpi.noDataYieldRequired"
                    noTimeDataPlaceholder="kpi.noDataInTimeRange"
                    pageSlug="output" />}
                <BackButtonTrayElement />
            </>,
            selectionTrigger: (settings) => {
                return settings.selection.product !== undefined ||
                    settings.selection.case !== undefined ||
                    settings.selection.timeperiod !== undefined;
            },
            dimensions: getDimensionParameters(session.projectId, "kpis/process"),
            activator: (preferences) => {
                let temp = viewSettingsInitialization(session,
                    settings,
                    preferences,
                    {
                        sortBy: [SortByType.Kpi, SortByType.Frequency, SortByType.Alphabetical, SortByType.DeviationFromComparison, SortByType.Median, SortByType.Percentiles],
                        aggregationTypes: [AggregationTypes.Case, AggregationTypes.Product, AggregationTypes.Time],
                        quantities: caseYieldQuantities,
                        kpis: KpiPresets.productOutputKpis,
                    });

                if (!temp)
                    return;

                temp = ObjectMerger.mergeObject(temp, getValidBenchmarkingControlSettings(session, temp, { kpis: KpiPresets.productOutputKpis }));

                // The allowed comparisons are depending on settings that might have been corrected above,
                // so we need to run this as a two-step process
                settings.set(viewSettingsInitialization(session, temp, undefined, {
                    comparisons: getDefaultEnabledComparisons(session, temp),
                    statistics: caseKpiControlsGetAllowedStatistics(session, settings, temp.kpi.selectedKpi, temp.kpi.aggregation),
                }));
            },
            stats: <ProductSideStatistics unmarkedAside={<LogStats />} />
        },
        ]}>
        <BackButtonTrayElement />
    </TabbedView>;

}