import React, { useContext } from "react";
import { AggregationTypes } from "../../../contexts/ContextTypes";
import { SettingsContext } from "../../../contexts/SettingsContext";
import { KpiTypes } from "../../../models/KpiTypes";
import { SideStatisticsProductCaseDeviation } from "../../planning-deviation/SideStatisticsProductCaseDeviation";
import { CaseStats } from "./CaseStats";
import ProductStats from "./ProductStats";
import TimeperiodStats from "./TimeperiodStats";

type ProductSideStatisticsProps = {
    unmarkedAside?: JSX.Element;
    hasError?: boolean;
};

const statsComponentLookup: Map<KpiTypes, {
    product?: JSX.Element,
    case?: JSX.Element,
    time?: JSX.Element,
}> = new Map();
statsComponentLookup.set(KpiTypes.ThroughputTime, {
    product: <ProductStats kpiTypes={[KpiTypes.ThroughputTime]} />,
    time: <TimeperiodStats />,
    case: <CaseStats />,
});
statsComponentLookup.set(KpiTypes.DeviationThroughputTime, {
    product: <SideStatisticsProductCaseDeviation />,
    case: <SideStatisticsProductCaseDeviation />,
});
statsComponentLookup.set(KpiTypes.DeviationRelativeThroughputTime, {
    product: <SideStatisticsProductCaseDeviation />,
    case: <SideStatisticsProductCaseDeviation />,
});
statsComponentLookup.set(KpiTypes.ProductionProcessRatio, {
    product: <ProductStats kpiTypes={[KpiTypes.ProductionProcessRatio]} />,
    time: <TimeperiodStats />,
    case: <CaseStats />,
});
statsComponentLookup.set(KpiTypes.BusyTime, {
    product: <ProductStats kpiTypes={[KpiTypes.BusyTime]} hasBusyTimeSubStats={true} />,
    time: <TimeperiodStats />,
    case: <CaseStats />,
});
statsComponentLookup.set(KpiTypes.ProductionTime, {
    product: <ProductStats kpiTypes={[KpiTypes.ProductionTime]} />,
    time: <TimeperiodStats />,
    case: <CaseStats />,
});
statsComponentLookup.set(KpiTypes.SetupTime, {
    product: <ProductStats kpiTypes={[KpiTypes.SetupTime]} />,
    time: <TimeperiodStats />,
    case: <CaseStats />,
});
statsComponentLookup.set(KpiTypes.InterruptionTime, {
    product: <ProductStats kpiTypes={[KpiTypes.InterruptionTime]} />,
    time: <TimeperiodStats />,
    case: <CaseStats />,
});
statsComponentLookup.set(KpiTypes.FailureTime, {
    product: <ProductStats kpiTypes={[KpiTypes.FailureTime]} />,
    time: <TimeperiodStats />,
    case: <CaseStats />,
});
statsComponentLookup.set(KpiTypes.TechnicalLosses, {
    product: <ProductStats kpiTypes={[KpiTypes.TechnicalLosses]} />,
    time: <TimeperiodStats />,
    case: <CaseStats />,
});
statsComponentLookup.set(KpiTypes.OrganizationalLosses, {
    product: <ProductStats kpiTypes={[KpiTypes.OrganizationalLosses]} />,
    time: <TimeperiodStats />,
    case: <CaseStats />,
});
statsComponentLookup.set(KpiTypes.ProcessLosses, {
    product: <ProductStats kpiTypes={[KpiTypes.ProcessLosses]} />,
    time: <TimeperiodStats />,
    case: <CaseStats />,
});
statsComponentLookup.set(KpiTypes.QualityLosses, {
    product: <ProductStats kpiTypes={[KpiTypes.QualityLosses]} />,
    time: <TimeperiodStats />,
    case: <CaseStats />,
});
statsComponentLookup.set(KpiTypes.QueuingTime, {
    product: <ProductStats kpiTypes={[KpiTypes.QueuingTime]} />,
    time: <TimeperiodStats />,
    case: <CaseStats />,
});
statsComponentLookup.set(KpiTypes.OrderCount, {
    product: <ProductStats kpiTypes={[]} />,
    time: <TimeperiodStats disableKpiStats={true} />,
    case: <CaseStats />,
});
statsComponentLookup.set(KpiTypes.ProducedQuantity, {
    product: <ProductStats kpiTypes={[KpiTypes.ProducedQuantity]} />,
    time: <TimeperiodStats />,
    case: <CaseStats />,
});
statsComponentLookup.set(KpiTypes.GoodQuantity, {
    product: <ProductStats kpiTypes={[KpiTypes.GoodQuantity]} />,
    time: <TimeperiodStats />,
    case: <CaseStats />,
});
statsComponentLookup.set(KpiTypes.ThroughputRate, {
    product: <ProductStats kpiTypes={[KpiTypes.ThroughputRate]} />,
    time: <TimeperiodStats />,
    case: <CaseStats />,
});
statsComponentLookup.set(KpiTypes.WorkInProcessInventory, {
    product: <ProductStats kpiTypes={[KpiTypes.WorkInProcessInventory]} disableProductFilter={true} />,
    time: <TimeperiodStats />,
    case: <CaseStats />,
});
statsComponentLookup.set(KpiTypes.ScrapQuantity, {
    product: <ProductStats kpiTypes={[KpiTypes.ScrapQuantity]} />,
    time: <TimeperiodStats />,
    case: <CaseStats />,
});
statsComponentLookup.set(KpiTypes.ScrapRatio, {
    product: <ProductStats kpiTypes={[KpiTypes.ScrapRatio]} />,
    time: <TimeperiodStats />,
    case: <CaseStats />,
});
statsComponentLookup.set(KpiTypes.OnTimeDelivery, {
    product: <ProductStats kpiTypes={[KpiTypes.OnTimeDelivery, KpiTypes.NumberOfDelayedOrders, KpiTypes.DelayTime]} />,
    time: <TimeperiodStats kpiTypes={[KpiTypes.OnTimeDelivery, KpiTypes.NumberOfDelayedOrders, KpiTypes.DelayTime]}/>,
    case: <CaseStats />,
});
statsComponentLookup.set(KpiTypes.DelayTime, {
    product: <ProductStats kpiTypes={[KpiTypes.OnTimeDelivery, KpiTypes.NumberOfDelayedOrders, KpiTypes.DelayTime]} />,
    time: <TimeperiodStats kpiTypes={[KpiTypes.OnTimeDelivery, KpiTypes.NumberOfDelayedOrders, KpiTypes.DelayTime]}/>,
    case: <CaseStats />,
});
statsComponentLookup.set(KpiTypes.Carbon, {
    product: <ProductStats kpiTypes={[KpiTypes.Carbon]}/>,
    time: <TimeperiodStats />,
    case: <CaseStats />,
});
statsComponentLookup.set(KpiTypes.Energy, {
    product: <ProductStats kpiTypes={[KpiTypes.Energy]}/>,
    time: <TimeperiodStats />,
    case: <CaseStats />,
});


export default function ProductSideStatistics(props: ProductSideStatisticsProps) {
    const settings = useContext(SettingsContext);

    const showProductStats = !!settings.selection.product && settings.kpi.aggregation === AggregationTypes.Product;
    const showCaseStats = !!settings.selection.case && settings.kpi.aggregation === AggregationTypes.Case;
    const showTimeStats = !!settings.selection.timeperiod && settings.kpi.aggregation === AggregationTypes.Time;
    const showDefaultStats = !showProductStats && !showCaseStats && !showTimeStats;

    return <>
        {(showDefaultStats || props.hasError) && props.unmarkedAside}
        {showProductStats && statsComponentLookup.get(settings.kpi.selectedKpi)?.product}
        {showCaseStats && statsComponentLookup.get(settings.kpi.selectedKpi)?.case}
        {showTimeStats && statsComponentLookup.get(settings.kpi.selectedKpi)?.time}
    </>;
}