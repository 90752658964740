import { intersection, isEqual, set, uniq } from "lodash";
import { possibleSecondGroupsMap } from "../components/controls/GroupingKeyControls";
import { AggregationTypes, KpiComparisons, LegacyAnalyzedValues } from "../contexts/ContextTypes";
import { SessionContextType, SessionType } from "../contexts/SessionContext";
import { DeviationAnalysis, GanttSettingsType, GraphOrientation, SettingsType, SortByType } from "../contexts/SettingsContext";
import { GroupingKeys } from "../models/Dfg";
import { EventKeys } from "../models/EventKeys";
import { KpiTypes, SortOrder, StatisticTypes } from "../models/KpiTypes";
import { DeepPartial, ObjectMerger } from "./ObjectMerger";
import { getAssignedGroupingKeys } from "./Quantities";
import { CaseGanttSetting, BaseQuantityType } from "../models/ApiTypes";

export type MakeSettingsValidType = (session: SessionContextType, settings: SettingsType) => SettingsType;

/**
 * Returns fixed settings or undefined in case the settings are fine. This is usually called
 * from within the SettingsContext whenever the session project changes
 */
export function makeSettingsValid(session: SessionContextType, settings: SettingsType) {
    const newSettings: SettingsType = { ...settings };

    // These functions modify the local copy of settings. The context is only updated
    // if anything actually changed here.
    makeGroupingKeySettingValid(session, newSettings);
    makeSecondGroupingValid(session, newSettings);

    if (isEqual(settings, newSettings))
        return;

    return newSettings;
}

function makeGroupingKeySettingValid(session: SessionContextType, settings: SettingsType) {
    if (!session.project?.eventKeys)
        return;

    const isGroupingValid = getIsGroupingValid(session.project.eventKeys, settings.groupingKey);

    if (isGroupingValid === false) {
        // Grouping by some column that's not defined in the current dataset.
        if (session.project.eventKeys.machine !== undefined) {
            if (session.project.eventKeys.passId !== undefined)
                settings.groupingKey = GroupingKeys.MachineValueStream;
            else
                settings.groupingKey = GroupingKeys.Machine;
        }
        else if (session.project.eventKeys.machineType !== undefined) {
            if (session.project.eventKeys.passId !== undefined)
                settings.groupingKey = GroupingKeys.MachineTypeValueStream;
            else
                settings.groupingKey = GroupingKeys.MachineType;
        }
        else if (session.project.eventKeys.location !== undefined) {
            if (session.project.eventKeys.passId !== undefined)
                settings.groupingKey = GroupingKeys.LocationValueStream;
            else
                settings.groupingKey = GroupingKeys.Location;
        }
        else if (session.project.eventKeys.passId !== undefined)
            settings.groupingKey = GroupingKeys.NoneValueStream;
        else
            settings.groupingKey = GroupingKeys.None;
    }
}

function makeSecondGroupingValid(session: SessionType, settings: SettingsType) {
    if (session.project === undefined)
        return;

    const secondGroupSetting = possibleSecondGroupsMap.find(v => v.groupingKey === settings.groupingKey);
    // we do not want the combination of machine grouping and objectType as secondary grouping level
    if (secondGroupSetting !== undefined &&
        (!secondGroupSetting.possibleSecondGroups.map(v => v.value).includes(settings.graph.secondGroupingLevel)
            || ([GroupingKeys.Machine, GroupingKeys.MachineValueStream].includes(settings.groupingKey) && settings.graph.secondGroupingLevel === GroupingKeys.ObjectType)))
        settings.graph.secondGroupingLevel = secondGroupSetting.defaultSecondGroup;
}

export function getIsGroupingValid(eventKeys: EventKeys | undefined, groupingKey: GroupingKeys) {
    if (!eventKeys)
        return;

    return (groupingKey === GroupingKeys.None) ||
        (groupingKey === GroupingKeys.Machine && !!eventKeys.machine) ||
        (groupingKey === GroupingKeys.Location && !!eventKeys.location) ||
        (groupingKey === GroupingKeys.MachineType && !!eventKeys.machineType) ||
        (groupingKey === GroupingKeys.NoneValueStream && !!eventKeys.passId) ||
        (groupingKey === GroupingKeys.MachineValueStream && !!eventKeys.passId && !!eventKeys.machine) ||
        (groupingKey === GroupingKeys.LocationValueStream && !!eventKeys.passId && !!eventKeys.location) ||
        (groupingKey === GroupingKeys.MachineTypeValueStream && !!eventKeys.passId && !!eventKeys.machineType) ||
        (groupingKey === GroupingKeys.ObjectType && !!eventKeys.objectType) ||
        (groupingKey === GroupingKeys.MachineObjectType && !!eventKeys.objectType && !!eventKeys.machine) ||
        (groupingKey === GroupingKeys.ObjectTypeValueStream && !!eventKeys.passId && !!eventKeys.objectType) ||
        (groupingKey === GroupingKeys.MachineObjectTypeValueStream && !!eventKeys.passId && !!eventKeys.objectType && !!eventKeys.machine) ||
        (groupingKey === GroupingKeys.PassValueStream && !!eventKeys.passId);
}

export type ValidValueTypes = {
    comparisons?: KpiComparisons[];
    analyses?: LegacyAnalyzedValues[];
    kpis?: KpiTypes[];
    statistics?: StatisticTypes[];
    groupingKeys?: GroupingKeys[];
    secondGroupingLevels?: GroupingKeys[];
    quantities?: BaseQuantityType[];
    aggregationTypes?: AggregationTypes[];
    objectTypes?: string[];
    caseGanttSettings?: CaseGanttSetting[];
    orientation?: GraphOrientation[];
    sortBy?: SortByType[];
    sortOrder?: SortOrder[];

    [key: string]: DeviationAnalysis[] | SortByType[] | GanttSettingsType[] | AggregationTypes[] | KpiComparisons[] | LegacyAnalyzedValues[] | GroupingKeys[] | BaseQuantityType[] | string[] | GraphOrientation[] | undefined;
}

/**
 * This function checks the settings provided for unsupported values. If a setting has a value
 * that's not supported, the first one will be set that is.
 * 
 * 
 * @param session 
 * @param settings 
 * @param preferences Settings object with values that should be preferred (and win over the current settings).
 * Currently, that mechanism is applied only for analyzedValue, but could easily be extended.
 * @param validValues One or multiple sets of supported valid values. If multiple sets are provided,
 * the values will be merged by intersecting the sets.
 */
export function viewSettingsInitialization(session: SessionType, settings: SettingsType, preferences: DeepPartial<SettingsType> | undefined, ...validValues: ValidValueTypes[]) {
    if (session.project?.eventKeys === undefined)
        return;

    // Merge all ValidValueTypes into one
    let merged: Partial<ValidValueTypes> = {};
    for (const vv of validValues)
        merged = mergeValidValues(merged, vv);

    const newState: Partial<SettingsType> = {};

    const assignedGroupingKeys = getAssignedGroupingKeys(session.project.eventKeys);
    if (!!merged.groupingKeys?.length && merged.groupingKeys.indexOf(settings.groupingKey) < 0)
        set(newState, "groupingKey", assignedGroupingKeys.filter(g => merged.groupingKeys!.indexOf(g) >= 0)[0]);

    if (!!merged.secondGroupingLevels?.length && merged.secondGroupingLevels.indexOf(newState.graph!.secondGroupingLevel) < 0)
        set(newState, "graph.secondGroupingLevel", merged.secondGroupingLevels[0]);

    if (!!merged.quantities?.length && (settings.quantity === undefined || merged.quantities.indexOf(settings.quantity) < 0))
        set(newState, "quantity", merged.quantities[0]);

    // Verify that kpi.analyzedValue has a valid value. Prefer value from preferences.
    set(newState, "kpi.analyzedValue", preferences?.kpi?.analyzedValue ?? settings.kpi?.analyzedValue);
    if (!!merged.analyses?.length && !merged.analyses.includes(newState.kpi!.analyzedValue))
        set(newState, "kpi.analyzedValue", merged.analyses[0]);

    // Verify that kpi.selectedKpi has a valid value. Prefer value from preferences.
    set(newState, "kpi.selectedKpi", preferences?.kpi?.selectedKpi ?? settings.kpi?.selectedKpi);
    if (!!merged.kpis?.length && !merged.kpis.includes(newState.kpi!.selectedKpi))
        set(newState, "kpi.selectedKpi", merged.kpis[0]);

    // Verify that kpi.statistic has a valid value. Prefer value from preferences.
    set(newState, "kpi.statistic", preferences?.kpi?.statistic ?? settings.kpi?.statistic);
    if (!!merged.statistics?.length && !merged.statistics.includes(newState.kpi!.statistic))
        set(newState, "kpi.statistic", merged.statistics[0]);

    if (!!merged.comparisons?.length && !merged.comparisons.includes(settings.kpi.comparisons))
        set(newState, "kpi.comparisons", merged.comparisons[0]);

    set(newState, "kpi.aggregation", preferences?.kpi?.aggregation ?? settings.kpi?.aggregation);
    if (!!merged.aggregationTypes?.length && !merged.aggregationTypes.includes(newState.kpi!.aggregation))
        set(newState, "kpi.aggregation", merged.aggregationTypes[0]);

    if (!!merged.objectTypes?.length && (settings.graph?.objectType === undefined || !merged.objectTypes.includes(settings.graph?.objectType)))
        set(newState, "graph.objectType", merged.objectTypes[0]);

    set(newState, "kpi.sortBy", preferences?.kpi?.sortBy ?? settings.kpi?.sortBy);
    if (!!merged.sortBy?.length && !merged.sortBy.includes(newState.kpi!.sortBy))
        set(newState, "kpi.sortBy", merged.sortBy[0]);

    set(newState, "kpi.sortOrder", preferences?.kpi?.sortOrder ?? settings.kpi?.sortOrder);
    if (!!merged.sortOrder?.length && !merged.sortOrder.includes(newState.kpi!.sortOrder))
        set(newState, "kpi.sortOrder", merged.sortOrder[0]);

    if (!!merged.caseGanttSettings?.length && !merged.caseGanttSettings.includes(settings.gantt?.caseGanttSettings))
        set(newState, "gantt.caseGanttSettings", merged.caseGanttSettings[0]);

    if (!!merged.orientation?.length && !merged.orientation.includes(settings.graph.orientation))
        set(newState, "graph.orientation", merged.orientation[0]);

    return ObjectMerger.mergeObject(settings, newState) as SettingsType;
}

function mergeHelper(a: any[] | undefined, b: any[] | undefined) {
    if (a === undefined || b === undefined)
        return a ?? b;

    return intersection(a, b);
}

function mergeValidValues(a: Partial<ValidValueTypes>, b: Partial<ValidValueTypes>) {
    const result: any = {};
    const props = uniq(Object.getOwnPropertyNames(a).concat(Object.getOwnPropertyNames(b)));

    for (const prop of props)
        result[prop] = mergeHelper(a[prop], b[prop]);

    return result as Partial<ValidValueTypes>;
}