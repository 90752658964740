import React from "react";
import i18n from "../../i18n";
import { ValueSpinner } from "../value-spinner/ValueSpinner";

export type StatsRowBaseProps = {
    isLoading?: boolean;
    label: string;
}

export type StatsRowStringProps = StatsRowBaseProps & {
    isHighlight?: boolean;
    value?: string;
}

export function StatsRowString(props: StatsRowStringProps) {
    return <div className="row">
        <div>
            {i18n.t(props.label)}
        </div>

        <ValueSpinner isLoading={props.isLoading === true} className={props.isHighlight === true ? "highlight" : undefined}>
            {props.value}
        </ValueSpinner>
    </div>;
}
