import React, { useContext } from "react";
import { SessionType } from "../../contexts/SessionContext";
import { SettingsContext, SettingsType } from "../../contexts/SettingsContext";
import { KpiTypes } from "../../models/KpiTypes";
import { DeepPartial, ObjectMerger } from "../../utils/ObjectMerger";
import { getDefaultEnabledComparisons } from "../../utils/SettingsUtils";
import { KpiComparisonControls, getValidKpiComparisonControlSettings } from "./KpiComparisonControls";
import { AggregationControls, BenchmarkingKpiSortBy, getValidAggregationControlSettings, getValidBenchmarkingKpiSortBySettings } from "./KpiControlPrimitives";
import { KpiControls, getValidKpiControlSettings } from "./KpiControls";
import { KpiHighlightControls } from "./HighlightControls";
import { KpiComparisons } from "../../contexts/ContextTypes";

export function getValidBenchmarkingControlSettings(session: SessionType, settings: SettingsType, props: BenchmarkingControlProps): DeepPartial<SettingsType> {
    return ObjectMerger.mergeObjects([
        getValidKpiControlSettings(session, settings, {
            kpis: props.kpis,
            hasYAxis: true,
            addQuantityDropdown: true,
            spotlightArguments: [settings.kpi.aggregation],
            isCaseStatistics: true
        }),
        getValidAggregationControlSettings(session, settings, { showTime: true }),
        getValidKpiComparisonControlSettings(session, settings, {
            enabledComparisons: (session, settings) => getDefaultEnabledComparisons(session, settings)
        }),
        getValidBenchmarkingKpiSortBySettings(session, settings),
    ]);
}

export type BenchmarkingControlProps = {
    /**
     * Check KpiPresets if there is something that suits your needs
     */
    kpis: KpiTypes[];
};

/**
 * This component is used across all dimensions for the benchmarking section.
 */
export default function BenchmarkingControls(props: BenchmarkingControlProps) {

    const settings = useContext(SettingsContext);

    return <div className="controls">

        <KpiControls kpis={props.kpis} hasYAxis={true} addQuantityDropdown={true} spotlightArguments={[settings.kpi.aggregation]} isCaseStatistics={true} />

        <AggregationControls showTime={true} />

        <KpiComparisonControls
            enabledComparisons={(session, settings) => getDefaultEnabledComparisons(session, settings)}
        />

        {settings.kpi.comparisons === KpiComparisons.Planning && <KpiHighlightControls />}

        <BenchmarkingKpiSortBy />

    </div>;
}