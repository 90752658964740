import React, { useContext, useMemo, useState } from "react";
import { SessionContext, SessionType } from "../../contexts/SessionContext";
import { MachineSortedBy, SettingsContext, SettingsType, MatrixComparison, ProductIdentifier } from "../../contexts/SettingsContext";
import { Graph, GroupingKeys, Node, NodeRoles } from "../../models/Dfg";
import { Formatter } from "../../utils/Formatter";
import i18n from "../../i18n";
import Modal from "../../components/modal/Modal";
import Dropdown, { StringOption } from "../../components/dropdown/Dropdown";
import { useGraph } from "../../hooks/UseGraph";
import { getEquipmentNodeKpi } from "../../utils/MainNodeKpi";
import { getCustomKpisDfg, getCustomKpisEquipmentStats } from "../../utils/DfgUtils";
import { useProductCaseAggregations } from "../../hooks/UseProductCaseAggregations";
import { useDeviationGraphs } from "../../hooks/UseGraphDeviation";
import { uniqBy } from "lodash";
import { Alignments } from "../../components/spotlight/Spotlight";
import Spinner from "../../components/spinner/Spinner";
import Matrix, { MatrixHeaderEntry } from "../../components/matrix/Matrix";
import { MatrixCellProps, MatrixCellVariantTypes } from "../../components/matrix/MatrixCell";
import { getAllowedKpis, getKpiDefinition, getUnit } from "../../models/Kpi";
import { KpiTypes, SortOrder, StatisticTypes } from "../../models/KpiTypes";
import { TrayElement } from "../../components/tray/TrayElement";
import { nodeHighlightColorMapDefault } from "../../components/dfg/DfGraph";
import { DfgLegend } from "../../components/dfg/DfgLegend";
import { Legend } from "../../components/graph/Legend";
import colors from "../../colors.json";
import { OrderSequenceMachineIdsRequest, useOrderSequenceMachineIds } from "../../hooks/UseOrderSequenceMachines";
import { SelectionPage } from "../../components/selection-page/SelectionPage";
import { EventKeys } from "../../models/EventKeys";
import { useEquipmentStats } from "../../hooks/UseEquipmentStats";

type Kpi = {
    kpiType: KpiTypes;
    statistic: StatisticTypes;
}

// List of KPIs that are displayed in the comparison
export const defaultKpiList = [
    { kpiType: KpiTypes.ProducedQuantity, statistic: StatisticTypes.Sum },
    { kpiType: KpiTypes.OverallEquipmentEffectiveness, statistic: StatisticTypes.Mean },
    { kpiType: KpiTypes.Availability, statistic: StatisticTypes.Mean },
    { kpiType: KpiTypes.UnitDownTime, statistic: StatisticTypes.Mean },
    { kpiType: KpiTypes.FailureTime, statistic: StatisticTypes.Sum },
    { kpiType: KpiTypes.TechnicalLosses, statistic: StatisticTypes.Mean },
    { kpiType: KpiTypes.OrganizationalLosses, statistic: StatisticTypes.Mean },
    { kpiType: KpiTypes.ProcessLosses, statistic: StatisticTypes.Mean },
    { kpiType: KpiTypes.QualityLosses, statistic: StatisticTypes.Mean },
    { kpiType: KpiTypes.SetupTime, statistic: StatisticTypes.Sum },
    { kpiType: KpiTypes.ProductionTime, statistic: StatisticTypes.Sum },
    { kpiType: KpiTypes.Effectiveness, statistic: StatisticTypes.Mean },
    { kpiType: KpiTypes.CycleTime, statistic: StatisticTypes.Mean },
    { kpiType: KpiTypes.QualityRate, statistic: StatisticTypes.Mean },
    { kpiType: KpiTypes.ScrapRatio, statistic: StatisticTypes.Mean },
];


export function getKpiList(session: SessionType, settings: SettingsType): Kpi[] {
 
    const allKpis = defaultKpiList.map(k => k.kpiType);
    const allowedKpis = getAllowedKpis(session, settings, allKpis) as KpiTypes[];
    const kpis =  defaultKpiList.filter(k => allowedKpis.includes(k.kpiType));

    return kpis;
}

export function EquipmentMatrixView() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const [isWorkplacesConfigModalVisible, setIsWorkplacesConfigModalVisible] = useState<boolean>(false);
    const [isRemoveColumnModalVisible, setIsRemoveColumnModalVisible] = useState<boolean>(false);
    const [selectedColumn, setSelectedColumn] = useState<string | undefined>(undefined);

    const kpiList = getKpiList(session, settings);

    const kpis = kpiList.map(kpiType => kpiType.kpiType);

    const equipmentStatsOptions = { 
        ...getCustomKpisEquipmentStats(settings, session, kpis),
        calculateNodes: true,
        calculateActivityValues: true,
    };

    const graphOptions = { 
        ...getCustomKpisDfg(settings, session, false, kpis),
        calculateNodes: true,
        calculateActivityValues: true,
    };
    // Interruption and failure time are not displayed in the deviation graph
    //change to new fct getCustomKpisDfg
    const graphDeviationOptions = { 
        ...getCustomKpisDfg(settings, session, true, kpis.filter(k => k !== KpiTypes.InterruptionTime && k !== KpiTypes.FailureTime)),
        calculateNodes: true,
    };

    const graph = useGraph({ ...graphOptions, groupingKey: GroupingKeys.Machine });

    const [comparisonData, isDeviationGraphLoading] = useDeviationGraphs({ ...graphDeviationOptions, groupingKey: GroupingKeys.Machine }, undefined, settings.kpiMatrix.comparison === MatrixComparison.None);

    const [equipmentStats, isEquipmentStatsLoading] = useEquipmentStats({
        ...equipmentStatsOptions,
        eventKeys: {
            ...session.project!.eventKeys,
            activityKeysGroup: GroupingKeys.Machine,
        } as EventKeys,
    }, {
        disable: !settings.kpiMatrix.machines ||
            !session.project?.uploadId ||
            !session.project?.eventKeys?.passId,
    });


    const nodeList = getNodes(settings.kpiMatrix.machines, graph);

    const [selectedSortBy, setSelectedSortBy] = useState<string | undefined>(undefined);
    const [selectedItem, setSelectedItem] = useState<string | undefined>(undefined);
    const [productCaseAggregations] = useProductCaseAggregations();

    // Remove OrderSequences from list options if we have more than one product in filter and remove WorkplaceTypes it is undefined in eventKeys
    const showOrderSequences = productCaseAggregations?.products.length === 1;
    const showWorkplaceType = session.project?.eventKeys?.machineType !== undefined;
    const workplaceTranslations = [
        { label: i18n.t("kpiMatrix.workplace"), value: MachineSortedBy.Workplaces },
        { label: i18n.t("kpiMatrix.workplaceType"), value: showWorkplaceType ? MachineSortedBy.WorkplaceTypes : undefined },
        { label: i18n.t("kpiMatrix.orderSequence"), value: showOrderSequences ? MachineSortedBy.OrderSequences : undefined },
    ];
    const workplaceOptions = workplaceTranslations.filter(t => (t.value !== undefined));

    const orderSequenceRequest = useOrderSequenceMachineIds([], undefined, {
        disable: !isWorkplacesConfigModalVisible || !showOrderSequences,
    });

    const isLoading = ((settings.kpiMatrix.machines.length > 0 && graph === undefined) || (settings.kpiMatrix.comparison !== MatrixComparison.None && isDeviationGraphLoading)
    || isEquipmentStatsLoading);

    const itemList = useMemo(() => {
        return getItems(selectedSortBy as MachineSortedBy, showOrderSequences? orderSequenceRequest.machineNameGraph : graph);
    }, [selectedSortBy, orderSequenceRequest.machineNameGraph ?? graph]);

    const { sortedViewData, headerX, headerY } = useMemo(() => {

        const viewData: MatrixCellProps[][] = [];
        let sortedViewData: MatrixCellProps[][] = [];


        for (let y = 0; y < kpiList.length; y++) {
            // Determine per row min/max
            let min: number | undefined = undefined;
            let max: number | undefined = undefined;
            for (let x = 0; x < nodeList.length; x++) {
                if (viewData[x] === undefined)
                    viewData[x] = [];

                const node = nodeList[x];
                const kpi = kpiList[y];

                const kpiValue = getEquipmentNodeKpi(node, equipmentStats?.equipment, settings, session, kpi);

                if (kpiValue?.value === undefined)
                    continue;

                min = Math.min(min ?? kpiValue.value, kpiValue.value);
                max = Math.max(max ?? kpiValue.value, kpiValue.value);
            }

            for (let x = 0; x < nodeList.length; x++) {
                const node = nodeList[x];
                const kpi = kpiList[y];

                const kpiValue = getEquipmentNodeKpi(node, equipmentStats?.equipment, settings, session, kpi);
                const kpiPlanValue = getEquipmentNodeKpi(node, equipmentStats?.planned?.equipment, settings, session, kpi);
                const comparisonKpi = getComparisonKpiFormattedValue(kpi, kpiValue.value, kpiPlanValue.value, kpiPlanValue.formattedValue, settings, session);
                const variantKpi = getVariant(kpiPlanValue.value, kpi, kpiValue.value, settings, session);


                viewData[x][y] = {
                    x,
                    y,
                    title: kpiValue.formattedValue ?? "-",
                    value: kpiValue.value,
                    variant: settings.kpiMatrix.highlightDeviations ? variantKpi as MatrixCellVariantTypes : undefined,
                    info: comparisonKpi,
                    from: { id: node.id, name: node.activityValues?.machine?.value ?? "" },
                    to: kpi.kpiType,
                    color: !settings.kpiMatrix.highlightDeviations || settings.kpiMatrix.comparison !== MatrixComparison.None || kpiValue.value === undefined || min === undefined || max === undefined || ((max ?? 0) - (min ?? 0) === 0) ? undefined :
                        getKpiDefinition(kpi.kpiType, { session, settings })?.isLessBetter ?
                            nodeHighlightColorMapDefault((kpiValue.value - min) / ((max - min) || 1)) :
                            nodeHighlightColorMapDefault(1 - ((kpiValue.value - min) / ((max - min) || 1))),
                };
            }
        }

        const getValue = (arr: MatrixCellProps[]) => {
            const item = arr.find(item => item.to === settings.kpi.selectedKpi);
            return item?.value ?? 0;
        };

        /**
        * Sort the data by the values of the selected KPI, and then change the order of columns by changing the x value
        */

        const sortedValues = viewData.sort((a, b) => getValue(a) - getValue(b));

        const reverseData = settings.kpi.sortOrder === SortOrder.Descending ? sortedValues.reverse() : sortedValues;

        sortedViewData = reverseData.map((row, rowIndex) => {
            row.forEach((item) => {
                item.x = rowIndex;
            });
            return row;
        });

        return {
            sortedViewData,
            headerX: sortedViewData.map((_, idx) => {
                return {
                    title: (sortedViewData[idx][0].from as ProductIdentifier).name,
                    id: (sortedViewData[idx][0].from as ProductIdentifier).id,
                } as MatrixHeaderEntry;
            }),
            headerY: kpiList.map(k => {
                const kpiDef = getKpiDefinition(k.kpiType, { session, settings });
                return {
                    title: i18n.t(kpiDef?.label ?? "").toString(),
                    spotlightId: kpiDef?.spotlightId,
                } as MatrixHeaderEntry;
            }),
        };
    }, [
        nodeList,
        settings.kpiMatrix.machines,
        settings.kpiMatrix.comparison,
        settings.quantity,
        comparisonData,
        session.numberFormatLocale,
        session.locale,
    ]);

    return <div className="fillParentMatrix">
        <Spinner isLoading={isLoading} />
        {!isLoading &&
            <>
                {settings.kpiMatrix.machines.length > 1 &&
                    settings.kpiMatrix.comparison === MatrixComparison.None &&
                    !!settings.kpiMatrix.highlightDeviations && <DfgLegend colormap={nodeHighlightColorMapDefault} leftLabel="common.better" rightLabel="common.worse" style={{ marginTop: -6, marginRight: 10, top: `${colors.$defaultMargin * 3}rem` }} />}

                {settings.kpiMatrix.highlightDeviations && settings.kpiMatrix.machines.length > 0 &&
                    settings.kpiMatrix.comparison !== MatrixComparison.None && <Legend
                    style={{ margin: `${2 * colors.$defaultMargin}rem ${colors.$smallMargin}rem 0 0` }}
                    className="dfgLegendContainer"
                    items={[{
                        color: colors.$setupGraphActualFaster,
                        label: "workflows.planningDeviation.betterThanPlan"
                    }, {
                        color: colors.$setupGraphActualSlower,
                        label: "workflows.planningDeviation.worseThanPlan"
                    }]} />}

                {settings.kpiMatrix.machines.length ?
                    <Matrix
                        alignmentHeaderY={Alignments.Left}
                        title={<div className="title">{i18n.t("kpiMatrix.title").toString()}</div>}
                        headerX={headerX}
                        headerY={headerY}
                        data={sortedViewData}
                        cellWidth={142}
                        cellHeight={40}
                        onClick={() => {
                            if (settings.selection.matrixElement)
                                settings.setSelection({});
                        }}
                        onRemoveClick={(id) => { setSelectedColumn(id); setIsRemoveColumnModalVisible(true); }}
                    /> :
                    <SelectionPage
                        title={i18n.t("kpiMatrix.title").toString()}
                        description={i18n.t("kpiMatrix.noMachineDescription").toString()}
                    />
                }
                <Modal
                    width={500}
                    isVisible={isWorkplacesConfigModalVisible}
                    isClosable={true}
                    canBlur={true}
                    title={i18n.t("kpiMatrix.selectWorkplaces").toString()}
                    showCancelButton={true}
                    onCancel={() => {
                        setSelectedSortBy(undefined);
                        setIsWorkplacesConfigModalVisible(false);
                    }}
                    onDone={async () => {
                        if (selectedSortBy !== undefined && selectedItem !== undefined) {
                            const matrixMachines = getMatrixMachines(selectedSortBy, selectedItem, graph, orderSequenceRequest);
                            settings.mergeSet({
                                kpiMatrix: {
                                    machines: [...new Set([...settings.kpiMatrix.machines, ...matrixMachines])]
                                }
                            });
                        }
                        setSelectedSortBy(undefined);
                        setIsWorkplacesConfigModalVisible(false);
                    }}
                >
                    <>
                        <div className="label mb">{getLabel(showWorkplaceType, showOrderSequences)}</div>
                        <Dropdown
                            isSearchable={true}
                            className="dropdownLight mb"
                            options={workplaceOptions}
                            value={workplaceOptions.find(o => o.value === selectedSortBy) ?? {
                                label: i18n.t("common.pleaseSelect"),
                                value: ""
                            }}
                            onChange={(option) => {
                                setSelectedSortBy(option!.value as string);
                                setSelectedItem(undefined);
                            }}
                        />
                        {selectedSortBy !== undefined && <div>
                            <div className="label mb">{i18n.t(`kpiMatrix.${selectedSortBy}`)}</div>
                            <Dropdown
                                isSearchable={true}
                                className="dropdownLight"
                                options={itemList}
                                value={itemList.find(i => i.value === selectedItem) ?? {
                                    label: i18n.t((showOrderSequences? orderSequenceRequest.isLoading : graph?.nodes === undefined) ? "common.initializing" : "common.pleaseSelect"),
                                    value: ""
                                }}
                                onChange={(option) => {
                                    setSelectedItem(option!.value as string);
                                }}
                            /></div>}
                    </>
                </Modal>

                <Modal
                    width={500}
                    isVisible={isRemoveColumnModalVisible}
                    isClosable={true}
                    canBlur={true}
                    title={i18n.t("kpiMatrix.removeModalTitle").toString()}
                    showCancelButton={true}
                    onCancel={() => {
                        setSelectedColumn(undefined);
                        setIsRemoveColumnModalVisible(false);
                    }}
                    onDone={async () => {
                        settings.mergeSet({ kpiMatrix: { machines: settings.kpiMatrix.machines.filter(m => m !== selectedColumn) } });
                        setIsRemoveColumnModalVisible(false);
                    }}
                >
                    <div className="label">{i18n.t("kpiMatrix.removeMatrixColumn")}</div>
                </Modal>

                <div className="bottomLeft">
                    <button
                        className="shortcutButton"
                        onClick={(e) => {
                            setIsWorkplacesConfigModalVisible(true);
                            e.preventDefault();
                            e.stopPropagation();
                        }
                        }>
                        {i18n.t("kpiMatrix.addNewWorkplace")}
                    </button>
                    {nodeList.length > 0 && <TrayElement>
                        <button
                            style={{ order: -1002 }}
                            className="shortcutButton"
                            onClick={() => {
                                settings.mergeSet({
                                    kpiMatrix: {
                                        machines: []
                                    }
                                });
                            }
                            }>
                            {i18n.t("kpiMatrix.startNewComparison").toString()}
                        </button>
                    </TrayElement>}
                </div>
            </>
        }
    </div>;
}

/**
 * Get second dropdown list, which can be machines, machine types or order sequences lists
 */
function getItems(selectedSortBy: MachineSortedBy, graph: Graph | undefined) {
    let options: { label: string; value: string }[] = [];

    switch (selectedSortBy) {

        case MachineSortedBy.Workplaces:
            options = (graph?.nodes.filter(n => n.activityValues?.machine?.value !== undefined && n.role !== NodeRoles.Inventory) ?? []).map(n => ({
                label: n.activityValues?.machine?.value ?? "",
                value: n.activityValues?.machine?.value ?? ""
            })).sort((a, b) => a.label.localeCompare(b.label));
            break;

        case MachineSortedBy.WorkplaceTypes:
            options = (graph?.nodes.filter(n => n.activityValues?.machineType?.value !== undefined && n.role !== NodeRoles.Inventory) ?? []).map(n => ({
                label: n.activityValues?.machineType?.value ?? "",
                value: n.activityValues?.machineType?.value ?? ""
            })).sort((a, b) => a.label.localeCompare(b.label));
            break;

        case MachineSortedBy.OrderSequences:
            options = (graph?.nodes.filter(n => n.activityValues?.passId?.value !== undefined && n.role !== NodeRoles.Inventory) ?? []).map(n => ({
                label: n.activityValues?.passId?.value ?? "",
                value: n.activityValues?.passId?.value ?? ""
            })).sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true }));
            break;
    }

    return uniqBy(options, (o) => o.label) as StringOption[];
}

/**
 * Get list of machines (based on selected options from first and second dropdown) that will 
 * be displayed as matrix header columns
 */
function getMatrixMachines(selectedSortBy: string, selectedItem: string, graph: Graph | undefined, orderSequenceRequest: OrderSequenceMachineIdsRequest | undefined) {
    let options: (string | undefined)[] = [];

    switch (selectedSortBy) {
        case MachineSortedBy.Workplaces:
            options = (graph?.nodes ?? []).filter(n => n.activityValues?.machine?.value === selectedItem)?.map(n => n.id);
            break;
        case MachineSortedBy.WorkplaceTypes:
            options = (graph?.nodes ?? []).filter(n => n.activityValues?.machineType?.value === selectedItem && n.role !== NodeRoles.Inventory)?.map(n => n.id);
            break;
        case MachineSortedBy.OrderSequences: {
            const passIdMachineNames = (orderSequenceRequest?.machineNameGraph?.nodes ?? []).filter(n =>
                n.activityValues?.passId?.value === selectedItem &&
                n.role !== NodeRoles.Inventory)?.map(n => n.activityValues?.machine?.value).filter(n => n !== undefined) as string[];

            options = passIdMachineNames.map(name => orderSequenceRequest?.machineIdGraph?.nodes.find(n => n.activityValues?.machine?.value === name)?.id).filter(n => n !== undefined) as string[];
            break;
        }
    }
    const machines = options.filter(value => value !== undefined);
    return machines as string[];
}

function getNodes(machines: (string | undefined)[], graph: Graph | undefined) {
    const nodes: Node[] = [];

    for (const machine of machines) {
        const node = graph?.nodes?.find(n => n?.id === machine);
        if (node)
            nodes.push(node);
    }

    return nodes;
}

/**
 * Prepare the comparison kpi formatted values
 */
function getComparisonKpiFormattedValue(kpi: Kpi, kpiValue: number | undefined, kpiPlanValue: number | undefined, kpiPlanValueFormatted: string | undefined, settings: SettingsType, session: SessionType) {

    if (kpiValue === undefined || kpiPlanValue === undefined)
        return undefined;

    const getSign = (value: number) => {
        return (value > 0) ? "+" : "";
    };

    const kpiDefinition = getKpiDefinition(kpi.kpiType, { session, settings });
    const unit = getUnit(kpiDefinition?.unit, kpi.statistic);

    switch (settings.kpiMatrix.comparison) {

        case MatrixComparison.None: {
            return undefined;
        }

        case MatrixComparison.Planning: {
            return kpiPlanValueFormatted;
        }

        case MatrixComparison.AbsoluteDeviation: {
            const value = kpiValue - kpiPlanValue;
            return unit?.formatter(value, { locale: session.numberFormatLocale, baseQuantity: settings.quantity });
        }

        case MatrixComparison.RelativeDeviation: {
            const value = kpiValue - kpiPlanValue;

            if (!!value && !!kpiPlanValue) {
                const relative = (value / kpiPlanValue);
                return getSign(relative) + Formatter.formatPercent(relative, 1, 1, session.numberFormatLocale);
            }

            return undefined;
        }

    }

}

function getLabel(showWorkplaceType: boolean, showOrderSequences: boolean) {

    if (!showOrderSequences && showWorkplaceType)
        return i18n.t("kpiMatrix.sortedByAllTypes");

    else if (showWorkplaceType && showOrderSequences)
        return i18n.t("kpiMatrix.sortedByType");

    else if (!showWorkplaceType && showOrderSequences)
        return i18n.t("kpiMatrix.sortedByOrderSequence");

    return i18n.t("kpiMatrix.sortedBySingle");
}

function getVariant(kpiPlannedValue: number | undefined, kpi: Kpi, kpiValue: number | undefined, settings: SettingsType, session: SessionType) {

    const kpiDefinition = getKpiDefinition(kpi.kpiType, { session, settings });
    if (kpiPlannedValue === undefined || kpiValue === undefined || settings.kpiMatrix.comparison === MatrixComparison.None || kpiDefinition?.isLessBetter === undefined)
        return "default";

    if ((kpiDefinition?.isLessBetter && kpiValue <= kpiPlannedValue) ||
        (!kpiDefinition?.isLessBetter && kpiValue >= kpiPlannedValue))
        return "green";

    return "red";
}
