import { useContext } from "react";
import { disableAllCalcOptions, PerTimeperiodDeviationStatisticsParams, PerTimeperiodDeviationStatisticsSchema } from "../models/ApiTypes";
import { SessionContext } from "../contexts/SessionContext";
import { SettingsContext } from "../contexts/SettingsContext";
import { EventKeys } from "../models/EventKeys";
import { Datastores } from "../utils/Datastores";
import { ApiHookOptions, useApi } from "./UseApi";


export function useDeviationTimeperiodStatistics(request: Partial<PerTimeperiodDeviationStatisticsParams>, options?: ApiHookOptions<PerTimeperiodDeviationStatisticsSchema> & {
    addEnergyStats?: boolean;
}):
    [PerTimeperiodDeviationStatisticsSchema | undefined, boolean] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    // It's of course difficult to get deviation data when no planning data is available. However,
    // e.g. the TimeProcessKpiChart uses this hook to access time series data (and if available, also deviations
    // over time). So if no planning data is around, we're still interested in keeping the API quiet.
    const hasPlanningData = (request?.planned?.uploadId ?? session.project?.uploadIdPlan) !== undefined;

    const requestOptions: PerTimeperiodDeviationStatisticsParams = {
        ...disableAllCalcOptions,
        ...request,
        actual: {
            uploadId: request?.actual?.uploadId ?? session.project?.uploadId ?? "",
            eventKeys: request?.actual?.eventKeys ?? session.project?.eventKeys ?? { } as EventKeys,
            eventFilters: request?.actual?.eventFilters ?? settings.previewFilters ?? settings.filters,
            uploads: session.project?.uploads,
        },
        planned: {
            uploadId: request?.planned?.uploadId ?? session.project?.uploadIdPlan ?? request?.actual?.uploadId ?? session.project?.uploadId ?? "",
            eventKeys: hasPlanningData ? (request?.planned?.eventKeys ?? session.project?.eventKeysPlan ?? { }) as EventKeys :
                (request?.actual?.eventKeys ?? session.project?.eventKeys ?? { }) as EventKeys,
        },
        tz: request?.tz ?? session.timezone ?? "UTC",
    };

    return useApi(Datastores.getDeviationTimeperiodStatistics, requestOptions, [ JSON.stringify(requestOptions) ], {
        ...options,
        disable: options?.disable || !requestOptions.actual.uploadId,
    });
}