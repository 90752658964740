import React, { useContext } from "react";
import { SessionContext } from "../../contexts/SessionContext";
import { Formatter, FormatterParams, UnitMetadata, unitToSigned } from "../../utils/Formatter";
import { StatsRowBaseProps, StatsRowString } from "./StatsRowString";
import { SettingsContext } from "../../contexts/SettingsContext";

export type StatsRowValueProps = StatsRowBaseProps & {
    value?: number;
    isHighlight?: boolean;
    unit?: UnitMetadata;
    formatterParams?: FormatterParams;
}

export function StatsRowValue(props: StatsRowValueProps) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    if (!props.isLoading && props.value === undefined)
        return null;

    const unit = props.unit ?? Formatter.defaultUnit;
    const renderedValue = unit.formatter(props.value, {
        locale: session.numberFormatLocale,
        baseQuantity: settings.quantity,
        ...props.formatterParams,
    });

    return <StatsRowString {...props} value={renderedValue} />;
}

export function StatsRowValueSigned(props: StatsRowValueProps) {
    const session = useContext(SessionContext);

    const unit = unitToSigned(props.unit ?? Formatter.defaultUnit);
    const renderedValue = unit?.formatter(props.value, {
        locale: session.numberFormatLocale,
        ...props.formatterParams,
    });

    return <StatsRowString {...props} value={renderedValue !== "" ? renderedValue : "-"} />;
}