import { get } from "lodash";
import React, { useContext, useEffect } from "react";
import { SessionContext } from "../../../contexts/SessionContext";
import { SettingsContext } from "../../../contexts/SettingsContext";
import { filterProduct } from "../../../hooks/UseProductCaseAggregations";
import { useProductStats } from "../../../hooks/UseProductStats";
import i18n from "../../../i18n";
import { KpiDefinition, getCustomKpiParameters, getKpiDefinition } from "../../../models/Kpi";
import AggregatedCaseStats from "./AggregatedCaseStats";
import { KpiComparisons } from "../../../contexts/ContextTypes";
import { KpiTypes } from "../../../models/KpiTypes";
import { getDefaultValueProp, getSort } from "../../../components/product-chart/ProductChart";
import { defaultProductLimit } from "../../../Global";

type ProductStatsProps = {
    kpiTypes: KpiTypes[];
    hasBusyTimeSubStats?: boolean;
    disableProductFilter?: boolean;
}

export default function ProductStats(props: ProductStatsProps) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const isPlanningComparison = settings.kpi.comparisons === KpiComparisons.Planning;

    const kpiDefinitions = props.kpiTypes.map(k => getKpiDefinition(k, { session, settings })).filter(k => k !== undefined) as KpiDefinition[];

    const kpiParams = getCustomKpiParameters(props.kpiTypes, settings, session, props.hasBusyTimeSubStats);

    const [productCaseAggregations, productDeviationAggregations, isLoading] = useProductStats(
        isPlanningComparison,
        {
            ...(props.disableProductFilter ? {} : filterProduct(settings.selection.product, session, settings)),
            ...kpiParams,
            sort: getSort(session, settings, isPlanningComparison, getDefaultValueProp),
            limit: defaultProductLimit,
        }, {
            disable: settings.selection.product === undefined
        });

    const selectedProduct = isPlanningComparison ? (productDeviationAggregations?.products ?? []).find(p => p.id === settings.selection.product?.id)?.actual : (productCaseAggregations?.products ?? []).find(p => p.id === settings.selection.product?.id);
    const selectedProductPlan = isPlanningComparison ? (productDeviationAggregations?.products ?? []).find(p => p.id === settings.selection.product?.id)?.planned : undefined;

    const productName = isPlanningComparison ? (productDeviationAggregations?.products ?? []).find(p => p.id === settings.selection.product?.id)?.name : get(selectedProduct, "name");
    const count = isPlanningComparison ? (productDeviationAggregations?.products ?? []).find(p => p.id === settings.selection.product?.id)?.caseCount : get(selectedProduct, "count");

    useEffect(() => {
        const isSelectionMissingInCases = productCaseAggregations !== undefined && (productCaseAggregations.products.length === 0 || productCaseAggregations.products.some(p => p.id === settings.selection.product?.id || p.name === settings.selection.product?.name));
        const isSelectionMissingInDeviation = productDeviationAggregations !== undefined && (productDeviationAggregations.products.length === 0 || productDeviationAggregations.products.some(p => p.id === settings.selection.product?.id || p.name === settings.selection.product?.name));

        if (!isLoading && (isSelectionMissingInCases || isSelectionMissingInDeviation) && selectedProduct === undefined) {
            settings.setSelection({ product: undefined });
        }
    }, [selectedProduct, isLoading, productCaseAggregations, productDeviationAggregations]);

    if (!settings.selection.product)
        return null;

    return <AggregatedCaseStats
        title={`${i18n.t("common.product")} ${productName}`}
        kpiDefinitions={kpiDefinitions}
        count={count}
        isLoading={isLoading}
        selection={selectedProduct}
        selectionPlan={selectedProductPlan}
        hasBusyTimeSubStats={props.hasBusyTimeSubStats}
    />;

}
