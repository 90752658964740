import React, { useContext } from "react";
import Global from "../../Global";
import { TaktTimeControls, getValidTaktTimeControlSettings } from "../../components/controls/TaktTimeControls";
import { NodeKpiChart } from "../../components/kpi-chart/NodeKpiChart";
import { NodeVarianceChart } from "../../components/kpi-chart/NodeVarianceChart";
import SideStatisticsValueStream from "../../components/side-statistics/SideStatisticsValueStream";
import { TabbedView, TabbedViewType } from "../../components/tabbed-view/TabbedView";
import { BackButtonTrayElement } from "../../components/tray/BackButtonTrayElement";
import { AggregationTypes } from "../../contexts/ContextTypes";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext, SortByType } from "../../contexts/SettingsContext";
import { AnalysisType } from "../../hooks/UseGraph";
import { GroupingKeys } from "../../models/Dfg";
import { KpiTypes, SortOrder, StatisticTypes } from "../../models/KpiTypes";
import { getEnabledComparisonsValueStream } from "../../utils/DfgUtils";
import { viewSettingsInitialization } from "../../utils/Initializers";
import { ObjectMerger } from "../../utils/ObjectMerger";
import { QuantityType, getAssignedQuantities } from "../../utils/Quantities";
import { ViewSubtitle } from "../../components/tabbed-view/ViewSubtitle";

export function CycleTimeView() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const quantities = getAssignedQuantities(session.project?.eventKeys, QuantityType.Yield, false);
    const baseQuantities = quantities.map(q => q.baseQuantity);

    // We explicitely want to set the grouping to PassValueStream or MachineValueStream as defaults here.
    // Except we are running in storybook where we also want to test other groupings.
    const allowedDefaultGroupingKeys = (Global.isRunningStorybook || Global.isRunningJestTest) ?
        [GroupingKeys.PassValueStream, GroupingKeys.MachineValueStream, GroupingKeys.MachineObjectTypeValueStream, GroupingKeys.MachineObjectType, GroupingKeys.ObjectTypeValueStream, GroupingKeys.ObjectType] :
        [GroupingKeys.PassValueStream, GroupingKeys.MachineValueStream];

    const views: TabbedViewType[] = [{
        tabTitle: "workflows.taktTime.title",
        tabSlug: "summary",
        breadcrumbs: [{ label: "common.analyses", url: `/projects/${session.projectId}/analyses` }, { label: "workflows.taktTime.title" }],
        subtitle: <ViewSubtitle />,
        selectionTrigger: (settings) => {
            return settings.selection.node !== undefined;
        },
        controller: <TaktTimeControls />,
        stats: <SideStatisticsValueStream makePassIdFirst={true} />,
        activator: (preferences) => {
            const temp = viewSettingsInitialization(session, settings, preferences, {
                quantities: baseQuantities,
                sortBy: [SortByType.OrderSequences],
                sortOrder: [SortOrder.Ascending],
                comparisons: getEnabledComparisonsValueStream(session, settings, true),
                kpis: [KpiTypes.CycleTime],
                groupingKeys: allowedDefaultGroupingKeys,
                aggregationTypes: [AggregationTypes.Product],
                // We should be defaulting to mean here because it is the only value that makes sense in order sequence grouping.
                // In tests however we also would like to test other configurations and therefore we allow more statistics.
                statistics: (Global.isRunningStorybook || Global.isRunningJestTest) ? [StatisticTypes.Mean, StatisticTypes.Median, StatisticTypes.Variance] : [StatisticTypes.Mean],
            });

            if (!temp)
                return;

            settings.mergeSet(ObjectMerger.mergeObject(temp, getValidTaktTimeControlSettings(session, temp)));

        },
        spotlightId: "TaktTimes",
        content: <>
            {settings.kpi.statistic === StatisticTypes.Variance ? <NodeVarianceChart title={"kpi.dashboardTitles.taktTimes"} analysisType={AnalysisType.ValueStream} noDataPlaceholder="" className="fillParentAnalyses" pageSlug="output" />
                : <NodeKpiChart title={"kpi.dashboardTitles.taktTimes"} analysisType={AnalysisType.ValueStream} noDataPlaceholder="" className="fillParentAnalyses" pageSlug="output" />}
            <BackButtonTrayElement />
        </>,
    }];

    return <TabbedView
        pages={views} />;
}
