/**
 * Convenience hook that pulls case-aggregated timeperiod data from the API,
 * with or without planning data, in a unified interface. It's like the API
 * we wished to have!
 * 
 * if you'd like to have planning data, we'll hit
 * api/processmining/deviations/statistics/cases/aggregations/timeperiods
 * just as the useDeviationTimeperiodStatistics hook does,
 * otherwise we'll use api/processmining/statistics/cases/aggregations/timeperiods
 * (like useTimeAggregatedCaseStatistics).
 */

import React, { useContext, useEffect, useState } from "react";
import { ApiPaginationOptions, CustomKpi, PerTimeperiodStatisticsSchema, PerTimeperiodDeviationStatisticsParams, PerTimeperiodDeviationStatisticsSchema, PerTimeperiodStatisticsParams, SubTimeOptions, TimePeriodFrequencies } from "../models/ApiTypes";
import { SessionContext } from "../contexts/SessionContext";
import { SettingsContext } from "../contexts/SettingsContext";
import { EventFilter } from "../models/EventFilter";
import { EventKeys } from "../models/EventKeys";
import { Datastores } from "../utils/Datastores";
import { groupSupportsConsolidatePasses } from "../utils/GroupingUtils";
import { ApiHookOptions } from "./UseApi";
import { disableAllCalcOptions  } from "../models/ApiTypes";
import { useMountedState } from "./UseMounted";
import { ignoreCancelledRequest } from "../api/Api";
import { SmartApiCache } from "../utils/SmartApiCache";
import { TimeperiodApis } from "../models/Kpi";

export type AggregatedTimeperiodOptions = SubTimeOptions & ApiPaginationOptions & {
    calculateOutputStats?: boolean;
    calculateEnergyStats?: boolean;
    calculateUnknownStats?: boolean;
    calculateBusyStats?: boolean;
    calculatePassStats?: boolean;
    calculateTimeAndFreqStats?: boolean;
    frequency?: TimePeriodFrequencies;
    eventFilters?: EventFilter[];
    customKpis?: CustomKpi[];
    tz?: string;
}

export function useAggregatedTimeperiods(request: Partial<AggregatedTimeperiodOptions>, api: TimeperiodApis, options?: ApiHookOptions<PerTimeperiodDeviationStatisticsSchema> & {
    addEnergyStats?: boolean;
}):
    [PerTimeperiodDeviationStatisticsSchema | undefined, boolean] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    // Subscription management
    const [subscriptionIdDeviation] = useState(() => { return Datastores.getDeviationTimeperiodStatistics.getSubscriptionId(); });
    const [subscriptionIdCases] = useState(() => { return Datastores.getTimeAggregatedCaseStatistics.getSubscriptionId(); });
    const [subscriptionIdEvents] = useState(() => { return Datastores.getTimeAggregatedEventStatistics.getSubscriptionId(); });

    useEffect(() => {
        return () => {
            Datastores.getDeviationTimeperiodStatistics.cancelSubscription(subscriptionIdDeviation);
            Datastores.getTimeAggregatedCaseStatistics.cancelSubscription(subscriptionIdCases);
            Datastores.getTimeAggregatedEventStatistics.cancelSubscription(subscriptionIdEvents);
        };
    }, []);

    const numRequests = React.useRef(0);
    const [isLoading, setIsLoading] = useState<number>(0);
    const [data, setData] = useState<PerTimeperiodDeviationStatisticsSchema | undefined>(undefined);

    const isMounted = useMountedState();

    const filters = request.eventFilters ?? settings.previewFilters ?? settings.filters;

    useEffect(() => {
        if (options?.disable)
            return;

        if (api === TimeperiodApis.CaseDeviation && session.project?.uploadIdPlan !== undefined)
            handlePlanningRequest();
        
        if (api === TimeperiodApis.Case)
            handleRequest(Datastores.getTimeAggregatedCaseStatistics, subscriptionIdCases);

        if (api === TimeperiodApis.Event)
            handleRequest(Datastores.getTimeAggregatedEventStatistics, subscriptionIdEvents);
    }, [
        JSON.stringify(request),
        JSON.stringify(filters),
        session.project,
        api,
        options?.disable,
        options?.addEnergyStats,
    ]);

    return [data, (isLoading > 0) && !options?.disable];


    // Handles a request with planning data and assumes, that planning data exists
    // in this project.
    function handlePlanningRequest() {
        Datastores.getDeviationTimeperiodStatistics.cancelSubscription(subscriptionIdDeviation);

        numRequests.current++;
        setIsLoading(numRequests.current);

        const requestOptions: PerTimeperiodDeviationStatisticsParams = {
            ...disableAllCalcOptions,
            ...request,
            actual: {
                uploadId: session.project?.uploadId ?? "",
                eventKeys: session.project?.eventKeys ?? {} as EventKeys,
                eventFilters: request.eventFilters ?? settings.previewFilters ?? settings.filters,
                uploads: session.project?.uploads,
            },
            planned: {
                uploadId: session.project?.uploadIdPlan ?? "",
                eventKeys: session.project?.eventKeysPlan ?? {} as EventKeys,
            },
            frequency: request.frequency,
            tz: request?.tz ?? session.timezone ?? "UTC",
        };

        Datastores.getDeviationTimeperiodStatistics.get(requestOptions, subscriptionIdDeviation).then((data) => {
            if (isMounted()) {
                const state = data;
                setData(state);

                if (options?.onData)
                    options.onData(state);
            }
        }).catch(ignoreCancelledRequest).finally(() => {
            numRequests.current--;
            if (isMounted())
                setIsLoading(numRequests.current);
        });
    }

    function handleRequest(ds: SmartApiCache<PerTimeperiodStatisticsSchema, PerTimeperiodStatisticsParams>, subscriptionId: number) {
        ds.cancelSubscription(subscriptionId);

        const requestOptions: PerTimeperiodStatisticsParams = {
            ...request,
            eventFilters: request.eventFilters ?? settings.previewFilters ?? settings.filters,
            eventKeys: session.project?.eventKeys ?? {} as EventKeys,
            uploadId: session.project?.uploadId ?? "",
            frequency: request.frequency ?? TimePeriodFrequencies.Month,
            tz: request.tz ?? session.timezone ?? "UTC",
            consolidatePasses: groupSupportsConsolidatePasses(session.project?.eventKeys?.activityKeysGroup),
            uploads: session.project?.uploads,
        };

        numRequests.current++;
        setIsLoading(numRequests.current);

        ds.get(requestOptions, subscriptionId).then((response) => {
            const data = {
                timeperiods: response.timeperiods.map(t => {
                    return {
                        actual: t,
                        timeperiodStartTime: t.timeperiodStartTime,
                        caseCount: t.count,
                    };
                }),
                frequency: response.frequency,
                log: {
                    timeperiodCount: response.timeperiods.length ?? 0,
                }
            };

            if (isMounted()) {
                setData(data);
                if (options?.onData)
                    options.onData(data);
            }
        }).catch(ignoreCancelledRequest).finally(() => {
            numRequests.current--;
            if (isMounted())
                setIsLoading(numRequests.current);
        });
    }
}