import { max, min, sum } from "lodash";
import React, { useContext } from "react";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { Formatter } from "../../utils/Formatter";
import { useSetupMatrixActualAndPlanned } from "../../views/setup/SetupMatrix";
import { StatsRowValue } from "../stats-section/StatsRowValue";
import { StatsSection, StatsSubSection } from "../stats-section/StatsSection";
import { NodeMatrixSchema } from "../../models/ApiTypes";
import { StatisticTypes } from "../../models/KpiTypes";

export function MatrixCellStats() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const [data, isDataLoading, comparisonData, isComparisonDataLoading] = useSetupMatrixActualAndPlanned(session, settings);

    const element = settings.selection.matrixElement;
    if (!element) {
        const count = sum(data?.transitions.map(t => t.frequencyStatistics.sum));
        const comparisonCount = sum(comparisonData?.transitions.map(t => t.frequencyStatistics.sum));

        return getMatrixStats({
            title: "common.allSetups",
            isLoading: isDataLoading || isComparisonDataLoading,
            count,
            max: max(data?.transitions.map(t => t.timeStatistics.max)),
            min: min(data?.transitions.map(t => t.timeStatistics.min)),
            mean: sum(data?.transitions.map(t => 
                t.timeStatistics.mean === undefined || t.frequencyStatistics.sum === undefined ? 
                    undefined : 
                    t.timeStatistics.mean * t.frequencyStatistics.sum)) / count,
            median: undefined,
            comparisonMean: sum(comparisonData?.transitions.map(t => 
                t.timeStatistics.mean === undefined || t.frequencyStatistics.sum === undefined ? 
                    undefined : 
                    t.timeStatistics.mean * t.frequencyStatistics.sum)) / comparisonCount,
        }, StatisticTypes.Mean);
    }

    const from = element.from as NodeMatrixSchema;
    const to = element.to as NodeMatrixSchema;
    const title = `${from?.activityValues?.product?.value} ➞ ${to?.activityValues?.product?.value}`;
    return getMatrixStats({
        title,
        isLoading: false,
        count: element.transition?.frequencyStatistics.sum,
        mean: element.transition?.timeStatistics.mean,
        median: element.transition?.timeStatistics.median,
        max: element.transition?.timeStatistics.max,
        min: element.transition?.timeStatistics.min,
        comparisonMean: element.comparisonTransition?.timeStatistics.mean,
    }, settings.kpi.statistic);
}

function getMatrixStats(data: {
    title: string,
    count: number | undefined,
    isLoading: boolean | undefined,
    max: number | undefined,
    min: number | undefined,
    mean: number | undefined,
    median: number | undefined,
    comparisonMean: number | undefined,
}, selectedStatistic: StatisticTypes) {
    return <StatsSection title={data.title}>
        <StatsSubSection title="common.setup">
            <StatsRowValue label="common.actionFrequency" value={data.count} isLoading={data.isLoading} />
            <StatsRowValue label="common.mean" value={data.mean} unit={Formatter.units.durationShort} isHighlight={selectedStatistic === StatisticTypes.Mean} isLoading={data.isLoading} />
            {data.median !== undefined && <StatsRowValue label="common.statistics.median" value={data.median} unit={Formatter.units.durationShort} isHighlight={selectedStatistic === StatisticTypes.Median} isLoading={data.isLoading} />}
            <StatsRowValue label="common.statistics.max" value={data.max} unit={Formatter.units.durationShort} isLoading={data.isLoading} />
            <StatsRowValue label="common.statistics.min" value={data.min} unit={Formatter.units.durationShort} isLoading={data.isLoading} />
        </StatsSubSection>

        {data.comparisonMean !== undefined && isFinite(data.comparisonMean) && <StatsSubSection title="common.plannedSetupTime">
            <StatsRowValue label="common.mean" value={data.comparisonMean} unit={Formatter.units.durationShort} isHighlight={true} isLoading={data.isLoading} />
        </StatsSubSection>} 
    </StatsSection>;
}