import { useContext, useEffect, useState } from "react";
import { SessionContext } from "../contexts/SessionContext";
import { SettingsContext } from "../contexts/SettingsContext";
import { AnalysisType, analysisGraphMapping } from "./UseGraph";
import { useCaseStatistics } from "./UseCaseStatistics";
import { useStatistics } from "./UseStatistics";
import { Formatter } from "../utils/Formatter";
import { getCaseAggregatedYieldStatistics } from "../utils/CaseStats";
import i18n from "../i18n";


export type ProductionTakt = {
    label: string,
    value: number
}

export function useCustomerTakt(disable = false) {

    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const [state, setState] = useState<ProductionTakt>();
    const analysisArguments = analysisGraphMapping.find((a) => a.analysisType === AnalysisType.ValueStream)?.arguments;

    const [caseStatistics, isCaseStatisticsLoading] = useCaseStatistics({...analysisArguments, limit: 0}, {disable});
    const [statistics] = useStatistics(undefined, {disable});

    const baseQuantity = settings.quantity;
    const unit = Formatter.units.timePerYield;

    useEffect(() => {
        if (isCaseStatisticsLoading || disable ||
            !caseStatistics || caseStatistics.log === undefined ||
            !statistics || !statistics.maxDate || !statistics.minDate)
            return;
        const aggregatedYield = getCaseAggregatedYieldStatistics(caseStatistics?.log, baseQuantity)?.sum;
        const logDuration = (statistics.maxDate.getTime() - statistics.minDate.getTime()) / 1000;
        if (aggregatedYield && aggregatedYield > 0) {
            const productionTakt = logDuration / aggregatedYield;
            setState({value: productionTakt, label: `${i18n.t("workflows.valueStream.customerTakt")} ${unit.formatter(productionTakt, {baseQuantity, locale: session.locale})}`});
        }
    }, [
        settings.kpi.showCustomerTakt,
        caseStatistics,
        statistics,
        baseQuantity,
        session.locale
    ]);

    return state;
}
