import sum from "hash-sum";

/**
 * Checks if the given value is a promise
 * @param value The value to check
 * @returns true if the value is a promise, false otherwise
 */
export function isPromise<T>(value: unknown): value is Promise<T> {
    return (
        typeof value === "object" &&
        value !== null &&
        typeof (value as Promise<T>).then === "function"
    );
}

/**
 * Generates a random UUID
 * @returns A random UUID
 */
export function randomUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0;
        const v = c == "x" ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function buildControllerSpotlightId(pathname: string, suffixes: string[]) {
    return buildSpotlightId(pathname, ["Controller", ...suffixes]);
}

export function buildSpotlightId(pathname: string, suffixes: string[]) {
    const groups = pathname.split("/");
    if (groups.length < 3)
        return undefined;

    const parts: string[] = [];
    let skip = Number.MAX_SAFE_INTEGER;
    for (let i=0; i<groups.length; i++) {
        // use everything after "projects"
        if (groups[i]?.toLowerCase() === "projects") {
            skip = 1;
            continue;
        }

        skip--;

        if (skip >= 0)
            continue;

        parts.push(groups[i]);
    }

    return [...parts, ...suffixes].filter(p => p?.length > 0).map(s => capitalizeFirst(s)!).join("-");
}

/**
 * Simple hash function. This is handy when e.g. comparing large objects for identity during debugging.
 * @param content the object to calculate the hash from
 * @returns hash value
 */
export function getHash(content: any | undefined) {
    if (content === undefined)
        return "";

    return sum(content);
}

/**
 * Helper method that logs the time the provided function takes.
 */
export function perf<R>(func: () => R, label: string | undefined = undefined) {
    const started = new Date().getTime();

    const result = func();

    const duration = new Date().getTime() - started;

    // eslint-disable-next-line no-console
    console.log(`${label ?? "function"} finished in ${duration}ms`);

    return result;
}

export function capitalizeFirst(val: string | undefined) {
    if (!val)
        return val;

    if (val.length === 1)
        return val.toUpperCase();

    return val.charAt(0).toUpperCase() + val.slice(1);
}

/**
 * Helper method for touch gestures. Calculates the center point in case you have
 * multiple touches.
 * @param touchList TouchList from your friendly React.TouchEventHandler instance
 * @returns center point as { x, y }
 */
export function getTouchCenter(touchList: React.TouchList) {
    if (touchList.length === 0)
        return undefined;

    const sum = { x: 0, y: 0 };
    for (let i = 0; i < touchList.length; i++) {
        sum.x += touchList.item(i).clientX;
        sum.y += touchList.item(i).clientY;
    }

    return {
        clientX: sum.x / touchList.length,
        clientY: sum.y / touchList.length,
    };
}

/**
 * Downloads the data provided as a file with the MIME type specified
 * @param data Data to download
 * @param fileName Filename
 * @param mimeType MIME type
 */
export function saveToFile(data: string, fileName: string, mimeType: string) {
    const blob = new Blob([data], { type: mimeType });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
}

export function classNames(names: (string | undefined | boolean)[]) {
    return names.map(n => !!n && n !== true ? n.trim() : "").filter(n => !!n).join(" ");
}
