import React, { useContext, useState } from "react";
import { StatsLineGraph } from "./StatsLineGraph";
import { SettingsContext } from "../../contexts/SettingsContext";
import { SessionContext } from "../../contexts/SessionContext";
import { TimePeriodFrequencies } from "../../models/ApiTypes";
import { useEdgeAggregationTimeperiods } from "../../hooks/UseEdgeAggregationTimeperiods";
import { EventKeys } from "../../models/EventKeys";
import { getCustomKpisDfg } from "../../utils/DfgUtils";
import { get, isObject } from "lodash";
import { useLineGraphData } from "./LineGraph";
import { KpiDefinition, buildKpiSpotlightId, getKpiDefinition } from "../../models/Kpi";
import { StatisticTypes } from "../../models/KpiTypes";

export default function EdgeStatsLineGraph() {

    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const [chartFrequency, setChartFrequency] = useState<TimePeriodFrequencies | undefined>(undefined);
    const graphOptions = getCustomKpisDfg(settings, session, false);
    const [edgeData, isEdgeDataLoading] = useEdgeAggregationTimeperiods({
        ...graphOptions,
        frequency: chartFrequency,
        eventKeys: {
            ...session.project?.eventKeys ?? {},
            activityKeysGroup: settings.groupingKey,
        } as EventKeys,
        edges: [{
            from: settings.selection.edge?.from ?? "",
            to: settings.selection.edge?.to ?? ""
        }],
        sort: ["-timeperiodStartTime"]
    }, {
        disable: chartFrequency === undefined,
    });
    const kpiDefinition = getKpiDefinition(settings.kpi.selectedKpi, {settings, session});
    if (kpiDefinition === undefined)
        return null;

    const propName = getEdgeOverTimeProp(kpiDefinition, settings.kpi.statistic);
    const edgeChartData = useLineGraphData(edgeData, propName);

    return <StatsLineGraph
        spotlightId={buildKpiSpotlightId(kpiDefinition.id, ["Edge", "OverTime"])}
        frequency={chartFrequency}
        setFrequency={setChartFrequency}
        isLoading={isEdgeDataLoading}
        data={edgeChartData} />;
}

function getEdgeOverTimeProp(kpiDefinition: KpiDefinition | undefined, statistic: StatisticTypes) {
    if (kpiDefinition === undefined)
        return "";
    if (isObject(kpiDefinition.edgeOverTimeStatisticsPath))
        return get(kpiDefinition.edgeOverTimeStatisticsPath, statistic) ?? "";
    return kpiDefinition.edgeOverTimeStatisticsPath ?? "";
}
