import React, { useContext } from "react";
import { SettingsContext, emptyRcaState, getRecentRcaByType } from "../../contexts/SettingsContext";
import { LegacyAnalyzedValues } from "../../contexts/ContextTypes";
import { SessionContext, isOniqEmployee } from "../../contexts/SessionContext";
import { QuantityType, getAssignedQuantities, isBaseQuantityType } from "../../utils/Quantities";
import { viewSettingsInitialization } from "../../utils/Initializers";
import { BreadcrumbsType, TabbedView, TabbedViewType } from "../../components/tabbed-view/TabbedView";
import { getRcaSelectionType } from "./RcaUtils";
import { RcaType } from "../../contexts/ContextTypes";
import RootCauseAnalysis from "./RootCauseAnalysis";
import { isRcaResultsPage, isRcaVisible } from "./RootCauseView";
import i18n from "../../i18n";
import { isProductFilter } from "../../utils/FilterBuilder";
import { ALL_PRODUCTS } from "../../components/product-dropdown/ProductDropdown";

export default function BottleneckView() {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const rcaBaseQuantities = getAssignedQuantities(session.project?.eventKeys, QuantityType.Yield, false).map(q => q.id).filter(isBaseQuantityType);

    const isOniqUser = isOniqEmployee(session);

    const rcaPages: TabbedViewType[] = [{
        isVisible: isRcaVisible(RcaType.Bottleneck, session, isOniqUser),
        disableSharing: isRcaResultsPage(RcaType.Bottleneck, settings),
        tabTitle: "common.bottleneck",
        tabSlug: "bottleneck",
        spotlightId: "Bottleneck-RCA",
        activator: (preferences) => {
            settings.set(viewSettingsInitialization(session, settings, preferences, {
                analyses: [LegacyAnalyzedValues.OutputRate],
                caseBaseQuantities: getAssignedQuantities(session.project?.eventKeys, QuantityType.CaseYield, false).map(q => q.baseQuantity),
                quantities: getAssignedQuantities(session.project?.eventKeys, QuantityType.Yield, false).map(q => q.baseQuantity),
            }));
        },
        selectionTrigger: (settings) => {
            return (settings.selection.node ||
                settings.selection.edge ||
                settings.selection.machine ||
                settings.selection.feature ||
                settings.selection.product ||
                settings.selection.category ||
                settings.selection.categoryValue) !== undefined;
        },
        statsSpotlightId: getRecentRcaByType(RcaType.Bottleneck, settings)?.status !== "finished" ? undefined : "Output-Variance" + (getRcaSelectionType(settings.selection) ? "-RCA-Statistics-Selection" : "-RCA-Statistics-NoSelection"),
        stats: undefined,
        controller: undefined,
        content: <>
            <RootCauseAnalysis
                type={RcaType.Bottleneck}
                title={"rca.titleBottleneckRca"}
                baseQuantities={rcaBaseQuantities}
                analyzedValue={LegacyAnalyzedValues.OutputRate}
            />
        </>,
    }];

    const isGroupedByMachine = settings.rca.product === ALL_PRODUCTS;

    const breadcrumbs: BreadcrumbsType[] = [{
        label: "common.analyses",
        url: `/projects/${session.projectId}/analyses`,
    }, {
        label: "common.bottleneck",
    }];

    // When we're showing results, add the results breadcrumb.
    if (isRcaResultsPage(RcaType.Bottleneck, settings)) {
        const rcas = settings.rcaStates[RcaType.Bottleneck];

        // Add "back" functionality to the last breadcrumb.
        breadcrumbs[breadcrumbs.length - 1].state = {
            ...settings,
            filters: rcas?.[0].rcaFilters,
            rcaStates: {
                ...settings.rcaStates,
                [RcaType.Bottleneck]: [rcas !== undefined && rcas.length > 0 ? {
                    ...rcas[0],
                    showResults: false,
                } : emptyRcaState],
            },
            history: [],
        };

        // if we have a drilldown, add a breadcrumb for it and add reset state
        const rca = getRecentRcaByType(RcaType.Bottleneck, settings);
        if (rca.drilldown) {
            breadcrumbs.push({ label: "common.bottleneckIndicators" });
            breadcrumbs[breadcrumbs.length - 1].state = {
                ...settings,
                filters: rcas?.[0].rcaFilters,
                rcaStates: {
                    ...settings.rcaStates,
                    [RcaType.Bottleneck]: rcas !== undefined && rcas.length > 1 ? [rcas[0]] : rcas!,
                },
                history: [],
            };
        }
    }

    const rca = getRecentRcaByType(RcaType.Bottleneck, settings);

    const productName = (rca.result?.request.eventsParams.eventlogs.actual.eventFilters ?? []).map(f => isProductFilter(f, false, session.project?.eventKeys)).find(p => p !== undefined);

    return <TabbedView
        subtitle={<div className="text">{isRcaResultsPage(RcaType.Bottleneck, settings) ? isGroupedByMachine ? i18n.t("common.allProducts") : `${i18n.t("common.product")} ${productName}`: i18n.t("common.productSelection").toString()}</div>}
        breadcrumbs={breadcrumbs}
        pages={rcaPages}
    />;
}
