import { Auth0ContextInterface, useAuth0, User } from "@auth0/auth0-react";
import axios from "axios";
import React, { useContext, useImperativeHandle, useRef, useState } from "react";
import Modal, { IModal, ModalProps } from "../../components/modal/Modal";
import { SessionContext } from "../../contexts/SessionContext";
import Global from "../../Global";
import i18n from "../../i18n";

export const ChangePasswordModal = React.forwardRef((props: Partial<ModalProps> & {
    storybookAuth?: Partial<Auth0ContextInterface<User>>,
}, ref: React.Ref<IModal>) => {
    const session = useContext(SessionContext);
    const auth0 = props.storybookAuth ?? useAuth0();
    const [lastPasswortChangeRequest, setLastPasswortChangeRequest] = useState<number | undefined>(undefined);
    const isLastPasswordResetEmailValid = !!lastPasswortChangeRequest && (new Date().getTime() - lastPasswortChangeRequest) < (1000 * 10 * 60);

    const changePasswordModalRef = useRef<IModal>(null);
    useImperativeHandle(ref, () => ({
        show() {
            changePasswordModalRef.current?.show();
        },
        hide() {
            changePasswordModalRef.current?.hide();
        }
    })); 
    
    const isResetOkay = !isLastPasswordResetEmailValid && !!isUsernamePasswordAuthenticated();
    const isResendingOkay = isLastPasswordResetEmailValid && !!isUsernamePasswordAuthenticated();

    const hasTwoButtons = isResetOkay || isResendingOkay;

    return <Modal
        {...props}
        isClosable={true}
        bottomClassName={hasTwoButtons ? "bottomMultiButton" : undefined}
        ref={changePasswordModalRef}
        defaultButtonLabel={!isUsernamePasswordAuthenticated() ? "common.OK" : "common.cancel"}
        bottom={
            isResetOkay ? <button
                className="shortcutButton"
                onClick={() => {
                    setLastPasswortChangeRequest(new Date().getTime());
                    requestPasswordResetEmail();
                }}>
                {i18n.t("profile.requestPasswordResetEmail")}
            </button> : isResendingOkay ? 
                <button
                    className="shortcutButton"
                    onClick={() => {
                        setLastPasswortChangeRequest(new Date().getTime());
                        requestPasswordResetEmail();
                    }}>
                    {i18n.t("profile.requestPasswordResetEmailAgain")}
                </button> : []
        }
        title="profile.changePassword">
        <>
            {isResetOkay && <div className="flexColumn mt mb">
                <div dangerouslySetInnerHTML={{ __html: i18n.t("profile.updateAuth0Password", { email: session.user?.email }) }}></div>
            </div>}

            {isResendingOkay && <div className="flexColumn mt mb">
                <div dangerouslySetInnerHTML={{ __html: i18n.t("profile.requestedPasswordResetEmail", { email: session.user?.email }) }}></div>
            </div>}

            {!isUsernamePasswordAuthenticated() && <div dangerouslySetInnerHTML={{ __html: i18n.t("profile.updateGooglePassword") }}></div>}
        </>
    </Modal>;

    function isUsernamePasswordAuthenticated() {
        const sub = auth0.user?.sub;
        const isPasswordAuthenticated = !(sub?.startsWith("google-oauth"));

        return isPasswordAuthenticated;
    }

    async function requestPasswordResetEmail() {
        await axios.post(`https://${Global.auth0.domain}/dbconnections/change_password`, {
            client_id: Global.auth0.clientId,
            email: session.user?.email,
            connection: "Username-Password-Authentication"
        }, {
            headers: {
                "Content-Type": "application/json",
            }
        });
    }
});